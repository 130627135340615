import _ from 'lodash'
export async function validateFields(fields, formik, yupValidation) {
  const { values, setErrors } = formik
  const validation = await yupValidation.validate(values, { abortEarly: false }).catch(error => error)
  const errors = _.get(validation, 'inner', []).filter(item => fields.find(item2 => item2 === item.path))
  const formatedErrors = {}
  errors.forEach(({ path, message }) => {
    formatedErrors[path] = message
  })
  setErrors(formatedErrors)
  return errors
}
