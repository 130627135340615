import React, { Component } from 'react'
import { connect } from 'react-redux'
import { IntlProvider } from 'react-intl'
import { Redirect, Route } from 'react-router-dom'

import URLSearchParams from 'url-search-params'
import { ConfigProvider } from 'antd'
import AppLocale from 'lngProvider'
import { onLayoutTypeChange, onNavStyleChange, setThemeType } from 'appRedux/actions/Setting'

import MainApp from './MainApp'
import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK
} from '../../constants/ThemeSetting'

// const { intl } = new IntlProvider({ locale: language, messages: messages }, {}).getChildContext();

class App extends Component {
  setLayoutType = layoutType => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout')
      document.body.classList.remove('framed-layout')
      document.body.classList.add('full-layout')
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout')
      document.body.classList.remove('framed-layout')
      document.body.classList.add('boxed-layout')
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout')
      document.body.classList.remove('full-layout')
      document.body.classList.add('framed-layout')
    }
  }

  setNavStyle = navStyle => {
    if (
      navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER
    ) {
      document.body.classList.add('full-scroll')
      document.body.classList.add('horizontal-layout')
    } else {
      document.body.classList.remove('full-scroll')
      document.body.classList.remove('horizontal-layout')
    }
  }

  // eslint-disable-next-line react/no-deprecated
  UNSAFE_componentWillMount() {
    const params = new URLSearchParams(this.props.location.search)

    if (params.has('theme')) {
      this.props.setThemeType(params.get('theme'))
    }
    if (params.has('nav-style')) {
      this.props.onNavStyleChange(params.get('nav-style'))
    }
    if (params.has('layout-type')) {
      this.props.onLayoutTypeChange(params.get('layout-type'))
    }
  }

  render() {
    const { match, location, themeType, layoutType, navStyle, locale } = this.props
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add('dark-theme')
    }
    if (location.pathname === '/') {
      return <Redirect to="/dashboard" />
    }

    this.setLayoutType(layoutType)

    this.setNavStyle(navStyle)

    const currentAppLocale = AppLocale[locale.locale]
    return (
      <ConfigProvider locale={currentAppLocale.antd}>
        <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
          <Route path={`${match.url}`} component={MainApp} />
        </IntlProvider>
      </ConfigProvider>
    )
  }
}

const mapStateToProps = ({ settings }) => {
  const { locale, navStyle, themeType, layoutType } = settings
  return { locale, navStyle, themeType, layoutType }
}
export default connect(mapStateToProps, { setThemeType, onNavStyleChange, onLayoutTypeChange })(App)
