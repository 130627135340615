import moment from 'moment-timezone'
import { getMessage } from './IntlMessages'

export const getProvider = (key, dataFilters) => {
  return key ? dataFilters['formated' + key[0].toUpperCase() + key.slice(1)] : []
}

export function getFormatedFilterLabel(item) {
  const { filters } = item
  let formatedResult = ''

  if (Array.isArray(filters)) {
    for (let i = 0; i < filters.length; i++) {
      const isTheLast = i === filters.length - 1
      const hasOther = i < filters.length - 1
      if (isTheLast || !hasOther) {
        formatedResult += `${filters[i]}.`
      } else {
        formatedResult += `${filters[i]}, `
      }
    }
    if (filters.length === 1) {
      formatedResult = filters[0]
    }
  } else if (moment.isMoment(filters)) {
    formatedResult = moment(filters).format('DD/MM/YYYY')
  } else {
    formatedResult = filters
  }

  return `${getMessage(item.identifier)}: ${formatedResult}`
}

export function generateFilters(values, setFilterValues, dataFilters) {
  const filterList = []
  for (var key in values) {
    if (values[key]) {
      const provider = getProvider(key, dataFilters)

      if (provider) {
        if (Array.isArray(values[key]) && values[key].length > 0) {
          const finalList = values[key].map(item => {
            const selectedItem = provider.find(item2 => item === item2.value)
            return selectedItem.description
          })
          if (finalList) {
            filterList.push({
              identifier: key,
              filters: finalList
            })
          }
        } else if (!Array.isArray(values[key])) {
          const finalResult = provider.find(item => item.value === values[key]) // eslint-disable-line

          if (finalResult) {
            filterList.push({
              identifier: key,
              filters: [finalResult.description]
            })
          }
        }
      } else {
        filterList.push({
          identifier: key,
          filters: values[key]
        })
      }
    }
  }

  setFilterValues(filterList)
}
