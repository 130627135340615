import React from 'react'
import { getMessage } from '../../util/IntlMessages'
import { Form, Modal, Button, Row, Col } from 'antd'
import './styles.css'

function ModalNewClient({ visible, children, handleReset, handleSubmit, handleCancel, loading }) {
  return (
    <div>
      <Modal visible={visible} closable footer={null} onCancel={handleCancel}>
        <Form layout="vertical">
          {children}
          <Row justify="end">
            <Col xs={24} sm={24} md={12} lg={12} xl={12} className={'mr5 col-btns'}>
              <Button type="primary" className={'mr5 button-cancel'} onClick={() => handleReset()}>
                {getMessage('clear')}
              </Button>
              <Button type="primary" loading={loading} onClick={() => handleSubmit()}>
                {getMessage('save')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  )
}

export default ModalNewClient
