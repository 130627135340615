export const COLUMNS = (isSale = true) => {
  return {
    ...(isSale && {
      ID: {
        title: 'id',
        align: 'center'
      }
    }),
    ...(!isSale && {
      OS_CODE: {
        title: 'osCode',
        align: 'center'
      }
    }),
    STORE_BRANCH: {
      title: 'storeBranch',
      align: 'center'
    },
    CUSTOMER: {
      title: 'customer',
      align: 'center'
    },
    TAGS: {
      title: 'tags',
      align: 'center'
    },
    STATUS: {
      title: 'status',
      align: 'center'
    },
    EMPLOYEE: {
      title: 'employee',
      align: 'center'
    },
    ORDER_TOTAL: {
      title: 'orderTotal',
      align: 'center'
    },
    ORDER_DATE: {
      title: 'orderDate',
      align: 'center'
    },
    ...(isSale && {
      NF_DANFE: {
        title: 'nf',
        align: 'center'
      }
    }),
    ...(!isSale && {
      DELIVERY_DATE: {
        title: 'deliveryDate',
        align: 'center'
      }
    })
  }
}

export const PRESCRIPTION_HMTL_TABLE = `<p><span style="font-size: 18px;"><span style="font-family: Arial;"><strong>Receita</strong></span></span></p><table><thead><tr><th><div><br></div></th><th><div>Esférico</div></th><th><div>Cilíndrico</div></th><th><div>Eixo</div></th><th><div>DNP</div></th><th><div>ALT</div></th></tr></thead><tbody><tr><td><div><br></div></td><td><div><br></div></td><td><div><br></div></td><td><h4>Longe</h4></td><td><div><br></div></td><td><div><br></div></td></tr><tr><td><div>OD</div></td><td><div></div></td><td><div></div></td><td><div></div></td><td><div></div></td><td><div><br></div></td></tr><tr><td><div>OE</div></td><td><div></div></td><td><div></div></td><td><div></div></td><td><div></div></td><td><div><br></div></td></tr><tr><td><div><br></div></td><td><div><br></div></td><td><div><br></div></td><td><h4>Perto</h4></td><td><div><br></div></td><td><div><br></div></td></tr><tr><td><div>OD</div></td><td><div><br></div></td><td><div><br></div></td><td><div><br></div></td><td><div> Adição</div></td><td><div><br></div></td></tr><tr><td><div>OE</div></td><td><div><br></div></td><td><div><br></div></td><td><div><br></div></td></tr></tbody></table><p><br></p>`
