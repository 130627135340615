import React from 'react'
import { Button, Row, Col, Card, Form } from 'antd'
import { getMessage } from '../../util/IntlMessages'
import queryString from 'query-string'
import { FormikProvider } from 'formik'
import './styles.css'

function ListScreen(props) {
  const {
    onClickBack,
    editTitleButton,
    editTitle,
    newTitle,
    newTitleButton,
    readOnlyTitle,
    readOnlyTitleButton,
    fields,
    context,
    isEdit = false
  } = props
  const isReadOnly = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true
  }).readOnly
  function getTitle() {
    if (isReadOnly) {
      return readOnlyTitle
    }
    if (isEdit) {
      return editTitle
    }

    return newTitle
  }
  function getButtonTitle() {
    if (isReadOnly) {
      return readOnlyTitleButton
    }
    if (isEdit) {
      return editTitleButton
    }

    return newTitleButton
  }

  return (
    <FormikProvider value={context}>
      <div className="container">
        <Row>
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <div className="header">
              <h1>{getTitle()}</h1>
              <div>
                <Button className="mr5" onClick={() => onClickBack()}>
                  <span>{getMessage('back')}</span>
                </Button>
                {!isReadOnly && (
                  <Button type="primary" className="btn_new" onClick={() => context.submitForm()}>
                    <span>{getButtonTitle()}</span>
                  </Button>
                )}
              </div>
            </div>
            <Card>
              <Form layout={'vertical'} initialValues={{ layout: 'vertical' }}>
                {fields(isReadOnly)}
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    </FormikProvider>
  )
}

export default ListScreen
