import React, { useState, useEffect } from 'react'
import { Row } from 'antd'
import { useFormik } from 'formik'

import CreateScreen from '../../components/CreateScreen'
import CustomInput from '../../components/CustomInput/customInput'
import queryString from 'query-string'
import { api, request } from '../../util/handlerRequestUtil'
import './createAttribute.css'
import * as yup from 'yup'

function CreateAttribute(props) {
  const { id } = props.match.params
  const { alert, loading, getMessage } = props
  const isDuplicate = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true
  }).duplicate
  const formik = useFormik({
    initialValues: {},
    validationSchema: yup.object().shape({
      name: yup
        .string()
        .typeError()
        .required(getMessage('isRequired', getMessage('name')))
    }),
    onSubmit: values => {
      let formatedValues = { ...values }

      onSubmit(formatedValues)
    }
  })
  const { setValues } = formik
  const [setFilters] = useState({})

  useEffect(() => {
    requests()
  }, [])

  async function requests() {
    try {
      loading.open()
      if (id) {
        await retrieve(id)
      }
      if (isDuplicate) {
        await retrieve(isDuplicate, true)
      }
      setFilters({})
    } catch (error) {
      console.tron.log(error)
    } finally {
      loading.close()
    }
  }

  function fields(isReadOnly) {
    return (
      <Row type="flex" justify="start">
        <CustomInput disabled={isReadOnly} field="name" rowSpan={12} context={formik} />
      </Row>
    )
  }

  async function onSubmit(values) {
    try {
      const queryParams = queryString.parse(window.location.search, {
        ignoreQueryPrefix: true
      })
      console.tron.log('queryParams', queryParams)
      if (id) {
        await request(
          api.updateAttribute(id, {
            ...values,
            ...queryParams
          })
        )
        alert.open(getMessage('successUpdateAttribute'), 'success')
      } else {
        await request(
          api.storeAttribute({
            ...values,
            ...queryParams
          })
        )
        alert.open(getMessage('successStoreAttribute'), 'success')
      }

      props.history.push(`/Attribute/list`)
    } catch (error) {}
  }

  async function retrieve(id, isDuplicate) {
    try {
      const response = await request(api.retrieveAttribute(id), true)
      if (isDuplicate) {
        delete response.id
      }
      setValues({
        ...response
      })
    } catch (error) {}
  }

  function onClickBack() {
    props.history.push('/Attribute/list')
  }

  return (
    <CreateScreen
      fields={fields}
      context={formik}
      editTitle={getMessage('updateAttribute')}
      editTitleButton={getMessage('edit')}
      newTitle={getMessage('newAttribute')}
      newTitleButton={getMessage('register')}
      onClickBack={() => onClickBack()}
      readOnlyTitle={getMessage('viewAttribute')}
      readOnlyTitleButton={getMessage('edit')}
      isEdit={id}
    />
  )
}

export default CreateAttribute
