import React from 'react'
import '@ant-design/compatible/assets/index.css'
import './styles.css'
import DashboardOrders from './dashboardOrders'

const Home = function(props) {
  const { getMessage, alert } = props
  return (
    <>
      <DashboardOrders getMessage={getMessage} alert={alert}></DashboardOrders>
    </>
  )
}

export default Home
