import React, { useState, useEffect } from 'react'
import { Button } from 'antd'
import { CustomPopover } from '../../components'
import { api, request } from '../../util/handlerRequestUtil'
import { getMessage } from '../../util/IntlMessages'
import { isEmpty } from 'lodash'

import { WOO_PLATFORM_ID } from './Constants/Variables'

import ConfigModal from './Components/ConfigModal/ConfigModal'
import SyncModal from './Components/SyncModal/SyncModal'

function Platforms({ alert }) {
  const [configModalVisible, setConfigModalVisible] = useState(false)
  const [syncModalVisible, setSyncModalVisible] = useState(false)
  const [wooData, setWooData] = useState({})

  const renderActions = () => {
    return (
      <>
        <Button className="btn__in_popover" type="link" onClick={() => setConfigModalVisible(true)}>
          {getMessage('config')}
        </Button>
        {!isEmpty(wooData) && (
          <Button className="btn__in_popover" type="link" onClick={() => setSyncModalVisible(true)}>
            {getMessage('sync')}
          </Button>
        )}
      </>
    )
  }

  useEffect(() => {
    async function initialRequest() {
      const platforms = await request(api.listPlatformConfig())

      if (platforms.items) {
        const wooData = platforms.items.find(p => p.platformId === WOO_PLATFORM_ID)

        setWooData(wooData)
      }
    }
    initialRequest()
  }, [])

  return (
    <>
      <CustomPopover content={renderActions} placement="bottom">
        <Button type="primary">WooCommerce</Button>
      </CustomPopover>

      <ConfigModal values={wooData} visible={configModalVisible} setVisible={setConfigModalVisible} alert={alert} />
      <SyncModal visible={syncModalVisible} setVisible={setSyncModalVisible} alert={alert} />
    </>
  )
}

export default Platforms
