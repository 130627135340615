import * as yup from 'yup'
import { getMessage } from './IntlMessages'

const types = {
  inputText: 'inputText'
}

let debounceRef = 0

export function debounceCall(fn, time) {
  clearTimeout(debounceRef)
  debounceRef = setTimeout(fn, time)
}

export function checkFieldRules(field, row, values = {}) {
  const { deps = [] } = field
  let renderField = false
  const hasDeep = deps.length > 0
  if (hasDeep) {
    renderField = deps.every(({ answer, fieldName }) => answer === values[fieldName])
  }
  return field.row === row && !field.hidden && (hasDeep ? renderField : true)
}

export function sortFields(fields) {
  return fields.sort((field1, field2) => field1.position - field2.position)
}

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index
}

export function generateYupValidation(data) {
  let yupObject = {}
  data.forEach(container => {
    container.fields.forEach(field => {
      yupObject[field.name] = generateYupProp(field)
    })
  })
  return yupObject
}

function generateYupProp(field) {
  const { fieldType, required, label } = field
  switch (fieldType) {
    case types.inputText:
      return required
        ? yup
            .string()
            .nullable()
            .required(getMessage('isRequired', label))
        : yup.string()
    default:
      return null
  }
}
