import React, { useState, useEffect } from 'react'
import { If } from 'react-extras'

import { Tag, Row } from 'antd'
import { useFormik } from 'formik'
import _ from 'lodash'

import NumberFormat from '../../components/NumberFormart'
import ListScreen from '../../components/ListScreen'
import { CustomSelect } from '../../components/index'
import { api, request } from '../../util/handlerRequestUtil'
import * as moment from 'moment-timezone'
import './styles.css'

function Products(props) {
  const { alert, getMessage, loading } = props
  const formik = useFormik({
    initialValues: {}
  })
  const [modalDelete, setModalDelete] = useState({
    description: getMessage('defaultDeleteDescription'),
    visible: false
  })
  const { values } = formik
  const [data, setData] = useState({})
  const { page = 1, pageSize = 10, count = 0, pages = 0 } = data
  const products = _.get(data, 'items', []).map(item => {
    return {
      key: item.id,
      sku: item.sku,
      name: item.name,
      categories: item.categories.map(item => item.name),
      stock: item.manageStock ? item.variations.reduce((total, curr) => curr.stockQuantity + total, 0) : '-',
      price: item.variations.map(item => (item.price ? item.price : 0)),
      promotionalPrice: item.variations.map(item => (item.salePrice ? item.salePrice : 0)),
      status: item.enable ? getMessage('active') : getMessage('inactive'),
      dateUpdated: moment(item.updated_at).format('DD/MM/YYYY')
    }
  })
  const columns = [
    {
      title: getMessage('sku'),
      dataIndex: 'sku',
      key: 'sku',
      sortDirections: ['descend']
    },
    {
      title: getMessage('name'),
      dataIndex: 'name',
      key: 'name',
      sortDirections: ['descend']
    },
    {
      title: getMessage('category'),
      dataIndex: 'categories',
      responsive: ['lg'],
      key: 'categories',
      render: categories => {
        return getFormatedCategories(categories)
      }
    },
    {
      title: getMessage('stock'),
      dataIndex: 'stock',
      key: 'stock',
      responsive: ['lg'],
      align: 'center'
    },
    {
      title: getMessage('price'),
      dataIndex: 'price',
      key: 'price',

      render: value => {
        let minValue = Math.min.apply(Math, value)
        let maxValue = Math.max.apply(Math, value)
        return {
          children: formatedPrice(minValue, maxValue)
        }
      }
    },
    {
      title: getMessage('promotionPrice'),
      dataIndex: 'promotionalPrice',
      responsive: ['lg'],
      key: 'promotionalPrice',
      render: value => {
        let minValue = Math.min.apply(Math, value)
        let maxValue = Math.max.apply(Math, value)
        return formatedPrice(minValue, maxValue)
      }
    },
    {
      title: getMessage('dateUpdated'),
      responsive: ['lg'],
      dataIndex: 'dateUpdated',
      key: 'dateUpdated',
      sortDirections: ['descend']
    }
  ]
  const formatedPrice = (minValue, maxValue) => {
    return (
      <>
        <If condition={minValue === maxValue}>
          <NumberFormat prefix="R$ " displayType={'text'} value={maxValue} />
        </If>
        <If condition={minValue !== maxValue}>
          <NumberFormat prefix="R$ " displayType={'text'} value={minValue} />
          <span> - </span>
          <NumberFormat prefix="R$ " displayType={'text'} value={maxValue} />
        </If>
      </>
    )
  }

  const getFormatedCategories = (categories = []) => {
    return (
      <span>
        <If condition={categories.length > 0}>
          {categories.map(tag => {
            return (
              <Tag color="blue" key={tag}>
                {tag}
              </Tag>
            )
          })}
        </If>
        <If condition={categories.length === 0}>{getMessage('n/a')}</If>
      </span>
    )
  }

  const [filters, setFilters] = useState({
    formatedStockStatus: [
      {
        value: 'INSTOCK',
        description: getMessage('inStock')
      },
      {
        value: 'OUTSTOCK',
        description: getMessage('outStock')
      }
    ],
    formatedVisibility: [
      {
        value: 'publish',
        description: getMessage('publish')
      },
      {
        value: 'private',
        description: getMessage('private')
      }
    ]
  })

  useEffect(() => {
    requests()
  }, [])

  //requests
  async function requests() {
    try {
      loading.open()
      const category = await requestCategory()
      const brand = await requestBrand()
      const platform = await requestPlatform()

      await requestDataProducts({}, true)

      setFilters({
        ...filters,
        ...category,
        ...brand,
        ...platform
      })
    } catch (error) {
      console.tron.log(error)
    } finally {
      loading.close()
    }
  }

  function onClickEdit(product) {
    console.tron.log('onClickEdit', product)
    props.history.push(`/products/${product.key}`)
  }

  async function onClickDelete(product) {
    setModalDelete({
      ...modalDelete,
      title: getMessage('deleteProductTitle', product.name),
      visible: true,
      onOk: async () => onConfirmDelete(product)
    })
  }

  async function onConfirmDelete(product) {
    alert.open(getMessage('successProductDelete'), 'success')
    await request(api.deleteProduct(product.key))
    setModalDelete({
      ...modalDelete,
      visible: false
    })
    await requestDataProducts(values)
  }

  const requestDataProducts = (params = {}, skipLoading = false) =>
    request(api.listProduct({ include: 'category,variations', ...params }), {
      skipLoading: skipLoading
    }).then(data => setData(data))

  const requestCategory = () =>
    request(api.listCategory({ ignorePagination: true }), {
      skipLoading: true
    }).then(({ items }) => {
      return {
        formatedCategory: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name
          }
        })
      }
    })

  const requestBrand = () =>
    request(api.listBrand({ ignorePagination: true }), {
      skipLoading: true
    }).then(({ items }) => {
      return {
        formatedBrand: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name
          }
        })
      }
    })

  const requestPlatform = () =>
    request(api.listPlatform({ ignorePagination: true }), {
      skipLoading: true
    }).then(({ items }) => {
      return {
        formatedPlatform: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name
          }
        })
      }
    })

  //functions
  function handleFilter(value) {
    const { category, platform, actionsStatus, brand, stockStatus, sku, visibility } = values

    const filters = {
      ...(category && { category }),
      ...(platform && { platform }),
      ...(actionsStatus && { actionsStatus }),
      ...(brand && { brand }),
      ...(value && value.trim() !== '' && { nameOrSku: value }),
      ...(stockStatus && { stockStatus }),
      ...(sku && { nameOrSku: sku }),
      ...(visibility && { status: visibility })
    }

    requestDataProducts({
      ...filters
    })
  }

  const onSearch = value => handleFilter(value)

  async function requestPage(page) {
    console.tron.log('page', page)
    await requestDataProducts({ page: page - 1 })
  }

  const onChangeCreateButton = () => {
    props.history.push('/products/create')
  }

  function onClickView(product) {
    props.history.push(`/products/${product.key}?readOnly=true`)
  }

  function onClickDuplicate(product) {
    props.history.push(`/products/create?duplicate=${product.key}`)
  }

  function onClickOpenOthertab(product) {
    const win = window.open(`/products/${product.key}`)
    win.focus()
  }

  function filtersList() {
    return (
      <Row type="flex" justify="start">
        <CustomSelect
          context={formik}
          showSearch
          field="category"
          rowSpan={12}
          values={filters.formatedCategory}
          multiple
        />
        <CustomSelect
          context={formik}
          showSearch
          field="platform"
          rowSpan={12}
          values={filters.formatedPlatform}
          multiple
        />
        <CustomSelect context={formik} showSearch field="brand" rowSpan={12} values={filters.formatedBrand} multiple />
        <CustomSelect
          context={formik}
          showSearch
          field="stockStatus"
          rowSpan={12}
          values={filters.formatedStockStatus}
        />
        <CustomSelect
          context={formik}
          showSearch
          field="visibility"
          rowSpan={12}
          values={filters.formatedVisibility}
        />
      </Row>
    )
  }

  const validFieldsKeys = ['stockStatus', 'brand', 'sku', 'platform', 'category', 'visibility']

  return (
    <ListScreen
      context={formik}
      columns={columns}
      data={products}
      title={getMessage('products')}
      titleButtonAdd={getMessage('newProduct')}
      onSearch={onSearch}
      filtersList={filtersList}
      searchPlaceholder={'nome ou sku'}
      validFieldsKeys={validFieldsKeys}
      dataFilters={filters}
      onChange={requestPage}
      onChangeCreateButton={onChangeCreateButton}
      pagination={{
        currentPage: page + 1,
        pages: pages,
        total: count,
        pageSize: pageSize
      }}
      onFilter={handleFilter}
      onClickDelete={onClickDelete}
      modalDelete={modalDelete}
      setModalDelete={setModalDelete}
      onClickView={onClickView}
      onClickEdit={onClickEdit}
      onClickDuplicate={onClickDuplicate}
      onClickOpenOthertab={onClickOpenOthertab}
    />
  )
}

export default Products
