import React from 'react'

import MaskedInput from 'antd-mask-input'
import ptBR from 'antd/es/locale/pt_BR'

import 'moment/locale/pt-br'
import moment from 'moment-timezone'
import { Choose } from 'react-extras'

import { Icon as LegacyIcon } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'

import { Col, ConfigProvider, Popover } from 'antd'
import _ from 'lodash'
import NumberFormat from 'react-number-format'
import { getMessage } from '../../util/IntlMessages'
import { Input, DatePicker, TimePicker, Form } from 'formik-antd'
function changeInput(form, field, event, onChangeWithField, customFormId) {
  console.tron.log('changeInput', event.target.value)
  const { setFieldValue } = form
  setFieldValue(field, event.target.value, customFormId)
  if (onChangeWithField) {
    onChangeWithField(field, event.target.value, customFormId)
  }
}

function renderFormItem(label, props, field, children) {
  const { errors } = props

  const customLabel = (
    <>
      {label}
      {props.required && (
        <span style={{ marginLeft: 5 }} className="required">
          *
        </span>
      )}
    </>
  )

  return (
    <Form.Item
      label={customLabel}
      validateStatus={_.get(errors, field) ? 'error' : 'validating'}
      help={_.get(errors, field)}
    >
      {children}
    </Form.Item>
  )
}

function renderDatePicker(labelName, props) {
  const { name = '', formikPrefix, rangePicker } = props
  let renderFieldName = name ? name : props.field
  if (formikPrefix) {
    renderFieldName = `${formikPrefix}.${renderFieldName}`
  }

  const propsDateComponent = {
    autoComplete: 'off',
    disabled: props.disabled || false,
    disabledDate: props.disabledDate,
    value: props.value[renderFieldName],
    defaultPickerValue: props.defaultPickerValue,
    format: props.showTime ? 'DD/MM/YYYY - HH:mm' : 'DD/MM/YYYY',
    suffixIcon: null,
    allowClear: props.allowClear || true
  }

  const date = moment(propsDateComponent.value)

  if (!date.isValid() || !propsDateComponent.value) {
    propsDateComponent.value = null
  } else {
    propsDateComponent.value = moment(propsDateComponent.value)
  }

  const textDatePicker = (
    <ConfigProvider locale={ptBR}>
      {rangePicker ? (
        <DatePicker.RangePicker
          {...propsDateComponent}
          showTime={props.showTime ? { format: 'HH:mm' } : false}
          style={{ width: '100%' }}
          onChange={date => {
            props.form.setFieldValue(renderFieldName, date)
          }}
          getPopupContainer={trigger => trigger.parentNode}
        />
      ) : (
        <DatePicker
          {...propsDateComponent}
          showTime={props.showTime ? { format: 'HH:mm' } : false}
          style={{ width: '100%' }}
          onChange={date => {
            props.form.setFieldValue(renderFieldName, date)
          }}
          getPopupContainer={trigger => trigger.parentNode}
        />
      )}
    </ConfigProvider>
  )
  return renderFormItem(labelName, props, renderFieldName, textDatePicker)
}

function renderTimePicker(labelName, props) {
  const { name = '', formikPrefix } = props
  let renderFieldName = name ? name : props.field
  if (formikPrefix) {
    renderFieldName = `${formikPrefix}.${renderFieldName}`
  }

  const time = moment(_.get(props.value, renderFieldName), 'HH:mm')
  const textTimePicker = (
    <ConfigProvider locale={ptBR}>
      <TimePicker
        format="HH:mm"
        disabled={props.disabled || false}
        style={{ width: '100%' }}
        value={time.isValid() ? time : null}
        suffixIcon={null}
        onChange={date => props.form.setFieldValue(renderFieldName, date)}
      />
    </ConfigProvider>
  )
  return renderFormItem(labelName, props, renderFieldName, textTimePicker)
}

function renderTextArea(labelName, props) {
  const { name = '', customFormId = '', formikPrefix, rows, className } = props
  let renderFieldName = name ? name : props.field
  if (formikPrefix) {
    renderFieldName = `${formikPrefix}.${renderFieldName}`
  }
  const textArea = (
    <Input.TextArea
      fast={true}
      allowClear={props.allowClear || true}
      autoSize={props.autoSize || false}
      prefix={props.prefix}
      placeholder={props.placeholder}
      name={props.value[renderFieldName]}
      value={_.get(props.value, renderFieldName)}
      onBlur={props.handleBlur}
      disabled={props.disabled || false}
      onChange={event => changeInput(props.form, renderFieldName, event, props.onChangeWithField, customFormId)}
      className={className}
      rows={rows}
    />
  )
  return renderFormItem(labelName, props, renderFieldName, textArea)
}

function renderInputText(labelName, props) {
  const { name = '', customFormId = '', formikPrefix, fixedValue } = props
  let renderFieldName = name ? name : props.field
  if (formikPrefix) {
    renderFieldName = `${formikPrefix}.${renderFieldName}`
  }

  let textINput = (
    <Input
      fast={true}
      addonAfter={props.addonAfter}
      addonBefore={props.addonBefore}
      prefix={props.prefix}
      placeholder={props.placeholder}
      type={props.type}
      name={props.value[renderFieldName]}
      value={fixedValue || _.get(props.value, renderFieldName)}
      onBlur={props.handleBlur}
      disabled={props.disabled || false}
      onChange={event => changeInput(props.form, renderFieldName, event, props.onChangeWithField, customFormId)}
    />
  )

  if (props.type === 'number') {
    textINput = (
      <NumberFormat
        name={props.value[renderFieldName]}
        prefix={props.prefix}
        suffix={props.suffix}
        value={_.get(props.value, renderFieldName)}
        customInput={Input}
        format={props.format}
        disabled={props.disabled || false}
        onChange={event => changeInput(props.form, renderFieldName, event, props.onChangeWithField, customFormId)}
        thousandSeparator={props.thousandSeparator || '.'}
        decimalSeparator={props.decimalSeparator || ','}
        decimalScale={props.decimalScale || 2}
        fixedDecimalScale={props.decimalScale || true}
        renderText={props.renderText}
        placeholder={props.placeholder}
      />
    )
  }

  if (props.mask != null && props.mask !== '') {
    textINput = (
      <MaskedInput
        placeholder={props.placeholder}
        type={props.type}
        mask={props.mask}
        name={props.value[renderFieldName]}
        value={props.value[renderFieldName]}
        onBlur={props.handleBlur}
        disabled={props.disabled || false}
        onChange={event => changeInput(props.form, renderFieldName, event, props.onChangeWithField, customFormId)}
      />
    )
  }

  const customLabel = (
    <>
      <span>{labelName}</span>
      <Popover title={props.title} content={props.information}>
        {props.information !== undefined ? <LegacyIcon type={props.icon} /> : ''}
      </Popover>
    </>
  )

  return renderFormItem(customLabel, props, renderFieldName, textINput)
}

function getLabelName({ customNameField, field, suffixLabel }) {
  return `${customNameField ? getMessage(customNameField) : getMessage(field)}${
    suffixLabel ? ' '.concat(suffixLabel) : ''
  }`
}

const CustomInput = function(props) {
  const { rowSpan, type, textArea, field, context, value, label } = props
  const labelName = label || getLabelName(props)
  const { values, errors, handleBlur } = context
  const formatedProps = {
    ...props,
    form: context,
    value: values,
    handleBlur: handleBlur[field],
    errors,
    fixedValue: value
  }

  return (
    <Col xs={24} sm={24} md={rowSpan} lg={rowSpan} xl={rowSpan}>
      <Choose>
        <Choose.When condition={type === 'textarea' && textArea != null}>
          {renderTextArea(labelName, formatedProps)}
        </Choose.When>
        <Choose.Otherwise>
          <Choose>
            <Choose.When condition={type === 'date'}>{renderDatePicker(labelName, formatedProps)}</Choose.When>
            <Choose.When condition={type === 'hour'}>{renderTimePicker(labelName, formatedProps)}</Choose.When>
            <Choose.Otherwise>{renderInputText(labelName, formatedProps)}</Choose.Otherwise>
          </Choose>
        </Choose.Otherwise>
      </Choose>
    </Col>
  )
}

export default CustomInput
