import React from 'react'
import { Tag } from 'antd'

const CustomTags = ({ tags = [] }) => {
  return (
    <>
      {tags.length > 0 ? (
        tags.map(({ id, name, color }) => (
          <Tag color={color || 'blue'} key={id}>
            {name}
          </Tag>
        ))
      ) : (
        <span>-</span>
      )}
    </>
  )
}

export default CustomTags
