import React from 'react'

import { CustomIcon } from '../../../components'
import { logos, status } from '../utils/constants'
import { cid } from '../../../util/util'

import woo from '../../../assets/icons/woo.svg'

import styles from './SyncItems.module.css'
import { getMessage } from '../../../util/IntlMessages'

const SyncItems = ({ items = [] }) => {
  const path = {
    [logos.WOO]: woo
  }

  const statusIcon = {
    [status.SUCCESS]: { icon: 'faCheckCircle', color: 'green' },
    [status.ERROR]: { icon: 'faExclamationTriangle', color: 'red' },
    [status.PENDING]: { icon: 'faSpinner', spin: true }
  }

  function renderItem({ name, platform, status }) {
    return (
      <>
        <span className={styles.name}>{`${getMessage('name')}: ${name}`}</span>
        <span className={styles.platform}>
          {`${getMessage('platform')}: `} {path[platform] ? <img src={path[platform]} alt={platform} /> : 'N/A'}
        </span>
        <div className={styles.status}>
          <span>{`${getMessage('status')}: ${getMessage(`status.${status}`)} `}</span>
          <CustomIcon name={statusIcon[status].icon} color={statusIcon[status].color} spin={statusIcon[status].spin} />
        </div>
      </>
    )
  }

  return (
    <>
      {items.map(item => (
        <div key={cid()} className={styles.item}>
          {renderItem(item)}
        </div>
      ))}
    </>
  )
}

export default SyncItems
