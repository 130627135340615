import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Creators as AlertActions } from '../../../../appRedux/ducks/alert'
import { Form, Row, Col, Button, Divider } from 'antd'
import { useFormik } from 'formik'
import { If } from 'react-extras'
import { get } from 'lodash'
import { getMessage } from '../../../../util/IntlMessages'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { api, request } from '../../../../util/handlerRequestUtil'
import { formatAddress, maskCpfCnpj, onlyNumbers } from '../../../../util/format'
import { CustomInput, CustomSelect } from '../../../../components'
import * as icons from '@fortawesome/free-solid-svg-icons'
import * as yup from 'yup'

import './customerPreview.css'

const CustomerPreview = function({ customerSelected = {}, isReadOnly }) {
  const dispatch = useDispatch()
  const [editabled, setEditabled] = useState(false)
  const [customerSelectedId, setCustomerSelectedId] = useState(undefined)
  const [filters, setFilters] = useState({
    states: [],
    cities: []
  })

  const schema = yup.object().shape({
    name: yup
      .string()
      .typeError()
      .required(getMessage('isRequired', getMessage('name'))),
    zipCode: yup
      .string()
      .typeError()
      .required(getMessage('isRequired', getMessage('zipCode'))),
    street: yup
      .string()
      .typeError()
      .required(getMessage('isRequired', getMessage('street'))),
    number: yup
      .string()
      .typeError()
      .required(getMessage('isRequired', getMessage('number'))),
    neighborhood: yup
      .string()
      .typeError()
      .required(getMessage('isRequired', getMessage('neighborhood'))),
    cityName: yup
      .string()
      .typeError()
      .required(getMessage('isRequired', getMessage('cityName')))
  })

  const formik = useFormik({
    initialValues: {},
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: schema,
    onSubmit: async values => {
      updateCustomer(customerSelectedId, values)
    }
  })
  const { values, handleSubmit, setValues } = formik

  async function requestState(skip) {
    try {
      const states = await request(api.listState({ ignorePagination: true }), skip)
      const format = get(states, 'items', []).map(s => ({
        value: s.stateAbbreviation,
        description: s.stateName
      }))

      setFilters(f => ({
        ...f,
        ...{ states: format }
      }))
    } catch (error) {
      console.tron.log(error)
    }
  }

  async function requestCity(state, skip) {
    try {
      const cities = await request(api.listCity({ ignorePagination: true, stateAbbreviation: state }), skip)
      const format = get(cities, 'items', []).map(c => ({
        value: c.id,
        description: c.cityName
      }))

      setFilters(f => ({
        ...f,
        ...{ cities: format }
      }))
    } catch (error) {
      console.tron.log(error)
    }
  }

  const requestUpdateCustomer = (id, payload) => request(api.updatePerson(id, { ...payload }))

  function payloadCustomerUpdate(values) {
    console.log('dddddddddddd')

    const address = {
      ...(get(values, 'addresses[0].id') && { id: values.addresses[0].id }),
      zipCode: onlyNumbers(values.zipCode),
      cityId: typeof values.cityName === 'string' && values.cityId ? Number(values.cityId) : Number(values.cityName),
      neighborhood: values.neighborhood,
      streetName: values.street,
      number: values.number,
      complement: values.complement
    }

    if (values.addresses.length > 0) {
      values.addresses[0] = address
    } else {
      values.addresses = [address]
    }

    const contact = {
      ...(get(values, 'contacts[0].id') && { id: values.contacts[0].id }),
      type: 'PHONE',
      value: values.phone
    }
    if (values.contacts.length > 1 && values.phone) {
      values.contacts[0] = contact
    } else if (values.phone) {
      values.contacts = [contact]
    }

    return {
      type: 'CUSTOMER',
      kindPerson: onlyNumbers(values.document).length > 11 ? 'LEGAL' : 'PHYSICAL',
      name: values.name,
      ...(values.document && { document: onlyNumbers(values.document) }),
      ...(values.email && { email: values.email }),
      ...(values.phone && {
        contacts: values.contacts
      }),
      addresses: values.addresses
    }
  }

  const updateCustomer = async (customerId, values) => {
    try {
      const customerPayload = await payloadCustomerUpdate(values)

      await requestUpdateCustomer(customerId, customerPayload)
      dispatch(AlertActions.openAlert(getMessage('successUpdateActions'), 'success'))
    } catch (error) {
      console.tron.log(error)
    }
  }

  useEffect(() => {
    if (customerSelected) {
      setValues({})
      const { id, name, document, email, contacts, addresses } = customerSelected

      setValues({
        ...values,
        name,
        email,
        document,
        ...(contacts && { phone: onlyNumbers(get(contacts[0], 'value')) }),
        ...(addresses && {
          zipCode: get(addresses[0], 'zipCode'),
          state: get(addresses[0], 'city.stateName'),
          cityId: get(addresses[0], 'cityId'),
          cityName: get(addresses[0], 'city.cityName'),
          neighborhood: get(addresses[0], 'neighborhood'),
          street: get(addresses[0], 'streetName'),
          number: get(addresses[0], 'number'),
          complement: (addresses[0], 'complement')
        }),
        contacts,
        addresses
      })
      setCustomerSelectedId(id)
    }
  }, [customerSelected])

  useEffect(() => {
    if (values.state) {
      //setFieldValue('cityName', undefined)
      requestCity(values.state)
    }
  }, [values.state])

  useEffect(() => {
    requestState(true)
  }, [])

  return (
    <div className="customer-preview-content">
      <If condition={!editabled}>
        <Row className="row-cliente-preview">
          <Col className="col-btn-edit">
            <strong className="title">Dados do cliente</strong>
            <Button className="btn_back edit__person" onClick={() => setEditabled(true)} disabled={isReadOnly}>
              <FontAwesomeIcon icon={icons.faPencilAlt} size="lg" className="icon" />
            </Button>
          </Col>
          <Col span={24} className="col-cliente-preview">
            <label>{getMessage('clienteName')}</label>
            <span id="name">{get(customerSelected, 'name')}</span>
            <Divider />

            <label>{getMessage('contact')}</label>
            <span>{get(customerSelected, 'contacts[0].value', '-')}</span>
            <Divider />

            <label>{getMessage('identificationId')}</label>
            <span>{maskCpfCnpj(get(customerSelected, 'document'))}</span>
            <Divider />

            <label>{getMessage('address')}</label>
            <span>{formatAddress(get(customerSelected, 'addresses[0]', {}))}</span>
          </Col>
        </Row>
      </If>
      <If condition={editabled}>
        <Form layout={'vertical'} onSubmit={handleSubmit}>
          <Row>
            <strong className="title">Dados do cliente</strong>
            <CustomInput context={formik} field="name" rowSpan={24} required />
            <CustomInput
              context={formik}
              field="document"
              rowSpan={24}
              mask={(customerSelected, 'document', []).length === 11 ? '111.111.111-11' : '11.111.111/1111-11'}
            />
            <CustomInput context={formik} field="email" rowSpan={24} />
            <CustomInput
              context={formik}
              field="phone"
              rowSpan={24}
              mask="(11) 11111-1111"
              placeholder="(DDD) + número"
            />
            <CustomInput context={formik} field="zipCode" rowSpan={24} mask="11111-111" required />
            <CustomSelect context={formik} field="state" rowSpan={24} values={filters.states} />
            <CustomSelect
              context={formik}
              field="cityName"
              rowSpan={24}
              values={filters.cities}
              disabled={!values.state}
              required
            />
            <CustomInput context={formik} field="neighborhood" rowSpan={24} required />
            <CustomInput context={formik} field="street" rowSpan={24} required />
            <CustomInput context={formik} field="number" rowSpan={24} required />
            <CustomInput context={formik} field="complement" rowSpan={24} />
          </Row>
          <Row justify="end" className="row-btns">
            <Button type="primary" className={'button-cancel'} onClick={() => setEditabled(false)}>
              <span>{getMessage('cancel')}</span>
            </Button>
            <Button type="primary" className={'mr5'} onClick={() => handleSubmit()}>
              <span>{getMessage('save')}</span>
            </Button>
          </Row>
        </Form>
      </If>
    </div>
  )
}

export default CustomerPreview
