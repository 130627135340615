import React, { useState, useEffect } from 'react'
import { Row, Button, Input, Table, Col, Form, Checkbox } from 'antd'
import { getMessage } from '../../../../util/IntlMessages'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { generateFilters } from '../../../../util/filtersUtil'
import { api, request } from '../../../../util/handlerRequestUtil'
import { useFormik } from 'formik'
import { faPlus, faSearch, faSlidersH } from '@fortawesome/free-solid-svg-icons'
import { onlyNumbers } from '../../../../util/format'
import { CustomInput, CustomSelect, ModalCustomerSelect } from '../../../../components'
import * as yup from 'yup'
import get from 'lodash/get'
import moment from 'moment-timezone'
import debounceEvent from '../../../../util/debounce'
import Pagination from '../../../../components/Pagination/index'
import ModalFilters from '../../../../components/ModalFilters'
import ModalNewCustomer from '../../../../components/ModalNewCustomer'
import TagsFilter from '../../../../components/TagsFilter'
import './customer.css'
import { isEmpty } from 'lodash'

const { Column } = Table

const Customer = function(props) {
  const { setSelectedTab, setFormValues, setCustomerPreview, id, personId, isReadOnly, loading, alert } = props

  const [visible, setVisible] = useState(false)
  const [visibleModalCustomer, setVisibleModalCustomer] = useState(false)
  const [visibleModalCustomerSelect, setVisibleModalCustomerSelect] = useState(false)
  const [expandedRowKeys, setExpandedRowKeys] = useState([''])
  const [filterValues, setFilterValues] = useState([])
  const [name, setName] = useState(' ')
  const [indicationPerson, setIndicationPerson] = useState({})
  const [loadingStorePerson, setLoadingStorePerson] = useState(false)
  const [hasAddress, setHasAddress] = useState(false)

  const [customers, setCustomers] = useState([])
  const { page = 0, count = 0, pages = 0, items = [] } = customers
  const formatedCustomers = get(customers, 'items', []).map(item => {
    return {
      key: item.id,
      name: item.name,
      contact: get(item, 'contacts[0].value', '-')
    }
  })
  const [filters, setFilters] = useState({
    states: [],
    cities: [],
    origin: []
  })

  const fieldsToValidation = ['zipCode', 'street', 'number', 'neighborhood', 'cityName']

  const getFieldsValidation = (fields = []) => {
    let validation = {}

    fields.forEach(name => {
      validation = {
        ...validation,
        [name]: yup
          .string()
          .typeError()
          .required(getMessage('isRequired', getMessage(name)))
      }
    })

    return hasAddress ? validation : {}
  }

  const formikCustomer = useFormik({
    initialValues: {},
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: yup.object().shape({
      name: yup
        .string()
        .typeError()
        .required(getMessage('isRequired', getMessage('name'))),
      ...getFieldsValidation(fieldsToValidation)
    }),
    onSubmit: async values => {
      storeCustomer(values)
    }
  })

  const { values, setFieldValue, setValues, handleSubmit, handleReset } = formikCustomer

  async function requests() {
    try {
      loading.open()

      if (id && personId) {
        await requestCustomers({ id: personId })
      } else {
        await requestCustomers()
      }

      await Promise.all[(requestOrigin(true), requestState(true))]
    } catch (error) {
      console.tron.log(error)
    } finally {
      loading.close()
    }
  }

  async function storeCustomer(values = {}) {
    try {
      setLoadingStorePerson(true)
      const payload = await payloadCustomer(values)

      await requestStoreCustomer(payload)
      alert.open(getMessage('successStoreActions'), 'success')
      setIndicationPerson({})

      await requestCustomers()
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingStorePerson(false)
    }
  }

  const requestCustomers = (params = {}) =>
    request(api.listPerson({ ...params }), { skipLoading: true }).then(data => setCustomers(data))

  const requestStoreCustomer = (params = {}) =>
    request(api.storePerson({ ...params })).then(() => {
      handleReset()
      setVisibleModalCustomer(false)
    })

  async function requestState(skip) {
    try {
      const states = await request(api.listState({ ignorePagination: true }), skip)
      const format = get(states, 'items', []).map(s => ({
        value: s.stateAbbreviation,
        description: s.stateName
      }))

      setFilters(f => ({
        ...f,
        ...{ states: format }
      }))
    } catch (error) {
      console.tron.log(error)
    }
  }

  async function requestCity(state, skip) {
    try {
      const cities = await request(api.listCity({ ignorePagination: true, stateAbbreviation: state }), skip)
      const format = get(cities, 'items', []).map(c => ({
        value: c.id,
        description: c.cityName
      }))

      setFilters(f => ({
        ...f,
        ...{ cities: format }
      }))
    } catch (error) {
      console.tron.log(error)
    }
  }

  async function requestOrigin(skip) {
    try {
      const origin = await request(api.listOrigin({ ignorePagination: true }), { skipLoading: skip })
      const format = origin.map(c => ({
        value: c.origin,
        description: c.origin
      }))

      setFilters(f => ({
        ...f,
        ...{ origin: format }
      }))
    } catch (error) {
      console.tron.log(error)
    }
  }

  function payloadCustomer(values) {
    const cityId = Number(values.cityName) || undefined
    const zipCode = onlyNumbers(values.zipCode) || undefined

    const addresses = [
      {
        ...(zipCode && { zipCode }),
        ...(cityId && { cityId }),
        ...(values.neighborhood && { neighborhood: values.neighborhood }),
        ...(values.street && { streetName: values.street }),
        ...(values.number && { number: values.number }),
        ...(values.complement && { complement: values.complement })
      }
    ]

    return {
      type: 'CUSTOMER',
      kindPerson: 'PHYSICAL',
      indicationPersonId: indicationPerson.id,
      name: values.name,
      ...(values.cpf && { document: onlyNumbers(values.cpf) }),
      ...(values.email && { email: values.email }),
      ...(values.phone && {
        contacts: [
          {
            type: 'PHONE',
            value: values.phone
          }
        ]
      }),
      ...(!isEmpty(addresses[0]) && { addresses }),
      ...(values.birth && { birth: moment(values.birth).toISOString(true) }),
      ...(values.instagram && { instagram: values.instagram }),
      ...(values.origin && { origin: values.origin })
    }
  }

  function filtersList() {
    return (
      <Row>
        <CustomInput context={formikCustomer} field="email" rowSpan={24} />
        <CustomInput context={formikCustomer} field="phone" rowSpan={12} mask="(11) 11111-1111" />
        <CustomInput context={formikCustomer} field="document" rowSpan={12} />
        <CustomSelect context={formikCustomer} field="state" rowSpan={12} values={filters.states} />
        <CustomSelect
          context={formikCustomer}
          field="city"
          rowSpan={12}
          values={filters.cities}
          disabled={!values.state}
        />
      </Row>
    )
  }

  const addOrigin = name => {
    setFilters({ ...filters, origin: [...filters.origin, { value: name, description: name }] })
  }

  function fieldsFormCustomer() {
    return (
      <Row className="fieldsFormCustomer__order">
        <Col span="24" className="col-title-formCustomer">
          <strong className="title">Cadastrar cliente</strong>
          <Checkbox onChange={({ target }) => setHasAddress(target.checked)}>Preencher endereço</Checkbox>
        </Col>
        <CustomInput context={formikCustomer} field="name" rowSpan={12} required />
        <CustomInput context={formikCustomer} field="cpf" rowSpan={12} mask="111.111.111-11" />
        <CustomInput context={formikCustomer} field="email" rowSpan={12} />
        <CustomInput
          context={formikCustomer}
          field="phone"
          rowSpan={12}
          mask="(11) 11111-1111"
          placeholder="(DDD) + número"
        />
        {hasAddress && (
          <>
            <CustomInput context={formikCustomer} field="zipCode" rowSpan={12} mask="11111-111" required={hasAddress} />
            <CustomSelect context={formikCustomer} field="state" rowSpan={12} values={filters.states} />
            <CustomSelect
              context={formikCustomer}
              field="cityName"
              rowSpan={12}
              values={filters.cities}
              disabled={!values.state}
              required={hasAddress}
            />
            <CustomInput context={formikCustomer} field="neighborhood" rowSpan={12} required={hasAddress} />
            <CustomInput context={formikCustomer} field="street" rowSpan={12} required={hasAddress} />
            <CustomInput context={formikCustomer} field="number" rowSpan={12} required={hasAddress} />
            <CustomInput context={formikCustomer} field="complement" rowSpan={12} />
          </>
        )}
        <CustomInput context={formikCustomer} field="instagram" rowSpan={12} />
        <CustomInput context={formikCustomer} field="birth" rowSpan={12} type="date" />
        <CustomSelect
          context={formikCustomer}
          field="origin"
          rowSpan={12}
          values={filters.origin}
          addOption={addOrigin}
        />
        <Col span={hasAddress ? 24 : 12} onClick={() => setVisibleModalCustomerSelect(true)}>
          <Form.Item label={getMessage('indicatedBy')}>
            <Input
              className="search__indicationPerson"
              value={indicationPerson.name}
              suffix={<FontAwesomeIcon icon={faPlus} />}
              disabled
            />
          </Form.Item>
        </Col>
      </Row>
    )
  }

  function handleOk() {
    generateFilters(values, setFilterValues, []) //data
    setVisible(false)
    handleFilter()
  }

  function handleFilter(value) {
    const { email, document, city, state, phone } = values
    let filters = {
      ...(email && { email }),
      ...(document && { document: onlyNumbers(document) }),
      ...(city && { city: Number(city) }),
      ...(state && { state }),
      ...(phone && { phone: onlyNumbers(phone) })
    }
    if (value && value.trim() !== '') {
      filters.name = value
    }
    requestCustomers({
      ...filters
    })
  }

  const onSearch = value => handleFilter(value)

  const onChangePage = async page => await requestCustomers({ page: page - 1 })

  const showModal = () => setVisible(true)

  const handleCancelModalNewCustomer = () => setVisibleModalCustomer(false)

  const handleCloseTag = item => {
    setFilterValues(filterValues.filter(item2 => item2.identifier !== item.identifier))
    setFieldValue(item.identifier, undefined)
    setFieldValue('forceUpdate', true)
  }

  const cleanFieldsModalNewCustomer = () => {
    setValues({})
    setIndicationPerson({})
  }

  //--->> Não está trazendo id da cidade
  useEffect(() => {
    if (values.zipCode) {
      const zipCode = onlyNumbers(values.zipCode)

      if (zipCode.length === 8) {
        fetch(`https://viacep.com.br/ws/${zipCode}/json/`)
          .then(res => res.json())
          .then(address => {
            return setValues({
              ...values,
              state: address.uf,
              //cityName: address.localidade,
              neighborhood: address.bairro,
              street: address.logradouro,
              complement: address.complemento
            })
          })
      }
    }
  }, [values.zipCode])

  useEffect(() => {
    if (values.state) {
      setFieldValue('cityName', undefined)
      requestCity(values.state)
    }
  }, [values.state])

  useEffect(() => {
    if (id && get(customers, 'items', []).length > 0) {
      const selectedCustomer = customers.items.find(customer => customer.id === personId)

      setExpandedRowKeys([personId])
      setCustomerPreview({ ...selectedCustomer, expanded: true })
    }
  }, [id, customers, personId])

  useEffect(() => {
    if (values.forceUpdate) {
      handleFilter(name)
      setFieldValue('forceUpdate', false)
    }
  }, [values.forceUpdate])

  useEffect(() => {
    requests()
  }, [])

  return (
    <>
      <div className="customer-content">
        <Row>
          <Row className="content-search">
            <Col xs={24} sm={24} md={10} lg={10} xl={10} className="customer-search">
              <Input
                value={name}
                onChange={event => {
                  const text = event.target.value
                  setName(text)
                  debounceEvent(() => onSearch(text))
                }}
                placeholder="Procure um Cliente"
                suffix={<FontAwesomeIcon icon={faSearch} size="lg" className="icon" />}
                className="input_search"
                disabled={isReadOnly}
              ></Input>
              <Button type="primary" className="btn_filter" onClick={showModal} disabled={isReadOnly}>
                <FontAwesomeIcon icon={faSlidersH} size="lg" className="icon" />
              </Button>
            </Col>
            <Col className="gutter-row rowTags" xs={24} sm={24} md={14} lg={14} xl={14}>
              <div className="containerTags">
                <TagsFilter filterValues={filterValues} onClose={handleCloseTag} />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="customer-pagination">
              <Pagination currentPage={page + 1} pages={pages} total={count} onChange={onChangePage} />
            </Col>
          </Row>
          <Table
            rowKey="key"
            className="tb-customers"
            expandedRowKeys={expandedRowKeys}
            dataSource={formatedCustomers}
            scroll={{ y: 300 }}
            pagination={false}
            size="middle"
            expandRowByClick
            expandable={
              !isReadOnly && {
                expandedRowRender: () => {},
                onExpandedRowsChange: rows =>
                  setExpandedRowKeys(rows[rows.length - 1] ? [rows[rows.length - 1]] : ['']),
                onExpand: (expanded, record) => {
                  const selectedCustomer = items.find(customer => customer.id === record.key)
                  setCustomerPreview({ ...selectedCustomer, expanded })
                  setFormValues(values => ({ ...values, customer: selectedCustomer.id }))
                }
              }
            }
          >
            <Column title={getMessage('name')} dataIndex="name" key="name" />
            <Column title={getMessage('contact')} dataIndex="contact" key="contact" />
          </Table>
        </Row>
        <Row justify="end">
          <Button type="primary" onClick={() => setSelectedTab('3')}>
            <span>{getMessage('continue')}</span>
          </Button>
        </Row>

        <ModalFilters
          visible={visible}
          onOk={handleOk}
          style={{ width: '20vw' }}
          handleReset={handleReset}
          onCancel={() => setVisible(false)}
        >
          {filtersList()}
        </ModalFilters>

        <ModalNewCustomer
          visible={visibleModalCustomer}
          handleReset={cleanFieldsModalNewCustomer}
          handleSubmit={handleSubmit}
          handleCancel={handleCancelModalNewCustomer}
          loading={loadingStorePerson}
        >
          {fieldsFormCustomer()}
        </ModalNewCustomer>

        <ModalCustomerSelect
          visible={visibleModalCustomerSelect}
          setVisible={setVisibleModalCustomerSelect}
          setPerson={setIndicationPerson}
        />
      </div>
    </>
  )
}

export default Customer
