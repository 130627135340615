import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as icons from '@fortawesome/free-solid-svg-icons'
import { Typography, Button, Row, Col } from 'antd'
import './TestPaulo.css'

const TestPaulo = () => {
  const { Title, Link } = Typography
  return (
    <section>
      <div className="headerPedido">
        <Row justify="space-between">
          <Col className="back gutter-row">
            {' '}
            <Button className="btn_back">
              <FontAwesomeIcon icon={icons.faArrowLeft} size="lg" className="icon" />
            </Button>{' '}
            <span>Voltar para pedidos</span>
          </Col>
          <Col className="back gutter-row">
            {' '}
            <Button className="btn_back">
              <FontAwesomeIcon icon={icons.faPen} size="lg" className="icon" />
            </Button>
            <Button className="headerBtn">Excluir</Button>
          </Col>
        </Row>
        <h1 className="title">Pedido</h1>
      </div>

      <div className="containerPedido">
        <Row justify="space-between">
          <Col className="back gutter-row">
            {' '}
            <Title level={3} style={{ margin: '0 1rem 0 0' }}>
              Pedido 0902
            </Title>
            <span className="span">
              Pago <FontAwesomeIcon icon={icons.faCheckDouble} />
            </span>
          </Col>
          <Button className="btn_back">
            <FontAwesomeIcon icon={icons.faShareAlt} size="lg" className="icon" />
          </Button>
        </Row>

        <div className="vendedor mt-1">
          <Row>
            <Title level={4}>Vendedor</Title>
          </Row>
          <Row>
            <Col span={12}>
              <Title level={5}>Loja/Filial</Title>
              <p>AJU01</p>
            </Col>
            <Col span={12}>
              <Title level={5}>Realizado em</Title>
              <p>13/02/2021 ás 12:03h</p>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Title level={5}>Funcionário</Title>
              <p>Vanessa Soares</p>
            </Col>
            <Col span={12}>
              <Title level={5}>Plataforma</Title>
              <Button className="platBtn">Presencial</Button>
            </Col>
          </Row>
        </div>

        <div className="cliente mt-1">
          <Row gutter={[8, 24]}>
            <Title level={4}>Cliente</Title>
          </Row>
          <Row>
            <Col span={12}>
              <Title level={5}>Nome do Cliente</Title>
              <p>Ana Luiza Silva Torres</p>
            </Col>
            <Col span={12}>
              <Title level={5}>Email</Title>
              <p>luizaana@gmail.com</p>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Title level={5}>Documento de Identificação</Title>
              <p>CNH 020 393 8229 23</p>
            </Col>
            <Col span={12}>
              <Title level={5}>Telefone</Title>
              <p>+55 79 99829 0938</p>
            </Col>
            <Col span={12}>
              <Title level={5}>Endereço</Title>
              <p>
                Rua Fernando Verissimo, 030. Em frente ao posto petrox. Laranjeiras, N/A. Sergipe - Brasil. 490503-000
              </p>
            </Col>
          </Row>
        </div>

        <div className="produtos mt-1">
          <Row gutter={[8, 24]}>
            <Title level={4}>Produtos (2)</Title>
          </Row>
          <Row>
            <Col span={12}>
              1x Óculos de grau feminino retrô quadrado gatinho Variação: Preto{' '}
              <Link href="#" underline>
                Ver Produto
              </Link>
            </Col>
            <Col span={6}>
              <Title level={5}>SKU</Title>
              <p>322900-32</p>
            </Col>
            <Col span={6}>
              {' '}
              <Title level={5}>Valor</Title>
              <p>R$299,00</p>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              1x Óculos de grau feminino retrô quadrado gatinho Variação: Preto{' '}
              <Link href="#" underline>
                Ver Produto
              </Link>
            </Col>
            <Col span={6}>
              <Title level={5}>SKU</Title>
              <p>322900-32</p>
            </Col>
            <Col span={6}>
              {' '}
              <Title level={5}>Valor</Title>
              <p>R$299,00</p>
            </Col>
          </Row>
        </div>

        <div className="entrega mt-1">
          <Row gutter={[8, 24]}>
            <Col span={12}>
              <Title level={4}>Entrega</Title>
              <p>Receber em até 8 dias úteis por R$ 22,02 Sedex Express</p>
              <Link href="#" underline>
                Rastrear
              </Link>
            </Col>
            <Col span={12}>
              <Title level={4}>Endereço de Entrega</Title>
              <Title level={5}>Ana Luiza Silva Torres</Title>
              <p>
                Rua Fernando Verissimo, 030. Em frente ao posto petrox. Laranjeiras, N/A. Sergipe - Brasil. 490503-000
              </p>
            </Col>
          </Row>
        </div>

        <div className="pagamento mt-1">
          <Row gutter={[8, 24]}>
            <Title level={4}>Pagamento</Title>
          </Row>
          <Row>
            <Col span={12}>
              <Title level={5}>Forma de Pagamento</Title>
              <p>Cartão</p>
            </Col>
            <Col span={12}>
              <Title level={5}>Cupom ou Desconto</Title>
              <p>MARIO123</p>
            </Col>
            <Col span={12}>
              <Title level={5}>Número do Cartão</Title>
              <p>0390 9393 0033 9472</p>
            </Col>
            <Col span={12}>
              <Title level={5}>Bandeira</Title>
              <p className="creditCard">Mastercard</p>
            </Col>
            <Col span={12}>
              <Title level={5}>Nome impresso no cartão</Title>
              <p>Ana Luiza S Torres</p>
            </Col>
            <Col span={12}>
              <Title level={5}>Parcelas</Title>
              <p>3X de R$ 39,46 sem juros</p>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  )
}

export default TestPaulo
