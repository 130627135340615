import React, { useState, useRef, useEffect } from 'react'
import moment from 'moment-timezone'
import { Modal, Form, Col, Button, Divider } from 'antd'
import { useFormik } from 'formik'
import { CustomIcon, CustomInput } from '../../../../components'
import { getMessage } from '../../../../util/IntlMessages'
import { api, request } from '../../../../util/handlerRequestUtil'
import { TIME_REQUEST } from '../../Constants/Variables'
import { getStatus, STATUS } from '../../Constants/Status'
import { isEmpty } from 'lodash'

import styles from './SyncModal.module.css'

function SyncModal(props) {
  const { visible, setVisible, alert } = props

  const [loading, setLoading] = useState(false)
  const [loadingCancel, setLoadingCancel] = useState(false)
  const [job, setJob] = useState({})

  const isPendingJob = job.status === STATUS.PENDING
  const timeoutRef = useRef()

  const form = useFormik({
    initialValues: {},
    onSubmit: async values => {
      const { period, orderId } = values

      await validePeriod(period)

      const params = {
        ...(period && {
          after: period[0].format('DD/MM/YYYY'),
          before: period[1].format('DD/MM/YYYY')
        }),
        ...(orderId && { orderId })
      }

      try {
        setLoading(true)
        const { id } = await request(api.storeSyncWoo(params), { skipLoading: true })

        checkJob(id)
      } catch {
        setLoading(false)
      }
    }
  })

  const checkJob = async id => {
    try {
      const job = await request(api.retrieveSyncWoo(id), { skipLoading: true })

      const { status, data } = job
      const { processing = 0, errors = [getMessage('notFound')] } = data

      setJob({
        id,
        status,
        processing,
        errors
      })
    } catch (error) {
      console.error(error)
    }

    timeoutRef.current = setTimeout(() => {
      checkJob(id)
    }, TIME_REQUEST)
  }

  const stopJob = async id => {
    try {
      setLoadingCancel(true)
      await request(api.deleteSyncWoo(id), { skipLoading: true })

      setLoading(false)
    } finally {
      setLoadingCancel(false)
    }
  }

  const validePeriod = period => {
    if (period) {
      const days = moment.duration(period[1].diff(period[0])).asDays()

      return days > 30 && alert.open('period.info', 'info')
    }
  }

  const handleCancel = () => setVisible(false)

  useEffect(() => {
    if (job.status === STATUS.COMPLETED) {
      clearTimeout(timeoutRef.current)
    }
  }, [job.status])

  return (
    <Modal
      title={getMessage('ordersSync')}
      width={350}
      visible={visible}
      footer={null}
      onCancel={handleCancel}
      maskClosable={false}
    >
      <Form layout="vertical">
        <CustomInput
          type="date"
          context={form}
          field="period"
          rowSpan={24}
          rangePicker
          disabled={form.values.orderId}
        />

        <div className={styles.divider}>
          <Divider>{getMessage('or')}</Divider>
        </div>

        <CustomInput context={form} field="orderId" rowspan={24} disabled={form.values.period} />

        <Col span={24} className={styles.colBtnSearch}>
          <Button
            type="primary"
            loading={isPendingJob && loading}
            onClick={() => form.submitForm()}
            disabled={!form.values.period && !form.values.orderId}
          >
            {getMessage(`${isPendingJob ? 'syncing' : 'search'}`)}
          </Button>
          {isPendingJob && (
            <Button type="danger" loading={loadingCancel} onClick={() => stopJob(job.id)} disabled>
              {getMessage(`${!loadingCancel ? 'cancel' : 'canceling'}`)}
            </Button>
          )}
        </Col>

        {!isEmpty(job) && (
          <Col span={24} className={styles.status}>
            <div>
              <span>{getMessage('status')}:</span>
              {getStatus(job.status)}
              {job.status === STATUS.COMPLETED && <CustomIcon name="faCheckCircle" color="green" />}
              {job.status === STATUS.FAILED && <CustomIcon name="faExclamationTriangle" color="red" />}
            </div>
            <div>
              <span>{getMessage('orders.success.proccess')}:</span>
              {job.processing || 0}
            </div>
            <div>
              <span>{getMessage('orders.error.proccess')}:</span>
              <span className={styles.statusErrors}>
                {job.errors.map((id, i, arr) => {
                  if (arr.length - 1 === i) {
                    return `${id}.`
                  }
                  return `${id} - `
                })}
              </span>
            </div>
          </Col>
        )}
      </Form>
    </Modal>
  )
}

export default SyncModal
