import React from 'react'
import { Breadcrumb, Button } from 'antd'
import { getMessage } from '../../util/IntlMessages'
import { useHistory } from 'react-router'

import styles from './CustomBreadcrumb.module.css'

const { Separator, Item } = Breadcrumb

const CustomBreadcrumb = props => {
  const { title, items, children, btnBack, btnAction } = props
  const history = useHistory()

  return (
    <>
      {items && (
        <Breadcrumb>
          <Separator>{`${getMessage('youAreIn')} `}</Separator>
          <Item className="blue-ellipsis link-like" onClick={() => history.push('/')}>
            {getMessage('initialPage')}
          </Item>
          {items.map(({ text, to, translate = true }, i) => (
            <Item
              {...(to && {
                className: 'blue-ellipsis link-like',
                onClick: () => (typeof to === 'string' ? history.push(to) : to())
              })}
              key={i + text}
            >
              {translate ? getMessage(text) : text}
            </Item>
          ))}
        </Breadcrumb>
      )}

      <div className={styles.title}>
        <h1>{getMessage(title)}</h1>
        <div className={styles.children}>
          {children}
          <Button onClick={btnBack.onClick}>
            <span>{getMessage(btnBack.title || 'back')}</span>
          </Button>
          <Button type="primary" className="btn_new" onClick={btnAction.onClick}>
            <span>{getMessage(btnAction.title)}</span>
          </Button>
        </div>
      </div>
    </>
  )
}

export default CustomBreadcrumb
