import React, { useEffect } from 'react'
import { PageHeader, Card, Row, Col, Button, Form } from 'antd'
import './styles.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as icons from '@fortawesome/free-solid-svg-icons'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { getMessage } from '../../util/IntlMessages'
import { useSelector, useDispatch } from 'react-redux'
import CustomInput from '../../components/CustomInput/customInput'
import { request, api } from '../../util/handlerRequestUtil'
import { Creators as authActions } from '../../appRedux/ducks/auth/auth'
import { FormikProvider } from 'formik'

const LoginScreen = function({ history }) {
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth.auth)

  useEffect(() => {
    if (auth.token) {
      history.push('/dashboard')
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)

    return () => {
      document.removeEventListener('keydown', handleKeyDown, false)
    }
  }, [])

  const form = useFormik({
    initialValues: {},
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: yup.object().shape({
      username: yup
        .string()
        .min(1, getMessage('minLenght', 'Usuário', '4'))
        .required(getMessage('isRequired', 'Usuário')),
      password: yup
        .string()
        .min(1, getMessage('minLenght', 'Senha', '4'))
        .required(getMessage('isRequired', 'Senha'))
    }),
    onSubmit: async values => {
      console.tron.log('values', values)
      try {
        const auth = await request(api.auth(values))
        console.tron.log('auth', auth)
        dispatch(
          authActions.success({
            user: auth
          })
        )
        history.push('/dashboard')
      } catch (error) {}
    }
  })

  const { handleSubmit, submitForm } = form

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      submitForm()
    }
  }

  return (
    <div className="mainlyContainer">
      <PageHeader className="header"></PageHeader>

      <Row>
        <Col xs={6} sm={1} md={3} lg={3} xl={4} />
        <Col xs={6} sm={1} md={2} lg={3} xl={4} />
        <Col xs={24} sm={20} md={14} lg={12} xl={8}>
          <Card className="containerLogin">
            <label id="login">LOGIN</label>
            <div className="containerForm">
              <FormikProvider value={form}>
                <Form className="login-form" layout="vertical" onSubmit={handleSubmit}>
                  <Row type="flex" justify="start">
                    <CustomInput
                      prefix={<FontAwesomeIcon icon={icons.faUser} size="sm" style={{ marginRight: 10 }} />}
                      context={form}
                      field="username"
                      rowSpan={24}
                      required
                    />
                    <CustomInput
                      type="password"
                      prefix={<FontAwesomeIcon icon={icons.faLock} size="sm" style={{ marginRight: 10 }} />}
                      context={form}
                      field="password"
                      rowSpan={24}
                      required
                    />
                    <Col span={24}>
                      <div className="containerFormActions__login">
                        <div></div>
                        <Button className="btn-login" type="primary" onClick={() => submitForm()}>
                          {getMessage('login')}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </FormikProvider>
            </div>
          </Card>
        </Col>
        <Col xs={6} sm={1} md={2} lg={3} xl={4} />
        <Col xs={6} sm={1} md={3} lg={3} xl={4} />
      </Row>
    </div>
  )
}

export default LoginScreen
