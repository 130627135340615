import React, { useState, useEffect } from 'react'
import { Tabs, Row, Col, Button, Form, Card } from 'antd'
import { getMessage } from '../../../util/IntlMessages'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'
import { validateFields } from ' ../../util/formikUtil'
import { api, request } from '../../../util/handlerRequestUtil'
import { get, isNull } from 'lodash'
import { PDV_PLATFORM_ID } from './constants'
import notFound from '../../../assets/images/noImage.png'
import * as icons from '@fortawesome/free-solid-svg-icons'
import queryString from 'query-string'
import * as yup from 'yup'

import Employee from './employee/employee'
import Customer from './customer/customer'
import CustomerPreview from './customer/customerPreview'
import Products from './product/products'
import OrderPreview from './product/orderPreview'
import Delivery from './delivery/delivery'
import Payment from './payment/payment'
import './tabs.css'
import { FormikProvider } from 'formik'
import { ORDER_TYPES } from '../types'
import { useQuery } from '../../../hooks'

const { TabPane } = Tabs

const MainTabs = function ({ history, alert, loading, match }) {
  const { id } = match.params
  const queryParams = useQuery()
  const isReadOnly = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true
  }).readOnly
  const orderTypeParam = queryParams.get('orderType')
  const isOrderTypeSale = orderTypeParam === ORDER_TYPES.SALE

  const [selectedTab, setSelectedTab] = useState('1')
  const [formValues, setFormValues] = useState({})
  const [customerPreview, setCustomerPreview] = useState({})
  const [orderProducts, setOrderProducts] = useState([])
  const [discounts, setDiscounts] = useState(0)
  const [rates, setRates] = useState(0)
  const [totalDelivery, setTotalDelivery] = useState(0)
  const [fieldsToValidate, setFieldsToValidate] = useState(['store', 'platform', 'status'])

  const getValidation = (fields = []) => {
    let validation = {}

    fields.forEach(name => {
      validation = {
        ...validation,
        [name]: yup
          .string()
          .typeError()
          .required(getMessage('isRequired', getMessage(name)))
      }
    })

    return validation
  }

  const yupValidation = yup.object().shape(getValidation(fieldsToValidate))

  const formik = useFormik({
    initialValues: formValues,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: yupValidation
  })
  const orderType = formik.values.orderType
  const { values, handleSubmit, setValues } = formik

  async function onChangeTabs(tabname = '2') {
    const hasErrors = await validateFields(fieldsToValidate, formik, yupValidation)
    if (hasErrors.length > 0) {
      alert.open(getMessage('thereIsRequiredFields'))
    } else {
      setSelectedTab(tabname)
    }
  }

  const getTitle = () => {
    if (isReadOnly) {
      if (orderType === ORDER_TYPES.SALE) {
        return getMessage('viewOrder')
      } else if (orderType === ORDER_TYPES.SERVICE) {
        return getMessage('viewOS')
      }
    }
    if (id) {
      if (orderType === ORDER_TYPES.SALE) {
        return getMessage('editOrder')
      } else if (orderType === ORDER_TYPES.SERVICE) {
        return getMessage('editOS') + ` #${formik.values.osCode}`
      }
    }
    if (orderType === ORDER_TYPES.SALE) {
      return getMessage('registerOrder')
    } else if (orderType === ORDER_TYPES.SERVICE) {
      return getMessage('registerOS')
    }
  }

  const backOrders = () => {
    history.push(`/order/list?orderType=${orderTypeParam}`)
  }

  async function requestNfce() {
    const order = await request(api.storeNf(id))
    window.open(order, '_blank', 'noopener,noreferrer')
  }

  async function retrieve(id) {
    try {
      const order = await request(api.retrieveOrder(id), true)
      const {
        storeBranchId,
        employeeId,
        platformId,
        personId,
        products,
        totalDiscounts,
        totalTax,
        totalShipping,
        shipping,
        payments,
        notes,
        internalStatusId,
        statusId,
        tags,
        orderType,
        parentOrder
      } = order

      setValues({
        parentOrder,
        ...order,
        store: storeBranchId,
        employee: employeeId,
        platform: platformId,
        customer: personId,
        ...(shipping.length > 0 && {
          shipping: {
            addressId: shipping[0].addressId,
            courierId: shipping[0].courierId,
            address: shipping[0].address
          }
        }),
        payments,
        totalShipping: +totalShipping,
        totalDiscounts: +totalDiscounts,
        totalTax: +totalTax,
        notes,
        internalStatus: internalStatusId,
        status: statusId,
        tags: tags.map(item => get(item, 'tag.name', null)).filter(tag => !isNull(tag)),
        orderType
      })
      const formatedOrderItems = products.map(
        ({ productId, lineItem, quantity, price, finalPrice, variantId, variation, recipeId, statusId, ...rest }) => ({
          ...rest,
          id: productId,
          name: lineItem,
          src: get(variation, 'media') ? variation.media.location : notFound,
          filledByVariation: {
            id: variantId,
            price: +price,
            ...(finalPrice !== price && { finalPrice: +finalPrice }),
            qtd: quantity,
            recipeId,
            statusId,
            productId,
            dueDateDelivery: order.dueDate
          },
          variation
        })
      )
      setOrderProducts(formatedOrderItems)
      setDiscounts(+totalDiscounts)
      setTotalDelivery(+totalShipping)
      setRates(+totalTax)
    } catch (error) {
      console.tron.log(error)
    }
  }

  useEffect(() => {
    if (!values.platform) return

    const isPdv = values.platform === PDV_PLATFORM_ID
    const EMPLOYEE = 'employee'

    if (isPdv) {
      setFieldsToValidate(values => [...values, EMPLOYEE])
    } else {
      setFieldsToValidate(values => values.filter(v => v !== EMPLOYEE))
    }
  }, [values.platform])

  useEffect(() => {
    setFormValues({
      ...formValues,
      ...values,
      discountsTotal: discounts,
      ratesTotal: rates
    })
  }, [discounts, rates, values])

  useEffect(() => {
    async function request() {
      try {
        if (id) {
          await retrieve(id)
        }
      } catch (error) {
        console.tron.log(error)
      }
    }
    request()
  }, [])

  function setTypeOrder() {
    if (!formValues.orderType && !id) {
      if (orderTypeParam) {
        formik.setValues({
          ...formik.values,
          orderType: orderTypeParam
        })
      } else {
        formik.setValues({
          ...formik.values,
          orderType: ORDER_TYPES.SALE
        })
      }
    }
  }

  useEffect(() => {
    setTypeOrder()
  }, [setTypeOrder])

  return !formik.values.orderType ? (
    <></>
  ) : (
    <FormikProvider value={formik}>
      <Row>
        <Col className="back gutter-row" xs={24} sm={24} md={8} lg={8} xl={8}>
          <Button className="btn_back" onClick={backOrders}>
            <FontAwesomeIcon icon={icons.faArrowLeft} size="lg" className="icon" />
          </Button>
          <span>{getMessage(isOrderTypeSale ? 'backToOrders' : 'backToOs')}</span>
        </Col>
        {isOrderTypeSale && id && !isReadOnly && (
          <Col className="back gutter-row" xs={24} sm={24} md={8} lg={8} xl={8}>
            <Button type="primary" onClick={requestNfce}>
              {getMessage('emitNf')}
            </Button>
          </Col>
        )}
      </Row>
      <h1 className="title">{getTitle()}</h1>

      <Row gutter={[10]} className="tabs-content">
        <Col xs={24} sm={24} md={24} lg={16} xl={16}>
          <Form layout={'vertical'} onSubmit={handleSubmit}>
            <Tabs
              className="row-content-orders"
              activeKey={selectedTab}
              type="card"
              onChange={onChangeTabs}
              style={{ margin: '0 0 15px' }}
              tabBarGutter={0}
            >
              <TabPane tab={getMessage('employee')} key="1">
                <Employee
                  onChangeTabs={onChangeTabs}
                  formik={formik}
                  setFormValues={setFormValues}
                  loading={loading}
                  id={id}
                  isReadOnly={isReadOnly}
                />
              </TabPane>
              <TabPane tab={getMessage('customer')} key="2">
                <Customer
                  setSelectedTab={setSelectedTab}
                  setCustomerPreview={setCustomerPreview}
                  setFormValues={setFormValues}
                  isReadOnly={isReadOnly}
                  id={id}
                  personId={values.customer}
                  loading={loading}
                  alert={alert}
                />
              </TabPane>
              <TabPane tab={getMessage('products')} key="3">
                <Products
                  formValues={formValues}
                  setSelectedTab={setSelectedTab}
                  setItemToOrder={setOrderProducts}
                  isReadOnly={isReadOnly || id}
                />
              </TabPane>
              <TabPane tab={getMessage('delivery')} key="4">
                <Delivery
                  setSelectedTab={setSelectedTab}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  alert={alert}
                  setTotalDelivery={setTotalDelivery}
                  isReadOnly={isReadOnly}
                  id={id}
                />
              </TabPane>
              <TabPane tab={getMessage('payment')} key="5">
                <Payment
                  items={orderProducts}
                  setDiscounts={setDiscounts}
                  setRates={setRates}
                  formValues={formValues}
                  history={history}
                  alert={alert}
                  id={id}
                  isReadOnly={isReadOnly}
                  totalDelivery={totalDelivery}
                  loading={loading}
                />
              </TabPane>
            </Tabs>
          </Form>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8} className="col-card">
          {selectedTab === '2' && get(customerPreview, 'expanded') && (
            <Card className="view-card">
              <CustomerPreview customerSelected={customerPreview} isReadOnly={isReadOnly} />
            </Card>
          )}

          {(selectedTab === '3' || selectedTab === '4' || selectedTab === '5') && (
            <Card className="view-card">
              <OrderPreview
                items={orderProducts}
                setItemsOrder={setOrderProducts}
                formValues={formValues}
                discounts={discounts}
                rates={rates}
                totalDelivery={totalDelivery}
                isReadOnly={isReadOnly || id}
                alert={alert}
              />
            </Card>
          )}
        </Col>
      </Row>
    </FormikProvider>
  )
}

export default MainTabs
