import React, { useState, useEffect, useRef } from 'react'
import { Row, Modal, Button, Tag } from 'antd'
import { useFormik } from 'formik'
import { get } from 'lodash'

import ListScreen from '../../components/ListScreen'
import { CustomInput, CustomSelect } from '../../components'
import { api, request } from '../../util/handlerRequestUtil'
import { formatDateWithHour } from '../../util/format'
import { scheduleStatus } from '../../util/dictionaries'
import { formatedStatus } from './ScheduleStatus'
import moment from 'moment-timezone'

function ListSchedules(props) {
  const { alert, getMessage } = props
  const formik = useFormik({
    initialValues: {}
  })
  const [modalDelete, setModalDelete] = useState({
    description: getMessage('defaultDeleteDescription'),
    visible: false
  })
  const { values, setFieldValue } = formik
  const [data, setData] = useState({})
  const [filters, setFilters] = useState({})
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [scheduleSelected, setScheduleSelected] = useState(undefined)
  const { page = 1, count = 0, pages = 0 } = data
  const messages = get(data, 'items', []).map(message => ({
    ...message
  }))
  const listScreenRef = useRef()

  const StatusColors = {
    SENT: '#28a745',
    PENDING: '#007bff',
    FAILED: '#dc3545'
  }

  function handlePhoneClick(phone, message, row) {
    setIsModalVisible(true)
    setScheduleSelected(row)
    window.open(
      `https://api.whatsapp.com/send?phone=${phone}&text=${window.encodeURIComponent(message.replaceAll(/$/g, '\n'))}`,
      '_blank'
    )
  }

  const columns = [
    {
      title: getMessage('sidebar.messages'),
      dataIndex: 'messageContent',
      key: 'message'
    },
    {
      title: getMessage('phone'),
      dataIndex: 'cellphone',
      key: 'cellphone',
      render: (phone, row) => (
        <a
          target="_blank"
          href={generateWhatsappLink({ message: row.messageContent, phone: row.cellphone })}
          rel="noreferrer"
          onClick={() => handlePhoneClick(row.cellphone, row.messageContent, row)}
        >
          {phone}
        </a>
      )
    },
    {
      title: getMessage('date'),
      dataIndex: 'dateTrigger',
      key: 'date',
      render: date => <label>{formatDateWithHour(date)}</label>
    },
    {
      title: getMessage('status'),
      dataIndex: 'status',
      key: 'status',
      render: status => <Tag color={StatusColors[status]}>{scheduleStatus[status]}</Tag>
    }
    // cli-columns
  ]

  // useLayoutEffect(() => {
  //   setFieldValue('dateTriggered', moment())
  // }, [])

  useEffect(() => {
    requests()
  }, [])

  useEffect(() => {
    if (values.state) {
      setFieldValue('city', undefined)
    }
  }, [values.state])

  function generateWhatsappLink({ message, phone }) {
    return `https://api.whatsapp.com/send?phone=${phone}&text=${message}`
  }

  async function requests() {
    try {
      listScreenRef.current.handleOk({ dateTrigger: moment() })
      const formatedOrders = await requestOrders()
      const formatedPersons = await requestPersons()

      setFilters(f => ({
        ...f,
        ...formatedPersons,
        ...formatedOrders,
        formatedStatus
      }))
    } catch (error) {
      console.tron.log(error)
    }
  }

  function onClickEdit(schedule) {
    props.history.push(`/schedules/${schedule.id}`)
  }

  async function onClickDelete(schedule) {
    setModalDelete({
      ...modalDelete,
      title: getMessage('info.delete'),
      visible: true,
      onOk: async () => onConfirmDelete(schedule)
    })
  }

  async function onConfirmDelete(schedule) {
    alert.open(getMessage('successDelete'), 'success')
    await request(api.deleteSchedule(schedule.id))
    setModalDelete({
      ...modalDelete,
      visible: false
    })
    await requestData(values)
  }

  function onClickView(schedule) {
    props.history.push(`/schedules/${schedule.id}?readOnly=true`)
  }

  function onClickDuplicate(schedule) {
    props.history.push(`/schedules/create?duplicate=${schedule.id}`)
  }

  const requestData = (params = {}) => request(api.listSchedules({ ...params })).then(data => setData(data))
  const requestPersons = (params = {}) =>
    request(api.listPerson({ ...params })).then(({ items }) => {
      return {
        formatedPersons: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name
          }
        })
      }
    })
  const requestOrders = (params = {}) =>
    request(api.listOrder({ ...params })).then(({ items }) => {
      return {
        formatedOrders: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: `${item.person.name} - ${new Date(item.orderDate).toLocaleDateString()}`
          }
        })
      }
    })

  const handleFilter = (value = '', values = {}, pageParam) => {
    const { phone, messageContent, dateTrigger, dateTriggered, person, order, status } = values

    const filters = {
      ...(phone && { phone }),
      ...(messageContent && { messageContent }),
      ...(dateTrigger && { dateTrigger: dateTrigger.toISOString(true) }),
      ...(dateTriggered && { dateTriggered: dateTriggered.toISOString(true) }),
      ...(person && { person }),
      ...(order && { order }),
      ...(status && { status }),
      ...(pageParam && { page: pageParam })
    }

    const trimValue = value.trim()

    if (value && trimValue !== '') {
      filters.phone = trimValue
    }

    requestData({
      ...filters
    })
  }

  const onSearch = value => handleFilter(value)

  async function requestPage(page) {
    await handleFilter('', values, page - 1)
  }

  const onChangeCreateButton = () => {
    props.history.push('/messages/create')
  }

  const handleOk = () => {
    const { id } = scheduleSelected

    const scheduleFieldsToUpdate = {
      status: 'SENT',
      dateTriggered: new Date()
    }
    request(api.updateSchedule(id, scheduleFieldsToUpdate))
      .then(() => {
        alert.open(getMessage('successUpdateScheduleStatus'), 'success')
        setData({
          ...data,
          items: data.items.map(item => {
            if (+item.id === +id) {
              return {
                ...item,
                status: 'SENT'
              }
            }
            return item
          })
        })
      })
      .catch(() => alert.open(getMessage('errorUpdateScheduleStatus'), 'error'))
      .finally(() => setIsModalVisible(false))
  }

  const handleCancel = () => {
    const { id } = scheduleSelected

    const scheduleFieldsToUpdate = {
      status: 'FAILED'
    }

    request(api.updateSchedule(id, scheduleFieldsToUpdate))
      .then(() => {
        alert.open(getMessage('successUpdateScheduleStatus'), 'success')
        setData({
          ...data,
          items: data.items.map(item => {
            if (+item.id === +id) {
              return {
                ...item,
                status: 'FAILED'
              }
            }
            return item
          })
        })
      })
      .catch(() => alert.open(getMessage('errorUpdateScheduleStatus'), 'error'))
      .finally(() => setIsModalVisible(false))
  }

  const validFieldsKeys = ['cellphone', 'dateTriggered', 'orderId', 'dateTrigger', 'status']

  function filtersList() {
    return (
      <Row type="flex" justify="start">
        <CustomInput context={formik} field={validFieldsKeys[0]} rowSpan={12} />
        <CustomInput context={formik} field={validFieldsKeys[3]} rowSpan={12} type="date" />
        <CustomInput context={formik} field={validFieldsKeys[1]} rowSpan={12} type="date" />
        <CustomInput context={formik} field={validFieldsKeys[2]} rowSpan={12} />
        <CustomSelect context={formik} showSearch field={validFieldsKeys[4]} rowSpan={12} values={formatedStatus} />
      </Row>
    )
  }

  return (
    <>
      <Modal
        title="A mensagem foi enviada ?"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        okText={getMessage('wasSent')}
        cancelText={getMessage('wasNotSent')}
        footer={[
          <Button key="notSent" onClick={handleCancel}>
            Não foi enviada
          </Button>,
          <Button key="Sent" onClick={handleOk} type="primary">
            Enviada com sucesso
          </Button>
        ]}
      >
        <p>Se a mensagem foi enviada, atualize o status dela !</p>
      </Modal>
      <ListScreen
        ref={listScreenRef}
        context={formik}
        columns={columns}
        data={messages}
        title={getMessage('sidebar.schedules')}
        titleButtonAdd={getMessage('new')}
        onSearch={onSearch}
        filtersList={filtersList}
        validFieldsKeys={validFieldsKeys}
        dataFilters={filters}
        onChange={requestPage}
        onChangeCreateButton={onChangeCreateButton}
        searchPlaceholder={getMessage('phone')}
        pagination={{
          currentPage: page + 1,
          pages: pages,
          total: count
        }}
        onFilter={handleFilter}
        onClickDelete={onClickDelete}
        modalDelete={modalDelete}
        setModalDelete={setModalDelete}
        onClickEdit={onClickEdit}
        onClickView={onClickView}
        onClickDuplicate={onClickDuplicate}
        hideCreateButton
        hideActionsColumn
      />
    </>
  )
}

export default ListSchedules
