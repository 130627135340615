import React from 'react'

import { CustomBreadcrumb2 } from '../../components'
import RenderContainer from './components/RenderContainer'
import { FormikProvider } from 'formik'
const DynamicRender = props => {
  const { header, data = [], formik } = props

  return (
    <>
      <FormikProvider value={formik}>
        <CustomBreadcrumb2 title={header.title} btnBack={header.btnBack} btnAction={header.btnAction}>
          {header.extraBreadcrumbButtons}
        </CustomBreadcrumb2>

        <RenderContainer data={data} formik={formik} />
      </FormikProvider>
    </>
  )
}

export default DynamicRender
