import React, { useEffect, useState } from 'react'
import { Form, Row, Button, Radio } from 'antd'
import { api, request } from '../../../../util/handlerRequestUtil'
import { get, isEmpty } from 'lodash'
import { getMessage } from '../../../../util/IntlMessages'
import { maskCep, onlyNumbers } from '../../../../util/format'
import { useFormik } from 'formik'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CustomInput, CustomSelect, CustomRadioGroup } from '../../../../components'
import * as yup from 'yup'
import AddressInfo from './addressInfo'

import './delivery.css'
import { formatSelectOptions } from '../../util/functions'

function Delivery(props) {
  const { formValues, setSelectedTab, alert, setFormValues, setTotalDelivery, id, isReadOnly } = props
  const [hasDelivery, setHasDelivery] = useState(false)
  const [customer, setCustomer] = useState({})
  const [visible, setVisible] = useState(true)
  const [couriers, setCouriers] = useState([])
  const [step, setStep] = useState(id ? 2 : 1)
  const [filters, setFilters] = useState({
    states: [],
    cities: []
  })
  const formik = useFormik({
    initialValues: {},
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: yup.object().shape({
      zipCode: yup
        .string()
        .typeError()
        .required(getMessage('isRequired', getMessage('zipCode'))),
      street: yup
        .string()
        .typeError()
        .required(getMessage('isRequired', getMessage('street'))),
      number: yup
        .string()
        .typeError()
        .required(getMessage('isRequired', getMessage('number'))),
      neighborhood: yup
        .string()
        .typeError()
        .required(getMessage('isRequired', getMessage('neighborhood'))),
      cityName: yup
        .string()
        .typeError()
        .required(getMessage('isRequired', getMessage('cityName')))
    }),
    onSubmit: async values => {
      if (customer.id) {
        await updateCustomer(customer.id, values)
      } else {
        const address = await formatAddressToPayload(values)
        setFormValues({
          ...formValues,
          shipping: { ...formValues.shipping, address }
        })
        setStep(2)
      }
    }
  })
  const { values, handleSubmit, setValues } = formik

  useEffect(() => {
    async function requests() {
      try {
        requestState(true)
        const couriers = await requestDeliveryCourier()

        setCouriers(couriers)
      } catch (error) {
        console.tron.log(error)
      }
    }
    requests()
  }, [])

  useEffect(() => {
    if (values.delivery) {
      setHasDelivery(values.delivery)

      if (formValues.shipping) {
        setAddress(get(formValues, 'shipping.address'))
      }
    }
  }, [values.delivery])

  useEffect(() => {
    if (values.state) {
      requestCity(values.state)
    }
  }, [values.state])

  useEffect(() => {
    if (!values.value) return

    const valueFormated = String(values.value)
      .replace('R$', '')
      .trim()

    setTotalDelivery(parseFloat(valueFormated))
  }, [values.value])

  useEffect(() => {
    if (id && !isEmpty(get(formValues, 'shipping'))) {
      const { totalShipping, shipping } = formValues

      setValues({
        delivery: 'yes',
        courier: shipping.courierId,
        value: totalShipping
      })
    }
  }, [id])

  useEffect(() => {
    async function retrieveCustomer() {
      const customer = await request(api.retrievePerson(formValues.customer))
      setCustomer(customer)
    }

    if (formValues.customer) {
      retrieveCustomer(formValues.customer)
    }
  }, [formValues.customer])

  useEffect(() => {
    if (values.delivery === 'no') {
      setFormValues(Object.assign(formValues, { shipping: {} }))
    }
  }, [values.delivery])

  async function requestState(skip) {
    try {
      const states = await request(api.listState({ ignorePagination: true }), skip)
      const format = get(states, 'items', []).map(s => ({
        value: s.stateAbbreviation,
        description: s.stateName
      }))

      setFilters(f => ({
        ...f,
        ...{ states: format }
      }))
    } catch (error) {
      console.tron.log(error)
    }
  }

  async function requestCity(state, skip) {
    try {
      const cities = await request(api.listCity({ ignorePagination: true, stateAbbreviation: state }), skip)
      const format = get(cities, 'items', []).map(c => ({
        value: c.id,
        description: c.cityName
      }))

      setFilters(f => ({
        ...f,
        ...{ cities: format }
      }))
    } catch (error) {
      console.tron.log(error)
    }
  }

  const requestDeliveryCourier = () =>
    request(api.listDeliveryCouriers()).then(({ items }) => formatSelectOptions(items, values.courier))

  const requestUpdateCustomer = (id, payload) =>
    request(api.updatePerson(id, { ...payload })).then(res =>
      setFormValues({ ...formValues, shipping: { ...formValues.shipping, addressId: get(res, 'addresses[0].id') } })
    )

  const updateCustomer = async (customerId, values) => {
    try {
      const customerPayload = await payloadCustomerUpdate(values)

      await requestUpdateCustomer(customerId, customerPayload)
      alert.open(getMessage('successUpdateActions'), 'success')
      setStep(2)
    } catch (error) {
      console.tron.log(error)
    }
  }

  function formatAddressToPayload(values) {
    return {
      id: get(customer, 'addresses[0].id', 0),
      zipCode: onlyNumbers(values.zipCode),
      cityId: typeof values.cityName === 'string' && values.cityId ? Number(values.cityId) : Number(values.cityName),
      neighborhood: values.neighborhood,
      streetName: values.street,
      number: values.number,
      complement: values.complement
    }
  }

  function payloadCustomerUpdate(values) {
    const address = formatAddressToPayload(values)
    let newPerson = { ...customer }

    if (get(newPerson, 'addresses', []).length > 1) {
      newPerson.addresses[0] = address
    } else {
      newPerson.addresses = [address]
    }
    delete newPerson.id
    return newPerson
  }

  const setAddress = address => {
    if (address) {
      const { zipCode, cityId, city, neighborhood, streetName, number, complement } = address

      setValues({
        ...values,
        cityId,
        zipCode,
        state: get(city, 'stateName'),
        cityName: get(city, 'cityName'),
        neighborhood,
        street: streetName,
        number,
        complement
      })
    }
  }

  const onCancel = () => {
    setVisible(false)
  }

  const onOk = () => {
    setAddress(get(customer, 'addresses[0]', undefined))
    setVisible(false)
  }

  return (
    <Form layout={'vertical'} onSubmit={handleSubmit} className="container__delivery">
      <Row>
        <CustomRadioGroup context={formik} field="delivery" span={8} value="no" disabled={isReadOnly}>
          <Radio.Button value="yes">{getMessage('yes')}</Radio.Button>
          <Radio.Button value="no">{getMessage('no')}</Radio.Button>
        </CustomRadioGroup>
      </Row>

      {hasDelivery === 'yes' && (
        <>
          {step === 1 && (
            <>
              {get(customer, 'addresses[0]', undefined) && visible && !id && (
                <AddressInfo customer={customer} onCancel={onCancel} onOk={onOk} />
              )}
              <Row className="container__delivery__top">
                <CustomInput
                  context={formik}
                  field="zipCode"
                  rowSpan={12}
                  mask="11111-111"
                  required
                  disabled={isReadOnly}
                />
                <CustomSelect
                  showSearch
                  context={formik}
                  field="state"
                  rowSpan={12}
                  values={filters.states}
                  disabled={isReadOnly}
                />
                <CustomSelect
                  showSearch
                  context={formik}
                  field="cityName"
                  rowSpan={12}
                  values={filters.cities}
                  disabled={!values.state || isReadOnly}
                  required
                />
                <CustomInput context={formik} field="neighborhood" rowSpan={12} required disabled={isReadOnly} />
                <CustomInput context={formik} field="street" rowSpan={12} required disabled={isReadOnly} />
                <CustomInput context={formik} field="number" rowSpan={12} required disabled={isReadOnly} />
                <CustomInput context={formik} field="complement" rowSpan={24} disabled={isReadOnly} />
              </Row>
              <Row justify="end" className="container__delivery__bottom">
                <Button type="primary" className={'mr5'} onClick={() => (!isReadOnly ? handleSubmit() : setStep(2))}>
                  <span>{getMessage('continue')}</span>
                </Button>
              </Row>
            </>
          )}
          {step === 2 && (
            <>
              <Row className="container__delivery__top__step2">
                <div>
                  <FontAwesomeIcon icon={faMapMarkerAlt} size="lg" color="#5339b5" />
                  <span onClick={() => setStep(1)}>{`CEP ${maskCep(values.zipCode)}`}</span>
                </div>
                <Row>
                  <CustomSelect context={formik} field="courier" rowSpan={12} values={couriers} disabled={isReadOnly} />
                  <CustomInput
                    context={formik}
                    field="value"
                    rowSpan={12}
                    prefix="R$ "
                    type="number"
                    disabled={isReadOnly}
                    required={values.courier ? true : false}
                  />
                </Row>
              </Row>
              <Row justify="end" className="container__delivery__bottom">
                <Button
                  type="primary"
                  className={'mr5'}
                  onClick={() => {
                    if (values.courier && !values.value) {
                      return alert.open(getMessage('isRequired', getMessage('value')))
                    }

                    console.log({ ...formValues.shipping, courierId: values.courier, total: values.value })

                    setFormValues({
                      ...formValues,
                      shipping: { ...formValues.shipping, courierId: values.courier, total: values.value }
                    })
                    setSelectedTab('5')
                  }}
                >
                  <span>{getMessage('continue')}</span>
                </Button>
              </Row>
            </>
          )}
        </>
      )}
    </Form>
  )
}

export default Delivery
