import React from 'react'
import '@ant-design/compatible/assets/index.css'

import { Button, Row, Col, Card } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as icons from '@fortawesome/free-solid-svg-icons'

import { If } from 'react-extras'
import NumberFormat from '../../components/NumberFormart'
import { getMessage } from '../../util/IntlMessages'
import sucessRegister from '../../assets/images/sucessRegister.png'
import { fadeInUp } from 'react-animations'

import Radium, { StyleRoot } from 'radium'
import './styles.css'

const styles = {
  bounce: {
    animation: 'x 1.0s',
    animationName: Radium.keyframes(fadeInUp, 'bounce')
  }
}

function SuccessRegister(props) {
  const { operation = 'store', product = {}, onReset, onList } = props
  const { name = 'Seu produto', variations = [] } = product
  return (
    <StyleRoot>
      <Row>
        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
          <Card className="d-flex justify-content-center text-center">
            <img alt="success" src={sucessRegister} style={styles.bounce}></img>
            <If condition={operation === 'store'}>
              <h1 className="title" style={styles.bounce}>
                {getMessage('product.success.register')}
              </h1>
              <label style={styles.bounce}>{getMessage('product.success.register.description', name)}</label>
            </If>
            <div className="mt-2 containerScrollRegisteredProducts" style={styles.bounce}>
              {variations.map(({ id, sku, price }) => (
                <div key={id} className="d-flex bg-tertiary p-2 pr-3 pl-3 mb-1">
                  <label className="flex-1 text-start">{sku}</label>
                  <div className="d-flex flex-2">
                    <NumberFormat className="flex-2 text-end" prefix="R$ " displayType={'text'} value={price} />
                  </div>
                </div>
              ))}
            </div>
            <Row className="d-flex flex-row mt-4">
              <Col className="p-1 flex-1 bg-white border-primary mb-1" xs={24} sm={24} md={16} lg={16} xl={16}>
                <Button
                  icon={<FontAwesomeIcon className={'mr-2'} icon={icons.faPlus}></FontAwesomeIcon>}
                  className="bg-white border-primary text-primary w-100"
                  onClick={() => onReset()}
                >
                  {getMessage('product.new')}
                </Button>
              </Col>

              <Col className="p-1 flex-1" xs={24} sm={24} md={16} lg={16} xl={16}>
                <Button onClick={() => onList()} type="primary" className="w-100">
                  {getMessage('product.view')}
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </StyleRoot>
  )
}

export default SuccessRegister
