import { formatToSelect } from '../../../util/format'

// must get field option values and field value
export function formatSelectOptions(values = [], fieldId) {
  const hasShowErp = values.some(v => v.showErp)

  if (hasShowErp) {
    const comboValues = values.filter(v => v.showErp)

    if (!fieldId) return formatToSelect(comboValues)

    const fieldValues = comboValues.find(v => v.id === fieldId)

    return formatToSelect(fieldValues ? comboValues : [...comboValues, fieldValues])
  } else {
    return formatToSelect(values)
  }
}
