import React, { useState, useEffect } from 'react'
import { Collapse, Divider } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getMessage } from '../../../../util/IntlMessages'
import { formatPrice, normalizeValue } from '../../../../util/format'
import { api, request } from '../../../../util/handlerRequestUtil'
import { get, round } from 'lodash'
import * as icons from '@fortawesome/free-solid-svg-icons'
import { ProductsItems } from './orderPreviewProducts'
import './orderPreview.css'

const { Panel } = Collapse
const url = 'https://erp-aju.s3.amazonaws.com/1595617610000.jpeg'

function OrderPreview(props) {
  const { items = [], setItemsOrder, formValues, discounts, rates, totalDelivery, isReadOnly, alert } = props
  const [worker, setWorker] = useState({})
  const [platform, setPlatform] = useState({})
  const [store, setStore] = useState({})
  const [customer, setCustomer] = useState({})
  const [subTotal, setSubTotal] = useState(0)

  useEffect(() => {
    if (formValues.employee) retriveWorker()
    if (formValues.platform) retrivePlatform()
    if (formValues.store) retrieveStore()
    if (formValues.customer) retrieveCustomer()
  }, [formValues.employee, formValues.platform, formValues.store, formValues.customer])

  useEffect(() => {
    setSubTotal(
      items.reduce(
        (total, p) => total + (p.filledByVariation.finalPrice || p.filledByVariation.price) * p.filledByVariation.qtd,
        0
      )
    )
  }, [items])

  async function retriveWorker() {
    const worker = await request(api.retrieveEmployee(formValues.employee))
    setWorker(worker)
  }

  async function retrivePlatform() {
    const platform = await request(api.retrievePlatform(formValues.platform))
    setPlatform(platform)
  }

  async function retrieveStore() {
    const store = await request(api.retrieveStoreBranch(formValues.store))
    setStore(store)
  }

  async function retrieveCustomer() {
    const customer = await request(api.retrievePerson(formValues.customer))
    setCustomer(customer)
  }

  const renderHeaderWorker = worker => (
    <div className="collapse__worker__header">
      <img src={url} alt="" />
      <span>{get(worker, 'name', 'Não encontrado')}</span>
    </div>
  )

  const removeProductItem = (data, indexToDrop) => {
    const order = data.filter((p, i) => i !== indexToDrop)

    setItemsOrder([...order])
  }

  const handlePrice = (id, values) => {
    const finalPrice = values.finalPrice ? normalizeValue(values.finalPrice) : values.finalPrice

    const orders = items.map(order => {
      if (order.filledByVariation.id === id) {
        return {
          ...order,
          filledByVariation: {
            ...order.filledByVariation,
            ...values,
            ...(finalPrice && { finalPrice: round(finalPrice, 2) })
          }
        }
      }
      return order
    })

    setItemsOrder(orders)
  }

  return (
    <>
      <div className="headerPreview">
        <FontAwesomeIcon icon={icons.faShoppingCart} size="lg" className="headerPreview__icon" />
        <span>{getMessage('order')}</span>
      </div>
      <div className="content__orderPreview">
        <Collapse expandIconPosition="right" bordered={false}>
          <Panel className="collapse__worker" header="Vendedor (a)" extra={renderHeaderWorker(worker)} key="1">
            <div className="collapse__worker__storeAndPlatform">
              <span>{getMessage('customer')}</span>
              <span>{get(customer, 'name', 'Não encontrado')}</span>
            </div>
            <div className="collapse__worker__storeAndPlatform">
              <span>{getMessage('store')}</span>
              <span>{get(store, 'name', 'Não encontrado')}</span>
            </div>
            <div className="collapse__worker__storeAndPlatform">
              <span>{getMessage('platform')}</span>
              <span>{get(platform, 'name', 'Não encontrado')}</span>
            </div>
          </Panel>
          <Divider className="collapse__divider" />
        </Collapse>
        <ProductsItems
          data={items}
          remove={removeProductItem}
          isReadOnly={isReadOnly}
          handlePrice={handlePrice}
          alert={alert}
          formValues={formValues}
        />
      </div>
      <div className="content__footer">
        <Divider className="collapse__divider" />
        <div className="totals">
          <div className="totals__title">
            <span>Subtotal:</span>
            <span>+ Taxas:</span>
            <span>+ Entrega:</span>
            <span>- Descontos:</span>
            <span>{`Total(${items.length} itens):`}</span>
          </div>
          <div className="totals__value">
            <span>{formatPrice(normalizeValue(subTotal))}</span>
            <span>{formatPrice(normalizeValue(rates))}</span>
            <span>{formatPrice(normalizeValue(totalDelivery))}</span>
            <span>{formatPrice(normalizeValue(discounts))}</span>
            <span>
              {formatPrice(
                normalizeValue(subTotal) +
                  normalizeValue(rates) +
                  normalizeValue(totalDelivery) -
                  normalizeValue(discounts)
              )}
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default OrderPreview
