import React, { useState, useEffect } from 'react'
import { Row } from 'antd'
import { useFormik } from 'formik'
import { api, request } from '../../util/handlerRequestUtil'
import queryString from 'query-string'

import CreateScreen from '../../components/CreateScreen'
import CustomInput from '../../components/CustomInput/customInput'
import './createProvider.css'
import * as yup from 'yup'

function CreateProvider(props) {
  const { id } = props.match.params
  const { alert, loading, getMessage } = props
  const isDuplicate = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true
  }).duplicate
  const formik = useFormik({
    initialValues: {},
    validationSchema: yup.object().shape({
      // cli-yup-declaration
      cnpj: yup
        .number()
        .typeError()
        .required(getMessage('isRequired', getMessage('cnpj'))),

      name: yup
        .string()
        .typeError()
        .required(getMessage('isRequired', getMessage('name')))
    }),
    onSubmit: values => {
      let formatedValues = { ...values }

      if (values.cnpj) {
        formatedValues.cnpj = Number(values.cnpj)
      }

      onSubmit(formatedValues)
    }
  })
  const { setValues } = formik
  const [setFilters] = useState({})

  useEffect(() => {
    requests()
  }, [])

  //requests
  async function requests() {
    try {
      loading.open()

      // cli-request-function
      if (id) {
        await retrieve(id)
      }
      if (isDuplicate) {
        await retrieve(isDuplicate, true)
      }
      setFilters({
        // cli-request-filter
      })
    } catch (error) {
      console.tron.log(error)
    } finally {
      loading.close()
    }
  }

  // cli-requests

  function fields(isReadOnly) {
    return (
      <Row type="flex" justify="start">
        <CustomInput disabled={isReadOnly} field="name" rowSpan={12} context={formik} />

        <CustomInput disabled={isReadOnly} field="cnpj" rowSpan={12} context={formik} />

        {/* cli-fields */}
      </Row>
    )
  }

  async function onSubmit(values) {
    try {
      if (id) {
        await request(api.updateProvider(id, values))
        alert.open(getMessage('successUpdateProvider'), 'success')
      } else {
        await request(api.storeProvider(values))
        alert.open(getMessage('successStoreProvider'), 'success')
      }

      props.history.push(`/provider/list`)
    } catch (error) {}
  }

  async function retrieve(id, isDuplicate) {
    try {
      const provider = await request(api.retrieveProvider(id), true)
      if (isDuplicate) {
        delete provider.id
      }
      setValues({
        ...provider
      })
    } catch (error) {}
  }

  return (
    <CreateScreen
      fields={fields}
      context={formik}
      editTitle={getMessage('updateProvider')}
      editTitleButton={getMessage('edit')}
      newTitle={getMessage('newProvider')}
      newTitleButton={getMessage('register')}
      readOnlyTitle={getMessage('viewProvider')}
      readOnlyTitleButton={getMessage('edit')}
      isEdit={id}
    />
  )
}

export default CreateProvider
