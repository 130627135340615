export function getGroupField(column, data) {
  const dates = new Set()
  data.forEach(item => {
    dates.add(item[column])
  })
  return [...dates]
}

export function getArrayGroupField(
  columnFilter,
  columnTarget,
  data,
  dataX,
  secundaryFilterField,
  secundaryFilterValue
) {
  const dates = []
  dataX.forEach(item => {
    if (secundaryFilterField && secundaryFilterValue) {
      const itemToPersist = data.find(
        item2 => item === item2[columnFilter] && item2[secundaryFilterField] === secundaryFilterValue
      )
      if (!itemToPersist) {
        dates.push(0)
      } else {
        dates.push(itemToPersist[columnTarget])
      }
    } else {
      const itemToPersist = data.find(item2 => item === item2[columnFilter])
      dates.push(itemToPersist[columnTarget])
    }
  })
  return dates
}

export function defaultConfigChartLine(title = '', categories = []) {
  return {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    title: {
      text: title,
      align: 'left'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      }
    },
    xaxis: {
      categories: categories
    },
    animations: {
      enabled: true,
      easing: 'easeinout',
      speed: 800,
      animateGradually: {
        enabled: true,
        delay: 150
      },
      dynamicAnimation: {
        enabled: true,
        speed: 350
      }
    }
  }
}

export function defaultConfigChartPie(title = '', categories = []) {
  return {
    labels: categories
  }
}
