import React from 'react'
import { Table, Row, Popconfirm } from 'antd'
import { CustomIcon } from '..'
import { formatDateWithHour } from '../../util/format'
import { getMessage } from '../../util/IntlMessages'

import './RecipeTable.css'

const RecipeTable = ({ data, isReadOnly, deleteRecipe, enableRecipeEdit }) => {
  function handlerChange(index, row) {
    return (
      <>
        {isReadOnly ? (
          <CustomIcon className="recipe_view_icon" name="faEye" onClick={() => enableRecipeEdit(row)} />
        ) : (
          <>
            <CustomIcon className="recipe_edit_icon" name="faEdit" onClick={() => enableRecipeEdit(row)} />
            <Popconfirm
              title={getMessage('info.delete')}
              onConfirm={() => deleteRecipe(index)}
              okText="Sim"
              cancelText="Não"
            >
              <CustomIcon className="recipe_delete_icon" name="faTrash" />
            </Popconfirm>
          </>
        )}
      </>
    )
  }

  const columns = [
    {
      title: 'Responsável',
      dataIndex: 'doctorName',
      align: 'center'
    },
    {
      title: 'Validade',
      dataIndex: 'dueDate',
      align: 'center',
      render: text => formatDateWithHour(text, 'date')
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      align: 'center',
      width: '20%',
      render: function(text, row, index) {
        return handlerChange(index, row)
      }
    }
  ]

  return (
    <Row>
      <Table
        key="key"
        className="tb__recipe"
        columns={columns}
        dataSource={data}
        bordered
        scroll={{ x: 600 }}
        locale={{ emptyText: getMessage('noRecords') }}
      />
    </Row>
  )
}

export default RecipeTable
