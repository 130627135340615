import React, { useState, useEffect } from 'react'
import { Row } from 'antd'
import { useFormik } from 'formik'
import { get } from 'lodash'
import { CustomInput } from '../../components'
import { api, request } from '../../util/handlerRequestUtil'
import ListScreen from '../../components/ListScreen'

function ListTypeTicket(props) {
  const { alert, getMessage, loading } = props
  const formik = useFormik({
    initialValues: {}
  })
  const [modalDelete, setModalDelete] = useState({
    description: getMessage('defaultDeleteDescription'),
    visible: false
  })
  const { values, setFieldValue } = formik
  const [data, setData] = useState({})
  const [person, setPerson] = useState({})
  const [filters, setFilters] = useState({
    formatedStatus: []
  })

  const { page = 1, pageSize = 10, count = 0, pages = 0 } = data
  const typeTickets = get(data, 'items', [])

  //requests
  async function requests() {
    try {
      loading.open()

      const [store, tags, status, employee] = await Promise.all([requestData()])

      setFilters(f => ({
        ...f,
        ...store,
        ...status,
        ...tags,
        ...employee
      }))
    } catch (error) {
      console.tron.log(error)
    } finally {
      loading.close()
    }
  }

  function onClickEdit(order) {
    props.history.push(`/type-tickets/${order.id}`)
  }

  async function onClickDelete(typeTicket) {
    setModalDelete({
      ...modalDelete,
      title: `${getMessage('deleteTypeTicket')} - ID: ${typeTicket.id}`,
      visible: true,
      onOk: async () => onConfirmDelete(typeTicket)
    })
  }

  async function onConfirmDelete(typeTicket) {
    await request(api.deleteTypeTicket(typeTicket.id))
    setModalDelete({
      ...modalDelete,
      visible: false
    })
    alert.open(getMessage('successTypeTicketDelete'), 'success')
    await requestData(values)
  }

  function onClickView(typeTicket) {
    props.history.push(`/type-tickets/${typeTicket.id}?readOnly=true`)
  }

  const requestData = (params = {}) =>
    request(
      api.listTypeTickets({
        ...params,
        enable: true
      }),
      true
    ).then(data => setData(data))

  function handleFilter(value) {
    const { name } = values

    let filters = {
      ...(name && { name: name })
    }

    if (value && value.trim() !== '') {
      filters.name = value
    }
    requestData({
      ...filters
    })
  }

  const onSearch = value => handleFilter(value)

  async function requestPage(page) {
    await requestData({ page: page - 1 })
  }

  const onChangeCreateButton = () => {
    props.history.push('/type-tickets/create')
  }

  const columns = [
    {
      title: getMessage('name'),
      dataIndex: 'name',
      key: 'name'
    }
  ]

  useEffect(() => {
    requests()
  }, [])

  function filtersList() {
    return (
      <Row type="flex" justify="start">
        <CustomInput context={formik} field="name" rowSpan={12} />
      </Row>
    )
  }

  const validFieldsKeys = ['name']

  useEffect(() => {
    setFilters(f => ({
      ...f
    }))
    setFieldValue('customer', person.id)
  }, [person])

  useEffect(() => {
    if (!values.customer) {
      setPerson({})
    }
  }, [values.customer])

  return (
    <ListScreen
      context={formik}
      columns={columns}
      data={typeTickets}
      title={getMessage('typeTickets')}
      titleButtonAdd={getMessage('newTypeTicket')}
      onSearch={onSearch}
      filtersList={filtersList}
      validFieldsKeys={validFieldsKeys}
      dataFilters={filters}
      onChange={requestPage}
      onChangeCreateButton={onChangeCreateButton}
      pagination={{
        currentPage: page + 1,
        pages: pages,
        total: count,
        pageSize: pageSize
      }}
      onFilter={handleFilter}
      onClickDelete={onClickDelete}
      modalDelete={modalDelete}
      setModalDelete={setModalDelete}
      onClickEdit={onClickEdit}
      onClickView={onClickView}
      searchPlaceholder={getMessage('typeTickets')}
    />
  )
}

export default ListTypeTicket
