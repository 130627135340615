import React from 'react'
import { FormattedMessage, injectIntl, IntlProvider } from 'react-intl'

import locale from '../lngProvider/entries/pt-BR'

const intlProvider = new IntlProvider(locale)
const { intl } = intlProvider.getChildContext()

export function getMessage(id, ...parameters) {
  const message = intl.formatMessage({
    id
  })
  if (message) {
    if (!parameters) {
      return message
    } else {
      let finalText = message
      for (let i = 0; i < parameters.length; i++) {
        finalText = finalText.replace(`[${i + 1}]`, parameters[i])
      }

      return finalText
    }
  }

  return ''
}

const InjectMassage = props => <FormattedMessage {...props} />
export default injectIntl(InjectMassage, {
  withRef: false
})
