export const responsiveCol = rowSpan => {
  return {
    xs: 24,
    sm: 24,
    md: rowSpan,
    lg: rowSpan,
    xl: rowSpan
  }
}

let ARRAY_INSTANCE = undefined
export const cid = () => {
  const crypto = window.crypto || window.msCrypto

  if (!ARRAY_INSTANCE) {
    ARRAY_INSTANCE = new Uint32Array(1)
  }

  return crypto.getRandomValues(ARRAY_INSTANCE)[0]
}

export function insertIf(condition, ...elements) {
  return condition ? elements : []
}
