import React from 'react'
import '@ant-design/compatible/assets/index.css'

import { Button, Row, Col, Radio, Divider } from 'antd'
import * as icons from '@fortawesome/free-solid-svg-icons'
import CustomSelect from '../../components/CustomSelect/customSelect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CustomRadioGroup from '../../components/CustomRadioGroup/customRadioGroup'
import { getMessage } from '../../util/IntlMessages'
import VariationBlock from '../../components/VariationsBlock'
import CustomInput from '../../components/CustomInput/customInput'

function Step3(props) {
  const { formik, filters, onAddVariation, attributeItems, setAttributeItems, variations, setVariations, isReadOnly = false } = props

  const { values, handleSubmit } = formik
  return (
    <div>
      <div className="content-tabPane">
        <Row type="flex" justify="start">
          {values.productType === 'variable' && (
            <CustomSelect
              disabled={isReadOnly}
              showSearch
              context={formik}
              field="attributeTable"
              rowSpan={24}
              values={filters.formatedAttribute}
              multiple
            />
          )}
          <Col span={12}></Col>
          <CustomRadioGroup disabled={isReadOnly} context={formik} field="manageInventory" span={12} value={false}>
            <Radio.Button value={false}>Não</Radio.Button>
            <Radio.Button value={true}>Sim</Radio.Button>
          </CustomRadioGroup>
          {values.manageInventory && (
            <>
              <CustomInput
                disabled={isReadOnly}
                showSearch
                context={formik}
                field="minStock"
                rowSpan={12}
                values={''}
              />
              <CustomInput
                disabled={isReadOnly}
                showSearch
                context={formik}
                field="maxStock"
                rowSpan={12}
                values={''}
              />
              <CustomInput
                disabled={isReadOnly}
                showSearch
                context={formik}
                field="minStockPerBranch"
                rowSpan={12}
                values={''}
              />
              <CustomInput
                disabled={isReadOnly}
                showSearch
                context={formik}
                field="maxStockPerBranch"
                rowSpan={12}
                values={''}
              />
              {/* <CustomInput disabled={isReadOnly} showSearch context={formik} field="locationStock" rowSpan={8} values={''} /> */}
            </>
          )}
        </Row>
      </div>
      {values.productType === 'variable' && values.attributeTable && values.attributeTable.length > 0 && (
        <Row type="flex" justify="start">
          <Col span={24}>
            <Divider className="divider-tabValues">
              <Button className="btn-divider-tabValues" onClick={() => onAddVariation()} disabled={isReadOnly}>
                <FontAwesomeIcon icon={icons.faPlus} className="icon colorPrimary" size="sm" />
                <span>Adicionar Variação</span>
              </Button>
            </Divider>
          </Col>
        </Row>
      )}
      {variations.map(item => (
        <VariationBlock
          key={item.id}
          filters={filters}
          attributeItems={attributeItems}
          attributeTable={values.attributeTable}
          formik={formik}
          variationId={item.id}
          setVariations={setVariations}
          variations={variations}
          isReadOnly={isReadOnly}
          setAttributeItems={setAttributeItems}
        ></VariationBlock>
      ))}

      {!isReadOnly && (
        <div className="content-form-btns">
          <Button
            type="primary"
            onClick={() => {
              handleSubmit()
            }}
            className={'mr5'}
          >
            <span>{getMessage('complete')}</span>
          </Button>
        </div>
      )}
    </div>
  )
}

export default Step3
