import { Creators as LoadingActions } from '../appRedux/ducks/loading'
import { Creators as AlertActions } from '../appRedux/ducks/alert'
import { getMessage } from './IntlMessages'
import store, { history } from '../appRedux/store'

export const helper = () => {
  return {
    loading: {
      open: () => store.dispatch(LoadingActions.openLoading()),
      close: () => store.dispatch(LoadingActions.closeLoading()),
      setDescription: () => store.dispatch(LoadingActions.setDescription()),
      setPercent: () => store.dispatch(LoadingActions.setPercent())
    },
    alert: {
      open: (msg, type = 'success', ignoreTranslation = false) =>
        store.dispatch(AlertActions.openAlert(ignoreTranslation ? msg : getMessage(msg), type))
    },
    history,
    getMessage,
    store
  }
}
