import React, { useState, useEffect } from 'react'
import { Row } from 'antd'
import { useFormik } from 'formik'
import CreateScreen from '../../components/CreateScreen'
import CustomSelect from '../../components/CustomSelect/customSelect'
import CustomInput from '../../components/CustomInput/customInput'
import queryString from 'query-string'
import { formatedTriggers, formatedOnlyNewCustomers, formatedProductType } from './TemplateMessageConst'
import { api, request } from '../../util/handlerRequestUtil'
import * as yup from 'yup'

function CreateTemplateMessage(props) {
  const { id } = props.match.params
  const { alert, loading, getMessage } = props
  const isDuplicate = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true
  }).duplicate
  const formik = useFormik({
    initialValues: {
      category: null,
      description: '',
      afterDays: 0,
      afterMinutes: 0,
      trigger: '',
      enable: true
    },
    validationSchema: yup.object().shape({
      category: yup.number().typeError(),
      description: yup
        .string()
        .typeError()
        .required(getMessage('isRequired', getMessage('description'))),
      afterDays: yup.number().typeError(),
      afterMinutes: yup.number().typeError(),
      specificHour: yup.string().typeError(),
      trigger: yup
        .string()
        .typeError()
        .required(getMessage('isRequired', getMessage('trigger')))
    }),
    onSubmit: values => {
      onSubmit(values)
    }
  })
  const { setValues } = formik
  const [filters, setFilters] = useState({})

  useEffect(() => {
    requests()
  }, [])

  async function requests() {
    try {
      loading.open()
      const formatedCategory = await requestCategory()
      if (id) {
        await retrieve(id)
      }
      if (isDuplicate) {
        await retrieve(isDuplicate, true)
      }
      setFilters({
        ...formatedCategory,
        formatedTriggers,
        formatedOnlyNewCustomers,
        formatedProductType
      })
    } catch (error) {
      console.tron.log(error)
    } finally {
      loading.close()
    }
  }

  const requestCategory = () =>
    request(
      api.listCategory({
        ignorePagination: true
      }),
      true
    ).then(({ items }) => {
      return {
        formatedCategory: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name
          }
        })
      }
    })

  function fields(isReadOnly) {
    return (
      <Row type="flex" justify="start">
        <CustomInput disabled={isReadOnly} field="description" rowSpan={12} context={formik} />
        <CustomSelect
          disabled={isReadOnly}
          context={formik}
          showSearch
          field="trigger"
          rowSpan={12}
          values={filters.formatedTriggers}
        />
        <CustomSelect
          disabled={isReadOnly}
          context={formik}
          showSearch
          field="productType"
          rowSpan={12}
          values={filters.formatedProductType}
        />
        <CustomInput disabled={isReadOnly} field="afterDays" rowSpan={12} context={formik} />
        <CustomInput disabled={isReadOnly} field="afterMinutes" rowSpan={12} context={formik} />
        <CustomInput disabled={isReadOnly} type="hour" field="specificHour" rowSpan={12} context={formik} />
        <CustomSelect
          context={formik}
          showSearch
          name="categoryId"
          field={getMessage('productCategory')}
          ignoreTranslate
          rowSpan={12}
          disabled={isReadOnly}
          values={filters.formatedCategory}
        />
        <CustomSelect
          context={formik}
          showSearch
          field="onlyNewCustomers"
          rowSpan={12}
          values={filters.formatedOnlyNewCustomers}
        />
      </Row>
    )
  }

  async function onSubmit(values) {
    try {
      const { afterDays, afterMinutes } = values
      values.afterDays = Number(afterDays)
      values.afterMinutes = Number(afterMinutes)
      if (id) {
        await request(api.updateTemplateMessage(id, values))
        alert.open(getMessage('successUpdateTemplateMessage'), 'success')
      } else {
        await request(api.storeTemplateMessage(values))
        alert.open(getMessage('successStoreTemplateMessage'), 'success')
      }
      props.history.push(`/template-messages/list`)
    } catch (error) {}
  }

  async function retrieve(id, isDuplicate) {
    try {
      const response = await request(api.retrieveTemplateMessage(id), true)
      if (isDuplicate) {
        delete response.id
      }
      setValues({
        ...response,
        category: response.categoryId
      })
    } catch (error) {}
  }

  function onClickBack() {
    props.history.push('/template-messages/list')
  }

  return (
    <CreateScreen
      fields={fields}
      context={formik}
      editTitle={getMessage('updateTemplateMessage')}
      editTitleButton={getMessage('edit')}
      newTitle={getMessage('newTemplateMessage')}
      newTitleButton={getMessage('register')}
      onClickBack={() => onClickBack()}
      readOnlyTitle={getMessage('viewTemplateMessage')}
      readOnlyTitleButton={getMessage('edit')}
      isEdit={id}
    />
  )
}

export default CreateTemplateMessage
