import React, { useState, useEffect } from 'react'
import './styles.css'

import { Form, Modal, Row, Col } from 'antd'
import _ from 'lodash'

function ModalChooseImage(props) {
  const { visible, onOk, handleReset, context, imageSelected } = props
  const formLayout = 'vertical'
  const [selectedImage, setSelectedImage] = useState({})

  useEffect(() => {
    console.tron.log('imageSelected', imageSelected)
    if (imageSelected) {
      setSelectedImage({ ...imageSelected })
    }
  }, [imageSelected])

  return (
    <>
      <Modal visible={visible} closable={true} footer={null} onCancel={handleReset}>
        <Form layout={formLayout} initialValues={{ layout: formLayout }}>
          <Row>
            <Col span={24}>
              <h3>Selecione a foto</h3>
              <Row>
                {_.get(context, 'values.files') &&
                  context.values.files.length > 0 &&
                  context.values.files.map(file => (
                    <Col
                      xs={12}
                      sm={12}
                      md={8}
                      lg={8}
                      xl={8}
                      className="containerUploadColumn"
                      key={file.location}
                      onClick={() => {
                        setSelectedImage(file)
                        setTimeout(() => {
                          onOk(selectedImage)
                          setSelectedImage({})
                        }, 350)
                      }}
                    >
                      <img
                        alt="variationImg"
                        className={`imgUpload  ${
                          selectedImage.location === file.location ? 'selectedImage borderPrimary' : ''
                        }`}
                        key={file.location}
                        src={file.location}
                      ></img>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default ModalChooseImage
