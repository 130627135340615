import React, { useState, useEffect } from 'react'
import { Row } from 'antd'
import { useFormik } from 'formik'

import CreateScreen from '../../components/CreateScreen'
import CustomInput from '../../components/CustomInput/customInput'
import queryString from 'query-string'
import { api, request } from '../../util/handlerRequestUtil'
import './createStore.css'
import * as yup from 'yup'

function CreateStore(props) {
  const { id } = props.match.params
  const { alert, loading, getMessage } = props
  const isDuplicate = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true
  }).duplicate
  const formik = useFormik({
    initialValues: {},
    validationSchema: yup.object().shape({
      // cli-yup-declaration
      name: yup
        .string()
        .typeError()
        .required(getMessage('isRequired', getMessage('name')))
    }),
    onSubmit: values => {
      let formatedValues = { ...values }

      onSubmit(formatedValues)
    }
  })
  const { setValues } = formik
  const [setFilters] = useState({})

  useEffect(() => {
    requests()
  }, [])

  //requests
  async function requests() {
    try {
      loading.open()

      // cli-request-function
      if (id) {
        await retrieve(id)
      }
      if (isDuplicate) {
        await retrieve(isDuplicate, true)
      }
      setFilters({
        // cli-request-filter
      })
    } catch (error) {
      console.tron.log(error)
    } finally {
      loading.close()
    }
  }

  // cli-requests

  function fields(isReadOnly) {
    return (
      <Row type="flex" justify="start">
        <CustomInput disabled={isReadOnly} field="name" rowSpan={12} context={formik} />

        {/* cli-fields */}
      </Row>
    )
  }

  function onClickBack() {
    props.history.push('/store/list')
  }

  async function onSubmit(values) {
    try {
      if (id) {
        await request(api.updateStore(id, values))
        alert.open(getMessage('successUpdateStore'), 'success')
      } else {
        await request(api.storeStore(values))
        alert.open(getMessage('successStoreStore'), 'success')
      }

      props.history.push(`/Store/list`)
    } catch (error) {}
  }

  async function retrieve(id, isDuplicate) {
    try {
      const response = await request(api.retrieveStore(id), true)
      if (isDuplicate) {
        delete response.id
      }
      setValues({
        ...response
        // cli-retrieve-state
      })
    } catch (error) {}
  }

  return (
    <CreateScreen
      fields={fields}
      context={formik}
      editTitle={getMessage('updateStore')}
      editTitleButton={getMessage('edit')}
      newTitle={getMessage('newStore')}
      newTitleButton={getMessage('register')}
      readOnlyTitle={getMessage('viewStore')}
      readOnlyTitleButton={getMessage('edit')}
      onClickBack={() => onClickBack()}
      isEdit={id}
    />
  )
}

export default CreateStore
