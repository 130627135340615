export const STATUS = {
  PENDING: 'pending',
  COMPLETED: 'completed',
  FAILED: 'failed'
}

const STATUS_NORMALIZATION = {
  pending: 'Em processamento',
  completed: 'Finalizado',
  failed: 'Falhou'
}

export function getStatus(status) {
  return STATUS_NORMALIZATION[status] || status
}
