import React, { useState, useEffect } from 'react'
import { Row, Button, Input, Table, Col } from 'antd'
import { useFormik } from 'formik'
import { getMessage } from '../../../../util/IntlMessages'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatedPrice } from '../../../../util/functions'
import { api, request } from '../../../../util/handlerRequestUtil'
import { generateFilters } from '../../../../util/filtersUtil'
import { CustomInput } from '../../../../components'
import * as icons from '@fortawesome/free-solid-svg-icons'
import get from 'lodash/get'
import TagsFilter from '../../../../components/TagsFilter'
import ModalFilters from '../../../../components/ModalFilters'
import debounceEvent from '../../../../util/debounce'
import Pagination from '../../../../components/Pagination/index'
import ProductModal from './productModal'
import './products.css'

const { Column } = Table

const Products = function(props) {
  const { setSelectedTab, setItemToOrder, isReadOnly, formValues } = props

  const [loadingTable, setLoadingTable] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [name, setName] = useState(' ')
  const [filterValues, setFilterValues] = useState([])
  const [visible, setVisible] = useState(false)
  const [productModalVisible, setProductModalVisible] = useState(false)
  const [expandedRowKeys, setExpandedRowKeys] = useState([''])
  const [selectedProduct, setSelectedProduct] = useState({})
  const [products, setProducts] = useState([])
  const [expandedProducts, setExpandedProducts] = useState([])
  const { page = 0, pages = 0 } = products
  const formatedProducts = get(products, 'items', []).map(item => {
    return {
      key: item.id,
      sku: item.sku,
      name: item.name,
      stock: item.variations.reduce((total, curr) => {
        const hasStock = get(curr, 'stock', []).find(stock => stock.storeBranchId === formValues.store)

        return (total += hasStock ? hasStock.quantity : 0)
      }, 0),
      price: item.variations.map(item => (item.price ? item.price : 0)),
      promotionPrice: item.variations.map(item => (item.salePrice ? item.salePrice : 0))
    }
  })

  const formikProduct = useFormik({
    initialValues: {},
    validateOnBlur: false,
    validateOnChange: false
  })

  const { values, setFieldValue, handleReset } = formikProduct

  useEffect(() => {
    async function requests() {
      try {
        setLoadingTable(true)
        await requestProducts()
      } catch (error) {
        console.tron.log(error)
      } finally {
        setLoadingTable(false)
      }
    }
    requests()
  }, [])

  useEffect(() => {
    if (values.forceUpdate) {
      handleFilter(name)
      setFieldValue('forceUpdate', false)
    }
  }, [values.forceUpdate])

  const requestProducts = (params = {}) =>
    request(api.listProduct({ include: 'variations', ...params })).then(data => setProducts(data))

  function handleFilter(value) {
    const { name } = values
    let filters = {
      ...(name && { name })
    }

    if (value && value.trim() !== '') {
      filters.nameOrSku = value
    }

    requestProducts({
      ...filters
    })
  }

  const onSearch = value => handleFilter(value)

  const onChangePage = async page => await requestProducts({ page: page - 1 })

  const getSelectedProduct = async id => {
    const wasExpanded = expandedProducts.find(p => p.id === id)

    try {
      setModalLoading(true)
      if (wasExpanded) {
        setSelectedProduct(wasExpanded)
      } else {
        const product = await request(api.retrieveProduct(id), { skipLoading: true })

        setExpandedProducts(values => [...values, { ...product }])
        setSelectedProduct(product)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setModalLoading(false)
    }
  }

  const showModal = () => setVisible(true)

  function handleOk() {
    generateFilters(values, setFilterValues, []) //data
    setVisible(false)
    handleFilter()
  }

  const handleCloseTag = item => {
    setFilterValues(filterValues.filter(item2 => item2.identifier !== item.identifier))
    setFieldValue(item.identifier, undefined)
    setFieldValue('forceUpdate', true)
  }

  function filtersList() {
    return (
      <Row>
        <CustomInput context={formikProduct} field="name" rowSpan={24} />
      </Row>
    )
  }

  return (
    <>
      <div className="product-content">
        <Row>
          <Row className="content-search">
            <Col xs={24} sm={24} md={10} lg={10} xl={10} className="product-search">
              <Input
                onChange={event => {
                  const text = event.target.value
                  setName(text)
                  debounceEvent(() => onSearch(text))
                }}
                placeholder="Procure  por nome ou SKU"
                suffix={<FontAwesomeIcon icon={icons.faSearch} size="lg" className="icon" />}
                className="input_search"
                disabled={isReadOnly}
              />
              {/* <Button type="primary" className="btn_filter" onClick={showModal} disabled={isReadOnly}>
                <FontAwesomeIcon icon={icons.faSlidersH} size="lg" className="icon" />
              </Button> */}
            </Col>
            <Col className="gutter-row rowTags" xs={24} sm={24} md={14} lg={14} xl={14}>
              <div className="containerTags">
                <TagsFilter filterValues={filterValues} onClose={handleCloseTag} />
              </div>
            </Col>
            <Col span={24} className="product-pagination">
              <Pagination currentPage={page + 1} pages={pages} onChange={onChangePage} />
            </Col>
          </Row>
          <Table
            loading={loadingTable}
            className="tb-products"
            expandedRowKeys={expandedRowKeys}
            dataSource={formatedProducts}
            scroll={{ x: 700, y: 300 }}
            bordered
            pagination={false}
            size="middle"
            expandable={
              !isReadOnly && {
                expandedRowRender: () => {},
                onExpandedRowsChange: rows =>
                  setExpandedRowKeys(rows[rows.length - 1] ? [rows[rows.length - 1]] : ['']),
                onExpand: (expanded, record) => {
                  setSelectedProduct({})
                  getSelectedProduct(record.key)

                  setProductModalVisible(expanded)
                }
              }
            }
          >
            <Column title={getMessage('sku')} dataIndex="sku" key="sku" />
            <Column title={getMessage('name')} dataIndex="name" key="name" />
            <Column title={getMessage('stock')} dataIndex="stock" key="stock" width={90} align="center" />
            <Column title={getMessage('price')} dataIndex="price" key="price" render={data => formatedPrice(data)} />
            <Column
              title={getMessage('promotionPrice')}
              dataIndex="promotionPrice"
              key="promotionPrice"
              render={data => formatedPrice(data)}
            />
          </Table>
        </Row>
        <Row justify="end" className="btn__end">
          <Button
            type="primary"
            onClick={() => {
              setSelectedTab('4')
            }}
          >
            <span>{getMessage('closeOrder')}</span>
          </Button>
        </Row>

        <ProductModal
          loading={modalLoading}
          setExpandedRowKeys={setExpandedRowKeys}
          data={selectedProduct}
          visible={productModalVisible}
          setItemToOrder={setItemToOrder}
          setVisible={setProductModalVisible}
          formValues={formValues}
        />

        <ModalFilters
          visible={visible}
          onOk={handleOk}
          style={{ width: '20vw' }}
          handleReset={handleReset}
          onCancel={() => setVisible(false)}
        >
          {filtersList()}
        </ModalFilters>
      </div>
    </>
  )
}

export default Products
