import { Card, Tabs } from 'antd'
import { CustomInput } from '../../index'
import { Divider } from 'antd'
const containerTypes = {
  card: Card,
  tab: Tabs
}

const fieldTypes = {
  inputText: CustomInput, 
  hr:  Divider
}

export { containerTypes, fieldTypes }
