import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Form, Row, Button, Col, Modal } from 'antd'
import { getMessage } from '../../../../util/IntlMessages'
import { normalizeValue, formatPrice } from '../../../../util/format'
import { useFormik } from 'formik'
import { get, isEmpty } from 'lodash'
import { api, request } from '../../../../util/handlerRequestUtil'
import { Creators as AlertActions } from '../../../../appRedux/ducks/alert'
import { CustomSelect, CustomInput, CustomTextArea } from '../../../../components/index'
import { PRESCRIPTION_HMTL_TABLE } from '../../util/constants'
import * as yup from 'yup'
import moment from 'moment-timezone'
import PaymentTable from './tablePayments'
import './payment.css'
import { ORDER_TYPES } from '../../types'
import { formatSelectOptions } from '../../util/functions'

function Payment(props) {
  const { items, setDiscounts, setRates, formValues, history, alert, id, totalDelivery, isReadOnly, loading } = props

  const dispatch = useDispatch()
  const [yupValidation, setYupValidation] = useState({})
  const [subTotal, setSubTotal] = useState(0)
  const [lack, setLack] = useState(0)
  const [payments, setPayments] = useState([])
  const [totalPayment, setTotalPayment] = useState(0)
  const [filters, setFilters] = useState({
    methods: [],
    parcels: [],
    gateways: [],
    flags: []
  })

  const formik = useFormik({
    initialValues: {},
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: yupValidation,
    onSubmit: async values => {
      addPaymentForm(values)
    }
  })
  const { values, handleSubmit, setValues, setFieldValue } = formik

  useEffect(() => {
    async function requests() {
      try {
        loading.open()

        const [methods, gateways, flags, parcels] = await Promise.all([
          requestPaymentMethod(),
          requestGateway(),
          requestFlag(),
          requestParcel()
        ])

        setFilters({
          ...filters,
          ...methods,
          ...gateways,
          ...flags,
          ...parcels
        })

        if (!id) {
          setFieldValue('orderDate', moment(new Date()))
        }
      } catch (error) {
        console.tron.log(error)
      } finally {
        loading.close()
      }
    }
    requests()
  }, [])

  useEffect(() => {
    if (id && filters.parcels.length > 0) {
      const formatedPayments = formValues.payments.map(p => ({
        id: p.id,
        paymentForm: p.paymentMethodId,
        flag: Number(p.flag),
        gateway: Number(p.gateway),
        parcels: p.parcels,
        transationCode: Number(p.idGateway),
        value: p.totalValue
      }))

      formatedPayments.forEach(payment => {
        addPaymentForm(payment)
      })

      setValues({
        ...values,
        rates: formValues.totalTax,
        discounts: formValues.totalDiscounts,
        ...(formValues.deliveryDate && { deliveryDate: moment(formValues.deliveryDate) }),
        ...(formValues.orderDate && { orderDate: moment(formValues.orderDate) }),
        ...(formValues.dueDate && { dueDate: moment(formValues.dueDate) })
      })
    }
  }, [filters.parcels])

  useEffect(() => {
    if (isPaymentCreditCard(values)) {
      setYupValidation(
        yup.object().shape({
          paymentForm: yup.number().required(getMessage('isRequired', getMessage('paymentForm'))),
          value: yup.string().required(getMessage('isRequired', getMessage('value'))),
          parcels: yup.number().required(getMessage('isRequired', getMessage('parcels'))),
          gateway: yup.number().required(getMessage('isRequired', getMessage('gateway'))),
          flag: yup.number().required(getMessage('isRequired', getMessage('flag')))
        })
      )
    } else if (isPaymentDebitCard(values)) {
      setYupValidation(
        yup.object().shape({
          paymentForm: yup.number().required(getMessage('isRequired', getMessage('paymentForm'))),
          value: yup.string().required(getMessage('isRequired', getMessage('value'))),
          gateway: yup.number().required(getMessage('isRequired', getMessage('gateway'))),
          flag: yup.number().required(getMessage('isRequired', getMessage('flag')))
        })
      )
    } else {
      setYupValidation(
        yup.object().shape({
          paymentForm: yup.number().required(getMessage('isRequired', getMessage('paymentForm'))),
          value: yup.string().required(getMessage('isRequired', getMessage('value')))
        })
      )
    }
  }, [values.paymentForm])

  useEffect(() => {
    setSubTotal(
      items.reduce(
        (total, p) => total + (p.filledByVariation.finalPrice || p.filledByVariation.price) * p.filledByVariation.qtd,
        0
      )
    )
  }, [items])

  useEffect(() => {
    if (payments) {
      const totalPayment = payments.reduce((total, curr) => (total += curr.value), 0)
      setTotalPayment(totalPayment)
    }
  }, [payments])

  useEffect(() => {
    let discount = values.discounts ? (id ? values.discounts : formatToFloat(values.discounts)) : 0
    let rate = values.rates ? (id ? values.rates : formatToFloat(values.rates)) : 0

    setDiscounts(discount)
    setRates(rate)

    const totalLack =
      normalizeValue(subTotal) -
      normalizeValue(discount) -
      normalizeValue(totalPayment) +
      normalizeValue(rate) +
      normalizeValue(totalDelivery)

    setLack(+totalLack.toFixed(2))
  }, [subTotal, values.discounts, values.rates, totalPayment, totalDelivery])

  const requestPaymentMethod = () =>
    request(
      api.listPaymentMethods({
        ignorePagination: true
      }),
      { skipLoading: true }
    ).then(({ items }) => ({
      methods: formatSelectOptions(items, values.paymentForm)
    }))

  const requestParcel = () =>
    request(api.listPaymentParcels(), { skipLoading: true }).then(items => ({
      parcels: items.map(v => ({
        value: Number(v),
        description: `${v}x`
      }))
    }))

  const requestGateway = () =>
    request(api.listPaymentGateways(), { skipLoading: true }).then(({ items }) => ({
      gateways: items.map(g => ({
        value: g.id,
        description: g.name
      }))
    }))

  const requestFlag = () =>
    request(api.listPaymentFlags(), { skipLoading: true }).then(({ items }) => ({
      flags: items.map(f => ({
        value: f.id,
        description: f.name
      }))
    }))

  const isPaymentCreditCard = ({ paymentForm }) => {
    if (paymentForm) {
      const { description } = filters.methods.find(m => m.value === paymentForm)
      if (description === 'Cartão de Crédito') return true
    }
    return false
  }

  const isPaymentDebitCard = ({ paymentForm }) => {
    if (paymentForm) {
      const { description } = filters.methods.find(m => m.value === paymentForm)
      if (description === 'Cartão de Débito') return true
    }
    return false
  }

  const formatToFloat = value =>
    parseFloat(
      value
        .slice(2, value.length)
        .trim('')
        .split('')
        .filter(v => v !== '.')
        .map(v => (v === ',' ? '.' : v))
        .join('')
    )

  const addPaymentForm = values => {
    const paymentMethod = filters.methods.find(m => m.value === values.paymentForm)
    const flagSelected = filters.flags.find(m => m.value === values.flag)
    const gatewaySeleced = filters.gateways.find(m => m.value === values.gateway)

    if (!id && formatToFloat(values.value) > lack && (isPaymentCreditCard(values) || isPaymentDebitCard(values))) {
      dispatch(AlertActions.openAlert(getMessage('infoAddPaymentMethod'), 'info'))
      return
    }

    setPayments(payments => [
      ...payments,
      {
        ...(id && { id: values.id }),
        payment: paymentMethod,
        parcels: values.parcels,
        transationCode: values.transationCode,
        value: typeof values.value === 'string' ? formatToFloat(values.value) : values.value,
        flag: flagSelected,
        gateway: gatewaySeleced
      }
    ])

    const { discounts, rates } = values
    setValues({ ...values, discounts, rates, value: '' })
  }

  const onClickDelete = index => {
    Modal.confirm({
      title: getMessage('infoDeletePayment'),
      onOk() {
        const paymentsNew = payments.filter((p, i) => i !== index)
        setPayments(paymentsNew)
      }
    })
  }

  const checkDisabledAdd = () => {
    // if (subTotal === 0) return true
    if (lack <= 0) return true
    return false
  }

  const addOrder = async values => {
    const paymentFormated = payments.map(p => ({
      ...(p.id && { id: p.id }),
      paymentMethodId: get(p, 'payment.value'),
      totalValue: p.value,
      ...(get(p, 'gateway.value') && { gateway: String(p.gateway.value) }),
      ...(get(p, 'flag.value') && { flag: String(p.flag.value) }),
      ...(p.parcels && { parcels: Number(p.parcels) }),
      ...(p.transationCode && { idGateway: String(p.transationCode) })
    }))

    const productsFormated = items.map(({ id, finalName, name, filledByVariation }) => ({
      ...(id && { id }),
      variationId: filledByVariation.id,
      productId: filledByVariation.productId,
      statusId: filledByVariation.statusId,
      recipeId: filledByVariation.recipeId,
      quantity: filledByVariation.qtd,
      price: +filledByVariation.price,
      finalPrice: filledByVariation.finalPrice,
      lineItem: finalName || name,
      dueDate: filledByVariation.dueDateDelivery
    }))

    const {
      discountsTotal,
      ratesTotal,
      customer,
      store,
      platform,
      employee,
      shipping = [],
      totalShipping,
      notes,
      status,
      internalStatus,
      tags,
      orderDate,
      deliveryDate,
      dueDate,
      orderType
    } = values

    const totalDelivery = get(shipping, 'total') || totalShipping

    const formatData = data => {
      if (moment.isMoment(data)) {
        return data.toISOString(true)
      }

      return data
    }

    const payload = {
      orderType,
      ...(orderDate && { orderDate: formatData(orderDate) }),
      ...(deliveryDate && { deliveryDate: formatData(deliveryDate) }),
      ...(dueDate && { dueDate: formatData(dueDate) }),
      tags,
      ...(internalStatus && { internalStatusId: internalStatus }),
      statusId: status,
      totalShipping: typeof totalDelivery === 'string' ? parseFloat(totalDelivery.replace('R$', '')) : totalDelivery,
      totalTax: +ratesTotal || 0,
      totalDiscounts: +discountsTotal || 0,
      notes,
      personId: customer,
      employeeId: employee,
      storeBranchId: store,
      platformId: platform,
      payments: paymentFormated,
      products: productsFormated,
      ...(!isEmpty(shipping) && {
        shipping: [
          {
            courierId: get(shipping, 'courierId'),
            addressId: get(shipping, 'addressId'),
            address: get(shipping, 'address')
          }
        ]
      })
    }

    try {
      if (id) {
        await request(api.updateOrder(id, payload))
      } else {
        await request(api.storeOrder(payload))
      }

      alert.open(getMessage('successCreateActionTypes'), 'success')
      history.push(`/order/list`)
    } catch (error) {
      console.tron.log(error)
    }
  }

  const checkAddTable = (tags = []) => {
    const hasLensTag = tags.some(tag => tag.toLowerCase() === 'lente')

    if (hasLensTag) {
      setFieldValue('notes', PRESCRIPTION_HMTL_TABLE)
    } else {
      setFieldValue('notes', '')
    }
  }

  useEffect(() => {
    if (!id) checkAddTable(formValues.tags)

    if (formValues.notes && id) setFieldValue('notes', formValues.notes)
  }, [formValues.tags, formValues.notes, id])

  const renderNotes = () => (
    <Col span={24}>
      <Form.Item label={getMessage('notes')} className="notes">
        <CustomTextArea
          value={formik.values.notes || ''}
          setValue={value => formik.setFieldValue('notes', value)}
          readOnly={isReadOnly || id}
          alert={alert}
        />
      </Form.Item>
    </Col>
  )

  const renderDueDate = () => (
    <CustomInput context={formik} field="dueDate" rowSpan={6} type="date" disabled={isReadOnly || id} />
  )

  return (
    <Form layout={'vertical'} onSubmit={handleSubmit} className="container__payment">
      {formValues.orderType === ORDER_TYPES.SERVICE && formValues.parentOrder && (
        <Row>
          <Col>
            <span>
              Dados do pagamento estão disponíves no pedido
              <Button type="link" onClick={() => window.open(`/order/${formValues.parentOrder}`)}>
                #{formValues.parentOrder}
              </Button>
            </span>
          </Col>
        </Row>
      )}
      {formValues.parentOrder && formValues.orderType === ORDER_TYPES.SERVICE && (
        <Row>
          {renderDueDate()}
          {renderNotes()}
        </Row>
      )}
      {!formValues.parentOrder && (
        <>
          <Row className="container__payment__top">
            <CustomSelect
              context={formik}
              field="paymentForm"
              rowSpan={12}
              values={filters.methods}
              disabled={isReadOnly || id}
            />
            {(isPaymentDebitCard(values) || isPaymentCreditCard(values)) && (
              <>
                <CustomInput
                  context={formik}
                  field="transationCode"
                  rowSpan={12}
                  placeholder={getMessage('insertCode')}
                />
                {isPaymentCreditCard(values) && (
                  <CustomSelect context={formik} field="parcels" rowSpan={12} values={filters.parcels} required />
                )}
                <CustomSelect context={formik} field="gateway" rowSpan={12} values={filters.gateways} required />
                <CustomSelect context={formik} field="flag" rowSpan={12} values={filters.flags} required />
              </>
            )}
            <CustomInput
              context={formik}
              field="value"
              rowSpan={12}
              prefix="R$ "
              type="number"
              required
              disabled={isReadOnly || id}
            />
            <Col span={24} className="valor">
              <Button type="primary" disabled={checkDisabledAdd()} onClick={() => handleSubmit()}>
                <span>{getMessage('add')}</span>
              </Button>
            </Col>

            <CustomInput
              context={formik}
              field="discounts"
              rowSpan={6}
              prefix="R$ "
              type="number"
              disabled={isReadOnly || id}
            />
            <CustomInput
              context={formik}
              field="rates"
              rowSpan={6}
              prefix="R$ "
              type="number"
              disabled={isReadOnly || id}
            />
            <CustomInput context={formik} field="orderDate" rowSpan={6} type="date" disabled={isReadOnly || id} />
            {formValues.orderType === ORDER_TYPES.SERVICE && renderDueDate()}

            <Col span={24}>{renderNotes()}</Col>

            <PaymentTable data={payments} deleteEntry={onClickDelete} isReadOnly={isReadOnly || id} />
          </Row>
        </>
      )}
      <Row justify="end" className="container__payment__bottom">
        {lack >= 0 && <span>{`${getMessage('missingValue')}: ${formatPrice(lack)}`}</span>}
        {lack < 0 && <span>{`${getMessage('change')}: ${formatPrice(lack * -1)}`}</span>}
        <Button
          type="primary"
          disabled={lack > 0 || payments.length === 0 || isReadOnly}
          onClick={() => {
            const params = {
              notes: values.notes ? values.notes : formValues.notes,
              orderDate: values.orderDate ? values.orderDate : formValues.orderDate,
              deliveryDate: values.deliveryDate ? values.deliveryDate : formValues.deliveryDate,
              dueDate: values.dueDate ? values.dueDate : formValues.dueDate
            }
            addOrder({ ...formValues, ...params })
          }}
        >
          <span>{getMessage('end')}</span>
        </Button>
      </Row>
    </Form>
  )
}

export default Payment
