import React from 'react'

import './config/ReactotronConfig'
import { Provider } from 'react-redux'

import { ConnectedRouter } from 'connected-react-router'
import { PersistGate } from 'redux-persist/integration/react'

import { hot } from 'react-hot-loader/root'
import 'assets/vendors/style'
import 'styles/wieldy.less'
import './app.css'
import store, { history, persistor } from './appRedux/store'
import Loading from './components/Loading/loading'
import Alert from './components/Alert/alert'
import Routes from './routes/routes'
import SyncDrawer from './containers/SyncDrawer'
import { ErrorBoundary } from './components'

const NextApp = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <ErrorBoundary>
          <Routes />
          <Loading />
          <Alert />
          <SyncDrawer />
        </ErrorBoundary>
      </ConnectedRouter>
    </PersistGate>
  </Provider>
)

export default hot(NextApp)
