import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { createStore, compose, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import reducers from '../reducers/index'
import sagas from '../sagas/index'

const history = createBrowserHistory()
const routeMiddleware = routerMiddleware(history)
const middlewares = [routeMiddleware]
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'settings']
}
const sagaMonitor = process.env.NODE_ENV === 'development' ? console.tron.createSagaMonitor() : null

const sagaMiddleware = createSagaMiddleware({ sagaMonitor })

middlewares.push(sagaMiddleware)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const composer =
  process.env.NODE_ENV === 'development'
    ? composeEnhancers(applyMiddleware(...middlewares), console.tron.createEnhancer())
    : composeEnhancers(applyMiddleware(...middlewares))

const rootReducer = reducers(history)

const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(persistedReducer, composer)
const persistor = persistStore(store)

sagaMiddleware.run(sagas)

export default store
export { history, persistor }
