import React from 'react'
import { Card, Button } from 'antd'
import { formatAddress } from '../../../../util/format'
import { get } from 'lodash'

import './addressInfo.css'
import { getMessage } from '../../../../util/IntlMessages'

function delivery(props) {
  const { customer, onCancel, onOk } = props

  return (
    <>
      <Card className="card__address">
        <div className="card__address__info">
          <strong>Este é o endereço de entrega?</strong>
          <strong>{get(customer, 'name')}</strong>
          <span>{formatAddress(get(customer, 'addresses[0]', undefined))}</span>
        </div>
        <div className="card__address__actions">
          <Button onClick={() => onOk()}>{getMessage('yes')}</Button>
          <Button onClick={() => onCancel()}>{getMessage('no')}</Button>
        </div>
      </Card>
    </>
  )
}

export default delivery
