import React from 'react'
import { useHistory } from 'react-router'

import { CustomScreen } from '../../components'

import data from './mock.json'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { generateYupValidation } from '../../util/customFieldsUtil'

const DynamicRender = () => {
  const history = useHistory()
  const formik = useFormik({
    initialValues: {},
    validationSchema: yup.object().shape({
      ...generateYupValidation(data)
    }),
    onSubmit: () => {
      alert('form submetido')
    }
  })
  return (
    <CustomScreen
      formik={formik}
      header={{
        title: 'create',
        btnBack: { onClick: () => history.push('/dr2') },
        btnAction: { title: 'create', onClick: () => formik.submitForm() }
      }}
      data={data}
    />
  )
}

export default DynamicRender
