import React from 'react'
import NumberFormat from 'react-number-format'
import { If } from 'react-extras'

function NumberFormatCustom(props) {
  const { displayType, value } = props
  return (
    <>
      <If condition={displayType === 'text'}>
        <NumberFormat
          displayType={'text'}
          thousandSeparator={'.'}
          decimalSeparator={','}
          {...props}
          value={value.toFixed(2).replace('.', ',')}
        />
      </If>
      <If condition={displayType !== 'text'}>
        <NumberFormat
          thousandSeparator={'.'}
          decimalSeparator={','}
          {...props}
          value={value.toFixed(2).replace('.', ',')}
        />
      </If>
    </>
  )
}

export default NumberFormatCustom
