import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Input } from 'antd'
import { useFormik } from 'formik'
import CreateScreen from '../../components/CreateScreen'
import { CustomInput, CustomSelect, ModalCustomerSelect, CustomTextArea, CustomIcon } from '../../components'
import queryString from 'query-string'
import { api, request } from '../../util/handlerRequestUtil'
import * as yup from 'yup'
import { formatedPriority, formatedStatus } from './TicketConst'
import { get } from 'lodash'

function CreateTicket(props) {
  const { id } = props.match.params
  const { alert, loading, getMessage } = props
  const isDuplicate = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true
  }).duplicate

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      enable: true
    },
    validationSchema: yup.object().shape({
      type: yup
        .number()
        .typeError('must be number')
        .required(getMessage('isRequired', getMessage('type'))),
      subject: yup
        .string()
        .typeError('must be string')
        .required(getMessage('isRequired', getMessage('subject'))),
      description: yup
        .string()
        .typeError('must be string')
        .required(getMessage('isRequired', getMessage('description'))),
      priority: yup
        .string()
        .typeError('must be string')
        .required(getMessage('isRequired', getMessage('priority'))),
      dueDate: yup
        .string()
        .typeError('must be date')
        .required(getMessage('isRequired', getMessage('dueDate')))
    }),
    onSubmit: values => {
      onSubmit(values)
    }
  })
  const { setValues } = formik
  const [filters, setFilters] = useState({})
  const [visibleModalCustomerSelect, setVisibleModalCustomerSelect] = useState(false)
  const [indicationPerson, setIndicationPerson] = useState({})

  useEffect(() => {
    requests()
  }, [])

  useEffect(() => {
    formik.setFieldValue('customer', indicationPerson)
  }, [indicationPerson])

  async function requests() {
    try {
      loading.open()

      if (id) {
        await retrieve(id)
      }

      if (isDuplicate) {
        await retrieve(isDuplicate, true)
      }

      if (!formik.values.description) {
        formik.setFieldValue(
          'description',
          '<p><strong>Descrição do ticket:</strong></p><p><strong>Solução:</strong></p>'
        )
      }

      const formatedTicketTypes = await requestTicketTypes()

      setFilters({
        ...formatedTicketTypes,
        formatedStatus,
        formatedPriority
      })
    } catch (error) {
      console.tron.log(error)
    } finally {
      loading.close()
    }
  }

  const requestTicketTypes = () =>
    request(
      api.listTypeTickets({
        ignorePagination: true
      }),
      true
    ).then(({ items }) => {
      return {
        formatedTicketTypes: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name
          }
        })
      }
    })

  function fields(isReadOnly) {
    return (
      <Row type="flex" justify="start" align="middle">
        <CustomInput disabled={isReadOnly} context={formik} field="subject" rowSpan={24} required />
        <CustomSelect
          disabled={isReadOnly}
          context={formik}
          showSearch
          field="type"
          rowSpan={12}
          values={filters.formatedTicketTypes}
          required
        />
        <CustomSelect
          disabled={isReadOnly}
          context={formik}
          showSearch
          field="priority"
          rowSpan={12}
          values={filters.formatedPriority}
          required
        />
        <CustomInput disabled={isReadOnly} type="date" context={formik} field="closingDate" rowSpan={12} />
        <CustomInput disabled={isReadOnly} type="date" context={formik} field="dueDate" rowSpan={12} required />
        <Col
          style={{ marginTop: 10 }}
          span={24}
          {...(!isReadOnly && { onClick: () => setVisibleModalCustomerSelect(true) })}
        >
          <Form.Item label={getMessage('customer')}>
            <Input
              style={{ pointerEvents: isReadOnly ? 'none' : 'all' }}
              value={get(formik, 'values.customer.name')}
              suffix={<CustomIcon name="faPlus" />}
              disabled={isReadOnly}
            />
          </Form.Item>
        </Col>
        <CustomTextArea
          value={formik.values.description}
          setValue={value => formik.setFieldValue('description', value)}
          readOnly={isReadOnly}
        />
      </Row>
    )
  }

  async function onSubmit(values) {
    const { customer, ...respParams } = values
    const payload = { customer: customer.id, ...respParams }

    if (id) {
      await request(api.updateTicket(id, payload))
      alert.open(getMessage('successTicketUpdate'), 'success')
    } else {
      await request(api.storeTicket(payload))
      alert.open(getMessage('successTicketStore'), 'success')
    }

    props.history.push(`/tickets/list`)
  }

  async function retrieve(id, isDuplicate) {
    const response = await request(api.retrieveTicket(id), true)

    if (isDuplicate) {
      delete response.id
    }

    setValues(response)
  }

  function onClickBack() {
    props.history.push('/tickets/list')
  }

  return (
    <>
      <ModalCustomerSelect
        visible={visibleModalCustomerSelect}
        setVisible={setVisibleModalCustomerSelect}
        setPerson={setIndicationPerson}
      />
      <CreateScreen
        fields={fields}
        context={formik}
        editTitle={getMessage('updateTicket')}
        editTitleButton={getMessage('edit')}
        newTitle={getMessage('newTicket')}
        newTitleButton={getMessage('register')}
        onClickBack={() => onClickBack()}
        readOnlyTitle={getMessage('viewTicket')}
        readOnlyTitleButton={getMessage('edit')}
        isEdit={id}
      />
    </>
  )
}

export default CreateTicket
