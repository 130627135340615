import React from 'react'
import { useSelector } from 'react-redux'

import { Modal, Spin, Typography } from 'antd'

const Loading = function() {
  const show = useSelector(store => store.loading.show)
  return (
    <Modal zIndex={9999} visible={show} footer={null} header={null} closable={false}>
      <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <Spin />
      </div>
      <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <Typography.Title level={4}>Carregando...</Typography.Title>
      </div>
    </Modal>
  )
}

export default Loading
