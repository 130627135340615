/* eslint-disable prettier/prettier */
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Creators as AlertActions } from '../appRedux/ducks/alert'
import { Creators as LoadingActions } from '../appRedux/ducks/loading'
import { getMessage } from '../util/IntlMessages'
import './adminRoutes.css'

import Home from '../containers/Home/home'
import CreateProduct from '../containers/Products/createProduct'
import Products from '../containers/Products/products'
import ListProvider from '../containers/Provider/listProvider'
import CreateProvider from '../containers/Provider/createProvider'
import ListCategory from '../containers/Category/listCategory'
import CreateCategory from '../containers/Category/createCategory'
import ListBrand from '../containers/Brand/listBrand'
import CreateBrand from '../containers/Brand/createBrand'
import ListOrder from '../containers/Order/listOrder'
import Calendar from '../containers/Order/calendarOs'
import CreateOrder from '../containers/Order/CreateOrder/tabs'
import ListAttribute from '../containers/Attribute/listAttribute'
import CreateAttribute from '../containers/Attribute/createAttribute'
import ListStore from '../containers/Store/listStore'
import CreateStore from '../containers/Store/createStore'
import ListStoreBranch from '../containers/StoreBranch/listStoreBranch'
import CreateStoreBranch from '../containers/StoreBranch/createStoreBranch'
import ListAttributeItem from '../containers/AttributeItem/listAttributeItem'
import CreateAttributeItem from '../containers/AttributeItem/createAttributeItem'
import CreateEmployee from '../containers/Employee/createEmployee'
import ListEmployee from '../containers/Employee/listEmployee'
import Persons from '../containers/Persons/listPersons'
import CreatePerson from '../containers/Persons/create/createPerson'
import ListPlatform from '../containers/Platform/Platform'
import TestPaulo from '../containers/Test/TestPaulo'
import DynamicRender from '../containers/DynamicRender'
import ListTemplateMessage from '../containers/TemplateMessage/listTemplateMessage'
import CreateTemplateMessage from '../containers/TemplateMessage/createTemplateMessage'
import ListMessages from '../containers/Messages/listMessages'
import CreateMessage from '../containers/Messages/createMessages'
import ListSchedules from '../containers/Schedules/listSchedules'
import ViewSchedule from '../containers/Schedules/ViewSchedule'
import ListTypeTicket from '../containers/TypeTicket/ListTypeTicket'
import CreateTypeTicket from '../containers/TypeTicket/CreateTypeTicket'
import ListTicket from '../containers/Ticket/ListTicket'
import CreateTicket from '../containers/Ticket/CreateTicket'
import Dashboard from '../containers/Home/dashboardOrders'

const App = ({ match }) => {
  const dispatch = useDispatch()
  const globalProps = {
    loading: {
      open: () => dispatch(LoadingActions.openLoading()),
      close: () => dispatch(LoadingActions.closeLoading())
    },
    alert: {
      open: (text, type) => dispatch(AlertActions.openAlert(text, type))
    },
    getMessage
  }

  return (
    <div className="app">
      <Switch>
        <Route path={`${match.url}dashboard`} render={props => <Dashboard {...props} {...globalProps}></Dashboard>} />
        <Route path={`${match.url}home`} render={props => <Home {...props} {...globalProps}></Home>} />
        <Route
          path={`${match.url}person/create`}
          render={props => <CreatePerson {...props} {...globalProps}></CreatePerson>}
        />
        <Route path={`${match.url}person/list`} render={props => <Persons {...props} {...globalProps}></Persons>} />
        <Route
          path={`${match.url}person/:id`}
          render={props => <CreatePerson {...props} {...globalProps}></CreatePerson>}
        />
        <Route
          path={`${match.url}products/create`}
          render={props => <CreateProduct {...props} {...globalProps}></CreateProduct>}
        />
        <Route path={`${match.url}products/list`} render={props => <Products {...props} {...globalProps}></Products>} />
        <Route
          path={`${match.url}products/:id`}
          render={props => <CreateProduct {...props} {...globalProps}></CreateProduct>}
        />
        <Route
          path={`${match.url}provider/list`}
          render={props => <ListProvider {...props} {...globalProps}></ListProvider>}
        />
        <Route
          path={`${match.url}provider/create`}
          render={props => <CreateProvider {...props} {...globalProps}></CreateProvider>}
        />
        <Route
          path={`${match.url}provider/:id`}
          render={props => <CreateProvider {...props} {...globalProps}></CreateProvider>}
        />
        <Route
          path={`${match.url}category/list`}
          render={props => <ListCategory {...props} {...globalProps}></ListCategory>}
        />
        <Route
          path={`${match.url}category/create`}
          render={props => <CreateCategory {...props} {...globalProps}></CreateCategory>}
        />
        <Route
          path={`${match.url}category/:id`}
          render={props => <CreateCategory {...props} {...globalProps}></CreateCategory>}
        />
        <Route path={`${match.url}brand/list`} render={props => <ListBrand {...props} {...globalProps}></ListBrand>} />
        <Route
          path={`${match.url}brand/create`}
          render={props => <CreateBrand {...props} {...globalProps}></CreateBrand>}
        />
        <Route
          path={`${match.url}brand/:id`}
          render={props => <CreateBrand {...props} {...globalProps}></CreateBrand>}
        />
        <Route path={`${match.url}order/list`} render={props => <ListOrder {...props} {...globalProps}></ListOrder>} />
        <Route path={`${match.url}callendar-os`} render={props => <Calendar {...props} {...globalProps}></Calendar>} />

        <Route
          path={`${match.url}order/create`}
          render={props => <CreateOrder {...props} {...globalProps}></CreateOrder>}
        />
        <Route
          path={`${match.url}order/:id`}
          render={props => <CreateOrder {...props} {...globalProps}></CreateOrder>}
        />
        <Route
          path={`${match.url}attribute/list`}
          render={props => <ListAttribute {...props} {...globalProps}></ListAttribute>}
        />
        <Route
          path={`${match.url}attribute/create`}
          render={props => <CreateAttribute {...props} {...globalProps}></CreateAttribute>}
        />
        <Route
          path={`${match.url}attribute/:id`}
          render={props => <CreateAttribute {...props} {...globalProps}></CreateAttribute>}
        />
        <Route path={`${match.url}store/list`} render={props => <ListStore {...props} {...globalProps}></ListStore>} />
        <Route
          path={`${match.url}store/create`}
          render={props => <CreateStore {...props} {...globalProps}></CreateStore>}
        />
        <Route
          path={`${match.url}store/:id`}
          render={props => <CreateStore {...props} {...globalProps}></CreateStore>}
        />
        <Route
          path={`${match.url}storebranch/list`}
          render={props => <ListStoreBranch {...props} {...globalProps}></ListStoreBranch>}
        />
        <Route
          path={`${match.url}storebranch/create`}
          render={props => <CreateStoreBranch {...props} {...globalProps}></CreateStoreBranch>}
        />
        <Route
          path={`${match.url}storebranch/:id`}
          render={props => <CreateStoreBranch {...props} {...globalProps}></CreateStoreBranch>}
        />
        <Route
          path={`${match.url}attributeitem/list`}
          render={props => <ListAttributeItem {...props} {...globalProps}></ListAttributeItem>}
        />
        <Route
          path={`${match.url}attributeitem/create`}
          render={props => <CreateAttributeItem {...props} {...globalProps}></CreateAttributeItem>}
        />
        <Route
          path={`${match.url}attributeitem/:id`}
          render={props => <CreateAttributeItem {...props} {...globalProps}></CreateAttributeItem>}
        />
        <Route
          path={`${match.url}employee/list`}
          render={props => <ListEmployee {...props} {...globalProps}></ListEmployee>}
        ></Route>
        <Route
          path={`${match.url}employee/create`}
          render={props => <CreateEmployee {...props} {...globalProps}></CreateEmployee>}
        ></Route>
        <Route
          path={`${match.url}employee/:id`}
          render={props => <CreateEmployee {...props} {...globalProps}></CreateEmployee>}
        ></Route>
        <Route
          path={`${match.url}platform/list`}
          render={props => <ListPlatform {...props} {...globalProps}></ListPlatform>}
        ></Route>
        <Route path={`${match.url}test`} render={props => <TestPaulo {...props} {...globalProps}></TestPaulo>}></Route>
        <Route
          path={`${match.url}dr`}
          render={props => <DynamicRender {...props} {...globalProps}></DynamicRender>}
        ></Route>
        <Route
          path={`${match.url}template-messages/list`}
          render={props => <ListTemplateMessage {...props} {...globalProps} />}
        />
        <Route
          path={`${match.url}template-messages/create`}
          render={props => <CreateTemplateMessage {...props} {...globalProps} />}
        />
        <Route
          path={`${match.url}template-messages/:id`}
          render={props => <CreateTemplateMessage {...props} {...globalProps} />}
        />
        <Route
          path={`${match.url}messages/list`}
          render={props => <ListMessages {...props} {...globalProps} />}
        />
        <Route
          path={`${match.url}messages/create`}
          render={props => <CreateMessage {...props} {...globalProps} />}
        />
        <Route
          path={`${match.url}messages/:id`}
          render={props => <CreateMessage {...props} {...globalProps} />}
        />
        <Route
          path={`${match.url}schedules/list`}
          render={props => <ListSchedules {...props} {...globalProps} />}
        />
        <Route
          path={`${match.url}schedules/:id`}
          render={props => <ViewSchedule {...props} {...globalProps} />}
        />
        <Route
          path={`${match.url}type-tickets/list`}
          render={props => <ListTypeTicket {...props} {...globalProps} />}
        />
        <Route
          path={`${match.url}type-tickets/create`}
          render={props => <CreateTypeTicket {...props} {...globalProps} />}
        />
        <Route
          path={`${match.url}type-tickets/:id`}
          render={props => <CreateTypeTicket {...props} {...globalProps} />}
        />
        <Route
          path={`${match.url}tickets/list`}
          render={props => <ListTicket {...props} {...globalProps} />}
        />
        <Route
          path={`${match.url}tickets/create`}
          render={props => <CreateTicket {...props} {...globalProps} />}
        />
        <Route
          path={`${match.url}tickets/:id`}
          render={props => <CreateTicket {...props} {...globalProps} />}
        />
      </Switch>
    </div>
  )
}

export default App
