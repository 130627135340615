import React, { useState, useEffect } from 'react'
import { Row } from 'antd'
import { useFormik } from 'formik'
import CreateScreen from '../../components/CreateScreen'
import CustomSelect from '../../components/CustomSelect/customSelect'
import CustomInput from '../../components/CustomInput/customInput'
import queryString from 'query-string'
import { api, request } from '../../util/handlerRequestUtil'
import * as yup from 'yup'
import { formatedStatus } from './ScheduleStatus'

function ViewSchedule(props) {
  const { id } = props.match.params
  const { alert, loading, getMessage } = props
  const isDuplicate = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true
  }).duplicate
  const formik = useFormik({
    initialValues: {
      enable: true
    },
    validationSchema: yup.object().shape({
      messageContent: yup
        .string()
        .typeError()
        .required(getMessage('isRequired', getMessage('messageContent'))),
      cellphone: yup
        .string()
        .typeError()
        .required(getMessage('isRequired', getMessage('cellphone')))
    }),
    onSubmit: values => {
      onSubmit(values)
    }
  })
  const { setValues } = formik
  const [filters, setFilters] = useState({})

  useEffect(() => {
    requests()
  }, [])

  async function requests() {
    try {
      loading.open()
      const formatedOrders = await requestOrders()
      const formatedPersons = await requestPersons()
      if (id) {
        await retrieve(id)
      }
      if (isDuplicate) {
        await retrieve(isDuplicate, true)
      }

      setFilters({
        ...formatedPersons,
        ...formatedOrders,
        formatedStatus
      })
    } catch (error) {
      console.tron.log(error)
    } finally {
      loading.close()
    }
  }

  const requestPersons = (params = {}) =>
    request(api.listPerson({ ...params })).then(({ items }) => {
      return {
        formatedPersons: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name
          }
        })
      }
    })

  const requestOrders = (params = {}) =>
    request(api.listOrder({ ...params })).then(({ items }) => {
      return {
        formatedOrders: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: `${item.person.name} - ${new Date(item.orderDate).toLocaleDateString()}`
          }
        })
      }
    })

  function fields(isReadOnly) {
    return (
      <Row type="flex" justify="start">
        <CustomInput disabled={isReadOnly} context={formik} field="cellphone" rowSpan={12} />
        <CustomInput disabled={isReadOnly} context={formik} field="messageContent" rowSpan={12} />
        <CustomInput disabled={isReadOnly} context={formik} field="dateTrigger" rowSpan={12} type="date" />
        <CustomInput disabled={isReadOnly} context={formik} field="dateTriggered" rowSpan={12} type="date" />
        <CustomSelect
          disabled={isReadOnly}
          context={formik}
          showSearch
          field="personId"
          rowSpan={12}
          values={filters.formatedPersons}
        />
        <CustomSelect
          disabled={isReadOnly}
          context={formik}
          showSearch
          field="orderId"
          rowSpan={12}
          values={filters.formatedOrders}
        />
        <CustomSelect
          disabled={isReadOnly}
          context={formik}
          showSearch
          field="status"
          rowSpan={12}
          values={filters.formatedStatus}
        />
      </Row>
    )
  }

  async function onSubmit(values) {
    try {
      if (id) {
        await request(api.updateSchedule(id, values))
        alert.open(getMessage('successUpdateMessage'), 'success')
      }
      props.history.push(`/schedules/list`)
    } catch (error) {
      console.error(error)
    }
  }

  async function retrieve(id, isDuplicate) {
    try {
      const response = await request(api.retrieveSchedule(id), true)
      if (isDuplicate) {
        delete response.id
      }
      setValues({
        ...response
      })
    } catch (error) {}
  }

  function onClickBack() {
    props.history.push('/schedules/list')
  }

  return (
    <CreateScreen
      fields={fields}
      context={formik}
      editTitle={getMessage('updateSchedule')}
      editTitleButton={getMessage('edit')}
      newTitle={getMessage('newSchedule')}
      newTitleButton={getMessage('register')}
      onClickBack={() => onClickBack()}
      readOnlyTitle={getMessage('viewSchedule')}
      readOnlyTitleButton={getMessage('edit')}
      isEdit={id}
    />
  )
}

export default ViewSchedule
