import React, { useState, useEffect } from 'react'
import { Row, Tag, Col } from 'antd'
import { useFormik } from 'formik'
import { get } from 'lodash'
import { CustomInput, CustomSelect, ModalCustomerSelect } from '../../components'
import { api, request } from '../../util/handlerRequestUtil'
import { formatDateWithHour } from '../../util/format'
import ListScreen from '../../components/ListScreen'
import { getMessage } from '../../util/IntlMessages'
import { formatedPriority, formatedStatus } from './TicketConst'

const StatusColors = {
  true: '#28a745',
  false: '#007bff'
}

const PriorityColors = {
  NORMAL: '#fdd835',
  URGENT: '#dc3545',
  CRITICAL: '#000'
}

const PriorityString = {
  NORMAL: 'Normal',
  URGENT: 'Urgente',
  CRITICAL: 'Crítico'
}

const ResolvedStatus = {
  true: getMessage('resolved'),
  false: getMessage('notResolved')
}

function ListTicket(props) {
  const { alert, getMessage, loading } = props
  const formik = useFormik({
    initialValues: {}
  })
  const [modalDelete, setModalDelete] = useState({
    description: getMessage('defaultDeleteDescription'),
    visible: false
  })
  const { values, setFieldValue } = formik
  const [data, setData] = useState({})
  const [person, setPerson] = useState({})
  const [filters, setFilters] = useState({
    formatedStatus: []
  })
  const [visibleModalCustomerSelect, setVisibleModalCustomerSelect] = useState(false)
  const [indicationPerson, setIndicationPerson] = useState({})

  const { page = 1, pageSize = 10, count = 0, pages = 0 } = data
  const typeTickets = get(data, 'items', []).map(ticket => ({
    ...ticket,
    typeName: ticket.type_ticket_obj.name,
    ownerName: ticket.userAdmin_obj.name,
    customerName: get(ticket, 'customer_obj.name', 'n/a')
  }))

  useEffect(() => {
    formik.setFieldValue('customer', indicationPerson.id)
  }, [indicationPerson])

  //requests
  async function requests() {
    try {
      loading.open()

      const [store, tags, status, employee] = await Promise.all([requestData()])

      const formatedTicketTypes = await requestTicketTypes()

      setFilters(f => ({
        ...f,
        ...store,
        ...status,
        ...tags,
        ...employee,
        ...formatedTicketTypes,
        formatedStatus,
        formatedPriority
      }))
    } catch (error) {
      console.tron.log(error)
    } finally {
      loading.close()
    }
  }

  function onClickEdit(ticket) {
    props.history.push(`/tickets/${ticket.id}`)
  }

  async function onClickDelete(ticket) {
    setModalDelete({
      ...modalDelete,
      title: `${getMessage('deleteTicket')} - ID: ${ticket.id}`,
      visible: true,
      onOk: async () => onConfirmDelete(ticket)
    })
  }

  async function onConfirmDelete(ticket) {
    await request(api.deleteTicket(ticket.id))
    setModalDelete({
      ...modalDelete,
      visible: false
    })
    alert.open(getMessage('successTicketDelete'), 'success')
    await requestData(values)
  }

  function onClickView(typeTicket) {
    props.history.push(`/tickets/${typeTicket.id}?readOnly=true`)
  }

  const requestTicketTypes = () =>
    request(
      api.listTypeTickets({
        ignorePagination: true
      }),
      true
    ).then(({ items }) => {
      return {
        formatedTicketTypes: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name
          }
        })
      }
    })

  const requestData = (params = {}) =>
    request(
      api.listTickets({
        ...params,
        enable: true
      })
    ).then(data => setData(data))

  function handleFilter(value) {
    const { type, priority, resolved, title, customer } = values

    let filters = {
      ...(type && { type: type }),
      ...(priority && { priority: priority }),
      ...(resolved && { resolved: resolved }),
      ...(title && { title: title }),
      ...(customer && { customer: customer })
    }

    if (value && value.trim() !== '') {
      filters.title = value
    }
    requestData({
      ...filters
    })
  }

  const onSearch = value => handleFilter(value)

  async function requestPage(page) {
    await requestData({ page: page - 1 })
  }

  const onChangeCreateButton = () => {
    props.history.push('/tickets/create')
  }

  const columns = [
    {
      title: getMessage('date'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: date => <label>{formatDateWithHour(date, '-')}</label>
    },
    {
      title: getMessage('type'),
      dataIndex: 'typeName',
      key: 'typeName'
    },
    {
      title: getMessage('title'),
      dataIndex: 'subject',
      key: 'subject'
    },
    {
      title: getMessage('priority'),
      dataIndex: 'priority',
      key: 'priority',
      render: priority => <Tag color={PriorityColors[priority]}>{PriorityString[priority]}</Tag>
    },
    {
      title: getMessage('resolved'),
      dataIndex: 'resolved',
      key: 'resolved',
      render: status => <Tag color={StatusColors[status]}>{ResolvedStatus[status]}</Tag>
    },
    {
      title: getMessage('customer'),
      dataIndex: 'customerName',
      key: 'customerName'
    },
    {
      title: getMessage('owner'),
      dataIndex: 'ownerName',
      key: 'ownerName'
    },
    {
      title: getMessage('closingDate'),
      dataIndex: 'closingDate',
      key: 'closingDate',
      render: date => <label>{formatDateWithHour(date, '-')}</label>
    },
    {
      title: getMessage('dueDate'),
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: date => <label>{formatDateWithHour(date, '-')}</label>
    }
  ]

  useEffect(() => {
    requests()
  }, [])

  function filtersList() {
    return (
      <Row type="flex" justify="start">
        <CustomInput context={formik} field="title" rowSpan={12} />
        <CustomSelect context={formik} showSearch field="type" rowSpan={12} values={filters.formatedTicketTypes} />
        <CustomSelect context={formik} showSearch field="priority" rowSpan={12} values={filters.formatedPriority} />
        <CustomSelect context={formik} showSearch field="resolved" rowSpan={12} values={filters.formatedStatus} />
        <Col type="flex" span={24} onClick={() => setVisibleModalCustomerSelect(true)}>
          <CustomInput field="customer" rowSpan={12} disabled context={formik} />
        </Col>
      </Row>
    )
  }

  const validFieldsKeys = ['name', 'type', 'priority', 'resolved', 'customer', 'title']

  useEffect(() => {
    setFilters(f => ({
      ...f
    }))
    setFieldValue('customer', person.id)
  }, [person])

  useEffect(() => {
    if (!values.customer) {
      setPerson({})
    }
  }, [values.customer])

  return (
    <>
      <ModalCustomerSelect
        visible={visibleModalCustomerSelect}
        setVisible={setVisibleModalCustomerSelect}
        setPerson={setIndicationPerson}
      />
      <ListScreen
        context={formik}
        columns={columns}
        data={typeTickets}
        title={getMessage('tickets')}
        titleButtonAdd={getMessage('newTicket')}
        onSearch={onSearch}
        filtersList={filtersList}
        validFieldsKeys={validFieldsKeys}
        dataFilters={filters}
        onChange={requestPage}
        onChangeCreateButton={onChangeCreateButton}
        pagination={{
          currentPage: page + 1,
          pages: pages,
          total: count,
          pageSize: pageSize
        }}
        onFilter={handleFilter}
        onClickDelete={onClickDelete}
        modalDelete={modalDelete}
        setModalDelete={setModalDelete}
        onClickEdit={onClickEdit}
        onClickView={onClickView}
        searchPlaceholder={getMessage('title')}
      />
    </>
  )
}

export default ListTicket
