import React from 'react'

import { Tag } from 'antd'

import { getFormatedFilterLabel } from '../../util/filtersUtil'

function TagsFilter(props) {
  const { filterValues, onClose } = props
  return (
    <>
      {filterValues.map(item => (
        <Tag key={item.identifier} color="magenta" closable onClose={() => onClose(item)}>
          {getFormatedFilterLabel(item)}
        </Tag>
      ))}
    </>
  )
}

export default TagsFilter
