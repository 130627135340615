export const Types = {
  LOADING_OPEN_PROGRESS: 'loading/LOADING_OPEN_PROGRESS',
  LOADING_CLOSE_PROGRESS: 'loading/LOADING_CLOSE_PROGRESS'
}

const initialState = {
  show: false
}

export default function loading(state = initialState, action) {
  switch (action.type) {
    case Types.LOADING_OPEN_PROGRESS:
      return { ...state, show: true }

    case Types.LOADING_CLOSE_PROGRESS:
      return {
        ...state,
        show: false
      }
    default:
      return state
  }
}

export const Creators = {
  openLoading: () => ({
    type: Types.LOADING_OPEN_PROGRESS,
    payload: {}
  }),
  closeLoading: () => ({
    type: Types.LOADING_CLOSE_PROGRESS,
    payload: {}
  })
}
