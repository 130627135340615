import React from 'react'
import { Col, Form, Radio } from 'antd'
import { getMessage } from '../../util/IntlMessages'

import './customRadioGroup.css'

const CustomRadioGroup = function(props) {
  const { context, field, span, buttonStyle = 'solid', children, value, required, disabled = false, ...rest } = props
  const { errors, setFieldValue, values } = context

  return (
    <Col xs={24} sm={24} md={span} lg={span} xl={span}>
      <Form.Item
        label={getMessage(field) + (required ? ' *' : '')}
        validateStatus={errors[field] ? 'error' : 'validating'}
        help={errors[field]}
      >
        <Radio.Group
          disabled={disabled}
          defaultValue={value}
          value={values[field]}
          buttonStyle={buttonStyle}
          className="radio-group"
          onChange={e => setFieldValue(field, e.target.value)}
          {...rest}
        >
          {children}
        </Radio.Group>
      </Form.Item>
    </Col>
  )
}

export default CustomRadioGroup
