import React from 'react'
import ReactDOM from 'react-dom'
import NextApp from './NextApp'
import moment from 'moment-timezone'
moment.tz.setDefault('America/Sao_Paulo')
const render = Component => {
  ReactDOM.render(<Component />, document.getElementById('root'))
}

render(NextApp)
