import React, { useState, useEffect } from 'react'
import { Row, Button } from 'antd'
import { getMessage } from '../../../../util/IntlMessages'
import { api, request } from '../../../../util/handlerRequestUtil'
import CustomSelect from '../../../../components/CustomSelect/customSelect'
import { useSelector } from 'react-redux'
import { PDV_PLATFORM_ID } from '../constants'
import './employee.css'
import { Col } from 'antd'
import { ORDER_TYPES } from '../../types'
const Customer = function ({ formik, setFormValues, loading, onChangeTabs, isReadOnly, id }) {
  const [filters, setFilters] = useState({
    formatedStores: [],
    formatedEmployees: [],
    formatedPlatforms: [],
    formatedStatus: [],
    formatedTags: []
  })

  const { values, setValues } = formik

  const user = useSelector(state => state.auth.auth.user.user)

  useEffect(() => {
    requests()
  }, [])

  useEffect(() => {
    if (id || filters.formatedPlatforms.length === 0) return

    setValues({
      ...values,
      platform: 1,
      employee: user.id
    })
  }, [filters.formatedPlatforms])

  //requests
  async function requests() {
    try {
      loading.open()

      // const store = await requestStore(true)
      // const employee = await requestEmployee(true)
      // const platform = await requestPlatform(true)
      // const tags = await requestTags(true)
      // const status = await requestStatus(true)

      const [store, employee, platform, tags, status] = await Promise.all([
        requestStore(true),
        requestEmployee(true),
        requestPlatform(true),
        requestTags(true),
        requestStatus(true)
      ])

      setFilters({
        ...filters,
        ...store,
        ...employee,
        ...platform,
        ...tags,
        ...status
      })
    } catch (error) {
      console.tron.log(error)
    } finally {
      loading.close()
    }
  }

  const requestStore = skip =>
    request(api.listStoreBranch({ ignorePagination: true }), { skipLoading: skip }).then(({ items }) => {
      return {
        formatedStores: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name
          }
        })
      }
    })

  const requestEmployee = skip =>
    request(api.listEmployee({ ignorePagination: true }), { skipLoading: skip }).then(({ items }) => {
      return {
        formatedEmployees: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name
          }
        })
      }
    })

  const requestPlatform = skip =>
    request(api.listPlatform({ ignorePagination: true }), { skipLoading: skip }).then(({ items }) => {
      return {
        formatedPlatforms: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name
          }
        })
      }
    })

  const requestStatus = async skip => {
    const { items } = await request(api.listOrderStatus({ ignorePagination: true }), { skipLoading: skip })
    return {
      formatedStatus: items
        .filter(item => item.orderType === values.orderType)
        .map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name,
            isInternal: item.isInternal
          }
        })
    }
  }

  const requestTags = async skip => {
    const { items } = await request(api.listTag({ type: values.orderType }), { skipLoading: skip })

    return {
      formatedTags: items.map(item => {
        return {
          ...item,
          value: item.name,
          description: item.name
        }
      })
    }
  }

  return (
    <div className="employee-content">
      <Row>
        <CustomSelect
          context={formik}
          field="store"
          rowSpan={12}
          values={filters.formatedStores}
          disabled={isReadOnly}
          required
        />
        <CustomSelect
          context={formik}
          field="tags"
          rowSpan={12}
          values={filters.formatedTags}
          disabled={isReadOnly}
          tags
        />
        <CustomSelect
          context={formik}
          field="employee"
          rowSpan={12}
          values={filters.formatedEmployees}
          disabled={isReadOnly}
          required={PDV_PLATFORM_ID === values.platform}
        />
        {values.orderType === ORDER_TYPES.SALE && (
          <CustomSelect
            context={formik}
            field="platform"
            rowSpan={12}
            values={filters.formatedPlatforms}
            disabled={isReadOnly}
            required
          />
        )}
        <CustomSelect
          field="status"
          rowSpan={12}
          context={formik}
          values={filters.formatedStatus}
          disabled={isReadOnly}
          required
        />
        {values.parentOrder && (
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="containerLinkOrder">
            <Button type="link" onClick={() => window.open(`/order/${values.parentOrder}`)}>
              {getMessage('viewOrderParent')} #{values.parentOrder}
            </Button>
          </Col>
        )}

        {/* <CustomSelect
          field="internalStatus"
          rowSpan={12}
          context={formik}
          values={filters.formatedStatus.filter(s => s.isInternal)}
          disabled={isReadOnly}
        /> */}
      </Row>
      <Row justify="end">
        <Button
          type="primary"
          className={'mr5'}
          onClick={() => {
            onChangeTabs('2')
            setFormValues(values)
          }}
        >
          <span>{getMessage('continue')}</span>
        </Button>
      </Row>
    </div>
  )
}

export default Customer
