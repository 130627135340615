import React from 'react'

import * as icons from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Pagination(props) {
  const txtPages = (
    <strong>
      {props.currentPage} - {props.pages}
    </strong>
  )
  const info1 = ` pags. de `
  const info2 = ` itens`

  const prevPage = () => {
    if (props.currentPage === 1) return
    props.onChange(props.currentPage - 1)
  }

  const nextPage = () => {
    if (props.currentPage === props.total) return
    props.onChange(props.currentPage + 1)
  }

  return (
    <div className="pgContent">
      <div>
        {txtPages}
        {props.total ? (
          <>
            {info1}
            <strong>{props.total}</strong>
            {info2}
          </>
        ) : (
          ''
        )}
      </div>
      <button type="primary" className="pgPrev" disabled={props.currentPage === 1} onClick={prevPage}>
        <FontAwesomeIcon icon={icons.faArrowLeft} size="sm" className="icon" />
      </button>
      <button type="primary" className="pgNext" disabled={props.currentPage === props.pages} onClick={nextPage}>
        <FontAwesomeIcon icon={icons.faArrowRight} size="sm" className="icon" />
      </button>
    </div>
  )
}

export default Pagination
