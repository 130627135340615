import React, { useState, useEffect } from 'react'
import { Row } from 'antd'
import { useFormik } from 'formik'
import _ from 'lodash'
import ListScreen from '../../components/ListScreen'
import CustomSelect from '../../components/CustomSelect/customSelect'
import CustomInput from '../../components/CustomInput/customInput'
import { api, request } from '../../util/handlerRequestUtil'
import './listCategory.css'

function ListCategory(props) {
  const { alert, getMessage } = props
  const formik = useFormik({
    initialValues: {}
  })
  const [modalDelete, setModalDelete] = useState({
    description: getMessage('defaultDeleteDescription'),
    visible: false
  })
  const { values } = formik
  const [data, setData] = useState({})
  const [filters, setFilters] = useState({})
  const { page = 1, pageSize = 10, count = 0, pages = 0 } = data
  const category = _.get(data, 'items', []).map(item => {
    return {
      key: item.id,
      ...item,
      categoryId: _.get(item, 'category.name')
    }
  })
  const columns = [
    {
      title: getMessage('name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: getMessage('description'),
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: getMessage('category'),
      dataIndex: 'categoryId',
      key: 'categoryId'
    }
  ]

  useEffect(() => {
    requests()
  }, [])

  async function requests() {
    try {
      const formatedCategory = await requestCategory()
      await requestData()
      setFilters({
        ...formatedCategory
      })
    } catch (error) {
      console.tron.log(error)
    }
  }

  function onClickEdit(category) {
    props.history.push(`/category/${category.key}`)
  }

  async function onClickDelete(category) {
    setModalDelete({
      ...modalDelete,
      title: getMessage('deleteCategoryTitle', category.name),
      visible: true,
      onOk: async () => onConfirmDelete(category)
    })
  }

  async function onConfirmDelete(category) {
    alert.open(getMessage('successCategoryDelete'), 'success')
    await request(api.deleteCategory(category.key))
    setModalDelete({
      ...modalDelete,
      visible: false
    })
    await requestData(values)
  }

  function onClickView(category) {
    props.history.push(`/category/${category.key}?readOnly=true`)
  }

  function onClickDuplicate(category) {
    props.history.push(`/category/create?duplicate=${category.key}`)
  }

  const requestData = (params = {}) =>
    request(
      api.listCategory({
        ...params,
        include: 'category'
      })
    ).then(data => setData(data))

  const requestCategory = () =>
    request(
      api.listCategory({
        ignorePagination: true
      })
    ).then(({ items }) => {
      return {
        formatedCategory: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name
          }
        })
      }
    })

  function handleFilter(value) {
    let filters = {}
    const { description, category } = values
    if (description && description.trim() !== '') {
      filters.description = description
    }
    if (category) {
      filters.categoryId = category
    }
    if (value && value.trim() !== '') {
      filters.name = value
    }
    requestData({
      ...filters
    })
  }

  const onSearch = value => handleFilter(value)

  async function requestPage(page) {
    await requestData({ page: page - 1 })
  }

  const onChangeCreateButton = () => {
    props.history.push('/category/create')
  }

  function filtersList() {
    return (
      <Row type="flex" justify="start">
        <CustomInput field="description" rowSpan={12} context={formik} />
        <CustomSelect context={formik} showSearch field="category" rowSpan={12} values={filters.formatedCategory} />
      </Row>
    )
  }
  const validFieldsKeys = ['description', 'category']

  return (
    <ListScreen
      context={formik}
      columns={columns}
      data={category}
      title={getMessage('category')}
      titleButtonAdd={getMessage('newCategory')}
      onSearch={onSearch}
      filtersList={filtersList}
      validFieldsKeys={validFieldsKeys}
      dataFilters={filters}
      onChange={requestPage}
      onChangeCreateButton={onChangeCreateButton}
      pagination={{
        currentPage: page + 1,
        pages: pages,
        total: count,
        pageSize: pageSize
      }}
      onFilter={handleFilter}
      onClickDelete={onClickDelete}
      modalDelete={modalDelete}
      setModalDelete={setModalDelete}
      onClickEdit={onClickEdit}
      onClickView={onClickView}
      onClickDuplicate={onClickDuplicate}
    />
  )
}

export default ListCategory
