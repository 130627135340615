import React, { useEffect } from 'react'
import { Divider } from 'antd'
import { cid } from '../../../util/util'
import moment from 'moment-timezone'

import RecipeView from '../../../components/Recipe/RecipeView'
import RecipeTable from '../../../components/Recipe/RecipeTable'

const stepRecipes = ({ dataToPost, isReadOnly, setValues }) => {
  const [recipe, setRecipe] = React.useState({})
  const [recipes, setRecipes] = React.useState(
    dataToPost.recipes ? dataToPost.recipes.map(r => ({ ...r, key: cid() })) : []
  )

  function formatRow(values) {
    return {
      key: cid(),
      ...recipe,
      ...(recipe.dueDate && { dueDate: moment(recipe.dueDate).toISOString() }),
      recipeData: { ...recipe.recipeData, ...values }
    }
  }

  function handleSave(e, recipe) {
    e.preventDefault()
    const updatedData = formatRow(recipe)

    const index = recipes.findIndex(r => r.key === updatedData.key)

    let recipesUpdated = []
    if (index !== -1) {
      recipesUpdated = [...recipes]
      recipesUpdated.splice(index, 1, updatedData)
    } else {
      recipesUpdated = [...recipes, updatedData]
    }

    setRecipe({})
    setRecipes(recipesUpdated)
  }

  function deleteRecipe(index) {
    setRecipes(recipes => recipes.filter((r, i) => i !== index))
  }

  function enableRecipeEdit(row) {
    setRecipe(row)
  }

  useEffect(() => {
    setValues({ ...dataToPost, recipes })
  }, [recipes])

  return (
    <>
      <RecipeView state={recipe} setState={setRecipe} handleSave={handleSave} isReadOnly={isReadOnly} />
      <Divider />
      <RecipeTable
        data={recipes}
        deleteRecipe={deleteRecipe}
        enableRecipeEdit={enableRecipeEdit}
        isReadOnly={isReadOnly}
      />
    </>
  )
}

export default stepRecipes
