import React, { useEffect, useState } from 'react'
import { Col, Row, Radio, Divider, Form, Input } from 'antd'
import { CustomInput, CustomSelect, CustomRadioGroup, ModalCustomerSelect } from '../../../components'
import { getMessage } from '../../../util/IntlMessages'
import { get } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import './stepPersonalData.css'

const PersonalData = props => {
  const { form, isReadOnly, options, id, isDuplicate, setFilters } = props

  const [visibleModalCustomerSelect, setVisibleModalCustomerSelect] = useState(false)
  const [indicationPerson, setIndicationPerson] = useState({})
  const kindPerson = get(form, ['values', 'kindPerson'])

  const addOrigin = name => {
    setFilters({ ...options, origin: [...options.origin, { value: name, description: name }] })
  }

  useEffect(() => {
    form.setFieldValue('indicationPerson', indicationPerson)
  }, [indicationPerson])

  useEffect(() => {
    if (!id && !isDuplicate) {
      form.setFieldValue('cpf', '')
      form.setFieldValue('cnpj', '')
    }
  }, [kindPerson])

  return (
    <Row className="container__personalData" type="flex" justify="start">
      <CustomSelect context={form} field="type" rowSpan={8} values={options.type} disabled={isReadOnly} required />
      <CustomInput context={form} field="email" rowSpan={16} disabled={isReadOnly} required />
      <CustomSelect
        context={form}
        field="origin"
        rowSpan={8}
        values={options.origin}
        disabled={isReadOnly}
        addOption={addOrigin}
        required
      />
      <Col span={16} {...(!isReadOnly && { onClick: () => setVisibleModalCustomerSelect(true) })}>
        <Form.Item label={getMessage('indicatedBy')}>
          <Input
            className={!isReadOnly && 'search__indicationPerson'}
            value={get(form, 'values.indicationPerson.name')}
            suffix={<FontAwesomeIcon icon={faPlus} />}
            disabled
          />
        </Form.Item>
      </Col>
      <CustomSelect
        context={form}
        field="kindPerson"
        rowSpan={8}
        values={options.kindPerson}
        disabled={isReadOnly}
        required
      />
      <CustomInput context={form} field="name" rowSpan={16} disabled={isReadOnly} required />
      {kindPerson === 'FOREIGNER' && (
        <CustomInput context={form} field="homeCountry" rowSpan={8} disabled={isReadOnly} />
      )}

      {kindPerson === 'PHYSICAL' && (
        <>
          <CustomInput context={form} field="cpf" rowSpan={6} mask="111.111.111-11" disabled={isReadOnly} />
          <CustomInput context={form} field="rg" rowSpan={6} disabled={isReadOnly} />
          <CustomInput context={form} field="issuingBody" rowSpan={6} disabled={isReadOnly} />
          <CustomInput context={form} field="birth" rowSpan={6} type="date" disabled={isReadOnly} required />
          <CustomRadioGroup context={form} field="gender" span={12} className="" disabled={isReadOnly} required>
            <Radio value="MALE">{getMessage('male')}</Radio>
            <Radio value="FEMALE">{getMessage('female')}</Radio>
          </CustomRadioGroup>
        </>
      )}
      {kindPerson === 'LEGAL' && (
        <CustomInput context={form} field="cnpj" rowSpan={8} mask="11.111.111/1111-11" disabled={isReadOnly} />
      )}

      <Col className="col" span={24}>
        {getMessage('socialNetwork')}
      </Col>
      <CustomInput context={form} field="instagram" rowSpan={8} disabled={isReadOnly} />
      <CustomInput context={form} field="facebook" rowSpan={8} disabled={isReadOnly} />
      <CustomInput context={form} field="linkedin" rowSpan={8} disabled={isReadOnly} />

      <Col className="col" span={24}>
        {getMessage('supervisor')}
      </Col>
      <CustomSelect context={form} field="taxPayer" rowSpan={24} values={options.taxPayer} disabled={isReadOnly} />
      {kindPerson === 'LEGAL' && (
        <CustomSelect context={form} field="taxRegime" rowSpan={8} values={options.taxRegime} disabled={isReadOnly} />
      )}
      <CustomInput context={form} field="countyRegistration" rowSpan={8} disabled={isReadOnly} />
      <CustomInput
        context={form}
        field="stateRegistration"
        rowSpan={8}
        disabled={isReadOnly || form.values.taxPayer === 2}
        {...(form.values.taxPayer === 1 && { required: true })}
      />

      <Divider />
      <CustomInput context={form} field="notes" rowSpan={24} type="textarea" disabled={isReadOnly} />

      <ModalCustomerSelect
        visible={visibleModalCustomerSelect}
        setVisible={setVisibleModalCustomerSelect}
        setPerson={setIndicationPerson}
      />
    </Row>
  )
}

export default PersonalData
