import React, { useState, useEffect } from 'react'
import { Button, Form, Modal, Row, Col, Divider } from 'antd'
import { normalizeValue, formatDateWithHour } from '../../../../util/format'
import { useFormik } from 'formik'
import { round, get } from 'lodash'
import { CustomInput, CustomSelect } from '../../../../components'
import { getMessage } from '../../../../util/IntlMessages'
import { request, api } from '../../../../util/handlerRequestUtil'
import { cid } from '../../../../util/util'
import { helper } from '../../../../util/helper'
import moment from 'moment-timezone'
import './orderPreviewProducts.css'
import { ORDER_TYPES } from '../../types'
import RecipeView from '../../../../components/Recipe/RecipeView'

const VariationPriceModal = props => {
  const { visible, setVisible, handlePrice, item, isReadOnly, formValues } = props
  const { filledByVariation } = item
  const { id, price, statusId, recipeId, finalPrice } = filledByVariation || {}
  const [openRecipes, setOpenRecipes] = useState(false)
  const [customer, setCustomer] = useState({})
  const [selectedRecipe, setSelectedRecipe] = useState()
  const [productType, setProductType] = useState('glasses')
  const [filters, setFilters] = useState({
    status: [],
    recipes: []
  })

  const { alert } = helper()

  const formik = useFormik({
    initialValues: {}
  })

  const { values, setValues, setFieldValue } = formik

  const handleCancel = () => setVisible(false)

  async function requestStatus(skip = true) {
    const { items } = await request(api.listOrderStatus({ ignorePagination: true }), { skipLoading: skip })

    return {
      status: items
        .filter(item => item.orderType === ORDER_TYPES.SERVICE)
        .map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name,
            isInternal: item.isInternal
          }
        })
    }
  }

  async function requestCostumer(skip = true) {
    if (!formValues.customer) return []

    const customer = await request(api.retrievePerson(formValues.customer), { skipLoading: skip })

    setCustomer(customer)

    const recipes = customer.recipes.map(r => ({
      ...r,
      value: r.id,
      description: formatDateWithHour(r.created_at),
      key: cid()
    }))

    return recipes
  }

  async function initialRequests() {
    const variationType = item.product || get(item, 'variation.product.product', 'glasses')

    if (variationType === 'lens') {
      const status = await requestStatus()

      const recipes = await requestCostumer()
      setFilters({
        ...filters,
        ...status,
        recipes
      })
    }

    setProductType(variationType)

    setValues({
      ...values,
      statusId: statusId || 11,
      recipeId: values.recipeId || recipeId,
      price,
      finalPrice
    })
  }

  const onSave = () => {
    // if (!values.finalPrice) {
    //   return alert.open(getMessage('info.no.changes'), 'info')
    // }

    handlePrice(id, values)
    setVisible(false)
  }

  function deleteRecipe(index) {
    setFilters({
      ...filters,
      recipes: filters.recipes.filter((_, i) => i !== index)
    })
  }

  function enableRecipeEdit(row) {
    setSelectedRecipe(row)
  }

  function formatRow(values) {
    return {
      key: cid(),
      ...selectedRecipe,
      ...(selectedRecipe.dueDate && { dueDate: moment(selectedRecipe.dueDate) }),
      recipeData: { ...selectedRecipe.recipeData, ...values }
    }
  }

  async function handleSaveRecipe(e, recipe) {
    e.preventDefault()
    const updatedData = formatRow(recipe)

    const index = filters.recipes.findIndex(r => r.key === updatedData.key)

    let recipesUpdated = []
    if (index !== -1) {
      recipesUpdated = [...filters.recipes]
      recipesUpdated.splice(index, 1, updatedData)
    } else {
      recipesUpdated = [...filters.recipes, updatedData]
    }

    setSelectedRecipe({})

    await request(api.updatePerson(customer.id, { ...customer, recipes: recipesUpdated }))

    const recipes = await requestCostumer(false)
    setOpenRecipes(false)
    setSelectedRecipe(undefined)
    setFieldValue('recipeId', undefined)
    alert.open('Receita cadastada com sucesso!')
    alert.open('Selecione receita do pedido')
    setFilters({
      ...filters,
      recipes
    })
  }

  useEffect(() => {
    const discountReal = +price - normalizeValue(values.finalPrice)
    const discountPercent = round((+discountReal / +price) * 100, 2)

    const isProductPriceZero = +price === 0

    setValues({
      ...values,
      discountReal: isProductPriceZero ? '' : discountReal,
      discountPercent: isProductPriceZero ? '' : discountPercent
    })
  }, [values.finalPrice])

  useEffect(() => {
    const recipe = filters.recipes.find(r => r.id === values.recipeId)
    setSelectedRecipe(recipe)
  }, [values.recipeId])

  useEffect(() => {
    initialRequests()
  }, [JSON.stringify(item)])

  return (
    <Modal width="96vw" visible={visible} footer={null} onCancel={() => handleCancel()}>
      <Form layout="vertical">
        <Row align="middle">
          <CustomInput context={formik} field="price" rowSpan={6} prefix="R$ " type="number" disabled />
          <CustomInput
            context={formik}
            customNameField="discount"
            field="discountReal"
            suffixLabel={' R$'}
            rowSpan={6}
            prefix="R$ "
            type="number"
            disabled
          />
          <CustomInput
            context={formik}
            customNameField="discount"
            field="discountPercent"
            suffixLabel={' %'}
            rowSpan={6}
            type="number"
            disabled
          />
          <CustomInput
            context={formik}
            field="finalPrice"
            rowSpan={6}
            prefix="R$ "
            type="number"
            disabled={isReadOnly}
          />

          {productType === 'lens' && (
            <>
              {!isReadOnly && (
                <CustomSelect name="statusId" field="status" rowSpan={8} context={formik} values={filters.status} />
              )}
              {!isReadOnly && <CustomInput context={formik} field="dueDateDelivery" rowSpan={8} type="date" />}

              <CustomSelect
                name="recipeId"
                field="recipeSelect"
                rowSpan={8}
                context={formik}
                values={filters.recipes}
                disabled={isReadOnly}
              />
              {!isReadOnly && (
                <Col span={8}>
                  <Button
                    type="primary"
                    disabled={isReadOnly}
                    onClick={() => {
                      if (item.product === 'lens' && !formValues.customer) {
                        return alert.open('É necessário selecionar um cliente para adicionar receita!')
                      }
                      setSelectedRecipe(undefined)
                      setFieldValue('recipeId', undefined)
                      setOpenRecipes(true)
                    }}
                  >
                    {getMessage('registerNewRecipe')}
                  </Button>
                </Col>
              )}
            </>
          )}
        </Row>
        <Row justify="end">
          <Col span={6} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 5 }}>
            <Button type="primary" onClick={onSave} disabled={isReadOnly}>
              {getMessage('save')}
            </Button>
          </Col>
        </Row>
      </Form>

      {(openRecipes || selectedRecipe) && (
        <>
          <Divider style={{ margin: '2rem 0' }}>Receitas</Divider>
          <RecipeView
            state={selectedRecipe}
            setState={setSelectedRecipe}
            handleSave={handleSaveRecipe}
            isReadOnly={selectedRecipe && selectedRecipe.id}
          />
        </>
      )}
    </Modal>
  )
}

export default VariationPriceModal
