import React from 'react'
import '@ant-design/compatible/assets/index.css'

import { Button, Row, Col } from 'antd'
import CustomInput from '../../components/CustomInput/customInput'
import CustomSelect from '../../components/CustomSelect/customSelect'
import get from 'lodash.get'
import { getMessage } from '../../util/IntlMessages'

function Step2(props) {
  const { formik, onPress, filters, isReadOnly = false } = props
  const { values } = formik
  const packageType = get(values, 'packageType', '')

  return (
    <div>
      <div className="content-tabPane">
        <Row type="flex" justify="start">
          <Col span={24}>
            <label className="mb-2 ml-1 d-block strong">{getMessage('shipping')}</label>
          </Col>
          <CustomInput
            disabled={isReadOnly}
            showSearch
            context={formik}
            field="liquidWeight"
            rowSpan={8}
            values={''}
            placeholder="Ex.: 2,00"
            type="number"
            suffixLabel="(g)"
          />
          <CustomInput
            disabled={isReadOnly}
            showSearch
            context={formik}
            field="grossWeight"
            rowSpan={8}
            values={''}
            placeholder="Ex.: 2,00"
            type="number"
            suffixLabel="(g)"
          />
          <CustomSelect
            disabled={isReadOnly}
            showSearch
            context={formik}
            field="packageType"
            rowSpan={8}
            values={filters.formatedPackageType}
          />
          {packageType && packageType !== '' && (
            <>
              {(packageType.includes('ENVELOPE') || packageType.includes('PACKAGE')) && (
                <CustomInput
                  disabled={isReadOnly}
                  showSearch
                  context={formik}
                  field="width"
                  rowSpan={8}
                  values={''}
                  placeholder="Ex.: 1,00"
                  type="number"
                  suffixLabel="(cm)"
                />
              )}
              {packageType.includes('PACKAGE') && (
                <CustomInput
                  disabled={isReadOnly}
                  showSearch
                  context={formik}
                  field="height"
                  rowSpan={8}
                  values={''}
                  placeholder="Ex.: 1,00"
                  type="number"
                  suffixLabel="(cm)"
                />
              )}
              <CustomInput
                disabled={isReadOnly}
                showSearch
                context={formik}
                field="length"
                rowSpan={8}
                values={''}
                placeholder="Ex.: 1,00"
                type="number"
                suffixLabel="(cm)"
              />
              {packageType.includes('CYLINDER') && (
                <CustomInput
                  disabled={isReadOnly}
                  showSearch
                  context={formik}
                  field="diameter"
                  rowSpan={8}
                  values={''}
                  addonAfter="cm"
                  placeholder="Ex.: 1,00"
                  type="number"
                  suffixLabel="(cm)"
                />
              )}
            </>
          )}
          {/* <Col span={12}></Col>
          <Col span={24}>
            <label className="mb-2 ml-1 d-block strong">{getMessage('taxation')}</label>
          </Col>
          <CustomSelect
            disabled={isReadOnly}
            showSearch
            context={formik}
            field="taxOriginId"
            rowSpan={16}
            values={filters.formatedTaxOrigin}
          />
          <CustomInput disabled={isReadOnly} showSearch context={formik} field="taxNcm" rowSpan={8} />
          <CustomInput disabled={isReadOnly} showSearch context={formik} field="taxCest" rowSpan={8} />
          <CustomSelect
            disabled={isReadOnly}
            showSearch
            context={formik}
            field="taxTypeItemId"
            rowSpan={8}
            values={filters.formatedTaxTypeItem}
          />
          <CustomInput disabled={isReadOnly} showSearch context={formik} field="taxPercent" rowSpan={8} />
          <Col span={24}>
            <label className="mb-2 ml-1 d-block strong">{getMessage('taxIcms')}</label>
          </Col>
          <CustomInput
            disabled={isReadOnly}
            showSearch
            context={formik}
            field="taxBaseValueIcmsRentetion"
            rowSpan={8}
          />
          <CustomInput disabled={isReadOnly} showSearch context={formik} field="taxValueIcmsRentetion" rowSpan={8} />
          <CustomInput disabled={isReadOnly} showSearch context={formik} field="taxValueOwnIcmsRentetion" rowSpan={8} />
          <Col span={24}>
            <label className="mb-2 ml-1 d-block strong">{getMessage('taxIpi')}</label>
          </Col>
          <CustomInput disabled={isReadOnly} showSearch context={formik} field="taxExceptionIpi" rowSpan={8} />
          <Col span={24}>
            <label className="mb-2 ml-1 d-block strong">{getMessage('taxPisCofins')}</label>
          </Col>
          <CustomInput disabled={isReadOnly} showSearch context={formik} field="taxValuePis" rowSpan={8} />
          <CustomInput disabled={isReadOnly} showSearch context={formik} field="taxValueCofins" rowSpan={8} /> */}
        </Row>
        <Row type="flex">
          <CustomInput disabled={isReadOnly} showSearch context={formik} field="taxNcm" rowSpan={8} />
          <CustomInput disabled={isReadOnly} showSearch context={formik} field="taxCest" rowSpan={8} />
          <CustomSelect
            disabled={isReadOnly}
            showSearch
            context={formik}
            field="taxClass"
            rowSpan={8}
            values={filters.formatedTaxClasses}
          />
        </Row>
      </div>
      <div className="content-form-btns">
        <Button
          type="primary"
          onClick={() => {
            onPress()
          }}
          className={'mr5'}
        >
          <span>{getMessage('continue')}</span>
        </Button>
      </div>
    </div>
  )
}

export default Step2
