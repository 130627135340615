import React, { useEffect, useState } from 'react'
import { Modal, Form, Col, Button } from 'antd'
import { useFormik } from 'formik'
import { CustomInput } from '../../../../components'
import { getMessage } from '../../../../util/IntlMessages'
import { api, request } from '../../../../util/handlerRequestUtil'
import { WOO_PLATFORM_ID } from '../../Constants/Variables'
import * as yup from 'yup'

import styles from './ConfigModal.module.css'

function ConfigModal(props) {
  const { data, visible, setVisible, alert } = props

  const [loading, setLoading] = useState(false)

  const getValidation = (fields = []) => {
    let validation = {}

    fields.forEach(name => {
      validation = {
        ...validation,
        [name]: yup
          .string()
          .typeError()
          .required(getMessage('isRequired', getMessage(name)))
      }
    })

    return validation
  }

  const form = useFormik({
    initialValues: {},
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: yup
      .object()
      .shape(getValidation(['consumerKey', 'consumerSecret', 'urlWoo', 'wordpressPassword', 'wordpressUsername'])),
    onSubmit: values => {
      onSubmit(values)
    }
  })

  const getPayload = ({ consumerKey, consumerSecret, urlWoo, wordpressUsername, wordpressPassword }) => {
    return {
      platformId: WOO_PLATFORM_ID,
      wordpressPassword,
      wordpressUsername,
      consumerSecret,
      consumerKey,
      urlWoo
    }
  }

  const onSubmit = async values => {
    try {
      setLoading(true)

      const payload = getPayload(values)

      if (data) {
        await request(api.updatePlatformConfig(payload), { skipLoading: true })
      } else {
        await request(api.storePlatformConfig(payload), { skipLoading: true })
      }
      alert.open(getMessage(`${data ? 'updatePlatformConfig' : 'successPlatformConfig'}`))
    } finally {
      setLoading(false)
    }
  }

  const handleCancel = () => {
    setVisible(false)
  }

  useEffect(() => {
    if (data) {
      form.setValues(data)
    }
  }, [data])

  return (
    <Modal width={400} visible={visible} footer={null} onCancel={handleCancel}>
      <Form layout="vertical">
        <CustomInput context={form} field="wordpressUsername" rowspan={24} required />
        <CustomInput context={form} field="wordpressPassword" rowspan={24} required />
        <CustomInput context={form} field="urlWoo" rowspan={24} required />
        <CustomInput context={form} field="consumerKey" rowspan={24} required />
        <CustomInput context={form} field="consumerSecret" rowspan={24} required />
        <Col span={24} className={styles.colBtnCreate}>
          <Button type="primary" loading={loading} onClick={() => form.submitForm()}>
            {getMessage('createConfig')}
          </Button>
        </Col>
      </Form>
    </Modal>
  )
}

export default ConfigModal
