import React from 'react'
import { Result, Button } from 'antd'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import { getMessage } from 'util/IntlMessages'

const ErrorBoundary = ({ children, resetFallback }) => {
  function ErrorFallback({ error, resetErrorBoundary }) {
    return (
      <Result
        status="warning"
        title={`${getMessage('thereWasAProblemOnTheOperation')}.`}
        subTitle={getMessage('info.errorBoundary')}
        extra={
          <Button type="primary" key="console" onClick={resetErrorBoundary}>
            {getMessage('screenReload')}
          </Button>
        }
      >
        <details>
          <summary>{getMessage('problemDetail')}</summary>
          <pre>{error.message}</pre>
        </details>
      </Result>
    )
  }

  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => (resetFallback ? resetFallback() : window.location.reload())}
    >
      {children}
    </ReactErrorBoundary>
  )
}

export { ErrorBoundary }
