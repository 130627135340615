import React, { Component } from 'react'
import { Layout } from 'antd'
import { toggleCollapsedSideNav } from '../../appRedux/actions/Setting'
import Auxiliary from 'util/Auxiliary'
import { TAB_SIZE } from '../../constants/ThemeSetting'
import { connect } from 'react-redux'
import { Creators as PlatformActions } from '../../appRedux/ducks/platform'
import SyncButton from './SyncButton/SyncButton'

const { Header } = Layout
class Topbar extends Component {
  render() {
    const { width } = this.props

    return (
      <Auxiliary>
        <Header style={{ height: 50 }}>
          {width < TAB_SIZE ? (
            <div className="gx-linebar gx-mr-3">
              <i className="gx-icon-btn icon icon-menu" onClick={this.props.toggleCollapsedSideNav} />
            </div>
          ) : null}

          <SyncButton onClick={this.props.onClickSyncButton} />
        </Header>
      </Auxiliary>
    )
  }
}

const mapStateToProps = ({ settings }) => {
  const { locale, navStyle, navCollapsed, width } = settings
  return { locale, navStyle, navCollapsed, width }
}

const mapDispatchToProps = dispatch => {
  return {
    onClickSyncButton: () => dispatch(PlatformActions.openSyncDrawer()),
    toggleCollapsedSideNav: () => dispatch(toggleCollapsedSideNav())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Topbar)
