const formatedStatus = [
  {
    value: 'SENT',
    description: 'Enviado'
  },
  {
    value: 'PENDING',
    description: 'Pendente'
  },
  {
    value: 'FAILED',
    description: 'Falhou'
  }
]

export { formatedStatus }
