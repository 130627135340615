import React, { useEffect, useRef } from 'react'
import { useFormik } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { Creators as PlatformActions } from '../../appRedux/ducks/platform'
import { request, api } from '../../util/handlerRequestUtil'
import { useSetState } from '../../hooks'
import { TIME_REQUEST } from './utils/constants'

import Drawer from './components/Drawer'
import SyncItems from './components/SyncItems'

import './styles.css'

function SyncDrawer() {
  const dispatch = useDispatch()
  const { synchDrawerVisible } = useSelector(state => state.platform)

  const [state, setState] = useSetState({
    data: [],
    initialData: []
  })
  const timeoutRef = useRef()

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: { status: 'all' }
  })

  function filter({ status }, initialData) {
    if (status === 'all') return initialData

    return initialData.filter(item => item.status === status)
  }

  function onClose() {
    dispatch(PlatformActions.closeSyncDrawer())
  }

  function updateData(items = []) {
    const formattedData = items.map(({ status, parameters }) => ({
      name: parameters.parameters.name,
      status,
      platform: parameters.platform
    }))

    setState({ data: formattedData, initialData: formattedData })
  }

  const checkSyncItems = async () => {
    clearTimeout(timeoutRef.current)
    const { items } = await request(api.checkSync(), { skipLoading: true })

    updateData(items)

    timeoutRef.current = setTimeout(() => {
      checkSyncItems()
    }, TIME_REQUEST)
  }

  useEffect(() => setState({ data: filter(formik.values, state.initialData) }), [formik.values])

  useEffect(() => checkSyncItems(), [])

  return (
    <div id="SyncDrawer">
      <Drawer visible={synchDrawerVisible} onClose={onClose} formik={formik}>
        <SyncItems items={state.data} />
      </Drawer>
    </div>
  )
}

export default SyncDrawer
