import _ from 'lodash'
import { getMessage } from '../util/IntlMessages'
import { Creators as LoadingActions } from '../appRedux/ducks/loading'
import { Creators as AlertActions } from '../appRedux/ducks/alert'
import store from '../appRedux/store/index'
import ServicesApi from '../services/api'

export const api = new ServicesApi.create()

export function handlerErrorRequest(error) {
  console.tron.log('error', error)
  if (_.get(error, 'code')) {
    const { code, field, type } = error
    if (code === 'entity-already-exist') {
      return {
        message: getMessage('api.already', getMessage(field)),
        status: undefined
      }
    }
    if (code === 'failed-field-validation') {
      if (type === 'required') {
        return {
          message: getMessage('api.required', getMessage(field)),
          status: undefined
        }
      }
    }
    if (code === 'failed-authentication') {
      return {
        message: getMessage('api.failed-authentication'),
        status: undefined
      }
    }
    return {
      message: error.message,
      status: undefined
    }
  }
  const response = {
    message: 'Ocorreu um erro desconhecido',
    status: undefined
  }
  return response
}
export function request(functionRequest, params = {}) {
  const { skipLoading = false, skipHandler = false } = params
  return new Promise((resolve, reject) => {
    if (!skipLoading) {
      store.dispatch(LoadingActions.openLoading())
    }
    functionRequest
      .then(request => {
        if (!request.ok) {
          throw request.data
        }
        if (!skipLoading) {
          store.dispatch(LoadingActions.closeLoading())
        }
        resolve(request.data)
      })
      .catch(error => {
        console.tron.log('error', error)
        store.dispatch(LoadingActions.closeLoading())
        if (!skipHandler) {
          store.dispatch(AlertActions.openAlert(handlerErrorRequest(error)))
        }
        reject(error ? error.response : {})
      })
  })
}
