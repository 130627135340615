import apisauce from 'apisauce'
import _ from 'lodash'

import store from '../appRedux/store'

const api = apisauce.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000
})
const { axiosInstance } = api
api.addMonitor(console.tron.apisauce)
api.addMonitor(response => {
  if (response.problem === 'SERVER_ERROR') {
    console.tron.apisauce(response)
  }
})
api.setHeaders('')
axiosInstance.interceptors.request.use(async config => {
  try {
    const token = _.get(store.getState(), 'auth.auth.user.token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  } catch (err) {
    console.tron.log(err)
  }
})

const create = () => {
  const auth = data => api.post('/auth', { ...data })
  const updateAttributeItem = (id, data) => api.put(`/attribute-item/${id}`, { ...data })
  const storeAttributeItem = data => api.post('/attribute-item', { ...data })
  const retrieveAttributeItem = id => api.get(`/attribute-item/${id}`)
  const deleteAttributeItem = (id, data) => api.delete(`/attribute-item/${id}`, { ...data })
  const updateStoreBranch = (id, data) => api.put(`/store-branch/${id}`, { ...data })
  const storeStoreBranch = data => api.post('/store-branch', { ...data })
  const retrieveStoreBranch = id => api.get(`/store-branch/${id}`)
  const deleteStoreBranch = (id, data) => api.delete(`/store-branch/${id}`, { ...data })
  const listStore = data => api.get('/store', data)
  const updateStore = (id, data) => api.put(`/store/${id}`, { ...data })
  const storeStore = data => api.post('/store', { ...data })
  const retrieveStore = id => api.get(`/store/${id}`)
  const deleteStore = (id, data) => api.delete(`/store/${id}`, { ...data })
  const updateAttribute = (id, data) => api.put(`/attribute/${id}`, { ...data })
  const storeAttribute = data => api.post('/attribute', { ...data })
  const retrieveAttribute = id => api.get(`/attribute/${id}`)
  const deleteAttribute = (id, data) => api.delete(`/attribute/${id}`, { ...data })
  const updateOrder = (id, data) => api.put(`/order/${id}`, { ...data })
  const storeOrder = data => api.post('/order', { ...data })
  const retrieveOrder = id => api.get(`/order/${id}`)
  const listOrder = data => api.get('/order', data)
  const listOrderCalendar = data => api.get('/order/calendar', data)

  const deleteOrder = (id, data) => api.delete(`/order/${id}`, { ...data })
  const updateBrand = (id, data) => api.put(`/brand/${id}`, { ...data })
  const storeBrand = data => api.post('/brand', { ...data })
  const retrieveBrand = id => api.get(`/brand/${id}`)
  const deleteBrand = (id, data) => api.delete(`/brand/${id}`, { ...data })
  const updateCategory = (id, data) => api.put(`/category/${id}`, { ...data })
  const storeCategory = data => api.post('/category', { ...data })
  const retrieveCategory = id => api.get(`/category/${id}`)
  const deleteCategory = (id, data) => api.delete(`/category/${id}`, { ...data })
  const updateProvider = (id, data) => api.put(`/provider/${id}`, { ...data })
  const storeProvider = data => api.post('/provider', { ...data })
  const retrieveProvider = id => api.get(`/provider/${id}`)
  const deleteProvider = (id, data) => api.delete(`/provider/${id}`, { ...data })

  const listCustomers = data => api.get('/customers', data)
  const storeCustomers = data => api.post('/customers', { ...data })
  const updateCustomer = (id, data) => api.put(`/customers/${id}`, { ...data })
  const retrieveCustomer = id => api.get(`/customers/${id}`)

  //test
  const updateAddress = (id, data) => api.put(`/addresses/${id}`, { ...data })
  const listAttributes = data => api.get('/attribute', data)
  const listAttributeItem = data => api.get(`attribute-item`, data)
  const listProduct = data => api.get('/product', data)
  const storeProduct = data => api.post('/product', { ...data })
  const updateProduct = (id, data) => api.put(`/product/${id}`, { ...data })
  const retrieveProduct = id => api.get(`/product/${id}`)
  const deleteProduct = id => api.delete(`/product/${id}`)
  const listCategory = data => api.get('/category', data)
  const listBrand = data => api.get('/brand', data)
  const listPlatform = data => api.get('/platform', data)
  const retrievePlatform = id => api.get(`/platform/${id}`)

  const listProvider = data => api.get('/provider', data)

  const listStoreBranches = data => api.get('/store-branches', data)
  const retrieveStoreBranches = id => api.get(`/stores/${id}`)

  const listPaymentMethods = data => api.get('/payment-method', data)

  const listPaymentParcels = data => api.get('/payment/parcels', data)

  const listPaymentGateways = data => api.get('/payment/gateways', data)

  const listPaymentFlags = data => api.get('/payment/flags', data)

  const listDeliveryCouriers = data => api.get('/courier', data)
  const listStoreBranch = data => api.get('/store-branch', data)
  const listPermission = data => api.get('/permission', data)
  const listEmployee = data => api.get('/employee', data)
  const listCommission = data => api.get('/commission', data)
  const retrieveEmployee = id => api.get(`/employee/${id}`)
  const updateEmployee = (id, data) => api.put(`/employee/${id}`, { ...data })
  const deleteEmployee = (id, data) => api.delete(`/employee/${id}`, data)
  const storeEmployee = data => api.post('/employee', { ...data })
  const listTaxClasses = data => api.get('/tax-class', data)
  const listTaxTypeItem = data => api.get('/tax-type-item', data)
  const listTaxOrigin = data => api.get('/tax-origin', data)
  const listPackageType = data => api.get('/package-type', data)
  const listTaxPayer = data => api.get('/tax-payer', data)
  const listTaxRegime = data => api.get('/tax-regime', data)
  const listState = data => api.get('/state', data)
  const listCity = data => api.get('/city', data)
  const updatePerson = (id, data) => api.put(`/person/${id}`, { ...data })
  const storePerson = data => api.post('/person', { ...data })
  const listOrigin = data => api.get('/person-origin', data)
  const listPerson = data => api.get('/person', data)
  const retrievePerson = id => api.get(`/person/${id}`)
  const deletePerson = id => api.delete(`/person/${id}`)
  const orderDashboardDetailByDate = data => api.get('/dashboard/order/detailByDate', data)
  const orderDashboardDetailBalanceByDate = data => api.get('/dashboard/order/detailBalanceByDate', data)
  const orderDashboardDetailOrderEmployeeByDate = data => api.get('/dashboard/order/detailEmployeeByDate', data)
  const orderDashboardDetailPaymentByDate = data => api.get('/dashboard/order/detailPaymentByDate', data)
  const orderDashboardDetailStatusByDate = data => api.get('/dashboard/order/detailOrderStatusByDate', data)
  const orderDashboardDetailPlatformByDate = data => api.get('/dashboard/order/detailPlatformByDate', data)
  const orderDashboardDetailStoreBranchByDate = data => api.get('/dashboard/order/detailStoreBranchByDate', data)

  const listOrderStatus = data => api.get('/order-status', data)
  const listTag = data => api.get('/tag', data)

  const upload = file => {
    const form = new FormData()
    form.append('file', file)
    const headers = {
      'Content-Type': 'multipart/form-data'
    }
    return api.post('/media', form, { headers })
  }

  const storePlatformConfig = data => api.post('/platform-config', { ...data })
  const updatePlatformConfig = data => api.put('/platform-config', { ...data })
  const listPlatformConfig = data => api.get('/platform-config', data)

  const storeSyncWoo = data => api.post('/sync/woo', { ...data })
  const retrieveSyncWoo = id => api.get(`/queue/${id}`)
  const deleteSyncWoo = id => api.delete(`/queue/${id}`)

  const checkSync = params => api.get('/platform-logs/', params)

  const listGlassesMaterial = data => api.get('/product-attributes/materials', data)
  const listGlassesShape = data => api.get('/product-attributes/shapes', data)

  const listTemplateMessages = data => api.get('/template-message', data)
  const storeTemplateMessage = data => api.post('/template-message', data)
  const deleteTemplateMessage = id => api.delete(`/template-message/${id}`)
  const retrieveTemplateMessage = id => api.get(`/template-message/${id}`)
  const updateTemplateMessage = (id, data) => api.put(`/template-message/${id}`, data)

  const listMessages = data => api.get('/message?include=TemplateMessage', data)
  const storeMessages = data => api.post('/message', data)
  const deleteMessage = id => api.delete(`/message/${id}`)
  const retrieveMessage = id => api.get(`/message/${id}`)
  const updateMessage = (id, data) => api.put(`/message/${id}`, data)

  const listSchedules = data => api.get('/schedule', data)
  const updateSchedule = (id, data) => api.put(`/schedule/${id}`, data)
  const deleteSchedule = id => api.delete(`/schedule/${id}`)
  const retrieveSchedule = id => api.get(`/schedule/${id}`)

  const listTypeTickets = data => api.get('/type-ticket/', data)
  const deleteTypeTicket = id => api.delete(`/type-ticket/${id}`)
  const retrieveTypeTicket = id => api.get(`/type-ticket/${id}`)
  const updateTypeTicket = (id, data) => api.put(`/type-ticket/${id}`, data)
  const storeTypeTicket = data => api.post(`/type-ticket`, data)

  const listTickets = data => api.get('/ticket?include=typeTicket,employee,customer', data)
  const deleteTicket = id => api.delete(`/ticket/${id}`)
  const retrieveTicket = id => api.get(`/ticket/${id}`)
  const updateTicket = (id, data) => api.put(`/ticket/${id}`, data)
  const storeTicket = data => api.post(`/ticket`, data)
  const storeNf = id => api.post(`/nf/` + id)
  return {
    auth,
    listProduct,

    storeProduct,
    retrieveStoreBranches,

    updateProduct,
    listCategory,
    listBrand,

    listPlatform,
    retrievePlatform,

    listProvider,
    listAttributes,
    listAttributeItem,
    listCommission,
    upload,

    listEmployee,
    retrieveEmployee,

    listStoreBranches,

    listCustomers,
    storeCustomers,
    updateCustomer,
    retrieveCustomer,

    updateAddress,
    retrieveProduct,
    deleteProduct,
    deleteProvider,
    retrieveProvider,
    storeProvider,
    updateProvider,
    deleteCategory,
    retrieveCategory,
    storeCategory,
    updateCategory,
    deleteBrand,
    retrieveBrand,
    storeBrand,
    updateBrand,
    deleteOrder,
    listOrder,
    retrieveOrder,
    storeOrder,
    updateOrder,

    listPaymentMethods,
    listPaymentParcels,
    listPaymentGateways,
    listPaymentFlags,
    listDeliveryCouriers,

    deleteAttribute,
    retrieveAttribute,
    storeAttribute,
    updateAttribute,
    deleteStore,
    listStore,
    retrieveStore,
    storeStore,
    updateStore,
    deleteStoreBranch,
    listStoreBranch,
    retrieveStoreBranch,
    storeStoreBranch,
    storeEmployee,
    updateEmployee,
    deleteEmployee,
    listPermission,
    updateStoreBranch,
    deleteAttributeItem,
    retrieveAttributeItem,
    storeAttributeItem,
    updateAttributeItem,
    listTaxClasses,
    listPerson,
    listTaxPayer,
    listTaxRegime,
    listState,
    listCity,

    updatePerson,
    storePerson,
    retrievePerson,
    deletePerson,
    listOrigin,

    listTaxTypeItem,
    listTaxOrigin,
    listPackageType,
    orderDashboardDetailByDate,
    orderDashboardDetailBalanceByDate,
    orderDashboardDetailOrderEmployeeByDate,
    orderDashboardDetailPaymentByDate,
    orderDashboardDetailStatusByDate,
    orderDashboardDetailPlatformByDate,
    orderDashboardDetailStoreBranchByDate,

    listOrderStatus,
    listTag,

    storePlatformConfig,
    updatePlatformConfig,
    listPlatformConfig,

    storeSyncWoo,
    retrieveSyncWoo,
    deleteSyncWoo,

    checkSync,

    listGlassesMaterial,
    listGlassesShape,
    storeNf,
    listTemplateMessages,
    storeTemplateMessage,
    deleteTemplateMessage,
    retrieveTemplateMessage,
    updateTemplateMessage,

    listMessages,
    storeMessages,
    deleteMessage,
    retrieveMessage,
    updateMessage,

    listSchedules,
    updateSchedule,
    deleteSchedule,
    retrieveSchedule,

    listTypeTickets,
    deleteTypeTicket,
    retrieveTypeTicket,
    updateTypeTicket,
    storeTypeTicket,

    listTickets,
    deleteTicket,
    retrieveTicket,
    updateTicket,
    storeTicket,
    listOrderCalendar
  }
}

export default {
  create
}
