import React from 'react'
import { CustomIcon } from '../../../components'

import styles from './SyncButton.module.css'

const SyncButton = ({ onClick }) => {
  return (
    <div className={styles.container} onClick={onClick}>
      <CustomIcon className={styles.icon} name="faSync" color="#5339b5" size="lg" spin />
    </div>
  )
}

export default SyncButton
