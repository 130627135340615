import React from 'react'
import './styles.css'

import { Form, Modal, Button, Row, Col, Typography } from 'antd'

const { Title, Paragraph } = Typography

function ModalFilters(props) {
  const {
    visible,
    onOk,
    title = 'Tem certeza que deseja excluir?',
    description = 'Os dados não poderão ser recuperados',
    handleCancel
  } = props
  const formLayout = 'vertical'

  return (
    <>
      <Modal visible={visible} closable={true} footer={null} onCancel={handleCancel}>
        <Form layout={formLayout} initialValues={{ layout: formLayout }}>
          <Row>
            <Col span={24} className="content">
              <Title level={3} className="description">
                {title}
              </Title>
              <Paragraph className="description">{description}</Paragraph>
            </Col>
            <Col span={24} className="containerBottom">
              <Button
                className="btn-cancel"
                onClick={() => {
                  if (handleCancel) handleCancel()
                }}
              >
                Cancelar
              </Button>
              <Button
                className="btn-filter"
                onClick={() => {
                  if (onOk) onOk()
                }}
              >
                Excluir
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default ModalFilters
