import React from 'react'

import '@ant-design/compatible/assets/index.css'
import ReactApexChart from 'react-apexcharts'
import { Spinner } from 'react-activity'
import './styles.css'
import empty1 from '../../assets/images/empty1.png'
import { defaultConfigChartLine } from '../../util/chartsUtil'
const BarChart = function(props) {
  const { data, title = '', axisX, axisY } = props
  return (
    <>
      {!data.isLoading && data[axisX] && data[axisY] && data[axisX].length > 0 && data[axisY].length > 0 && (
        <ReactApexChart
          options={defaultConfigChartLine(title, data[axisX])}
          series={data[axisY]}
          type="bar"
          height={360}
        />
      )}
      {!data.isLoading && data[axisX] && data[axisY] && data[axisX].length === 0 && (
        <>
          <h4>{title}</h4>
          <div className="containerEmpty">
            <img src={empty1} alt={title} className={'imgEmpty'}></img>
            <p>Nenhum dado encontrado</p>
          </div>
        </>
      )}
      {data.isLoading && (
        <div className="card-block">
          <Spinner className="primary-color"></Spinner>
        </div>
      )}
    </>
  )
}

export default BarChart
