import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import Settings from './Settings'
import loading from '../ducks/loading'
import alert from '../ducks/alert'
import platform from '../ducks/platform'
import auth from '../ducks/auth/auth'
import passwordRecovery from '../ducks/auth/passwordRecovery'
import resetPassword from '../ducks/auth/resetPassword'

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    loading,
    alert,
    auth,
    passwordRecovery,
    resetPassword,
    platform
  })
export default createRootReducer
