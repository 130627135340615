import React, { useState } from 'react'
import { Button } from 'antd'
import { formatPrice } from '../../../../util/format'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import notFound from '../../../../assets/images/noImage.png'
import * as icons from '@fortawesome/free-solid-svg-icons'
import './orderPreviewProducts.css'

import VariationPriceModal from './orderPreviewProductsModal'

export function ProductsItems({ data = [], remove, isReadOnly, handlePrice, alert, formValues }) {
  const [priceModalVisible, setPriceModalVisible] = useState(false)
  const [variationSelected, setVariationSelected] = useState({})

  function renderItem(product) {
    const {
      name,
      filledByVariation: { qtd, attributes, finalPrice, price }
    } = product

    return (
      <div className="container__item__left__variation">
        <div className="content">
          <span>
            {qtd}
            {' x '}
          </span>
          <div>
            <span>{name}</span>
            <div>{attributes && attributes.map(att => <span key={att.id}>{`${att.name} `}</span>)}</div>
          </div>
        </div>

        {finalPrice ? (
          <div className="price">
            <span>{formatPrice(price * qtd)}</span>
            <span>{formatPrice(finalPrice * qtd)}</span>
          </div>
        ) : (
          <div>{formatPrice(price * qtd)}</div>
        )}
      </div>
    )
  }

  const handleClickVariation = p => {
    setVariationSelected(p)
    setPriceModalVisible(true)
  }

  return (
    <div className="container__body_items">
      {data &&
        data.map((p, i) => (
          <div key={i} className="container__item">
            <div className="container__item__left" onClick={() => handleClickVariation(p)}>
              <img src={p.src || notFound} alt="" />
              <span>{renderItem(p)}</span>
            </div>
            <div className="container__item__right">
              <Button type="link" onClick={() => remove(data, i)} disabled={isReadOnly}>
                <FontAwesomeIcon icon={icons.faTrash} color="black" size="sm" />
              </Button>
            </div>
          </div>
        ))}

      {priceModalVisible && (
        <VariationPriceModal
          item={variationSelected}
          handlePrice={handlePrice}
          visible={priceModalVisible}
          setVisible={setPriceModalVisible}
          alert={alert}
          isReadOnly={isReadOnly}
          formValues={formValues}
        />
      )}
    </div>
  )
}
