import { isEmpty } from 'lodash'
import moment from 'moment-timezone'

export function formatToSelect(arr = []) {
  const list = arr.map(r => ({
    value: r.id,
    description: r.name
  }))
  return list
}

export function onlyNumbers(text) {
  return text ? text.replace(/[^0-9]+/g, '') : null
}

export function phoneMask(text) {
  return text ? text.replace(/(\d{2})(\d{5})(\d{4})/g, '($1) $2-$3') : null
}

export function maskCpfCnpj(text) {
  if (text) {
    if (text.length <= 11) {
      return text.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
    } else {
      return text.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
    }
  }
  return '-'
}

export function maskCep(text = '00000000') {
  return text.replace(/(\d{6})(\d{2})/g, '$1-$2')
}

export function formatPrice(number = 0, noCurr) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: noCurr ? '' : 'BRL'
  }).format(number)
}

export function formatAddress(address = {}) {
  if (isEmpty(address)) return '-'

  let formatedAddress = ''
  if (address.streetName) {
    formatedAddress += `${address.streetName}, `
  }
  if (address.number) {
    formatedAddress += `${address.number}, `
  }
  if (address.complement) {
    formatedAddress += `${address.complement}. `
  }
  if (address.neighborhood) {
    formatedAddress += `${address.neighborhood}, `
  }
  if (address.city) {
    formatedAddress += `${address.city.cityName}. ${address.city.stateName}. `
  }
  if (address.zipCode) {
    formatedAddress += `${maskCep(address.zipCode)}.`
  }

  return formatedAddress
}

export function toNumberEn(value) {
  if (!value) return null

  const result = String(value)
    .replace(/\./g, '')
    .replace(',', '.')
  return +result
}

export const removePricePrefix = text => {
  if (text) {
    const valueFromField = String(text)
      .replace('R$', '')
      .trim()

    return valueFromField
  }
}

export const formatDateWithHour = date => {
  if (!date) return '-'

  return moment(date).format('DD/MM/YYYY HH:mm:ss')
}

export const formatTemplateMessagesDate = (date, empty) => {
  if (!date) return empty

  const _date = new Date(date)

  if (_date.toString() === 'Invalid Date') {
    return date
  }

  const hour = String(_date.getHours()).padStart(2, '0')
  const minutes = String(_date.getMinutes()).padStart(2, '0')

  if (hour && minutes) {
    return `${hour}:${minutes}`
  }
}

export function normalizeValue(v) {
  return typeof v === 'string' ? toNumberEn(removePricePrefix(v)) : v
}
