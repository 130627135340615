import React from 'react'
import './styles.css'

import { Form, Modal, Button, Row, Col } from 'antd'

function ModalFilters(props) {
  const { visible, onOk, children, handleReset, onCancel } = props
  const formLayout = 'vertical'

  return (
    <>
      <Modal visible={visible} footer={null} zIndex={9} onCancel={onCancel}>
        <Form layout={formLayout} initialValues={{ layout: formLayout }}>
          {children}
          <Row className="rowBottomModalFilter">
            <Col xs={24} sm={24} md={14} lg={14} xl={14} className="containerBottomModalFilters gutter-row">
              <Button className="btn-cleanFiltersModalFilter" onClick={handleReset}>
                Limpar filtros
              </Button>
              <Button className="btn-filterModalFilter" onClick={() => onOk()}>
                Filtrar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default ModalFilters
