import React, { useState } from 'react'
import CustomSelect from '../CustomSelect/customSelect'
import CustomInput from '../CustomInput/customInput'
import { getMessage } from '../../util/IntlMessages'
import { Row, Typography, Form, Col, Button, Tag, Input } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as icons from '@fortawesome/free-solid-svg-icons'
import { If } from 'react-extras'
import _ from 'lodash'
import './variationsBlock.css'
import { api, request } from '../../util/handlerRequestUtil'
import ModalChooseImage from '../ModalChooseImage'
import ModalStock from '../ModalStock'

const VariationBlock = function (props) {
  const { filters, attributeItems, attributeTable = [], variationId, formik, variations, setVariations, isReadOnly } = props
  const { values, setValues, errors } = formik
  const { formatedAttribute } = filters
  const [isExpanded, setIsExpanded] = useState(false)
  const [showChooseImage, setShowChooseImage] = useState(false)
  const [showStock, setShowStock] = useState(false)
  const isVariable = values.productType === 'variable'
  function onClickBtn() {
    setIsExpanded(!isExpanded)
  }
  function getFormatedName() {
    let nameValue = ''
    attributeTable.forEach((item, index) => {
      const attribute = formatedAttribute.find(attribute => attribute.id === item)
      const hasValue = _.get(values, `variation${variationId}.${attribute.name}`)
      if (hasValue) {
        const list = attributeItems[`attribute${item}`]
        const formatedName = list.find(item => item.value === hasValue)
        if (formatedName) {
          nameValue = nameValue.concat(`${index !== 0 ? '-' : ''}${formatedName.name}`)
        }
      }
    })

    return nameValue !== '' ? ` - ${nameValue}` : ''
  }
  function onRemoveVariation() {
    setVariations(variations.filter(item => item.id !== variationId))
  }
  function onDuplicateVariation() {
    const lastOne = variations[variations.length - 1]
    if (lastOne) {
      setVariations([
        ...variations,
        {
          id: lastOne.id + 1
        }
      ])
      let valuesToSend = {}
      let itemToDuplicate = { ...values[`variation${variationId}`] }
      delete itemToDuplicate.id
      valuesToSend[`variation${lastOne.id + 1}`] = {
        ...itemToDuplicate,
        stock: [],
        wooId: undefined
      }
      setValues({
        ...values,
        ...valuesToSend
      })
    }
  }
  function onSelectImageVariation(file) {
    setShowChooseImage(false)
    let newValues = { ...values }
    newValues[`variation${variationId}`] = {
      ...newValues[`variation${variationId}`],
      image: file
    }
    console.log('newValues', newValues)
    setValues({
      ...newValues
    })
  }

  const addOrigin = async (name) => {
    const attributeId = attributeTable[0]
    if (attributeId) {
      await request(api.storeAttributeItem({ name, attributeId }))
      setValues({
        ...values,
        attributeTable: [...values.attributeTable]
      })
    }

  }
  return (
    <>
      {isVariable && (
        <div className={errors[`variation${variationId}`] ? 'validationError mb-2' : 'mb-2'}>
          {errors[`variation${variationId}`] && <label className="mb-2 d-block">Validações pendentes</label>}
          <div className="d-flex flex-row">
            <Button className="bg-white mr-2 buttonVariationBlock" onClick={onClickBtn}>
              <FontAwesomeIcon icon={!isExpanded ? icons.faPen : icons.faMinus}></FontAwesomeIcon>
            </Button>
            <Button className="bg-white mr-2 buttonVariationBlock" onClick={onDuplicateVariation} disabled={isReadOnly}>
              <FontAwesomeIcon icon={icons.faCopy}></FontAwesomeIcon>
            </Button>
            <Typography.Title level={4}>
              {values.name}
              {getFormatedName()}
            </Typography.Title>
            <Button className="bg-white ml-2 buttonVariationBlock" onClick={onRemoveVariation} disabled={isReadOnly}>
              <FontAwesomeIcon icon={icons.faTimes}></FontAwesomeIcon>
            </Button>
          </div>
        </div>
      )}
      <If condition={isVariable ? isExpanded : true}>
        <div>
          <Row type="flex" justify="start">
            {isVariable && (
              <Col span={24} className="mb-2">
                <Button
                  disabled={isReadOnly}
                  className="bgTertiary"
                  onClick={() => setShowChooseImage(true)}
                  icon={<FontAwesomeIcon className="colorPrimary" icon={icons.faUpload}></FontAwesomeIcon>}
                >
                  <label className="colorPrimary ml-2">{getMessage('choosePicture')}</label>
                </Button>
                {_.get(values, `variation${variationId}.image`) && (
                  <Tag className="ml-2">{_.get(values, `variation${variationId}.image.key`)}</Tag>
                )}
              </Col>
            )}
            {isVariable &&
              formatedAttribute &&
              attributeTable &&
              attributeTable.map(item => {
                const attribute = formatedAttribute.find(attribute => attribute.id === item)
                return (
                  <CustomSelect
                    disabled={isReadOnly}
                    key={item}
                    context={formik}
                    field={attribute.name}
                    ignoreTranslate
                    rowSpan={12}
                    values={attributeItems ? attributeItems[`attribute${item}`] : []}
                    formikPrefix={`variation${variationId}`}
                    addOption={addOrigin}
                  />
                )
              })}
          </Row>
          <Row type="flex" justify="start">
            <CustomInput
              disabled={isReadOnly}
              type="number"
              context={formik}
              field="cost"
              rowSpan={12}
              addonBefore="R$"
              placeholder="Insira o custo do produto"
              formikPrefix={`variation${variationId}`}
            />
            {isVariable && (
              <CustomInput
                disabled={isReadOnly}
                context={formik}
                field="sku"
                rowSpan={12}
                placeholder=""
                formikPrefix={`variation${variationId}`}
                suffixLabel={'- Variação'}
              />
            )}

            <CustomInput
              disabled={isReadOnly}
              type="number"
              context={formik}
              field="price"
              rowSpan={12}
              values={''}
              addonBefore="R$"
              placeholder="Insira o preço"
              formikPrefix={`variation${variationId}`}
            />
            <CustomInput
              type="number"
              disabled={isReadOnly}
              context={formik}
              field="promotionalValue"
              rowSpan={12}
              values={''}
              addonBefore="R$"
              placeholder="Insira o valor promocional"
              formikPrefix={`variation${variationId}`}
            />
            <If condition={_.get(values, 'manageInventory')}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} onClick={() => setShowStock(true)}>
                <Form.Item label={getMessage('stock')} className="w-100">
                  <Input
                    className="w-100"
                    disabled
                    value={_.get(values, `variation${variationId}.stock`, []).reduce(
                      (total, item) => total + Number(item.quantity),
                      0
                    )}
                  ></Input>
                </Form.Item>
              </Col>
            </If>
          </Row>
        </div>
      </If>
      <ModalChooseImage
        handleReset={() => setShowChooseImage(false)}
        context={formik}
        visible={showChooseImage}
        onOk={file => onSelectImageVariation(file)}
        imageSelected={_.get(values, `variation${variationId}.image`)}
      ></ModalChooseImage>
      <ModalStock
        filters={filters}
        handleReset={() => setShowStock(false)}
        context={formik}
        variationId={variationId}
        visible={showStock}
      ></ModalStock>
    </>
  )
}

export default VariationBlock
