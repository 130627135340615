import React, { useEffect } from 'react'
import { Form, Row } from 'antd'

import { containerTypes, fieldTypes } from '../utils/enum'
import { onlyUnique } from '../../../util/customFieldsUtil'
const RenderContainer = ({ data, formik }) => {
  return data.map(({ containerType, fields, position }) => {
    const ContainerTag = containerTypes[containerType]
    const uniqueRows = fields.map((item) => item.row).filter(onlyUnique);


    function setFormikDefaultValues(fields, formik) {
      const defaultValuesFields = fields
        .filter(field => field.name)
        .reduce((values, field) => ({ ...values, [field.name]: field.defaultValue }), {})

      formik.setValues(defaultValuesFields)
    }

    useEffect(() => setFormikDefaultValues(fields, formik), [])

    console.log('data', data)
    console.log('unique rows', uniqueRows)

    return (
      <ContainerTag key={containerType + position}>
        <Form layout={'vertical'} initialValues={{ layout: 'vertical' }}>
          {uniqueRows.map((row) => {
            return (<Row type="flex" justify="start" key={`row-${row}`}>
              {fields.sort((field1, field2) => field1.position - field2.position).filter((field) => field.row === row && !field.hidden).map(field => {
                const FieldTag = fieldTypes[field.fieldType]
                return (
                  <FieldTag
                    key={field.name + field.position}
                    field={field.name}
                    label={field.label}
                    context={formik}
                    rowSpan={field.col}
                    defaultValue={field.defaultValue}
                    placeholder={field.placeholder}
                    disabled={field.disabled}
                    required={field.required}
                  />
                )
              })}
            </Row>)
          })}
        </Form>
      </ContainerTag>
    )
  })
}

export default RenderContainer
