import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Layout } from 'antd'
import { footerText } from 'util/config'

import Sidebar from '../Sidebar/index'

import Topbar from '../Topbar/index'
import App from '../../routes/adminRoutes'
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE
} from '../../constants/ThemeSetting'

const { Content, Footer } = Layout

export class MainApp extends Component {
  getContainerClass = navStyle => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return 'gx-container-wrap'
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return 'gx-container-wrap'
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return 'gx-container-wrap'
      case NAV_STYLE_BELOW_HEADER:
        return 'gx-container-wrap'
      case NAV_STYLE_ABOVE_HEADER:
        return 'gx-container-wrap'
      default:
        return ''
    }
  }

  getSidebar = (navStyle, width) => {
    if (width < TAB_SIZE) {
      return <Sidebar />
    }
    switch (navStyle) {
      case NAV_STYLE_FIXED:
        return <Sidebar />
      case NAV_STYLE_DRAWER:
        return <Sidebar />
      case NAV_STYLE_MINI_SIDEBAR:
        return <Sidebar />
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <Sidebar />
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <Sidebar />
      default:
        return null
    }
  }

  render() {
    const { match, width, navStyle } = this.props

    return (
      <Layout className="gx-app-layout">
        {this.getSidebar(navStyle, width)}

        <Layout>
          <Topbar />
          <Content className={`gx-layout-content ${this.getContainerClass(navStyle)} `}>
            <App match={match} />
            <Footer>
              <div className="gx-layout-footer-content">{footerText}</div>
            </Footer>
          </Content>
        </Layout>
      </Layout>
    )
  }
}

const mapStateToProps = ({ settings }) => {
  const { width, navStyle } = settings
  return { width, navStyle }
}
export default connect(mapStateToProps)(MainApp)
