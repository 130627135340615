import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import { If } from 'react-extras'
import { fadeInUp } from 'react-animations'

import Radium, { StyleRoot } from 'radium'
import { Button, Table, Input, Row, Col, Tooltip } from 'antd'
import * as icons from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchOutlined } from '@ant-design/icons'
import { getMessage } from '../../util/IntlMessages'
import { FormikProvider } from 'formik'
import debounceEvent from '../../util/debounce'
import Pagination from '../../components/Pagination'
import TagsFilter from '../../components/TagsFilter'
import ModalFilters from '../../components/ModalFilters'
import ModalDelete from '../../components/ModalDelete'
import { generateFilters } from '../../util/filtersUtil'
import { CustomPopover, CustomIcon } from '../'
import { insertIf } from '../../util/util'

import './styles.css'

const styles = {
  bounce: {
    animation: 'x 0.3s',
    animationName: Radium.keyframes(fadeInUp, 'bounce')
  }
}

const ListScreen = forwardRef((props, ref) => {
  const [name, setName] = useState('')
  const {
    columns,
    data,
    title,
    titleButtonAdd,
    iconButtonAdd,
    onSearch,
    filtersList,
    dataFilters,
    onChange,
    onChangeCreateButton,
    pagination: { currentPage, pages, total },
    onFilter,
    context,
    onClickDelete,
    modalDelete = {},
    setModalDelete,
    onClickEdit,
    onClickView,
    onClickOpenOthertab,
    onClickDuplicate,
    searchPlaceholder,
    validFieldsKeys = [],
    hideCreateButton = false,
    hideActionsColumn = false,
    hideActions = [],
    expandable
  } = props
  const [visible, setVisible] = useState(false)
  const [filterValues, setFilterValues] = useState([])

  const { values, setFieldValue, handleReset, setValues } = context
  const showModal = () => {
    setVisible(true)
  }

  const handleOk = (initialValues = {}) => {
    const combinedValues = { ...initialValues, ...values }

    if (validFieldsKeys.length > 0) {
      // just sent existentes keys to tags generate
      Object.keys(combinedValues).forEach(key => validFieldsKeys.includes(key) || delete combinedValues[key])
    }

    setValues(combinedValues)

    generateFilters(combinedValues, setFilterValues, dataFilters)
    setVisible(false)

    onFilter(name, combinedValues)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const onChangePage = (page, name) => onChange(page, name)

  function getActionsFormatted(index) {
    return (
      <StyleRoot className={styles.container}>
        <div className={'container-actions'} style={styles.bounce}>
          <Tooltip placement="bottom" title={'Editar'}>
            <FontAwesomeIcon
              icon={icons.faPen}
              size="2x"
              className="columnIcon"
              onClick={() => {
                onClickEdit(data[index])
              }}
            />
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={'Visualizar'}
            onClick={() => {
              onClickView(data[index])
            }}
          >
            <FontAwesomeIcon icon={icons.faEye} size="2x" className="columnIcon" />
          </Tooltip>
          {!hideActions.includes('copy') && (
            <Tooltip
              placement="bottom"
              title={'Copiar'}
              onClick={() => {
                onClickDuplicate(data[index])
              }}
            >
              <FontAwesomeIcon icon={icons.faCopy} size="2x" className="columnIcon" />
            </Tooltip>
          )}
          {!hideActions.includes('delete') && (
            <Tooltip placement="bottom" title={'Excluir'}>
              <FontAwesomeIcon
                icon={icons.faTrash}
                size="2x"
                className="columnIcon"
                onClick={() => {
                  onClickDelete(data[index])
                }}
              />
            </Tooltip>
          )}
          {onClickOpenOthertab && (
            <Tooltip placement="bottom" title="Abrir em nova aba">
              <FontAwesomeIcon
                icon={icons.faExternalLinkSquareAlt}
                size="2x"
                className="columnIcon"
                onClick={() => {
                  onClickOpenOthertab(data[index])
                }}
              />
            </Tooltip>
          )}
        </div>
      </StyleRoot>
    )
  }

  const handleCloseTag = item => {
    setFilterValues(filterValues.filter(item2 => item2.identifier !== item.identifier))
    setFieldValue(item.identifier, undefined)
    setFieldValue('forceUpdate', true)
  }

  useEffect(() => {
    if (context.values.forceUpdate) {
      onFilter(name)
      setFieldValue('forceUpdate', false)
    }
  }, [context.values.forceUpdate])

  useImperativeHandle(ref, () => ({
    handleOk
  }))

  return (
    <FormikProvider value={context}>
      <div className="conteiner">
        <div className="content_product">
          <h1>{title}</h1>
          {!hideCreateButton && (
            <Button type="primary" className="btn_newProduct" onClick={onChangeCreateButton}>
              <If condition={Boolean(!iconButtonAdd)}>
                <FontAwesomeIcon icon={icons.faPlus} size="sm" />
              </If>
              <If condition={Boolean(iconButtonAdd)}>{iconButtonAdd}</If>
              <span>{titleButtonAdd}</span>
            </Button>
          )}
        </div>
        <Row>
          <Col className="gutter-row" xs={24} sm={24} md={8} lg={8} xl={8}>
            <div className="containerSearch">
              <Input
                value={name}
                onChange={event => {
                  const text = event.target.value
                  setName(text)
                  debounceEvent(() => onSearch(text))
                }}
                placeholder={`Procure pelo(a) ${searchPlaceholder || 'nome'}`}
                suffix={<SearchOutlined />}
                className="input_search"
              ></Input>
              <Button type="primary" className="btn_filter" onClick={showModal}>
                <FontAwesomeIcon icon={icons.faSlidersH} size="lg" className="icon" />
              </Button>
            </div>
          </Col>
          <Col className="gutter-row rowTags" xs={24} sm={24} md={10} lg={10} xl={10}>
            <div className="containerTags">
              <TagsFilter filterValues={filterValues} onClose={handleCloseTag} />
            </div>
          </Col>
          <Col className="gutter-row containerPagination" xs={24} sm={24} md={6} lg={6} xl={6}>
            <Pagination
              currentPage={currentPage}
              pages={pages}
              total={total}
              onChange={onChangePage}
              description={getMessage('products')}
            />
          </Col>
        </Row>
        <Table
          expandable={expandable}
          columns={[
            ...columns,
            ...insertIf(!hideActionsColumn, {
              title: getMessage('actions'),
              key: 'actions',
              render: (_, __, index) => {
                return (
                  <div id="ListScreenActions" className={`width-${hideActions.length}x`}>
                    <CustomPopover content={getActionsFormatted(index)} trigger="hover">
                      <CustomIcon name="faEllipsisH" className="ml5 primary-color" />
                    </CustomPopover>
                  </div>
                )
              }
            })
          ]}
          className="table-products"
          dataSource={data}
          currentPage={currentPage}
          pagination={false}
          scroll={{ x: true }}
          rowKey="key"
        ></Table>
      </div>
      <div>
        <ModalFilters visible={visible} onOk={handleOk} onCancel={handleCancel} handleReset={handleReset}>
          {filtersList()}
        </ModalFilters>
        <ModalDelete
          {...modalDelete}
          visible={modalDelete.visible}
          handleCancel={() =>
            setModalDelete({
              ...modalDelete,
              visible: false
            })
          }
        ></ModalDelete>
      </div>
    </FormikProvider>
  )
})

export default ListScreen
