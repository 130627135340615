import { Calendar, Badge, ConfigProvider, Card } from 'antd'
import React, { useState, useEffect } from 'react'
import ptBR from 'antd/lib/locale/pt_BR'
import { api, request } from '../../util/handlerRequestUtil'

function CalendarOs(props) {
  const { getMessage } = props
  let uniqueStatus
  const colors = ['pink', 'red', 'yellow', 'blue', 'purple', 'geekblue', 'magenta', 'volcano', 'gold', 'lime']
  const [isLoading, seIsLoading] = useState(true)
  const [formatedColors, setFormatedColors] = useState({})
  const [legend, setLegend] = useState([])
  const [orders, setOrders] = useState([])
  function dateCellRender(value) {
    const listData = []
    orders.forEach(item => {
      if (value.format('YYYY-MM-DD') === item.date) {
        listData.push({ type: 'error', ...item })
      }
    })
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }} onClick={() => onSelect(value)}>
        {listData.map(item => {
          return (
            <Badge
              key={`${item.date}${item.statusId}`}
              color={formatedColors[item.statusId]}
              text={`Pedidos - ${item.qtd}`}
            />
          )
        })}
      </div>
    )
  }

  function init() {
    uniqueStatus = new Set()
    const formatedLegend = []
    orders.forEach(({ statusId }) => {
      uniqueStatus.add(statusId)
    })
    Array.from(uniqueStatus).forEach(statusId => {
      const itemOrder = orders.find(order => order.statusId === statusId)

      if (statusId === 11) {
        formatedColors[statusId] = 'orange'
      } else if (statusId === 12) {
        formatedColors[statusId] = 'green'
      } else if (statusId === 12) {
        formatedColors[statusId] = 'cyan'
      } else {
        const item = colors[Math.floor(Math.random() * colors.length)]
        formatedColors[statusId] = item
        colors.unshift(item)
      }
      formatedLegend.push({
        color: formatedColors[statusId],
        description: itemOrder.description
      })
    })
    setFormatedColors(formatedColors)
    setLegend(formatedLegend)
  }

  useEffect(() => {
    request(api.listOrderCalendar()).then(data => {
      setOrders(data)
      seIsLoading(false)
    })
  }, [])

  useEffect(() => {
    if (orders.length > 0) {
      init()
    }
  }, [orders])

  function onSelect(value) {
    window.open(`/order/list?dueDate=${value.format('YYYY-MM-DD')}&orderType=SERVICE`, '_blank', 'noopener,noreferrer')
  }
  return (
    <>
      <Card title={getMessage('calendaryLens')}>
        <ConfigProvider locale={ptBR}>
          {!isLoading && (
            <>
              {legend.map(legend => {
                return <Badge key={legend.color} color={legend.color} text={legend.description} />
              })}
              <Calendar locale={ptBR} dateCellRender={dateCellRender} />
            </>
          )}
        </ConfigProvider>
      </Card>
    </>
  )
}

export default CalendarOs
