export function parseCurrency(number) {
  if (!number) {
    return null
  }
  return Number(
    String(number)
      .replace(/\./g, '')
      .replace(',', '.')
  )
}
