import React, { useState, useEffect } from 'react'
import { Row } from 'antd'
import { useFormik } from 'formik'
import _ from 'lodash'
import ListScreen from '../../components/ListScreen'
import { api, request } from '../../util/handlerRequestUtil'
import './listBrand.css'

function ListBrand(props) {
  const { alert, getMessage } = props
  const formik = useFormik({
    initialValues: {}
  })
  const [modalDelete, setModalDelete] = useState({
    description: getMessage('defaultDeleteDescription'),
    visible: false
  })
  const { values } = formik
  const [data, setData] = useState({})
  const [filters, setFilters] = useState({})
  const { page = 1, pageSize = 10, count = 0, pages = 0 } = data
  const brand = _.get(data, 'items', []).map(item => {
    return {
      key: item.id,
      ...item
    }
  })
  const columns = [
    {
      title: getMessage('name'),
      dataIndex: 'name',
      key: 'name'
    }
  ]

  useEffect(() => {
    requests()
  }, [])
  
  async function requests() {
    try {
      await requestData()
      setFilters({})
    } catch (error) {
      console.tron.log(error)
    }
  }

  function onClickEdit(brand) {
    props.history.push(`/brand/${brand.key}`)
  }

  async function onClickDelete(brand) {
    setModalDelete({
      ...modalDelete,
      title: getMessage('deleteBrandTitle', brand.name),
      visible: true,
      onOk: async () => onConfirmDelete(brand)
    })
  }

  async function onConfirmDelete(brand) {
    alert.open(getMessage('successBrandDelete'), 'success')
    await request(api.deleteBrand(brand.key))
    setModalDelete({
      ...modalDelete,
      visible: false
    })
    await requestData(values)
  }

  function onClickView(brand) {
    props.history.push(`/brand/${brand.key}?readOnly=true`)
  }

  function onClickDuplicate(brand) {
    props.history.push(`/brand/create?duplicate=${brand.key}`)
  }

  const requestData = (params = {}) =>
    request(
      api.listBrand({
        ...params,
        include: `
    // cli-include
    `
      })
    ).then(data => setData(data))
  
  function handleFilter(value) {
    let filters = {}
    if (value && value.trim() !== '') {
      filters.name = value
    }
    requestData({
      ...filters
    })
  }

  const onSearch = value => handleFilter(value)

  async function requestPage(page) {
    await requestData({ page: page - 1 })
  }

  const onChangeCreateButton = () => {
    props.history.push('/brand/create')
  }

  function filtersList() {
    return (
      <Row type="flex" justify="start">
        {/* cli-filters */}
      </Row>
    )
  }
  return (
    <ListScreen
      context={formik}
      columns={columns}
      data={brand}
      title={getMessage('brand')}
      titleButtonAdd={getMessage('newBrand')}
      onSearch={onSearch}
      filtersList={filtersList}
      dataFilters={filters}
      onChange={requestPage}
      onChangeCreateButton={onChangeCreateButton}
      pagination={{
        currentPage: page + 1,
        pages: pages,
        total: count,
        pageSize: pageSize
      }}
      onFilter={handleFilter}
      onClickDelete={onClickDelete}
      modalDelete={modalDelete}
      setModalDelete={setModalDelete}
      onClickEdit={onClickEdit}
      onClickView={onClickView}
      onClickDuplicate={onClickDuplicate}
    />
  )
}

export default ListBrand
