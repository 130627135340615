import React, { useState, useEffect, memo } from 'react'
import { Modal, Button, Row, Col, Spin } from 'antd'
import { getMessage } from '../../../../util/IntlMessages'
import { formatPrice } from '../../../../util/format'
import { get } from 'lodash'
import { Creators as AlertActions } from '../../../../appRedux/ducks/alert'
import { useDispatch } from 'react-redux'
import { Variations } from './productModalVariations'
import { CustomIcon } from '../../../../components'
import notFound from '../../../../assets/images/noImage.png'
import Image from '../../../../components/Helper/Image'
import './productModal.css'

const ProductModal = props => {
  const dispatch = useDispatch()
  const { setVisible, data, setItemToOrder, formValues, setExpandedRowKeys, visible, loading } = props
  const { id: productId, name, sku, brand, imageId, type, images, variations = [], ...restSelectedProductProps } = data
  const [imgs, setImgs] = useState([])
  const [mainPhoto, setMainPhoto] = useState('')
  const [amount, setAmount] = useState([])
  const [total, setTotal] = useState(0)
  const [variationsFormated, setVariationsFormated] = useState([])

  useEffect(() => {
    if (images) {
      const imgsFormat = images.map(i => ({ id: i.id, src: i.location }))
      const mainPhotoLocation = images.find(i => i.id === imageId)

      setImgs(imgsFormat)
      setMainPhoto(get(mainPhotoLocation, 'location', notFound))
    }
  }, [imageId])

  useEffect(() => {
    if (variations.length > 0) {
      const amount = variations.map(v => ({
        id: v.id,
        qtd: 0,
        price: v.price,
        finalPrice: '',
        attributes: v.attributes,
        productId: v.productId
      }))

      const data = variations.map(v => {
        const hasStock = v.stock.find(stock => stock.storeBranchId === formValues.store)
        v.stockQuantity = hasStock ? hasStock.quantity : 0
        return v
      })

      setAmount(amount)
      setVariationsFormated(data)
    }
  }, [variations])

  useEffect(() => {
    if (amount.length > 0) {
      const total = amount.reduce(
        (total, variation) => total + variation.qtd * (variation.finalPrice || variation.price),
        0
      )

      setTotal(total)
    }
  }, [amount])

  const handleMinusVariation = i => {
    setAmount(prev => {
      let newAmount = [...prev]
      newAmount[i].qtd -= 1

      return newAmount
    })
  }

  const handlePlusVariation = i => {
    setAmount(prev => {
      let newAmount = [...prev]
      newAmount[i].qtd += 1

      return newAmount
    })
  }

  const addItemToOrder = (id, name, src, amount) => {
    const qtd = amount.reduce((total, curr) => curr.qtd + total, 0)
    if (qtd === 0) {
      return dispatch(AlertActions.openAlert('Informe a quantidade para que o produto seja adicionado!', 'error'))
    } else {
      setExpandedRowKeys([''])
      setVisible(false)
    }

    const itemsOrder = amount
      .filter(v => v.qtd > 0)
      .map(v => ({
        ...restSelectedProductProps,
        id,
        name,
        src,
        filledByVariation: v,
        finalName: type === 'simple' ? name : `${name} - ${v.attributes.map(a => a.name).join(', ')}`
      }))

    setItemToOrder(prev => {
      if (prev.length === 0) return itemsOrder

      let newOrder = [...prev]
      itemsOrder.forEach((p, i) => {
        const prodExist = newOrder.findIndex(prod => prod.filledByVariation.id === p.filledByVariation.id)
        if (prodExist !== -1) {
          newOrder[prodExist].filledByVariation.qtd =
            p.filledByVariation.qtd + newOrder[prodExist].filledByVariation.qtd

          newOrder[prodExist].filledByVariation.finalPrice = p.filledByVariation.finalPrice
        } else {
          newOrder.push(itemsOrder[i])
        }
      })

      return newOrder
    })
  }

  const handleBack = () => {
    setExpandedRowKeys([''])
    setVisible(false)
    setTotal(0)
    setImgs([])
    setMainPhoto('')
    setVariationsFormated([])
  }

  return (
    <Modal visible={visible} footer={null} closable={true} onCancel={() => handleBack()}>
      <Spin spinning={loading} tip={getMessage('loading')}>
        <Row justify="center" className="content-product-modal">
          <Col xs={18} sm={18} md={12} lg={12} xl={12}>
            <div className="left">
              <div className="sm-photos">
                {imgs.map((img, i) => {
                  return (
                    <Image
                      key={i}
                      src={img.src}
                      alt={`Foto ${i + 1}`}
                      onClick={() => setMainPhoto(img.src)}
                      className={`photo ${mainPhoto === img.src && 'selected'}`}
                    />
                  )
                })}
              </div>

              <div style={{ height: 255 }}>
                {visible && <Image className="main-photo" src={mainPhoto} alt="Foto Principal" />}
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className="right">
              <span className="brand">{get(brand, 'name', '')}</span>

              <strong className="name">{name}</strong>

              <span>SKU</span>
              <span className="sku">{sku || getMessage('notFound')}</span>

              <span>{getMessage('price')}</span>

              <div>
                <Variations
                  amount={amount}
                  variations={variationsFormated}
                  images={images}
                  handleMinus={handleMinusVariation}
                  handlePlus={handlePlusVariation}
                  formValues={formValues}
                  manageStock={data.manageStock}
                />
              </div>
            </div>
          </Col>
        </Row>
        <div className="content-btns-actions">
          <div className="btn">
            <Button className="btn_back" onClick={() => handleBack()}>
              <CustomIcon name="faArrowLeft" className="icon" />
            </Button>
            <span>{getMessage('backToOrder')}</span>
          </div>
          <Button className="btn" type="primary" onClick={() => addItemToOrder(productId, name, mainPhoto, amount)}>
            Adicionar <span>{formatPrice(total)}</span>
          </Button>
        </div>
      </Spin>
    </Modal>
  )
}

export default memo(ProductModal)
