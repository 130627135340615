import React, { useEffect, useState } from 'react'
import { Row } from 'react-dom'
import ListScreen from '../../components/ListScreen'
import { useFormik } from 'formik'
import _ from 'lodash'
import { api, request } from '../../util/handlerRequestUtil'
import './listEmployee.css'

function ListEmployee(props) {
    const { alert, getMessage } = props
    const formik = useFormik({
        initialValues: {}
    })
    const { values } = formik
    const [data, setData] = useState({})
    const [filters, setFilters] = useState({})
    const [modalDelete, setModalDelete] = useState({
        description: getMessage('defaultDeleteDescription'),
        visible: false
    })
    const { page = 1, pageSize = 10, count = 0, pages = 0 } = data
    const employee = _.get(data, 'items', []).map(item => {
        return {
            key: item.id,
            ...item
        }
    })

    const columns = [
        {
            title: getMessage('name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: getMessage('username'),
            dataIndex: 'username',
            key: 'username'
        },
        {
            title: getMessage('stores'),
            dataIndex: 'storeBranch',
            key: 'storeBranch',
            render: stores => (
                <>
                    {stores.map((store, index) => {
                        let display = store.name
                        if (stores.length - 1 !== index) display += ', ' 
                        return (
                            <span key={store.id}>{display}</span>
                        )
                    })}
                </>
            )
        },
    ]

    useEffect(() => {
        requests()
    }, [])

    async function requests() {
        try {
            await requestData()
            setFilters({})
        } catch (error) {
            console.tron.log(error)
        }
    }

    const requestData = (params = {}) => 
        (request(
            api.listEmployee({
                ...params,
                include: 'storeBranch'
            })
        ).then(data => setData(data)))

    function filtersList() {
        return (
            <Row type="flex" justify="start">
            {/* cli-filters */}
            </Row>
        )
    }

    const onChangeCreateButton = () => {
        props.history.push('/employee/create')
    }

    function onClickEdit(employee) {
        props.history.push(`/employee/${employee.key}`)
    }

    function onClickView(employee) {
        props.history.push(`/employee/${employee.key}?readOnly=true`)
    }

    async function onClickDelete(employee) {
        setModalDelete({
            ...modalDelete,
            title: getMessage('deleteEmployeeTitle', employee.name),
            visible: true,
            onOk: async () => onConfirmDelete(employee)
        })
    }

    async function onConfirmDelete(employee) {
        alert.open(getMessage('successEmployeeDelete'), 'success')
        await request(api.deleteEmployee(employee.key))
        setModalDelete({
            ...modalDelete,
            visible: false
        })
        await requestData(values)
    }

    function onClickDuplicate(employee) {
        props.history.push(`/employee/create?duplicate=${employee.key}`)
    }

    async function requestPage(page) {
        await requestData({ page: page - 1 })
    }

    function handleFilter(value) {
        let filters = {}
        if (value && value.trim() !== '') {
            filters.name = value
        }
        requestData({
            ...filters
        })
    }

    const onSearch = value => handleFilter(value)

    return (
        <ListScreen
            context={formik}
            columns={columns}
            data={employee}
            title={getMessage('employee')}
            titleButtonAdd={getMessage('newEmployee')}
            dataFilters={filters}
            filtersList={filtersList}
            onChangeCreateButton={onChangeCreateButton}
            onChange={requestPage}
            pagination={{
                currentPage: page + 1,
                pages: pages,
                total: count,
                pageSize: pageSize
            }}
            onFilter={handleFilter}
            onSearch={onSearch}
            onClickDelete={onClickDelete}
            modalDelete={modalDelete}
            setModalDelete={setModalDelete}
            onClickEdit={onClickEdit}
            onClickView={onClickView}
            onClickDuplicate={onClickDuplicate}
        />
    );
}

export default ListEmployee;