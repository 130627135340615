const formatedPriority = [
  {
    value: 'NORMAL',
    description: 'Normal'
  },
  {
    value: 'URGENT',
    description: 'Urgente'
  },
  {
    value: 'CRITICAL',
    description: 'Crítico'
  }
]

const formatedStatus = [
  {
    value: true,
    description: 'Resolvido'
  },
  {
    value: false,
    description: 'Não resolvido'
  }
]

export { formatedPriority, formatedStatus }
