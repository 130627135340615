import React from 'react'
import './styles.css'

import { Form, Modal, Button, Row, Col, Table } from 'antd'
import _ from 'lodash'
import { getMessage } from '../../util/IntlMessages'
import CustomInput from '../CustomInput/customInput'
import CustomSelect from '../CustomSelect/customSelect'
import * as yup from 'yup'
import { useFormik } from 'formik'

function ModalStock(props) {
  const { visible, handleReset, context, filters, variationId } = props
  const { values, setFieldValue } = context
  const formLayout = 'vertical'
  const dataSource = values[`variation${variationId}`] ? values[`variation${variationId}`].stock : []
  const columns = [
    {
      title: getMessage('branch'),
      dataIndex: 'branch',
      key: 'branch'
    },
    {
      title: getMessage('quantity'),
      dataIndex: 'quantity',
      key: 'quantity'
    }
  ]
  const formik = useFormik({
    initialValues: {},
    validationSchema: yup.object().shape({
      branch: yup.string().required(getMessage('isRequired', getMessage('branch'))),
      stock: yup.number().required(getMessage('isRequired', getMessage('stock')))
    }),
    onSubmit: () => {
      addStock()
    }
  })

  function addStock() {
    const formatedResult = [...values[`variation${variationId}`].stock]
    const { branch, stock } = formik.values
    const index = formatedResult.findIndex(item => {
      return item.storeBranchId === branch
    })
    if (index === -1) {
      formatedResult.push({
        storeBranchId: branch,
        quantity: Number(stock),
        branch: filters.formatedStoreBranch.find(item => String(item.id) === String(branch)).name,
        stock,
        key: String(branch)
      })
    } else {
      formatedResult[index].quantity = Number(stock)
    }
    const updatedData = {
      ...values[`variation${variationId}`],
      stock: [...formatedResult]
    }
    setFieldValue(`variation${variationId}`, updatedData)
    console.tron.log('updatedData', updatedData)
    formik.resetForm()
  }

  return (
    <>
      <Modal visible={visible} closable={true} onCancel={handleReset} footer={null}>
        <Form layout={formLayout} initialValues={{ layout: formLayout }}>
          <Row type="flex">
            <Col span={24}>
              <h4>{getMessage('operationStock')}</h4>
            </Col>
            <CustomSelect
              values={_.get(filters, 'formatedStoreBranch', [])}
              context={formik}
              field="branch"
              rowSpan={12}
            />
            <CustomInput showSearch context={formik} field="stock" rowSpan={12} />
            <Col span={24}>
              <Button className="mb-2" type="primary" onClick={() => formik.submitForm()}>
                {getMessage('update')}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <h4>{getMessage('stockByBranch')}</h4>
              <Table
                rowKey="storeBranchId"
                locale={{ emptyText: getMessage('noRecords') }}
                columns={columns}
                dataSource={dataSource}
                pagination={false}
              ></Table>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default ModalStock
