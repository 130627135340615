import React, { useState, useEffect, useRef } from 'react'
import { If, For } from 'react-extras'
import '@ant-design/compatible/assets/index.css'
import { useFormik } from 'formik'
import './createEmployee.css'
import * as yup from 'yup'
import { api, request } from '../../util/handlerRequestUtil'
import { FormikProvider } from 'formik'
import queryString from 'query-string'

import { Button, Row, Col, Card, Form, Divider, Radio, Avatar, Checkbox, Table, Upload, Input } from 'antd'
import { UserOutlined } from '@ant-design/icons'

import CustomInput from '../../components/CustomInput/customInput'
import CustomSelect from '../../components/CustomSelect/customSelect'
import CustomRadioGroup from '../../components/CustomRadioGroup/customRadioGroup'

function CreateEmployee(props) {
  const { getMessage, alert } = props
  const { id } = props.match.params
  const formik = useFormik({
    initialValues: {},
    validationSchema: yup.object().shape({
      name: yup.string().required(getMessage('isRequired', getMessage('name'))),
      username: yup.string().required(getMessage('isRequired', getMessage('username'))),
      password: yup.string().required(getMessage('isRequired', getMessage('password'))),
      confirmPassword: yup
        .string()
        .required(getMessage('isRequired', getMessage('confirmPassword')))
        .oneOf([yup.ref('password'), null], getMessage('confirmPassword')),
      email: yup
        .string()
        .email(getMessage('isInvalidEmail'))
        .nullable(),
      isFixedCommission: yup.boolean(),
      limitDiscount: yup
        .number()
        .typeError(getMessage('shouldBeNumber'))
        .min(0, getMessage('range', getMessage('limitDiscount'), 0, 100))
        .max(100, getMessage('range', getMessage('limitDiscount'), 0, 100))
        .nullable(),
      storeBranches: yup.array().of(
        yup
          .object()
          .shape({})
          .nullable()
      ),
      platforms: yup.array(),
      permissions: yup.array(),
      commissionId: yup.number().nullable(),
      fixedCommission: yup
        .number()
        .typeError(getMessage('shouldBeNumber'))
        .nullable(),
      dynamicCommission: yup
        .array()
        .of(
          yup.object().shape({
            key: yup.number(),
            discount: yup
              .number()
              .min(0, getMessage('range', getMessage('limitDiscount'), 0, 100))
              .max(100, getMessage('range', getMessage('limitDiscount'), 0, 100))
              .typeError(getMessage('shouldBeNumber')),
            commission: yup
              .number()
              .min(0, getMessage('range', getMessage('limitDiscount'), 0, 100))
              .max(100, getMessage('range', getMessage('limitDiscount'), 0, 100))
              .typeError(getMessage('shouldBeNumber'))
          })
        )
        .nullable()
    }),
    onSubmit: values => {
      let formatedValues = { ...values }
      onSubmit(formatedValues)
    }
  })
  const [filters, setFilters] = useState({
    formatedStoreBranch: [],
    formatedPermissions: []
  })
  const [tableData, setTableData] = useState([])
  const [tableCounter, setTableCounter] = useState(0)
  const [editingKey, setEditingKey] = useState('')
  const isReadOnly = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true
  }).readOnly
  const isDuplicate = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true
  }).duplicate
  const isEditing = record => record.key === editingKey
  const inputCommission = useRef(null)
  const inputDiscount = useRef(null)
  const [errorMessages, setErrorMessages] = useState({ commission: '', discount: '' })

  const columns = [
    {
      title: 'Desconto até %',
      dataIndex: 'discount',
      key: 'discount',
      editable: true
    },
    {
      title: '% Comissão',
      dataIndex: 'commission',
      key: 'commission',
      editable: true
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (text, record) =>
        record.key === editingKey ? (
          <Button type="primary" disabled={isReadOnly} className="btn_table" onClick={() => handleRowSave(record)}>
            {getMessage('register')}
          </Button>
        ) : (
          <Button type="primary" disabled={isReadOnly} onClick={() => handleRowDelete(record.key)}>
            {getMessage('remove')}
          </Button>
        )
    }
  ]

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: record => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    }
  })

  const renderEditableCell = ({ editing, dataIndex, title, record, index, children, ...restProps }) => {
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            className="table_input"
            validateStatus={errorMessages[dataIndex] ? 'error' : 'validating'}
            help={errorMessages[dataIndex]}
          >
            <Input
              disabled={isReadOnly}
              name={dataIndex}
              type="number"
              onChange={elem => handleInputChange(dataIndex, elem)}
              ref={dataIndex === 'commission' ? inputCommission : inputDiscount}
            />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    )
  }

  const tableComponents = {
    body: {
      cell: renderEditableCell
    }
  }

  useEffect(() => {
    requests()
  }, [])

  async function retrieve(id, isDuplicate) {
    try {
      const response = await request(api.retrieveEmployee(id), true)

      if (isDuplicate) {
        delete response.id
      }

      await formik.setValues(
        {
          ...response,
          password: '',
          platforms: response.platform.map(plat => plat.id),
          storeBranches: response.storeBranch.map(store => store.storeId),
          permissions: response.permission.map(perm => perm.id)
        },
        false
      )

      setTableData(response.dynamicCommission)

      return response
    } catch (error) {}
  }

  async function requests() {
    try {
      let response

      if (id) {
        response = await retrieve(id)
      }
      if (isDuplicate) {
        response = await retrieve(isDuplicate, true)
      }
      const platform = await requestPlatform()
      const storeBranch = await formatedStoreBranch()
      const commission = await requestCommission()
      const permission = await formatedPermissions()

      if (response) {
        const ids = response.permission.map(perm => perm.id)

        permission.formatedPermissions.forEach(mod => {
          let activateCounter = 0
          mod.submodules.forEach(sub => {
            const isEqual = ids.find(id => sub.id === id)

            if (isEqual) {
              sub.active = true
              activateCounter++
            }
          })

          if (activateCounter === mod.submodules.length) {
            mod.active = true
          }
        })
      }

      setFilters({
        ...storeBranch,
        ...permission,
        ...commission,
        ...platform
      })
    } catch (error) {
      console.tron.log(error)
    }
  }

  const requestPlatform = () =>
    request(api.listPlatform()).then(({ items }) => {
      return {
        formatedRegisteredPlatforms: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name
          }
        })
      }
    })

  const formatedStoreBranch = () =>
    request(api.listStoreBranch()).then(({ items }) => {
      return {
        formatedStoreBranch: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name
          }
        })
      }
    })

  const parsePermissions = permissions => {
    const formatedPermissions = []

    permissions.forEach(item => {
      const existModule = formatedPermissions.findIndex(module => module.module === item.module)
      if (existModule !== -1) {
        formatedPermissions[existModule].submodules.push({
          id: item.id,
          module: item.name
        })
      } else {
        formatedPermissions.push({
          module: item.module,
          submodules: [
            {
              id: item.id,
              module: item.name
            }
          ]
        })
      }
    })

    return formatedPermissions
  }

  const formatedPermissions = () =>
    request(api.listPermission()).then(({ items }) => {
      const permissions = items.map(item => {
        return {
          ...item,
          value: item.id,
          description: item.name
        }
      })

      return { formatedPermissions: parsePermissions(permissions) }
    })

  const requestCommission = () =>
    request(api.listCommission()).then(({ items }) => {
      return {
        requestCommission: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name
          }
        })
      }
    })

  function fields() {
    return (
      <>
        <Row type="flex" justify="start">
          <CustomInput disabled={isReadOnly} field="name" rowSpan={12} context={formik} />
          <CustomInput disabled={isReadOnly} field="username" rowSpan={12} context={formik} />
          <CustomInput disabled={isReadOnly} field="password" type="password" rowSpan={12} context={formik} />
          <CustomInput disabled={isReadOnly} field="confirmPassword" type="password" rowSpan={12} context={formik} />
          <CustomInput disabled={isReadOnly} field="email" rowSpan={24} context={formik} />
          <CustomSelect
            disabled={isReadOnly}
            field="storeBranches"
            values={filters.formatedStoreBranch}
            rowSpan={12}
            context={formik}
            multiple
          />
          <CustomSelect
            disabled={isReadOnly}
            field="platforms"
            rowSpan={12}
            values={filters.formatedRegisteredPlatforms}
            context={formik}
            multiple
          />
        </Row>
      </>
    )
  }

  async function onSubmit(data) {
    const {
      name,
      password,
      username,
      email,
      commissionId,
      storeBranches,
      fixedCommission,
      platforms,
      permissions,
      isFixedCommission,
      dynamicCommission,
      limitDiscount
    } = data
    const employee = {
      name,
      password,
      username,
      email,
      commissionId,
      storeBranches,
      fixedCommission: Number(fixedCommission) || undefined,
      isFixedCommission,
      platforms,
      permissions,
      dynamicCommission: dynamicCommission
        ? dynamicCommission.map(obj => ({
            commission: obj.commission,
            discount: obj.discount
          }))
        : [],
      limitDiscount: Number(limitDiscount) || undefined,
      enable: true
    }
    try {
      if (id) {
        await request(api.updateEmployee(id, employee))
        alert.open(getMessage('successUpdateEmployee'), 'success')
      } else {
        await request(api.storeEmployee(employee))
        alert.open(getMessage('successStoreEmployee'), 'success')
      }

      props.history.push('/employee/list')
    } catch (error) {
      console.tron.log('error', error)
    }
  }

  function handleInputChange(dataIndex, elem) {
    if (dataIndex === 'commission') {
      inputCommission.current.focus()
    }
    if (dataIndex === 'discount') {
      inputDiscount.current.focus()
    }
  }

  function handleRowDelete(key) {
    const newState = [...formik.values.dynamicCommission].filter(item => item.key !== key)
    const newTable = tableData.filter(item => item.key !== key)

    formik.setFieldValue('dynamicCommission', newState)
    setTableData(newTable)
  }

  function handleRowAdd() {
    const counter = tableCounter + 1

    const newItem = {
      key: counter,
      discount: 0,
      commission: 0
    }

    setTableData(tableData.concat([newItem]))
    setTableCounter(counter)
    setEditingKey(counter)
  }

  async function handleRowSave() {
    const currentTable = [...tableData]
    const currentIndex = tableData.length - 1

    const current = {
      key: tableCounter,
      commission: Number(inputCommission.current.state.value),
      discount: Number(inputDiscount.current.state.value)
    }

    if (!formik.values.dynamicCommission) {
      await formik.setFieldValue('dynamicCommission', [current])
    } else {
      const currentState = [...formik.values.dynamicCommission]
      currentState[currentIndex] = current

      await formik.setFieldValue('dynamicCommission', currentState)
    }

    try {
      const row = await formik.validateForm()

      if (!row.dynamicCommission) {
        currentTable[currentIndex] = current

        setTableData(currentTable)
        setErrorMessages({ commission: '', discount: '' })
        setEditingKey(0)
      } else {
        setErrorMessages({
          commission: row.dynamicCommission[currentIndex].commission,
          discount: row.dynamicCommission[currentIndex].discount
        })
      }
    } catch (errInfo) {
      console.tron.log('Validate Failed: ', errInfo)
    }
  }

  function onChangeBackEmployeeList() {
    props.history.push('/employee/list')
  }

  function onChangeModule(module, index, submodule, subIndex) {
    const permissions = [].concat(formik.values.permissions || [])
    const formatPermissions = [].concat(filters.formatedPermissions)
    const changedModule = formatPermissions[index]

    if (submodule) {
      if (submodule.active) {
        changedModule.submodules[subIndex].active = false

        formik.setFieldValue(
          'permissions',
          permissions.filter(id => submodule.id !== id)
        )
      } else {
        changedModule.submodules[subIndex].active = true

        formik.setFieldValue('permissions', [...permissions, submodule.id])
      }

      const isAllChecked = changedModule.submodules.every(obj => obj.active === true)

      if (isAllChecked) {
        changedModule.active = true
      } else {
        changedModule.active = false
      }
    } else {
      const ids = changedModule.submodules.map(sub => sub.id)

      if (module.active) {
        changedModule.active = false
        changedModule.submodules.forEach(sub => (sub.active = false))

        formik.setFieldValue(
          'permissions',
          permissions.filter(id => ids.every(moduleId => moduleId !== id))
        )
      } else {
        changedModule.active = true
        changedModule.submodules.forEach(sub => (sub.active = true))

        formik.setFieldValue('permissions', [...permissions, ...ids])
      }
    }

    setFilters({ ...filters, formatedPermissions: formatPermissions })
  }

  return (
    <div className="container">
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="header">
            <h1>
              {isReadOnly
                ? getMessage('viewEmployee')
                : id
                ? getMessage('updateEmployee')
                : getMessage('createEmployee')}
            </h1>
            <div className="btn_group">
              <Button type="ghost" onClick={() => onChangeBackEmployeeList()}>
                {getMessage('back')}
              </Button>
              <If condition={!isReadOnly}>
                <Button type="primary" onClick={() => formik.submitForm()}>
                  {id ? getMessage('edit') : getMessage('storeEmployee')}
                </Button>
              </If>
            </div>
          </div>
          <Card>
            <span>Dados básicos</span>
            <Divider></Divider>
            <FormikProvider value={formik}>
              <Row>
                <Col lg={6} xl={6} className="avatar">
                  <Upload>
                    <Avatar size={128} icon={<UserOutlined />}></Avatar>
                  </Upload>
                </Col>
                <Col lg={18} xl={18}>
                  <Form layout={'vertical'}>{fields}</Form>
                </Col>
              </Row>

              <span>Comissionamento</span>
              <Divider></Divider>
              <Form layout={'vertical'}>
                <Row>
                  <Col lg={12} xl={12}>
                    <CustomSelect
                      disabled={isReadOnly}
                      field="commissionId"
                      values={filters.requestCommission}
                      value={formik.commissionId}
                      rowSpan={12}
                      context={formik}
                    ></CustomSelect>
                    <CustomRadioGroup context={formik} field="isFixedCommission" span={6}>
                      <Row>
                        <Radio value={true} disabled={isReadOnly}>
                          Alíquota fixa
                        </Radio>
                        <Radio value={false} disabled={isReadOnly}>
                          Alíquota conforme desconto por item
                        </Radio>
                      </Row>
                    </CustomRadioGroup>
                  </Col>
                  <If condition={formik.values.isFixedCommission === false}>
                    <Col lg={12}>
                      <Table
                        size="small"
                        components={tableComponents}
                        dataSource={tableData}
                        columns={mergedColumns}
                        scroll={{ y: 300 }}
                      ></Table>
                      <Col push={18}>
                        <Button type="link" disabled={editingKey || isReadOnly} onClick={() => handleRowAdd()}>
                          Adicionar outro
                        </Button>
                      </Col>
                    </Col>
                  </If>
                  <If condition={formik.values.isFixedCommission === true}>
                    <CustomInput disabled={isReadOnly} field="fixedCommission" rowSpan={12} context={formik} />
                  </If>
                </Row>

                <span>Desconto</span>
                <Divider></Divider>

                <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                  <CustomInput
                    disabled={isReadOnly}
                    context={formik}
                    field="limitDiscount"
                    placeholder="10%"
                  ></CustomInput>
                </Col>
              </Form>
            </FormikProvider>

            <span>Módulos</span>
            <Divider></Divider>
            <For
              of={filters.formatedPermissions}
              render={(module, index) => (
                <React.Fragment key={index}>
                  <Button
                    type="ghost"
                    disabled={isReadOnly}
                    onClick={() => onChangeModule(module, index)}
                    className="btn_module"
                    block
                  >
                    <Row className="module">
                      <Checkbox disabled={isReadOnly} checked={module.active} className="check_module"></Checkbox>
                      <span>{module.module}</span>
                    </Row>
                  </Button>

                  <For
                    of={module.submodules || []}
                    render={(submodule, subIndex) => (
                      <Button
                        key={subIndex}
                        type="ghost"
                        disabled={isReadOnly}
                        onClick={() => onChangeModule(module, index, submodule, subIndex)}
                        className="sub btn_module"
                        block
                      >
                        <Row className="module">
                          <Checkbox
                            disabled={isReadOnly}
                            checked={submodule.active}
                            className="check_module"
                          ></Checkbox>
                          <span>{submodule.module}</span>
                        </Row>
                      </Button>
                    )}
                  />
                </React.Fragment>
              )}
            />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default CreateEmployee
