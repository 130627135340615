import appLocaleData from 'react-intl/locale-data/pt'

import antdEn from 'antd/lib/locale-provider/en_US'

import enMessages from '../locales/pt_BR.json'
import datePickerTranstale from '../locales/datepickerTranslate.json'

const ptBr = {
  messages: {
    ...enMessages
  },
  antd: antdEn,
  locale: 'pt-BR',
  data: appLocaleData,
  datePickerTranstale: {
    ...datePickerTranstale
  }
}
export default ptBr
