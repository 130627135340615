import React, { useState, useEffect } from 'react'
import { Row } from 'antd'
import { useFormik } from 'formik'

import CreateScreen from '../../components/CreateScreen'
import CustomSelect from '../../components/CustomSelect/customSelect'
import CustomInput from '../../components/CustomInput/customInput'
import Address from './stepAddress'
import queryString from 'query-string'
import { api, request } from '../../util/handlerRequestUtil'
import './createStoreBranch.css'
import * as yup from 'yup'

function CreateStoreBranch(props) {
  const { id } = props.match.params
  const { alert, loading, getMessage } = props
  const isDuplicate = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true
  }).duplicate
  const formik = useFormik({
    initialValues: {},
    validationSchema: yup.object().shape({
      // cli-yup-declaration
      store: yup
        .number()
        .typeError()
        .required(getMessage('isRequired', getMessage('store'))),

      name: yup
        .string()
        .typeError()
        .required(getMessage('isRequired', getMessage('name'))),

      address: yup.object()
    }),
    onSubmit: values => {
      let formatedValues = { ...values }

      if (values.store) {
        formatedValues.storeId = Number(values.store)

        delete formatedValues.store
      }

      if (isSubmitting && values.address) {
        onSubmit(formatedValues)
      }

      setIsSubmitting(true)
    }
  })
  const { values, setValues, setFieldValue, submitForm, isValidating } = formik
  const [filters, setFilters] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    requests()
  }, [])

  //requests
  async function requests() {
    try {
      loading.open()

      const formatedStore = await requestStore()

      // cli-request-function
      if (id) {
        await retrieve(id)
      }
      if (isDuplicate) {
        await retrieve(isDuplicate, true)
      }
      setFilters({
        ...formatedStore

        // cli-request-filter
      })
    } catch (error) {
      console.tron.log(error)
    } finally {
      loading.close()
    }
  }

  const requestStore = () =>
    request(
      api.listStore({
        ignorePagination: true
      }),
      true
    ).then(({ items }) => {
      return {
        formatedStore: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name
          }
        })
      }
    })

  // cli-requests

  function fields(isReadOnly) {
    return (
      <Row type="flex" justify="start">
        <CustomInput disabled={isReadOnly} field="name" rowSpan={12} context={formik} required />

        <CustomSelect
          context={formik}
          showSearch
          field="store"
          rowSpan={12}
          disabled={isReadOnly}
          values={filters.formatedStore}
          required
        />

        <Address
          id={id}
          isDuplicate={isDuplicate}
          isReadOnly={isReadOnly}
          dataToPost={values}
          setAddress={setFieldValue}
          submitting={isSubmitting}
          submit={submitForm}
          validating={isValidating}
          options={filters}
        />

        {/* cli-fields */}
      </Row>
    )
  }

  function onClickBack() {
    props.history.push('/storebranch/list')
  }

  async function onSubmit(values) {
    try {
      if (id) {
        await request(api.updateStoreBranch(id, values))
        alert.open(getMessage('successUpdateStoreBranch'), 'success')
      } else {
        await request(api.storeStoreBranch(values))
        alert.open(getMessage('successStoreStoreBranch'), 'success')
      }

      props.history.push(`/StoreBranch/list`)
    } catch (error) {}
  }

  async function retrieve(id, isDuplicate) {
    try {
      const response = await request(api.retrieveStoreBranch(id), true)
      if (isDuplicate) {
        delete response.id
        delete response.name
      }
      setValues({
        ...response,
        store: response.storeId
        // cli-retrieve-state
      })
    } catch (error) {}
  }

  return (
    <CreateScreen
      fields={fields}
      context={formik}
      editTitle={getMessage('updateStoreBranch')}
      editTitleButton={getMessage('edit')}
      newTitle={getMessage('newStoreBranch')}
      newTitleButton={getMessage('register')}
      readOnlyTitle={getMessage('viewStoreBranch')}
      readOnlyTitleButton={getMessage('edit')}
      onClickBack={() => onClickBack()}
      isEdit={id}
    />
  )
}

export default CreateStoreBranch
