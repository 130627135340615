import React from 'react'
import { Drawer as DrawerAntd, Radio, Form } from 'antd'
import { useWindowDimensions } from '../../../hooks'
import { status } from '../utils/constants'
import { getMessage } from '../../../util/IntlMessages'

import styles from './Drawer.module.css'

const Drawer = props => {
  const { children, visible, className, onClose, formik } = props

  const { width } = useWindowDimensions()

  const isMobile = width < 900

  function renderOptions() {
    return [
      { key: 'all', label: getMessage(`status.all`), value: 'all' },
      ...Object.values(status).map(value => ({
        key: value,
        label: getMessage(`status.${value}`),
        value
      }))
    ]
  }

  return (
    <Form layout="vertical">
      <DrawerAntd
        title={<span className={`${styles.title}`}>{getMessage('synchronization')}</span>}
        className={className}
        placement={isMobile ? 'left' : 'right'}
        closable={isMobile}
        onClose={onClose}
        visible={visible}
        getContainer={false}
      >
        <Radio.Group
          className={styles.radio}
          options={renderOptions()}
          onChange={({ target }) => formik.setFieldValue('status', target.value)}
          value={formik.values['status']}
          optionType="button"
          buttonStyle="solid"
        />
        {children}
      </DrawerAntd>
    </Form>
  )
}

export default Drawer
