import React, { useState, useEffect } from 'react'
import { Row, Tabs, Typography } from 'antd'
import { useFormik } from 'formik'
import CreateScreen from '../../../components/CreateScreen'
import queryString from 'query-string'
import { api, request } from '../../../util/handlerRequestUtil'
import { formatToSelect, onlyNumbers } from '../../../util/format'
import * as yup from 'yup'
import moment from 'moment-timezone'
import './createPerson.css'
import { get } from 'lodash'

import PersonalData from './stepPersonalData'
import Contacts from './stepContacts'
import Address from './stepAddress'
import Recipes from './stepRecipes'
const { Text } = Typography
const { TabPane } = Tabs

function CreatePerson(props) {
  const { id } = props.match.params
  const { alert, loading, getMessage } = props

  const [selectedTab, setSelectedTab] = useState('1')
  const [filters, setFilters] = useState({
    taxPayer: [],
    taxRegime: [],
    origin: [],
    type: [
      { value: 'CUSTOMER', description: getMessage('customer') },
      { value: 'PROVIDER', description: getMessage('provider') }
    ],
    kindPerson: [
      { value: 'PHYSICAL', description: getMessage('physical') },
      { value: 'LEGAL', description: getMessage('legal') },
      { value: 'FOREIGNER', description: getMessage('foreigner') }
    ],
    contactTypes: [
      {
        value: 'PHONE',
        description: getMessage('phone')
      },
      {
        value: 'EMAIL',
        description: getMessage('email')
      }
    ]
  })
  const isDuplicate = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true
  }).duplicate

  const [validationForm, setValidationForm] = useState({
    name: yup
      .string()
      .typeError()
      .required(getMessage('isRequired', getMessage('name'))),
    email: yup
      .string()
      .typeError()
      .required(getMessage('isRequired', getMessage('email'))),
    origin: yup
      .string()
      .typeError()
      .required(getMessage('isRequired', getMessage('origin'))),
    type: yup
      .string()
      .typeError()
      .required(getMessage('isRequired', getMessage('type'))),
    kindPerson: yup
      .string()
      .typeError()
      .required(getMessage('isRequired', getMessage('kindPerson')))
  })
  const formik = useFormik({
    initialValues: {
      contacts: []
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object().shape(validationForm),
    onSubmit: values => {
      const hasPhoneContact = values.contacts ? values.contacts.some(c => c.type === 'PHONE') : undefined

      if (!hasPhoneContact) {
        return alert.open(getMessage('mustAddPhoneContact'), 'info')
      }

      const formatedValues = getPayload(values)

      onSubmit(formatedValues)
    }
  })
  const { values, setValues, setFieldValue } = formik

  useEffect(() => {
    requests()
  }, [])

  useEffect(() => {
    if (!id && !isDuplicate && values.type) {
      setFieldValue('kindPerson', '')
    }
  }, [values.type])

  useEffect(() => {
    if (values.taxPayer === 1) {
      setValidationForm(v => ({
        ...v,
        stateRegistration: yup
          .string()
          .typeError()
          .required(getMessage('isRequired', getMessage('stateRegistration')))
      }))
    }

    if (values.taxPayer === 2) {
      setFieldValue('stateRegistration', 'INSENTO')
    }
    if (!id && !isDuplicate && values.taxPayer !== 2) {
      setFieldValue('stateRegistration', '')
    }
  }, [values.taxPayer])

  useEffect(() => {
    if (values.kindPerson === 'PHYSICAL') {
      setValidationForm(v => ({
        ...v,
        birth: yup
          .string()
          .typeError()
          .required(getMessage('isRequired', getMessage('birth'))),
        gender: yup
          .string()
          .typeError()
          .required(getMessage('isRequired', getMessage('gender')))
      }))
    } else {
      setValidationForm(v => {
        delete v.birth
        delete v.gender

        return v
      })
    }
  }, [values.kindPerson])

  //requests
  async function requests() {
    try {
      loading.open()

      await requestTaxPayer(true)
      await requestTaxRegime(true)
      await requestOrigin(true)

      if (id) {
        await retrieve(id)
      }
      if (isDuplicate) {
        await retrieve(isDuplicate, true)
      }
    } catch (error) {
      console.tron.log(error)
    } finally {
      loading.close()
    }
  }

  // cli-requests

  function fields(isReadOnly) {
    return (
      <Row type="flex" justify="start" className="row__fields__form">
        <Tabs activeKey={selectedTab} onChange={onChangeTabs}>
          <TabPane tab={getMessage('personalData')} key="1" />
          <TabPane tab={getMessage('contacts')} key="2" />
          <TabPane tab={getMessage('address')} key="3" />
          <TabPane tab={getMessage('recipes')} key="4" />
          {id && <TabPane tab={getMessage('viewOrders')} key="5" />}
          {id && <TabPane tab={getMessage('loyaltyPlan')} key="6" />}
        </Tabs>

        {selectedTab === '1' && (
          <PersonalData
            id={id}
            isDuplicate={isDuplicate}
            form={formik}
            options={filters}
            setFilters={setFilters}
            isReadOnly={isReadOnly}
          />
        )}
        {selectedTab === '2' && (
          <Contacts
            id={id}
            isDuplicate={isDuplicate}
            isReadOnly={isReadOnly}
            dataToPost={values}
            setContacts={setValues}
            options={filters}
          />
        )}
        {selectedTab === '3' && (
          <Address
            id={id}
            isDuplicate={isDuplicate}
            isReadOnly={isReadOnly}
            dataToPost={values}
            setAddresses={setValues}
            options={filters}
          />
        )}
        {selectedTab === '4' && <Recipes isReadOnly={isReadOnly} dataToPost={values} setValues={setValues} />}
        {selectedTab === '6' && (
          <div>
            {values.loyalts.map(item => {
              return (
                <div key={item.id}>
                  <h5>{item.LoyaltyPlanConfig.currencyName}</h5>
                  <Text>Pontos utilizados: {item.rescued}</Text>
                  <br />
                  <Text>Pontos: {item.points - item.rescued}</Text>
                </div>
              )
            })}
          </div>
        )}

        {/* cli-fields */}
      </Row>
    )
  }

  async function onSubmit(values) {
    try {
      if (id) {
        await request(api.updatePerson(id, values))
        alert.open(getMessage('successUpdatePerson'), 'success')
      } else {
        await request(api.storePerson(values))
        alert.open(getMessage('successStorePerson'), 'success')
      }

      props.history.push(`/person/list`)
    } catch (error) {
      console.tron.log(error)
    }
  }

  async function retrieve(id, isDuplicate) {
    try {
      const person = await request(api.retrievePerson(id), true)
      if (isDuplicate) {
        delete person.id
      }

      const doc =
        person.kindPerson === 'LEGAL' ? { cnpj: get(person, 'document', '') } : { cpf: get(person, 'document', '') }

      let contactsToShow = person.contacts.map(c => ({
        id: c.id,
        value: c.value,
        description: c.description,
        type: c.type
      }))

      let addressesToShow = person.addresses.map(c => ({
        id: c.id,
        state: get(c, 'city.stateName'),
        cityId: Number(c.cityId),
        cityName: get(c, 'city.cityName'),
        streetName: c.streetName,
        neighborhood: c.neighborhood,
        number: c.number,
        zipCode: c.zipCode.replace(/[^0-9]+/g, ''),
        ...(c.complement && { complement: c.complement }),
        ...(c.city && { city: c.city }),
        ...(c.description && { description: c.description })
      }))

      setValues({
        ...person,
        taxPayer: get(person, 'taxPayerId', ''),
        taxRegime: get(person, 'taxRegimeId', ''),
        ...(person.birth && { birth: moment(new Date(person.birth), 'DD/MM/YYYY') }),
        ...doc,
        contacts: contactsToShow,
        addresses: addressesToShow
      })
    } catch (error) {
      console.tron.log(error)
    }
  }

  async function requestTaxPayer(skip) {
    try {
      const taxPayer = await request(api.listTaxPayer(), { skipLoading: skip })

      setFilters(f => ({
        ...f,
        ...{ taxPayer: formatToSelect(taxPayer.items) }
      }))
    } catch (error) {
      console.tron.log(error)
    }
  }

  async function requestTaxRegime(skip) {
    try {
      const taxRegime = await request(api.listTaxRegime(), { skipLoading: skip })

      setFilters(f => ({
        ...f,
        ...{ taxRegime: formatToSelect(taxRegime.items) }
      }))
    } catch (error) {
      console.tron.log(error)
    }
  }

  async function requestOrigin(skip) {
    try {
      const origin = await request(api.listOrigin({ ignorePagination: true }), { skipLoading: skip })
      const format = origin.map(c => ({
        value: c.origin,
        description: c.origin
      }))

      setFilters(f => ({
        ...f,
        ...{ origin: format }
      }))
    } catch (error) {
      console.tron.log(error)
    }
  }

  const onChangeTabs = value => {
    if (+value === 5) {
      window.open(`/order/list?personId=${id}`, '_blank', 'noopener,noreferrer')
      return
    }
    setSelectedTab(value)
  }

  const getPayload = values => {
    const {
      type,
      email,
      kindPerson,
      origin,
      homeCountry,
      name,
      cpf,
      rg,
      issuingBody,
      birth,
      gender,
      cnpj,
      instagram,
      facebook,
      linkedin,
      taxPayer,
      taxRegime,
      countyRegistration,
      stateRegistration,
      notes,
      contacts = [],
      addresses = [],
      recipes = [],
      indicationPerson
    } = values

    return {
      ...(type && { type }),
      ...(email && { email }),
      ...(name && { name }),
      ...(kindPerson && { kindPerson }),
      ...(cnpj && { document: onlyNumbers(cnpj) }),
      ...(cpf && { document: onlyNumbers(cpf) }),
      ...(gender && { gender }),
      ...(notes && { notes }),
      ...(birth && { birth: moment(birth).toISOString() }),
      ...(instagram && { instagram }),
      ...(facebook && { facebook }),
      ...(linkedin && { linkedin }),
      ...(rg && { rg }),
      ...(issuingBody && { issuingBody }),
      ...(stateRegistration && { stateRegistration }),
      ...(countyRegistration && { countyRegistration }),
      ...(origin && { origin }),
      ...(homeCountry && { homeCountry }),
      ...(taxPayer && { taxPayerId: taxPayer }),
      ...(taxRegime && { taxRegimeId: taxRegime }),
      ...(contacts && { contacts }),
      ...(addresses && { addresses }),
      ...(indicationPerson && { indicationPersonId: indicationPerson.id }),
      recipes
    }
  }

  const onClickBack = () => props.history.push('/person/list')

  return (
    <CreateScreen
      fields={fields}
      context={formik}
      onClickBack={onClickBack}
      editTitle={getMessage('updatePerson')}
      editTitleButton={getMessage('edit')}
      newTitle={getMessage('newPerson')}
      newTitleButton={getMessage('register')}
      readOnlyTitle={getMessage('viewPerson')}
      readOnlyTitleButton={getMessage('edit')}
      isEdit={id}
    />
  )
}

export default CreatePerson
