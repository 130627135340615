import React, { useState, useEffect } from 'react'
import { Row } from 'antd'
import { useFormik } from 'formik'
import _ from 'lodash'
import ListScreen from '../../components/ListScreen'
import { api, request } from '../../util/handlerRequestUtil'
import './listAttributeItem.css'
import queryString from 'query-string'

function ListAttributeItem(props) {
  const { alert, getMessage } = props
  const formik = useFormik({
    initialValues: {}
  })
  const [modalDelete, setModalDelete] = useState({
    description: getMessage('defaultDeleteDescription'),
    visible: false
  })
  const queryParams = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true
  })
  const attributeId = queryParams.attributeId ? Number(queryParams.attributeId) : undefined
  const { values } = formik
  const [data, setData] = useState({})
  const [filters, setFilters] = useState({})
  const { page = 1, pageSize = 10, count = 0, pages = 0 } = data
  const attributeitem = _.get(data, 'items', []).map(item => {
    return {
      key: item.id,
      ...item,
      attributeId: _.get(item, 'attribute.name')
      // cli-formatter-table
    }
  })
  const columns = [
    {
      title: getMessage('name'),
      dataIndex: 'name',
      key: 'name'
    }
    // cli-columns
  ]

  useEffect(() => {
    requests()
  }, [])

  //requests
  async function requests() {
    try {
      // cli-request-function
      await requestData()

      setFilters({
        // cli-request-filter
      })
    } catch (error) {
      console.tron.log(error)
    }
  }

  function onClickEdit(attributeitem) {
    props.history.push(`/attributeitem/${attributeitem.key}?attributeId=${attributeId}`)
  }

  async function onClickDelete(attributeitem) {
    setModalDelete({
      ...modalDelete,
      title: getMessage('deleteAttributeItemTitle', attributeitem.name),
      visible: true,
      onOk: async () => onConfirmDelete(attributeitem)
    })
  }

  async function onConfirmDelete(attributeitem) {
    alert.open(getMessage('successAttributeItemDelete'), 'success')
    await request(api.deleteAttributeItem(attributeitem.key))
    setModalDelete({
      ...modalDelete,
      visible: false
    })
    await requestData(values)
  }

  function onClickView(attributeitem) {
    props.history.push(`/attributeitem/${attributeitem.key}?attributeId=${attributeId}&readOnly=true`)
  }

  function onClickDuplicate(attributeitem) {
    props.history.push(`/attributeitem/create?attributeId=${attributeId}&duplicate=${attributeitem.key}`)
  }

  const requestData = (params = {}) =>
    request(
      api.listAttributeItem({
        attributeId,
        ...params,
        include: `
    // cli-include
attribute,
    `
      })
    ).then(data => setData(data))

  // cli-requests

  //functions
  function handleFilter(value) {
    let filters = {}
    //const {} = values
    // cli-handle-filters
    if (value && value.trim() !== '') {
      filters.name = value
    }
    requestData({
      ...filters
    })
  }

  const onSearch = value => handleFilter(value)

  async function requestPage(page) {
    await requestData({ page: page - 1 })
  }

  const onChangeCreateButton = () => {
    props.history.push(`/attributeitem/create?attributeId=${attributeId}`)
  }

  function filtersList() {
    return (
      <Row type="flex" justify="start">
        {/* cli-filters */}
      </Row>
    )
  }
  return (
    <ListScreen
      context={formik}
      columns={columns}
      data={attributeitem}
      title={getMessage('attributeitem')}
      titleButtonAdd={getMessage('newAttributeItem')}
      onSearch={onSearch}
      filtersList={filtersList}
      dataFilters={filters}
      onChange={requestPage}
      onChangeCreateButton={onChangeCreateButton}
      pagination={{
        currentPage: page + 1,
        pages: pages,
        total: count,
        pageSize: pageSize
      }}
      onFilter={handleFilter}
      onClickDelete={onClickDelete}
      modalDelete={modalDelete}
      setModalDelete={setModalDelete}
      onClickEdit={onClickEdit}
      onClickView={onClickView}
      onClickDuplicate={onClickDuplicate}
    />
  )
}

export default ListAttributeItem
