import React, { Component } from 'react'

import { Upload, Row, Col, Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as icons from '@fortawesome/free-solid-svg-icons'
import { request, api } from '../../util/handlerRequestUtil'
import { getMessage } from '../../util/IntlMessages'
import './uploadPictures.css'
import loadingImage from '../../assets/images/loadingImage.gif'
import _ from 'lodash'

export default class UploadComponent extends Component {
  state = {
    //fileList: [],
    requestLoading: false
  }
  isLoading = false
  onRemove = item => {
    this.props.context.setFieldValue(
      'files',
      this.props.context.values.files.filter(files => files.file.uid !== item.file.uid)
    )
  }

  setMainly = fileToSend => {
    console.log('fileToSend', fileToSend)
    console.log('this.props.context.values.files', this.props.context.values.files)
    const indexToUpdate = this.props.context.values.files.findIndex(item => item.file.uid === fileToSend.file.uid)
    let newArray = [...this.props.context.values.files].map(item => ({
      ...item,
      mainly: false
    }))
    newArray[indexToUpdate] = {
      ...newArray[indexToUpdate],
      mainly: true
    }
    console.log('newArray', newArray)
    this.props.context.setFieldValue('files', newArray)
  }

  uploadFile = async (fileToSend, resend = false) => {
    console.log('uploadFile', fileToSend)
    if (resend) {
      const indexToUpdate = this.props.context.values.files.findIndex(item => item.file.uid === fileToSend.uid)
      let newArray = [...this.props.context.values.files]
      newArray[indexToUpdate] = {
        ...newArray[indexToUpdate],
        status: 'loading'
      }
      console.log('data beforeSend', newArray)
      this.props.context.setFieldValue('files', newArray)
    }
    try {
      const response = await request(api.upload(fileToSend), {
        skipLoading: true,
        skipHandler: true
      })
      const indexToUpdate = this.props.context.values.files.findIndex(item => item.file.uid === fileToSend.uid)
      let newArray = [...this.props.context.values.files]
      newArray[indexToUpdate] = {
        ...newArray[indexToUpdate],
        ...response,
        status: 'success'
      }
      console.log('newArray', newArray)
      this.props.context.setFieldValue('files', newArray)
    } catch (error) {
      const indexToUpdate = this.props.context.values.files.findIndex(item => item.file.uid === fileToSend.uid)
      let newArray = [...this.props.context.values.files]
      newArray[indexToUpdate] = {
        ...newArray[indexToUpdate],
        status: 'error'
      }
      this.props.context.setFieldValue('files', newArray)
    }
  }

  uploadFiles = async listFiles => {
    if (!this.isLoading) {
      this.isLoading = true
      this.setState({
        requestLoading: true
      })
      const formatedFiles = listFiles.map(file => ({
        file: file,
        status: 'loading'
      }))
      console.log('formatedFiles', formatedFiles)
      this.props.context.setFieldValue('files', [...this.props.context.values.files, ...formatedFiles])
      for (let i = 0; i < listFiles.length; i++) {
        await this.uploadFile(listFiles[i])
      }
      this.setState({
        requestLoading: false
      })
      this.isLoading = false
    }
  }
  render() {
    return (
      <div className="upload__content">
        <div className="upload__title">{getMessage('productPhoto')}</div>
        <Row>
          {_.get(this, 'props.context.values.files') &&
            this.props.context.values.files.map(item => (
              <Col xs={12} sm={12} md={4} lg={4} xl={4} className="containerUploadColumn" key={item.file.uid}>
                {item.status === 'success' && (
                  <div className="containerActions">
                    <FontAwesomeIcon
                      className={`iconHome ${item.mainly ? 'selectedImage' : ''}`}
                      icon={icons.faHome}
                      size={'2x'}
                      color="#fff"
                      onClick={() => this.setMainly(item)}
                    ></FontAwesomeIcon>
                    <FontAwesomeIcon
                      className={'iconDelete ml-1'}
                      icon={icons.faMinus}
                      size={'2x'}
                      color="#fff"
                      onClick={() => this.onRemove(item)}
                    ></FontAwesomeIcon>
                  </div>
                )}
                <div className="containerImg">
                  {item.status === 'success' && <img alt="success" className="imgUpload" src={item.location}></img>}
                  {item.status === 'loading' && (
                    <div className="loadingContainer">
                      <img alt="loading" src={loadingImage}></img>
                    </div>
                  )}
                  {item.status === 'error' && (
                    <div className="loadingContainer">
                      <FontAwesomeIcon icon={icons.faTimes} size={'3x'} color="red"></FontAwesomeIcon>
                      <Button onClick={() => this.uploadFile(item.file, true)}>{getMessage('tryAgain')}</Button>
                    </div>
                  )}
                </div>
              </Col>
            ))}
        </Row>

        <Upload
          disabled={this.state.requestLoading}
          showUploadList={false}
          multiple
          beforeUpload={(file, fileList) => this.uploadFiles(fileList)}
          customRequest={async props => {}}
        >
          <Button type="primary">Anexar</Button>
        </Upload>
        {/* <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal> */}
      </div>
    )
  }
}
