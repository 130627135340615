import React, { useRef } from 'react'

import { Col, Select, Form, Divider } from 'antd'
import get from 'lodash.get'
import { getMessage } from '../../util/IntlMessages'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './style.css'

const { Option } = Select

const CustomSelect = function({
  rowSpan,
  values = [],
  field,
  onChange,
  multiple,
  disabled,
  onChangeWithField,
  showSearch,
  onSearch,
  loading,
  suffixLabel,
  name = '',
  customFormId = '',
  placeholder,
  context,
  formikPrefix,
  ignoreTranslate = false,
  prefixLabel,
  required,
  noLabel,
  addOption,
  tags
}) {
  let renderFieldName = name ? name : field
  if (formikPrefix) {
    renderFieldName = `${formikPrefix}.${renderFieldName}`
  }
  const { errors, setFieldValue, values: value } = context
  const searchRef = useRef('')

  return (
    <Col xs={24} sm={24} md={rowSpan} lg={rowSpan} xl={rowSpan}>
      <Form.Item
        label={
          noLabel ? (
            ''
          ) : (
            <span>
              {prefixLabel ? prefixLabel.concat(' ') : ''}
              {ignoreTranslate ? field : getMessage(field)}
              {suffixLabel ? ' '.concat(suffixLabel) : ''}
              {required ? <span className="required"> *</span> : ''}
            </span>
          )
        }
        validateStatus={get(errors, renderFieldName) ? 'error' : 'validating'}
        help={get(errors, renderFieldName)}
      >
        <Select
          allowClear={true}
          showSearch={showSearch || true}
          onSearch={onSearch || (value => (searchRef.current = value))}
          disabled={disabled || false}
          mode={multiple ? 'multiple' : tags ? 'tags' : ''}
          name={renderFieldName}
          placeholder={placeholder ? placeholder : getMessage('select')}
          onChange={value => {
            setFieldValue(renderFieldName, value, customFormId)
            if (onChange) {
              onChange(value, customFormId)
            }

            if (onChangeWithField) {
              onChangeWithField(renderFieldName, value, customFormId)
            }
          }}
          optionFilterProp="children"
          value={get(value, renderFieldName)}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          loading={loading || false}
          notFoundContent={getMessage('noRecords')}
          dropdownRender={menu => {
            return (
              <>
                {menu}
                {addOption && get(menu, 'props.flattenOptions', []).length === 0 && (
                  <>
                    <Divider className="divider__select" />
                    <div onClick={() => addOption(searchRef.current)} className="add__item">
                      {getMessage('addItem')}
                      <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faPlus} />
                    </div>
                  </>
                )}
              </>
            )
          }}
        >
          {values.map(item => (
            <Option key={item.value} value={item.value}>
              {item.description}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
  )
}
export default CustomSelect
