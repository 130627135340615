import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as icons from '@fortawesome/free-solid-svg-icons'

import styles from './CustomIcon.module.css'

function CustomIcon({ name, ...props }) {
  return <FontAwesomeIcon className={styles.icon} icon={icons[name]} size="lg" {...props} />
}

export default CustomIcon
