import Reactotron from 'reactotron-react-js'
import { reactotronRedux } from 'reactotron-redux'
import sagaPlugin from 'reactotron-redux-saga'
import apisaucePlugin from 'reactotron-apisauce'

//if (process.env.NODE_ENV !== 'production') {
const tron = Reactotron.configure()
  .use(sagaPlugin())
  .use(reactotronRedux())
  .use(apisaucePlugin())
  .connect()

tron.clear()

console.tron = tron
//}
