import React, { useEffect, useRef } from 'react'
import SunEditor, { buttonList } from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css' // Import Sun Editor's CSS File
import { request, api } from '../../util/handlerRequestUtil'
import { getMessage } from '../../util/IntlMessages'

const SunTextEditor = ({ value = '', setValue, readOnly, alert }) => {
  const editorRef = useRef()

  const handleImageUploadBefore = (files, info, uploadHandler) => {
    let response = {}
    request(api.upload(files[0]), { skipLoading: true })
      .then(img => {
        response = {
          result: [
            {
              url: img.location,
              name: img.originalName || 'Imagem',
              size: img.size
            }
          ]
        }

        uploadHandler(response)
      })
      .catch(() => alert.open(getMessage('upload.info.error'), 'info'))
  }

  useEffect(() => {
    if (editorRef.current && !editorRef.current.editor) {
      return
    }
    if (value && editorRef.current.editor.getText().trim() === '') {
      const html = value
        .replace(/\([^()]*\)/gm, '') // () dentro de []
        .replace(/[([].*?[)\]]/gm, '') // tudo dentro de []
        .replace(/\\r\\n/g, '')
        .replace(/\\/g, '')

      editorRef.current.editor.setContents(html)
    }
  }, [value])

  return (
    <SunEditor
      hideToolbar={true}
      ref={editorRef}
      setAllPlugins={false}
      lang="pt_br"
      autoFocus={true}
      height="300"
      onChange={content => setValue(content)}
      setOptions={{
        buttonList: buttonList.complex
      }}
      {...(readOnly && { disable: true, enableToolbar: false })}
      onImageUploadBefore={handleImageUploadBefore}
    />
  )
}
export default SunTextEditor
