import React, { useState, useRef } from 'react'
import { Table, Input, Col, Row, DatePicker, Button, ConfigProvider } from 'antd'
import { CustomIcon } from '..'
import { responsiveCol } from '../../util/util'
import { helper } from '../../util/helper'
import { get } from 'lodash'
import NumberFormat from 'react-number-format'
import moment from 'moment-timezone'
import ptBR from 'antd/es/locale/pt_BR'

import './RecipeView.css'

const RecipeView = ({ state, setState, handleSave, isReadOnly }) => {
  let tableValues = useRef({})
  const [toggle, setToggle] = useState(false)
  const { alert, getMessage } = helper()

  function setTableFieldValue(id, value) {
    tableValues.current = { ...tableValues.current, [id]: +value }
  }

  function renderEye(text) {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
        <CustomIcon name="faEye" />
        <span>{text}</span>
      </div>
    )
  }

  const handleCellValue = React.useCallback(v => {
    return v || ' '
  }, [])

  function RenderTableInput(id, initialValue, i) {
    const [state, setState] = useState(initialValue)

    React.useEffect(() => {
      setState(initialValue)
    }, [initialValue, toggle])

    return (
      <NumberFormat
        disabled={isReadOnly}
        className={[0, 1].includes(i) ? 'line_blue' : 'line_red'}
        name={id}
        value={state}
        customInput={Input}
        onChange={({ target }) => {
          setTableFieldValue(id, target.value)
          setState(target.value)
        }}
        thousandSeparator=","
        decimalSeparator="."
        decimalScale={2}
      />
    )
  }

  const columns = [
    {
      dataIndex: 'far',
      colSpan: 0,
      align: 'center',
      render: (text, row, index) => {
        const obj = {
          children: '',
          props: {
            rowSpan: 0
          }
        }

        if (index === 0) {
          obj.children = 'Longe'
          obj.props.rowSpan = 2
        }

        if (index === 2) {
          obj.children = 'Perto'
          obj.props.rowSpan = 2
        }

        return obj
      }
    },
    {
      colSpan: 0,
      dataIndex: 'eye',
      width: '15%',
      align: 'center',
      render: (text, row, index) => {
        if (index === 0) {
          return renderEye('OD')
        }
        if (index === 1) {
          return renderEye('OE')
        }
        if (index === 2) {
          return renderEye('OD')
        }
        if (index === 3) {
          return renderEye('OE')
        }
      }
    },
    {
      title: 'Esférico',
      dataIndex: 'spherical',
      className: 'spherical_col',
      colSpan: 3,
      align: 'right',
      width: '16%',
      render: (text, row, index) => {
        switch (index) {
          case 0:
            return RenderTableInput('lOdEsferico', handleCellValue(row.lOdEsferico), index)
          case 1:
            return RenderTableInput('lOeEsferico', handleCellValue(row.lOeEsferico), index)
          case 2:
            return RenderTableInput('pOdEsferico', handleCellValue(row.pOdEsferico))
          default:
            return RenderTableInput('pOeEsferico', handleCellValue(row.pOeEsferico))
        }
      }
    },
    {
      title: 'Cilíndrico',
      dataIndex: 'cylindrical',
      align: 'center',
      width: '16%',
      render: (text, row, index) => {
        switch (index) {
          case 0:
            return RenderTableInput('lOdCilindrico', handleCellValue(row.lOdCilindrico), index)
          case 1:
            return RenderTableInput('lOeCilindrico', handleCellValue(row.lOeCilindrico), index)
          case 2:
            return RenderTableInput('pOdCilindrico', handleCellValue(row.pOdCilindrico))
          default:
            return RenderTableInput('pOeCilindrico', handleCellValue(row.pOeCilindrico))
        }
      }
    },
    {
      title: 'Eixo',
      dataIndex: 'axis',
      align: 'center',
      width: '16%',
      render: (text, row, index) => {
        switch (index) {
          case 0:
            return RenderTableInput('lOdEixo', handleCellValue(row.lOdEixo), index)
          case 1:
            return RenderTableInput('lOeEixo', handleCellValue(row.lOeEixo), index)
          case 2:
            return RenderTableInput('pOdEixo', handleCellValue(row.pOdEixo))
          default:
            return RenderTableInput('pOeEixo', handleCellValue(row.pOeEixo))
        }
      }
    },
    {
      title: 'Altura',
      dataIndex: 'height',
      align: 'center',
      width: '15%',
      render: (text, row, index) => {
        switch (index) {
          case 0:
            return RenderTableInput('lOdAlt', handleCellValue(row.lOdAlt), index)
          case 1:
            return RenderTableInput('lOeAlt', handleCellValue(row.lOeAlt), index)
          case 2:
            return RenderTableInput('pOdAlt', handleCellValue(row.pOdAlt))
          default:
            return RenderTableInput('pOeAlt', handleCellValue(row.pOeAlt))
        }
      }
    },
    {
      title: 'DPN',
      dataIndex: 'dpn',
      align: 'center',
      width: '15%',
      render: (text, row, index) => {
        switch (index) {
          case 0:
            return RenderTableInput('lOdDnp', handleCellValue(row.lOdDnp), index)
          case 1:
            return RenderTableInput('lOeDnp', handleCellValue(row.lOeDnp), index)
          case 2:
            return RenderTableInput('pOdDnp', handleCellValue(row.pOdDnp))
          default:
            return RenderTableInput('pOeDnp', handleCellValue(row.pOeDnp))
        }
      }
    }
  ]

  const data = [
    {
      key: '1',
      lOdEsferico: get(state, 'recipeData.lOdEsferico'),
      lOdCilindrico: get(state, 'recipeData.lOdCilindrico'),
      lOdEixo: get(state, 'recipeData.lOdEixo'),
      lOdDnp: get(state, 'recipeData.lOdDnp'),
      lOdAlt: get(state, 'recipeData.lOdAlt')
    },
    {
      key: '2',
      lOeEsferico: get(state, 'recipeData.lOeEsferico'),
      lOeCilindrico: get(state, 'recipeData.lOeCilindrico'),
      lOeEixo: get(state, 'recipeData.lOeEixo'),
      lOeDnp: get(state, 'recipeData.lOeDnp'),
      lOeAlt: get(state, 'recipeData.lOeAlt')
    },
    {
      key: '3',
      pOdEsferico: get(state, 'recipeData.pOdEsferico'),
      pOdCilindrico: get(state, 'recipeData.pOdCilindrico'),
      pOdEixo: get(state, 'recipeData.pOdEixo'),
      pOdDnp: get(state, 'recipeData.pOdDnp'),
      pOdAlt: get(state, 'recipeData.pOdAlt')
    },
    {
      key: '4',
      pOeEsferico: get(state, 'recipeData.pOeEsferico'),
      pOeCilindrico: get(state, 'recipeData.pOeCilindrico'),
      pOeEixo: get(state, 'recipeData.pOeEixo'),
      pOeDnp: get(state, 'recipeData.pOeDnp'),
      pOeAlt: get(state, 'recipeData.pOeAlt')
    }
  ]

  return (
    <ConfigProvider locale={ptBR}>
      <Row className="inline_container">
        <Col className="inline_container" {...responsiveCol(10)}>
          <label htmlFor="doctorName">Nome do médico:</label>
          <Input
            disabled={isReadOnly}
            id="doctorName"
            value={get(state, 'doctorName')}
            onChange={({ target }) => setState(v => ({ ...v, doctorName: target.value }))}
          />
        </Col>
        <Col className="inline_container" {...responsiveCol(13)} style={{ justifyContent: 'flex-end' }}>
          <label htmlFor="dueDate">Válida até:*</label>
          <DatePicker
            disabled={isReadOnly}
            value={get(state, 'dueDate') ? moment(state.dueDate).utc() : undefined}
            format="DD/MM/YYYY"
            onChange={date => setState(v => ({ ...v, dueDate: date }))}
          />

          <Button
            disabled={isReadOnly}
            type="primary"
            onClick={e => {
              setToggle(!toggle)
              if (!get(state, 'dueDate')) {
                return alert.open(getMessage('isRequired', 'Válida até'), 'error')
              }
              handleSave(e, tableValues.current)
            }}
          >
            Salvar receita
          </Button>
        </Col>

        <Table
          className="tb__recipe_view"
          columns={columns}
          dataSource={data}
          pagination={false}
          rowClassName={(record, index) => ([0, 1].includes(index) ? 'line_blue' : 'line_red')}
          bordered
          scroll={{ x: 600 }}
        />

        <Col style={{ marginTop: 10, display: 'flex', justifyContent: 'flex-end' }} span={24}>
          <div className="inline_container">
            <label htmlFor="addiction">Adição:</label>
            <NumberFormat
              disabled={isReadOnly}
              name="addiction"
              value={get(state, 'recipeData.adicao', ' ')}
              customInput={Input}
              onChange={({ target }) => setTableFieldValue('adicao', target.value)}
              thousandSeparator=","
              decimalSeparator="."
              decimalScale={2}
            />
          </div>
        </Col>

        <Col span={24}>
          <label htmlFor="addiction">Observações</label>
          <Input.TextArea
            disabled={isReadOnly}
            value={get(state, 'observations')}
            onChange={({ target }) => setState(v => ({ ...v, observations: target.value }))}
          />
        </Col>
      </Row>
    </ConfigProvider>
  )
}

export default RecipeView
