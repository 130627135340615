import React, { useState, useEffect } from 'react'
import { Row } from 'antd'
import { useFormik } from 'formik'
import _ from 'lodash'

import ListScreen from '../../components/ListScreen'
import { api, request } from '../../util/handlerRequestUtil'
import './listStore.css'

function ListStore(props) {
  const { alert, getMessage } = props
  const formik = useFormik({
    initialValues: {}
  })
  const [modalDelete, setModalDelete] = useState({
    description: getMessage('defaultDeleteDescription'),
    visible: false
  })
  const { values } = formik
  const [data, setData] = useState({})
  const [filters, setFilters] = useState({})
  const { page = 1, pageSize = 10, count = 0, pages = 0 } = data
  const store = _.get(data, 'items', []).map(item => {
    return {
      key: item.id,
      ...item
      // cli-formatter-table
    }
  })
  const columns = [
    {
      title: getMessage('name'),
      dataIndex: 'name',
      key: 'name'
    }
    // cli-columns
  ]

  useEffect(() => {
    requests()
  }, [])

  //requests
  async function requests() {
    try {
      // cli-request-function
      await requestData()

      setFilters({
        // cli-request-filter
      })
    } catch (error) {
      console.tron.log(error)
    }
  }

  function onClickEdit(store) {
    props.history.push(`/store/${store.key}`)
  }

  async function onClickDelete(store) {
    setModalDelete({
      ...modalDelete,
      title: getMessage('deleteStoreTitle', store.name),
      visible: true,
      onOk: async () => onConfirmDelete(store)
    })
  }

  async function onConfirmDelete(store) {
    alert.open(getMessage('successStoreDelete'), 'success')
    await request(api.deleteStore(store.key))
    setModalDelete({
      ...modalDelete,
      visible: false
    })
    await requestData(values)
  }

  function onClickView(store) {
    props.history.push(`/store/${store.key}?readOnly=true`)
  }

  function onClickDuplicate(store) {
    props.history.push(`/store/create?duplicate=${store.key}`)
  }

  const requestData = (params = {}) =>
    request(
      api.listStore({
        ...params,
        include: `
    // cli-include
    `
      })
    ).then(data => setData(data))

  // cli-requests

  //functions
  function handleFilter(value) {
    let filters = {}
    // const {
    //   // cli-handler-filters-destruturing
    // } = values

    if (value && value.trim() !== '') {
      filters.name = value
    }
    requestData({
      ...filters
    })
  }

  const onSearch = value => handleFilter(value)

  async function requestPage(page) {
    await requestData({ page: page - 1 })
  }

  const onChangeCreateButton = () => {
    props.history.push('/store/create')
  }

  function filtersList() {
    return (
      <Row type="flex" justify="start">
        {/* cli-filters */}
      </Row>
    )
  }
  return (
    <ListScreen
      context={formik}
      columns={columns}
      data={store}
      title={getMessage('store')}
      titleButtonAdd={getMessage('newStore')}
      onSearch={onSearch}
      filtersList={filtersList}
      dataFilters={filters}
      onChange={requestPage}
      onChangeCreateButton={onChangeCreateButton}
      pagination={{
        currentPage: page + 1,
        pages: pages,
        total: count,
        pageSize: pageSize
      }}
      onFilter={handleFilter}
      onClickDelete={onClickDelete}
      modalDelete={modalDelete}
      setModalDelete={setModalDelete}
      onClickEdit={onClickEdit}
      onClickView={onClickView}
      onClickDuplicate={onClickDuplicate}
    />
  )
}

export default ListStore
