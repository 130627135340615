import React, { useState, useEffect } from 'react'
import { Table } from 'antd'
import { getMessage } from '../../../../util/IntlMessages'
import { formatPrice } from '../../../../util/format'
import { get, isEmpty } from 'lodash'
import { CustomIcon, CustomPopover } from '../../../../components'
import './tablePayments.css'

function PaymentTable({ data = [], deleteEntry, isReadOnly }) {
  const [dataFormated, setDataFormated] = useState([])

  useEffect(() => {
    if (data) {
      const dataTransformed = data.map(row => {
        return {
          id: `${row.value}-${Math.random * 999}`,
          paymentForm: get(row, 'payment.description', '-'),
          parcels: row.parcels ? `${row.parcels}x` : '-',
          value: row.value,
          ...(row.transationCode && { transationCode: row.transationCode }),
          ...(row.flag && { flag: row.flag.description }),
          ...(row.gateway && { gateway: row.gateway.description })
        }
      })

      setDataFormated(dataTransformed)
    }
    if (data.length === 0) return
  }, [data])

  const renderContent = (info = {}) => {
    return (
      <>
        {Object.entries(info).map(([key, value]) => (
          <div key={key}>
            <strong>{`${key}: `}</strong>
            {value}
          </div>
        ))}
      </>
    )
  }

  function handlerChange(index, row) {
    const { transationCode, flag, gateway } = row
    const info = {
      ...(transationCode && { [`Código da transação`]: transationCode }),
      ...(flag && { Bandeira: flag }),
      ...(gateway && { Gateway: gateway })
    }

    return (
      <>
        <CustomIcon name="faTrash" onClick={() => deleteEntry(index)} className={isReadOnly && 'disabled'} />
        {!isEmpty(info) && (
          <CustomPopover content={renderContent(info)} trigger="hover">
            <CustomIcon name="faInfoCircle" className="ml5 primary-color" />
          </CustomPopover>
        )}
      </>
    )
  }

  const columns = [
    {
      title: getMessage('paymentForm'),
      dataIndex: 'paymentForm',
      align: 'center',
      width: 200
    },
    {
      title: getMessage('parcels'),
      dataIndex: 'parcels',
      align: 'center'
    },
    {
      title: getMessage('value'),
      dataIndex: 'value',
      align: 'center',
      render: text => formatPrice(text)
    },
    {
      title: getMessage('actions'),
      dataIndex: 'actions',
      align: 'center',
      render: function(text, row, index) {
        return handlerChange(index, row)
      }
    }
  ]

  return (
    <Table
      className="tablePayments"
      dataSource={dataFormated}
      locale={{ emptyText: getMessage('noRecords') }}
      rowKey="id"
      columns={columns}
      bordered
      scroll={{ x: 500 }}
      pagination={false}
    />
  )
}

export default PaymentTable
