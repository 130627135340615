export const logos = {
  WOO: 'WooCommerce'
}

export const status = {
  ERROR: 'error',
  PENDING: 'pending',
  SUCCESS: 'success'
}

export const TIME_REQUEST = 60000
