import React from 'react'
import { connect } from 'react-redux'

import Widget from 'components/Widget/index'

import { THEME_TYPE_DARK } from '../../constants/ThemeSetting'
import { Spinner } from 'react-activity'

const IconWithTextCard = props => {
  const { icon, title, subTitle, isLoading = false } = props

  if (props.themeType === THEME_TYPE_DARK) {
    props.iconColor = 'white'
  }

  return (
    <Widget>
      <div className="gx-media gx-align-items-center gx-flex-nowrap">
        <div className="gx-mr-lg-4 gx-mr-3">
          <i
            className={`icon icon-${icon} gx-fs-xlxl gx-d-flex primary-color`}
            style={{
              fontSize: 45
            }}
          />
        </div>
        <div className="gx-media-body">
          {isLoading && (
            <>
              {' '}
              <Spinner className="primary-color"></Spinner>
            </>
          )}
          {!isLoading && (
            <>
              <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{title}</h1>
            </>
          )}
          <p className="gx-text-grey gx-mb-0">{subTitle}</p>
        </div>
      </div>
    </Widget>
  )
}

const mapStateToProps = ({ settings }) => {
  const { themeType } = settings
  return { themeType }
}
export default connect(mapStateToProps, null)(IconWithTextCard)
