import React, { useState, useEffect } from 'react'
import '@ant-design/compatible/assets/index.css'

import { Button, Tabs, Row, Col, Form } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as icons from '@fortawesome/free-solid-svg-icons'
import { useFormik } from 'formik'
import * as yup from 'yup'

import Step1 from './step1'
import Step2 from './step2'
import Step3 from './step3'
import { api, request } from '../../util/handlerRequestUtil'
import { validateFields } from ' ../../util/formikUtil'
import SuccessRegister from './successRegister'
import { If } from 'react-extras'
import _ from 'lodash'
import queryString from 'query-string'
import { parseCurrency } from '../../util/numberFormat'
import { FormikProvider } from 'formik'
import { toNumberEn } from '../../util/format'
const { TabPane } = Tabs

function CreateProduct(props) {
  const isDuplicate = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true
  }).duplicate
  const [attributeItems, setAttributeItems] = useState(undefined)
  const { alert, getMessage, match, loading } = props
  const { id } = match.params
  const [filters, setFilters] = useState({
    formatedCategory: [],
    formatedBrand: [],
    formatedStoreBranch: [],
    formattedGlassesMaterial: [],
    formattedGlassesShape: []
  })
  const [registeredProduct, setRegisteredProduct] = useState(undefined)
  const [operation] = useState('store')
  const [selectedTab, setSelectedTab] = useState('1')
  const [dynamicYupValidation, setDynamicYupValidation] = useState({})
  const isReadOnly = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true
  }).readOnly
  const yupValidation = yup.object().shape({
    //Step1
    name: yup.string().required(getMessage('isRequired', getMessage('name'))),
    description: yup.string().nullable(),
    brand: yup.number().nullable(),
    category: yup.array().nullable(),
    provider: yup.string().nullable(),
    registeredPlatforms: yup.array().required(getMessage('isRequired', getMessage('registeredPlatforms'))),
    productType: yup.string().required(getMessage('isRequired', getMessage('productType'))),
    product: yup.string().required(getMessage('isRequired', getMessage('product'))),
    //Step2
    weight: yup.string().nullable(),
    length: yup.string().nullable(),
    width: yup.string().nullable(),
    height: yup.string().nullable(),
    sku: yup.string().required(getMessage('isRequired', getMessage('sku'))),
    status: yup.string().required(getMessage('isRequired', getMessage('status'))),
    manageInventory: yup.string().required(getMessage('isRequired', getMessage('manageInventory'))),
    promotionalValue: yup.number(),
    attributeTable: yup.string().when('productType', (productType, schema) => {
      if (productType === 'variable') {
        return schema.required(getMessage('isRequired', getMessage('attributeTable')))
      }
      return schema
    }),
    ...dynamicYupValidation
  })
  const formik = useFormik({
    initialValues: {
      files: [],
      manageInventory: false,
      productType: 'simple',
      status: 'publish',
      description: ''
    },
    validationSchema: yupValidation,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async values => {
      if (values.productType === 'variable' && variations.length === 0) {
        props.alert.open(getMessage('isRequired', getMessage('variations')))
      } else {
        storeProduct(values)
      }

      //storeProductSimple(values)
    }
  })
  const [variations, setVariations] = useState([])

  const { handleSubmit, values, setValues, setFieldValue } = formik

  const onChangeBackProductsButton = () => {
    props.history.push('/products/list')
  }

  useEffect(() => {
    requests()
  }, [])

  //requests
  async function requests() {
    try {
      loading.open()
      const category = await requestCategory()
      const brand = await requestBrand()
      const platform = await requestPlatform()
      const provider = await requestProvider()
      const attributes = await formatedAttribute()
      const storeBranches = await formatedStoreBranch()
      const taxOrigin = await requestTaxOrigin()
      const taxTypeItem = await requestTaxTypeItem()
      const taxClasses = await requestTaxClasses()
      const packageType = await requestPackageType()
      const glassesShape = await requestGlassesShape()
      const glassesMaterial = await requestGlassesMaterial()
      setFilters({
        ...filters,
        ...category,
        ...brand,
        ...platform,
        ...provider,
        ...attributes,
        ...storeBranches,
        ...taxOrigin,
        ...taxTypeItem,
        ...packageType,
        ...glassesMaterial,
        ...glassesShape,
        ...taxClasses,
        wooPlatform: platform.formatedRegisteredPlatforms.find(item => item.description === 'WooCommerce')
      })
      if (id) {
        const retrieveProduct = await request(api.retrieveProduct(id))
        const formatedAttributeTable = new Set()
        const formatedVariations = []
        let formatedVariationsFormik = {}
        _.get(retrieveProduct, 'variations', []).forEach((item, index) => {
          formatedVariations.push({
            id: index + 1
          })
          formatedVariationsFormik[`variation${index + 1}`] = {
            ...item,
            stock: item.stock
              ? item.stock.map(stock => ({
                  ...stock,
                  branch: _.get(stock, 'storeBranch.name')
                }))
              : [],
            promotionalValue: item.salePrice ? item.salePrice : undefined,
            image: _.get(retrieveProduct, 'images', []).find(image => image.id === item.imageId)
          }

          _.get(item, 'attributes', []).forEach(item2 => {
            formatedAttributeTable.add(item2.attributeId)
            formatedVariationsFormik[`variation${index + 1}`][`${item2.attribute.name}`] = item2.id
          })
        })
        const formatedData = {
          ...retrieveProduct,
          registeredPlatforms: _.get(retrieveProduct, 'platform', []).map(item => item.id),
          productType: retrieveProduct.type,
          manageInventory: retrieveProduct.manageStock,
          attributeTable: [...formatedAttributeTable],
          ...formatedVariationsFormik,
          wooId: retrieveProduct.wooId,
          brand: retrieveProduct.brand ? retrieveProduct.brand.id : undefined,
          category:
            retrieveProduct.categories && retrieveProduct.categories.length > 0
              ? retrieveProduct.categories.map(category => {
                  return category.id
                })
              : undefined,
          provider: retrieveProduct.provider ? retrieveProduct.provider.id : undefined,
          files: retrieveProduct.images.map(item => {
            return {
              ...item,
              file: { uid: item.location },
              status: 'success',
              mainly: retrieveProduct.imageId === item.id
            }
          }),
          product: retrieveProduct.product,
          glassesShape: retrieveProduct.glassesShape,
          glassesMaterial: retrieveProduct.glassesMaterial,
          ...(retrieveProduct.heightGlasses && { heightGlasses: +retrieveProduct.heightGlasses }),
          ...(retrieveProduct.lengthGlasses && { lengthGlasses: +retrieveProduct.lengthGlasses }),
          ...(retrieveProduct.wideGlasses && { heiwideGlassesghtGlasses: +retrieveProduct.wideGlasses }),
          wooSubtitle: retrieveProduct.wooSubtitle,
          ...(retrieveProduct.taxClassId && { taxClass: retrieveProduct.taxClassId })
        }
        setValues(formatedData)
        setVariations([...formatedVariations])
      }
      if (isDuplicate) {
        const retrieveProduct = await request(api.retrieveProduct(isDuplicate))
        const formatedAttributeTable = new Set()
        const formatedVariations = []
        let formatedVariationsFormik = {}
        _.get(retrieveProduct, 'variations', []).forEach((item, index) => {
          delete retrieveProduct.variations[index].id
          delete retrieveProduct.variations[index].wooId
          _.get(item, 'stock', []).forEach((stock, index) => {
            delete retrieveProduct.variations[index].stock[index].id
          })
          formatedVariations.push({
            id: index + 1
          })
          formatedVariationsFormik[`variation${index + 1}`] = {
            ...item,
            stock: item.stock
              ? item.stock.map(stock => ({
                  ...stock,
                  branch: _.get(stock, 'storeBranch.name')
                }))
              : [],
            promotionalValue: item.salePrice ? item.salePrice : undefined,
            image: _.get(retrieveProduct, 'images', []).find(image => image.id === item.imageId)
          }

          _.get(item, 'attributes', []).forEach(item2 => {
            formatedAttributeTable.add(item2.attributeId)
            formatedVariationsFormik[`variation${index + 1}`][`${item2.attribute.name}`] = item2.id
          })
        })
        let formatedData = {
          ...retrieveProduct,
          registeredPlatforms: _.get(retrieveProduct, 'platform', []).map(item => item.id),
          productType: retrieveProduct.type,
          manageInventory: retrieveProduct.manageStock,
          attributeTable: [...formatedAttributeTable],
          ...formatedVariationsFormik,
          brand: retrieveProduct.brand ? retrieveProduct.brand.id : undefined,
          category:
            retrieveProduct.categories && retrieveProduct.categories.length > 0
              ? retrieveProduct.categories.map(category => {
                  return category.id
                })
              : undefined,
          provider: retrieveProduct.provider ? retrieveProduct.provider.id : undefined,
          files: retrieveProduct.images.map(item => {
            return {
              ...item,
              file: { uid: item.location },
              status: 'success',
              mainly: retrieveProduct.imageId === item.id
            }
          }),
          wooId: undefined
        }
        delete formatedData.id
        formatedData.variations.forEach((item, index) => {
          delete formatedData.variations[index].id
          delete formatedData.variations[index].wooId
        })
        setValues(formatedData)
        setVariations(formatedVariations)
      }
    } catch (error) {
      console.tron.log(error)
    } finally {
      loading.close()
    }
  }

  const requestCategory = () =>
    request(
      api.listCategory({
        ignorePagination: true
      }),
      {
        skipLoading: true
      }
    ).then(({ items }) => {
      return {
        formatedCategory: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name
          }
        })
      }
    })

  const requestPackageType = () =>
    request(api.listPackageType(), {
      skipLoading: true
    }).then(({ items }) => {
      return {
        formatedPackageType: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name
          }
        })
      }
    })

  const requestTaxTypeItem = () =>
    request(
      api.listTaxTypeItem({
        ignorePagination: true
      }),
      {
        skipLoading: true
      }
    ).then(({ items }) => {
      return {
        formatedTaxTypeItem: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name
          }
        })
      }
    })

  const requestTaxClasses = () =>
    request(
      api.listTaxClasses({
        ignorePagination: true
      }),
      {
        skipLoading: true
      }
    ).then(({ items }) => {
      return {
        formatedTaxClasses: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name
          }
        })
      }
    })

  const requestTaxOrigin = () =>
    request(
      api.listTaxOrigin({
        ignorePagination: true
      }),
      {
        skipLoading: true
      }
    ).then(({ items }) => {
      return {
        formatedTaxOrigin: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name
          }
        })
      }
    })

  const requestBrand = () =>
    request(
      api.listBrand({
        ignorePagination: true
      }),
      {
        skipLoading: true
      }
    ).then(({ items }) => {
      return {
        formatedBrand: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name
          }
        })
      }
    })

  const requestPlatform = () =>
    request(
      api.listPlatform({
        ignorePagination: true
      }),
      {
        skipLoading: true
      }
    ).then(({ items }) => {
      return {
        formatedRegisteredPlatforms: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name
          }
        })
      }
    })

  const requestProvider = () =>
    request(api.listPerson({ type: 'PROVIDER', ignorePagination: true }), {
      skipLoading: true
    }).then(({ items }) => {
      return {
        formatedProvider: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name
          }
        })
      }
    })

  const formatedAttribute = () =>
    request(
      api.listAttributes({
        ignorePagination: true
      }),
      {
        skipLoading: true
      }
    ).then(({ items }) => {
      return {
        formatedAttribute: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name
          }
        })
      }
    })

  const formatedStoreBranch = () =>
    request(
      api.listStoreBranch({
        ignorePagination: true
      }),
      {
        skipLoading: true
      }
    ).then(({ items }) => {
      return {
        formatedStoreBranch: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name
          }
        })
      }
    })

  const requestGlassesMaterial = () =>
    request(
      api.listGlassesMaterial({
        ignorePagination: true
      }),
      {
        skipLoading: true
      }
    ).then(data => ({
      formattedGlassesMaterial: data.map(({ glasses_material }) => ({
        value: glasses_material,
        description: glasses_material
      }))
    }))

  const requestGlassesShape = () =>
    request(
      api.listGlassesShape({
        ignorePagination: true
      }),
      {
        skipLoading: true
      }
    ).then(data => ({
      formattedGlassesShape: data.map(({ glasses_shape }) => ({
        value: glasses_shape,
        description: glasses_shape
      }))
    }))

  function onChangeTabs(value) {
    step1ToStep2(value)
  }
  async function step1ToStep2(tabname = '2') {
    const hasErrors = await validateFields(
      ['name', 'registeredPlatforms', 'productType', 'sku', 'status'],
      formik,
      yupValidation
    )
    if (hasErrors.length > 0) {
      alert.open(getMessage('thereIsRequiredFields'))
    } else {
      if (values.productType === 'simple') {
        onAddProductSimple()
      }
      setSelectedTab(tabname)
    }
  }

  async function step2ToStep3() {
    setSelectedTab('3')
  }

  useEffect(() => {
    const newValues = { ...values }
    variations.forEach(item => {
      delete newValues[`variation${item.id}`]
    })
    setValues({ ...newValues })
    if (!isDuplicate && !id) {
      setVariations([])
    }
    setDynamicYupValidation({})
  }, [values.productType])

  useEffect(() => {
    async function run() {
      if (values.attributeTable && values.attributeTable.length > 0) {
        try {
          let attributeItemObj = {}
          for (let i = 0; i < values.attributeTable.length; i++) {
            const attribute = values.attributeTable[i]
            const response = await request(
              api.listAttributeItem({
                attributeId: attribute,
                ignorePagination: true
              })
            )
            attributeItemObj[`attribute${attribute}`] = response.items.map(item => ({
              ...item,
              value: item.id,
              description: item.name
            }))
          }
          setAttributeItems(attributeItemObj)
        } catch (error) {
          console.tron.log('error', error)
        }
      }
    }
    run()
  }, [values.attributeTable])

  function resetScreen() {
    window.location.replace('/products/create')
  }
  function listScreen() {
    props.history.push('/products/list')
  }

  function onAddProductSimple() {
    if (variations.length === 0) {
      const variationNumber = variations.length + 1
      setVariations([
        ...variations,
        {
          id: variationNumber
        }
      ])
      const newValues = { ...values }
      newValues[`variation${variationNumber}`] = {
        stock: [],
        productType: 'simple'
      }
      setValues({ ...newValues })
      setDynamicYupValidation({
        variation1: yup.object({
          price: yup.string().required(getMessage('isRequired', getMessage('price')))
        })
      })
    }
  }

  async function onAddVariation() {
    const variationNumber = variations.length + 1
    setVariations([
      ...variations,
      {
        id: variationNumber
      }
    ])
    const newValues = { ...values }
    newValues[`variation${variationNumber}`] = {
      stock: []
    }
    setValues({ ...newValues })
  }

  async function storeProduct(data) {
    const {
      name,
      description,
      shortDescription,
      manageInventory,
      sku,
      brand,
      category,
      provider,
      registeredPlatforms,
      grossWeight,
      diameter,
      liquidWeight,
      length,
      width,
      height,
      files = [],
      status,
      wooId,
      heightGlasses,
      lengthGlasses,
      wideGlasses,
      taxClass
    } = data
    const mainlyImage = files.find(image => image.mainly)
    const variations = getVariationsFormated(values)

    const productData = {
      ...data,
      name,
      wooId,
      type: values.productType,
      visible: true,
      description,
      shortDescription,
      manageStock: manageInventory,
      variations: variations,
      sku,
      brandId: brand,
      grossWeight: _.isString(grossWeight) ? toNumberEn(grossWeight) : grossWeight,
      diameter: _.isString(diameter) ? toNumberEn(diameter) : diameter,
      liquidWeight: _.isString(liquidWeight) ? toNumberEn(liquidWeight) : liquidWeight,
      length: _.isString(length) ? toNumberEn(length) : length,
      width: _.isString(width) ? toNumberEn(width) : width,
      height: _.isString(height) ? toNumberEn(height) : height,
      mainlyPhoto: 1,
      providerId: provider,
      platforms: registeredPlatforms,
      platform: registeredPlatforms,
      categories: category,
      images: files.map(file => file.id),
      imageId: mainlyImage ? mainlyImage.id : undefined,
      status,
      heightGlasses: _.isString(heightGlasses) ? toNumberEn(heightGlasses) : heightGlasses,
      lengthGlasses: _.isString(lengthGlasses) ? toNumberEn(lengthGlasses) : lengthGlasses,
      wideGlasses: _.isString(wideGlasses) ? toNumberEn(wideGlasses) : wideGlasses,
      taxClassId: taxClass
    }
    console.tron.log('productData', productData)
    try {
      if (!id) {
        const registeredProduct = await request(api.storeProduct(productData))

        setRegisteredProduct(registeredProduct)
      } else {
        const registeredProduct = await request(api.updateProduct(id, productData))

        setRegisteredProduct(registeredProduct)
      }
    } catch (error) {
      console.tron.log('error', error)
    }
  }

  function getVariationsFormated(values) {
    const formatedVariations = []
    for (let i = 0; i < variations.length; i++) {
      const variationObj = values[`variation${variations[i].id}`]
      const { promotionalValue = 0, price = 0, stock, id: idVariation, cost = 0, image, wooId, sku } = variationObj
      const attributeItems = []
      if (values.productType === 'variable') {
        filters.formatedAttribute
          .filter(item => values.attributeTable.indexOf(item.value) !== -1)
          .forEach(item => {
            attributeItems.push(variationObj[item.description])
          })
      }

      let variation = {
        sku,
        price: _.isNumber(price) ? price : parseCurrency(price),
        regularPrice: _.isNumber(price) ? price : parseCurrency(price),
        salePrice: promotionalValue
          ? _.isNumber(promotionalValue)
            ? promotionalValue
            : parseCurrency(promotionalValue)
          : null,
        stock: stock ? stock : [],
        cost: cost ? parseCurrency(cost) : null,
        imageId: _.get(image, 'id') ? image.id : null,
        wooId
      }

      if (values.productType === 'variable') {
        variation.attributeItems = attributeItems
      }
      if (idVariation) {
        variation.id = idVariation
      }
      formatedVariations.push(variation)
    }
    return formatedVariations
  }

  function addExtraGlassesMaterial(value) {
    setFilters(f => ({
      ...f,
      formattedGlassesMaterial: [...f.formattedGlassesMaterial, { value, description: value }]
    }))
  }
  function addExtraGlassesShape(value) {
    setFilters(f => ({
      ...f,
      formattedGlassesShape: [...f.formattedGlassesShape, { value, description: value }]
    }))
  }

  useEffect(() => {
    if (variations && variations.length > 0) {
      let newYupSchema = {}
      if (filters.formatedAttribute && values.attributeTable) {
        variations.forEach(variationItem => {
          let objToSave = {
            price: yup.string().required(getMessage('isRequired', getMessage('price'))),
            ...(values.manageInventory && { sku: yup.string().required(getMessage('isRequired', getMessage('sku'))) })
          }
          if (values.productType === 'variable') {
            values.attributeTable.forEach(attributeId => {
              const attributeObj = filters.formatedAttribute.find(item => item.id === attributeId)
              objToSave[attributeObj.name] = yup.number().required(getMessage('isRequired', attributeObj.name))
            })
          }

          newYupSchema[`variation${variationItem.id}`] = yup.object({
            ...objToSave
          })
        })
        setDynamicYupValidation(newYupSchema)
      }
    }
  }, [variations, values.attributeTable])

  useEffect(() => {
    if (values.product === 'lens') {
      setFieldValue('productType', 'simple')
    }
  }, [values.product])

  return (
    <FormikProvider value={formik}>
      <div className="createProduct">
        <div className="conteiner">
          <Row>
            <Col className="back gutter-row" onClick={onChangeBackProductsButton} xs={24} sm={24} md={8} lg={8} xl={8}>
              <Button className="btn_back">
                <FontAwesomeIcon icon={icons.faArrowLeft} size="lg" className="icon" />
              </Button>
              <span>{getMessage('backToProducts')}</span>
            </Col>
          </Row>
          <h1 className="title">{getMessage('registerProduct')}</h1>
          <If condition={!registeredProduct}>
            <Row>
              <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                <div className="conteiner-screen-tabs">
                  <Form layout={'vertical'} onSubmit={handleSubmit}>
                    <div className="card-container">
                      <Tabs
                        activeKey={selectedTab}
                        className="tabs"
                        type="card"
                        tabBarStyle={{ width: '100%' }}
                        onChange={onChangeTabs}
                      >
                        <TabPane tab={getMessage('general')} key="1">
                          <Step1
                            id={id}
                            filters={filters}
                            formik={formik}
                            setSelectedTab={setSelectedTab}
                            onPress={() => step1ToStep2()}
                            isReadOnly={isReadOnly}
                            addExtraGlassesMaterial={addExtraGlassesMaterial}
                            addExtraGlassesShape={addExtraGlassesShape}
                          ></Step1>
                        </TabPane>
                        <TabPane tab={getMessage('shippingShortAndTributes')} key="2">
                          <Step2
                            filters={filters}
                            formik={formik}
                            setSelectedTab={setSelectedTab}
                            onAddVariation={onAddVariation}
                            attributeItems={attributeItems}
                            variations={variations}
                            setVariations={setVariations}
                            onPress={() => step2ToStep3()}
                            isReadOnly={isReadOnly}
                          ></Step2>
                        </TabPane>
                        <TabPane tab={getMessage('valures')} key="3">
                          <Step3
                            filters={filters}
                            formik={formik}
                            setSelectedTab={setSelectedTab}
                            onAddVariation={onAddVariation}
                            attributeItems={attributeItems}
                            setAttributeItems={setAttributeItems}
                            variations={variations}
                            setVariations={setVariations}
                            isReadOnly={isReadOnly}
                          ></Step3>
                        </TabPane>
                      </Tabs>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </If>
          <If condition={registeredProduct}>
            <SuccessRegister
              product={registeredProduct}
              operation={operation}
              onReset={resetScreen}
              onList={listScreen}
            ></SuccessRegister>
          </If>
        </div>
      </div>
    </FormikProvider>
  )
}

export default CreateProduct
