import React from 'react'
import '@ant-design/compatible/assets/index.css'

import { Button, Row, Col, Radio, Form } from 'antd'
import { getMessage } from '../../util/IntlMessages'
import { get } from 'lodash'

import { CustomTextArea, CustomSelect, CustomRadioGroup, CustomInput, UploadPictures } from '../../components'

function Step1(props) {
  const { formik, filters, onPress, id, isReadOnly = false, addExtraGlassesMaterial, addExtraGlassesShape } = props
  const { values } = formik

  return (
    <div>
      <div className="content-tabPane">
        <Row type="flex">
          <CustomInput suffixLabel="*" showSearch context={formik} field="name" rowSpan={24} disabled={isReadOnly} />
          <Col span={24} style={{ marginBottom: 15 }}>
            <UploadPictures context={formik} disabled={isReadOnly} />
          </Col>
          <Col span={24}>
            <Form.Item label={getMessage('description')} className="w-100">
              <CustomTextArea
                value={formik.values.description}
                setValue={value => formik.setFieldValue('description', value)}
                readOnly={isReadOnly}
              />
            </Form.Item>
          </Col>

          <CustomRadioGroup context={formik} field="product" span={12} disabled={id} required>
            <Radio.Button value="glasses">Óculos</Radio.Button>
            <Radio.Button value="lens">Lentes</Radio.Button>
          </CustomRadioGroup>

          <CustomRadioGroup
            context={formik}
            field="productType"
            span={12}
            disabled={id || values.product === 'lens'}
            required
          >
            <Radio.Button value="simple">Simples</Radio.Button>
            <Radio.Button value="variable">Variável</Radio.Button>
          </CustomRadioGroup>

          <CustomInput
            suffixLabel="*"
            disabled={isReadOnly}
            showSearch
            context={formik}
            field="sku"
            rowSpan={12}
            values={''}
          />
          <CustomSelect
            disabled={isReadOnly}
            showSearch
            suffixLabel="*"
            context={formik}
            field="registeredPlatforms"
            rowSpan={12}
            values={filters.formatedRegisteredPlatforms}
            multiple
          />
          <CustomSelect
            showSearch
            context={formik}
            field="brand"
            rowSpan={12}
            values={filters.formatedBrand}
            disabled={isReadOnly}
          />
          <CustomSelect
            showSearch
            context={formik}
            field="category"
            rowSpan={12}
            values={filters.formatedCategory}
            disabled={isReadOnly}
            multiple
          />
          <CustomSelect
            disabled={isReadOnly}
            showSearch
            context={formik}
            field="provider"
            rowSpan={12}
            values={filters.formatedProvider}
          />
          <CustomSelect
            disabled={isReadOnly}
            showSearch
            context={formik}
            field="status"
            rowSpan={12}
            values={[
              {
                value: 'publish',
                description: getMessage('publish')
              },
              {
                value: 'private',
                description: getMessage('private')
              },
              {
                value: 'draft',
                description: getMessage('draft')
              }
            ]}
          />

          {values.product === 'glasses' && (
            <>
              <CustomSelect
                context={formik}
                field="glassesShape"
                rowSpan={12}
                values={filters.formattedGlassesShape}
                disabled={isReadOnly}
                addOption={addExtraGlassesShape}
              />
              <CustomSelect
                context={formik}
                field="glassesMaterial"
                rowSpan={12}
                values={filters.formattedGlassesMaterial}
                disabled={isReadOnly}
                addOption={addExtraGlassesMaterial}
              />
              <CustomInput
                disabled={isReadOnly}
                context={formik}
                field="heightGlasses"
                type="number"
                rowSpan={12}
                placeholder="Ex.: 1,00"
                type="number"
                suffixLabel="(cm)"
              />
              <CustomInput
                disabled={isReadOnly}
                type="number"
                context={formik}
                field="lengthGlasses"
                rowSpan={12}
                placeholder="Ex.: 1,00"
                type="number"
                suffixLabel="(cm)"
              />
              <CustomInput
                disabled={isReadOnly}
                context={formik}
                type="number"
                field="wideGlasses"
                rowSpan={12}
                placeholder="Ex.: 1,00"
                type="number"
                suffixLabel="(cm)"
              />
            </>
          )}
          {get(formik.values, 'registeredPlatforms', []).some(v => v === get(filters.wooPlatform, 'id')) && (
            <CustomInput disabled={isReadOnly} context={formik} field="wooSubtitle" rowSpan={24} />
          )}
        </Row>
      </div>
      <div className="content-tabPane">
        <Row justify="end">
          <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={12}></Col>
          <Col className="buttons-products gutter-row" xs={24} sm={24} md={12} lg={12} xl={12}>
            <Button type="primary" className={'mr5'} onClick={() => onPress()}>
              <span>{getMessage('continue')}</span>
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Step1
