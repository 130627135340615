import React from 'react'
import NumberFormat from 'react-number-format'
import { If } from 'react-extras'

export const formatedPrice = data => {
  let minValue = Math.min.apply(Math, data)
  let maxValue = Math.max.apply(Math, data)
  return (
    <>
      <If condition={minValue === maxValue}>
        <NumberFormat prefix="R$ " displayType={'text'} value={maxValue} />
      </If>
      <If condition={minValue !== maxValue}>
        <NumberFormat prefix="R$ " displayType={'text'} value={minValue} />
        <span> - </span>
        <NumberFormat prefix="R$ " displayType={'text'} value={maxValue} />
      </If>
    </>
  )
}
