import React, { useState, useEffect } from 'react'
import { Row, Col, Input, Form, Typography } from 'antd'
import { useFormik } from 'formik'
import { get } from 'lodash'
import { CustomIcon, CustomInput, CustomSelect, ModalCustomerSelect, CustomTags } from '../../components'
import { api, request } from '../../util/handlerRequestUtil'
import { formatPrice, formatToSelect } from '../../util/format'
import { COLUMNS } from './util/constants'
import moment from 'moment-timezone'
import ListScreen from '../../components/ListScreen'
import './listOrder.css'
import queryString from 'query-string'
import { ORDER_TYPES } from './types'

const { Text } = Typography

function ListOrder(props) {
  const [isLoading, setIsLoading] = useState(false)
  const { alert, getMessage, loading } = props
  const orderTypeParam =
    queryString.parse(window.location.search, {
      ignoreQueryPrefix: true
    }).orderType || ORDER_TYPES.SALE
  const queryParams = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true
  })
  const formik = useFormik({
    initialValues: {
      ...queryParams
    }
  })

  const [modalDelete, setModalDelete] = useState({
    description: getMessage('defaultDeleteDescription'),
    visible: false
  })
  const { values, setFieldValue } = formik
  const [data, setData] = useState({})
  const [visibleModalCustomerSelect, setVisibleModalCustomerSelect] = useState(false)
  const [person, setPerson] = useState({})
  const [filters, setFilters] = useState({
    formatedStatus: []
  })
  const isSale = orderTypeParam === ORDER_TYPES.SALE

  const { page = 1, pageSize = 10, count = 0, pages = 0 } = data
  const order = get(data, 'items', []).map(item => {
    const hasNf = get(item, 'nfLogs')
    let danfe = ''
    try {
      if (hasNf) {
        danfe = JSON.parse(hasNf).danfe
      }
    } catch (error) {}
    return {
      key: item.id,
      id: item.id,
      storeBranch: get(item, 'storeBranch.name', '-'),
      customer: get(item, 'person.name', '-'),
      tags: item.tags.map(tag => ({
        id: tag.id,
        name: tag.tag.name
      })),
      status: get(item, 'status.name', '-'),
      internalStatus: get(item, 'internalStatus.name', '-'),
      employee: get(item, 'employee.name', '-'),
      orderTotal: formatPrice(item.totalOrder),
      orderDate: moment(new Date(item.orderDate)).format('DD/MM/YYYY'),
      deliveryDate: item.dueDate ? moment(new Date(item.dueDate)).format('DD/MM/YYYY') : '-',
      nf: danfe,
      osCode: item.osCode,
      products: item.products
    }
  })

  //requests
  async function requests() {
    try {
      setIsLoading(true)
      loading.open()
      const [store, tags, status, employee] = await Promise.all([
        requestStoreBranch(true),
        requestTags(true),
        requestStatus(true),
        requestEmployee(true),
        requestData(formik.values)
      ])

      setFilters(f => ({
        ...f,
        ...store,
        ...status,
        ...tags,
        ...employee
      }))
    } catch (error) {
      console.tron.log(error)
    } finally {
      setIsLoading(false)
      loading.close()
    }
  }

  function onClickEdit(order) {
    props.history.push(`/order/${order.id}?orderType=${orderTypeParam}`)
  }

  async function onClickDelete(order) {
    setModalDelete({
      ...modalDelete,
      title: `${getMessage('deleteOrderTitle')} - ID: ${order.id}`,
      visible: true,
      onOk: async () => onConfirmDelete(order)
    })
  }

  async function onConfirmDelete(order) {
    await request(api.deleteOrder(order.id))
    setModalDelete({
      ...modalDelete,
      visible: false
    })
    alert.open(getMessage('successOrderDelete'), 'success')
    await requestData(values)
  }

  function onClickView(order) {
    props.history.push(`/order/${order.id}?readOnly=true&orderType=${orderTypeParam}`)
  }

  const requestData = (params = {}) => {
    return request(
      api.listOrder({
        ...params,
        include: 'storeBranch, person, status, internalStatus, tags,employee',
        enable: true,
        orderType: orderTypeParam
      }),
      true
    ).then(data => setData(data))
  }

  const requestStoreBranch = async skip => {
    const { items } = await request(api.listStoreBranch(), { skipLoading: skip })
    const data = formatToSelect(items)

    return { formatedStoreBranch: data }
  }

  const requestStatus = async skip => {
    const { items } = await request(api.listOrderStatus({ ignorePagination: true }), { skipLoading: skip })
    const data = items
      .filter(item => item.orderType === orderTypeParam)
      .map(item => {
        return {
          ...item,
          value: item.id,
          description: item.name
        }
      })

    return { formatedStatus: data, formatedInternalStatus: data }
  }

  const requestTags = async skip => {
    const { items } = await request(api.listTag({ type: 'ORDER' }), { skipLoading: skip })

    return {
      formatedTags: items.map(item => {
        return {
          ...item,
          value: item.name,
          description: item.name
        }
      })
    }
  }

  const requestEmployee = skip =>
    request(api.listEmployee({ ignorePagination: true }), { skipLoading: skip }).then(({ items }) => {
      return {
        formatedEmployee: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name
          }
        })
      }
    })

  function handleFilter(value, combinedValues, page = 0) {
    const { orderDate, orderId, storeBranch, status, internalStatus, customer, dueDate, tags, employee } = values

    let filters = {
      ...(storeBranch && { storeBranchId: storeBranch }),
      ...(customer && { personId: customer }),
      ...(status && { statusId: status }),
      ...(internalStatus && { internalStatusId: internalStatus }),
      ...(orderId && { id: Number(orderId) }),
      ...(orderDate && { orderDate: orderDate.format('YYYY-MM-DD') }),
      ...(dueDate && { dueDate: dueDate.format('YYYY-MM-DD') }),
      ...(tags && { tags }),
      ...(employee && { employeeId: employee })
    }

    if (value && value.trim() !== '') {
      filters.personName = value
    }
    requestData({
      ...filters,
      page
    })
  }

  const onSearch = value => handleFilter(value)

  async function requestPage(page, value) {
    await handleFilter(value, values, page - 1)
  }

  const onChangeCreateButton = () => {
    props.history.push(`/order/create?orderType=${orderTypeParam}`)
  }

  const columns = Object.values(COLUMNS(isSale)).map(({ title, ...props }) => {
    const customTitle = {}

    return {
      ...props,
      title: customTitle[title] || getMessage(title),
      dataIndex: title,
      ...(title === COLUMNS(isSale).TAGS.title && {
        render: tags => <CustomTags tags={tags} />
      }),
      ...(isSale &&
        title === COLUMNS(isSale).NF_DANFE.title && {
          render: danfe => (
            <>
              {danfe && danfe.trim() !== '' && (
                <a onClick={() => window.open(danfe, '_blank', 'noopener,noreferrer')}>
                  <CustomIcon name="faFile" />
                </a>
              )}
            </>
          )
        })
    }
  })

  useEffect(() => {
    requests()
  }, [orderTypeParam])

  function filtersList() {
    return (
      <Row type="flex" justify="start">
        <CustomSelect field="storeBranch" rowSpan={12} context={formik} values={filters.formatedStoreBranch} />
        <CustomInput context={formik} field="orderId" rowSpan={12} />

        <CustomSelect field="employee" rowSpan={12} context={formik} values={filters.formatedEmployee} />
        <CustomSelect context={formik} field="tags" rowSpan={12} values={filters.formatedTags} tags />

        <CustomSelect field="status" rowSpan={12} context={formik} values={filters.formatedStatus} />
        {/* <CustomSelect field="internalStatus" rowSpan={12} context={formik} values={filters.formatedStatus} /> */}

        <CustomInput context={formik} field="orderDate" rowSpan={12} type="date" />
        <CustomInput context={formik} field="dueDate" rowSpan={12} type="date" />

        <Col span="24" onClick={() => setVisibleModalCustomerSelect(true)}>
          <Form.Item label={getMessage('customer')}>
            <Input
              className="search__indicationPerson"
              value={person.name}
              suffix={<CustomIcon name="faPlus" />}
              disabled
            />
          </Form.Item>
        </Col>

        <ModalCustomerSelect
          visible={visibleModalCustomerSelect}
          setVisible={setVisibleModalCustomerSelect}
          setPerson={setPerson}
        />
      </Row>
    )
  }
  function onClickOpenOthertab(order) {
    const win = window.open(`/order/${order.key}&orderType=SERVICE`)
    win.focus()
  }

  const validFieldsKeys = [
    'dueDate',
    'orderDate',
    'internalStatus',
    'status',
    'tags',
    'employee',
    'orderId',
    'storeBranch',
    'customer'
  ]

  useEffect(() => {
    setFilters(f => ({
      ...f,
      formatedCustomer: [
        {
          value: person.id,
          description: person.name
        }
      ]
    }))
    setFieldValue('customer', person.id)
  }, [person])

  useEffect(() => {
    if (!values.customer) {
      setPerson({})
    }
  }, [values.customer])

  return (
    <ListScreen
      isLoading={isLoading}
      context={formik}
      columns={columns}
      data={order}
      title={orderTypeParam === ORDER_TYPES.SALE ? getMessage('order') : getMessage('OS')}
      titleButtonAdd={orderTypeParam === ORDER_TYPES.SALE ? getMessage('newOrder') : getMessage('newOS')}
      onSearch={onSearch}
      filtersList={filtersList}
      validFieldsKeys={validFieldsKeys}
      dataFilters={filters}
      onChange={requestPage}
      onChangeCreateButton={onChangeCreateButton}
      pagination={{
        currentPage: page + 1,
        pages: pages,
        total: count,
        pageSize: pageSize
      }}
      expandable={{
        defaultExpandAllRows: true,
        expandedRowRender: record => (
          <div>
            {record.products.map(item => {
              return (
                <p style={{ marginBottom: 0 }} key={item.id}>
                  <Text strong>{item.lineItem}</Text> - {formatPrice(item.finalPrice)} x {item.quantity} ={' '}
                  {formatPrice(item.finalPrice * item.quantity)}
                </p>
              )
            })}
          </div>
        ),
        rowExpandable: record => {
          return record.products && record.products.length > 0
        }
      }}
      onFilter={handleFilter}
      onClickDelete={onClickDelete}
      modalDelete={modalDelete}
      setModalDelete={setModalDelete}
      onClickEdit={onClickEdit}
      onClickView={onClickView}
      searchPlaceholder={getMessage('order.customerName')}
      hideActions={['copy']}
      onClickOpenOthertab={onClickOpenOthertab}
    />
  )
}

export default ListOrder
