import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { Menu } from 'antd'
import Auxiliary from 'util/Auxiliary'

import { getMessage } from '../../util/IntlMessages'
import { Creators as authActions } from '../../appRedux/ducks/auth/auth'
import * as settingsActions from '../../appRedux/actions/Setting'
import { THEME_TYPE, THEME_TYPE_DARK, NAV_STYLE_FIXED } from '../../constants/ThemeSetting'
import SidebarLogo from './SidebarLogo'
import * as icons from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ORDER_TYPES } from '../Order/types'

class SidebarContent extends Component {
  renderMenuItem(key, to, icon, message, onClick) {
    return (
      <Menu.Item
        key={key}
        icon={
          icon && (
            <FontAwesomeIcon
              className={this.props.navStyle === NAV_STYLE_FIXED ? 'mr-2' : 'mr-5'}
              icon={icon}
              size="lg"
            />
          )
        }
      >
        <Link to={to} onClick={onClick}>
          <span className="menu-item-span">
            <span className="menu-item-icon-text">{getMessage(message)}</span>
          </span>
        </Link>
      </Menu.Item>
    )
  }

  getSubMenuTitle(icon, message) {
    return (
      <span className="sub-menu-title">
        <div className="mr-2">
          <FontAwesomeIcon
            className={this.props.navStyle === NAV_STYLE_FIXED ? 'mr-2' : 'mr-5'}
            icon={icon}
            size="lg"
          />
        </div>
        <span>{getMessage(message)}</span>
      </span>
    )
  }

  render() {
    return (
      <Auxiliary>
        <SidebarLogo />
        <div className="gx-sidebar-content">
          {this.props.themeType && (
            <Menu mode="inline">
              <Menu.Item
                onClick={() => {
                  if (this.props.themeType === THEME_TYPE_DARK) {
                    this.props.setThemeType(THEME_TYPE)
                    document.body.className = document.body.className.replace('dark-theme', '')
                  } else {
                    this.props.setThemeType(THEME_TYPE_DARK)
                  }
                }}
                icon={
                  <FontAwesomeIcon
                    className={this.props.navStyle === NAV_STYLE_FIXED ? 'mr-2' : 'mr-5'}
                    icon={this.props.themeType === THEME_TYPE_DARK ? icons.faSun : icons.faMoon}
                    size="lg"
                  />
                }
              >
                <span className="menu-item-span">
                  <span className="menu-item-icon-text">
                    {this.props.themeType === THEME_TYPE_DARK ? 'Light Version' : 'Dark version'}
                  </span>
                </span>
              </Menu.Item>
              {this.renderMenuItem('home', '/home', icons.faHome, 'sidebar.home')}
              <Menu.SubMenu key="registerKey" title={this.getSubMenuTitle(icons.faBook, 'sidebar.register')}>
                {this.renderMenuItem('user', '/person/list', undefined, 'persons')}
                {this.renderMenuItem('products', '/products/list', undefined, 'sidebar.products')}
                {this.renderMenuItem('category', '/category/list', undefined, 'sidebar.category')}
              </Menu.SubMenu>
              <Menu.SubMenu key="sellingKey" title={this.getSubMenuTitle(icons.faCoins, 'sidebar.selling')}>
                {this.renderMenuItem('order', '/order/list', undefined, 'sidebar.order')}
                {this.renderMenuItem('OS', `/order/list?orderType=${ORDER_TYPES.SERVICE}`, undefined, 'sidebar.OS')}
                {this.renderMenuItem('calendar', `/callendar-os`, undefined, 'sidebar.calendar')}
              </Menu.SubMenu>
              <Menu.SubMenu key="configKey" title={this.getSubMenuTitle(icons.faCogs, 'sidebar.config')}>
                {this.renderMenuItem('platform', '/platform/list', undefined, 'sidebar.platforms')}
                {this.renderMenuItem('attribute', '/attribute/list', undefined, 'sidebar.attribute')}
                {this.renderMenuItem('employee', '/employee/list', undefined, 'sidebar.employee')}
                {this.renderMenuItem('storeBranch', '/storebranch/list', undefined, 'sidebar.storeBranch')}
                {this.renderMenuItem('store', '/store/list', undefined, 'sidebar.store')}
                {this.renderMenuItem('brand', '/brand/list', undefined, 'sidebar.brand')}
              </Menu.SubMenu>
              <Menu.SubMenu key="messagesKey" title={this.getSubMenuTitle(icons.faCommentDots, 'sidebar.messages')}>
                {this.renderMenuItem(
                  'template-messages',
                  '/template-messages/list',
                  undefined,
                  'sidebar.template-messages'
                )}
                {this.renderMenuItem('messages', '/messages/list', undefined, 'sidebar.messages')}
                {this.renderMenuItem('schedule', '/schedules/list', undefined, 'sidebar.schedules')}
              </Menu.SubMenu>
              <Menu.SubMenu key="ticketKey" title={this.getSubMenuTitle(icons.faTicketAlt, 'tickets')}>
                {this.renderMenuItem('tickets', '/type-tickets/list', undefined, 'typeTickets')}
                {this.renderMenuItem('tickets', '/tickets/list', undefined, 'tickets')}
              </Menu.SubMenu>
              {this.renderMenuItem('logout', '#', icons.faSignOutAlt, 'menuNameLogout', () => this.props.reset())}
              {/* sidebar-marker */}
            </Menu>
          )}
        </div>
      </Auxiliary>
    )
  }
}

SidebarContent.propTypes = {}
const mapStateToProps = ({ settings }) => {
  const { navStyle, themeType, locale, pathname } = settings
  return { navStyle, themeType, locale, pathname }
}
export default connect(mapStateToProps, {
  ...authActions,
  ...settingsActions
})(SidebarContent)
