const formatedOnlyNewCustomers = [
  {
    value: true,
    description: 'SIM'
  },
  {
    value: false,
    description: 'NÃO'
  }
]

const formatedTriggers = [
  {
    value: 'PERSON',
    description: 'PESSOA'
  },
  {
    value: 'ORDER',
    description: 'PEDIDO'
  }
]

const formatedProductType = [
  {
    value: 'GLASSES',
    description: 'ÓCULOS'
  },
  {
    value: 'LENS',
    description: 'LENTES'
  }
]

const TriggerEnum = {
  PERSON: 'PESSOA',
  'PERSON-INTERNAL': 'PESSOA',
  ORDER: 'PEDIDO'
}

export { formatedOnlyNewCustomers, formatedTriggers, TriggerEnum, formatedProductType }
