import React, { useState, useEffect } from 'react'
import { Row, Modal } from 'antd'
import { useFormik } from 'formik'
import CreateScreen from '../../components/CreateScreen'
import CustomSelect from '../../components/CustomSelect/customSelect'
import CustomInput from '../../components/CustomInput/customInput'
import queryString from 'query-string'
import { api, request } from '../../util/handlerRequestUtil'
import * as yup from 'yup'

import './createMessage.css'

function CreateMessage(props) {
  const { id } = props.match.params
  const { alert, loading, getMessage } = props
  const isDuplicate = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true
  }).duplicate
  const formik = useFormik({
    initialValues: {
      enable: true
    },
    validationSchema: yup.object().shape({
      message: yup
        .string()
        .typeError()
        .required(getMessage('isRequired', getMessage('messages'))),
      templateId: yup
        .number()
        .typeError()
        .required(getMessage('isRequired', getMessage('templateId')))
    }),
    onSubmit: values => {
      onSubmit(values)
    }
  })
  const { setValues } = formik
  const [filters, setFilters] = useState({})

  useEffect(() => {
    requests()
  }, [])

  async function requests() {
    try {
      loading.open()
      const formatedTemplateMessage = await requestTemplateMessage()
      if (id) {
        await retrieve(id)
      }
      if (isDuplicate) {
        await retrieve(isDuplicate, true)
      }
      setFilters({
        ...formatedTemplateMessage
      })
    } catch (error) {
      console.tron.log(error)
    } finally {
      loading.close()
    }
  }

  const requestTemplateMessage = () =>
    request(
      api.listTemplateMessages({
        ignorePagination: true
      }),
      true
    ).then(({ items }) => {
      return {
        formatedTemplateMessage: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.description
          }
        })
      }
    })

  function fields(isReadOnly) {
    return (
      <Row>
        <CustomInput
          disabled={isReadOnly}
          textArea
          field="message"
          rowSpan={24}
          context={formik}
          type="textarea"
          rows={10}
        />
        <div className="row">
          <CustomSelect
            context={formik}
            showSearch
            field="templateId"
            rowSpan={12}
            disabled={isReadOnly}
            values={filters.formatedTemplateMessage}
          />
          <a onClick={showTokens} className="view-tokens">
            Ver tokens
          </a>
        </div>
      </Row>
    )
  }

  function showTokens() {
    const tokens = [
      '{customer:firstname}',
      '{customer:employee}',
      '{customer:sunshinepoints}',
      '{customer:indication:name}'
    ]
    Modal.info({
      title: 'Tokens disponíveis',
      content: (
        <ul>
          {tokens.map(token => (
            <li key={token}>{token}</li>
          ))}
        </ul>
      )
    })
  }

  async function onSubmit(values) {
    try {
      if (id) {
        await request(api.updateMessage(id, values))
        alert.open(getMessage('successUpdateMessage'), 'success')
      } else {
        await request(api.storeMessages(values))
        alert.open(getMessage('successStoreMessage'), 'success')
      }
      props.history.push(`/messages/list`)
    } catch (error) {}
  }

  async function retrieve(id, isDuplicate) {
    try {
      const response = await request(api.retrieveMessage(id), true)
      if (isDuplicate) {
        delete response.id
      }
      setValues({
        ...response
      })
    } catch (error) {}
  }

  function onClickBack() {
    props.history.push('/messages/list')
  }

  return (
    <CreateScreen
      fields={fields}
      context={formik}
      editTitle={getMessage('updateMessage')}
      editTitleButton={getMessage('edit')}
      newTitle={getMessage('newMessage')}
      newTitleButton={getMessage('register')}
      onClickBack={() => onClickBack()}
      readOnlyTitle={getMessage('viewMessage')}
      readOnlyTitleButton={getMessage('edit')}
      isEdit={id}
    />
  )
}

export default CreateMessage
