import React, { useState, useEffect } from 'react'
import { Row } from 'antd'
import { useFormik } from 'formik'
import { get } from 'lodash'

import ListScreen from '../../components/ListScreen'
import { CustomInput, CustomSelect } from '../../components'
import { api, request } from '../../util/handlerRequestUtil'
import { formatTemplateMessagesDate } from '../../util/format'
import { formatedOnlyNewCustomers, formatedTriggers, TriggerEnum } from './TemplateMessageConst'

const EMPTY_TABLE_ITEM_CHARACTER = '-'

function ListTemplateMessage(props) {
  const { alert, getMessage } = props
  const formik = useFormik({
    initialValues: {}
  })
  const [modalDelete, setModalDelete] = useState({
    description: getMessage('defaultDeleteDescription'),
    visible: false
  })
  const { values, setFieldValue } = formik
  const [data, setData] = useState({})
  const [filters, setFilters] = useState({})
  const { page = 1, count = 0, pages = 0 } = data
  const templateMessages = get(data, 'items', []).map(item => {
    return {
      ...item,
      onlyNewCustomers: item.onlyNewCustomers ? getMessage('yes') : getMessage('no'),
      afterDays: item.afterDays ? item.afterDays : EMPTY_TABLE_ITEM_CHARACTER,
      afterMinutes: item.afterMinutes ? item.afterMinutes : EMPTY_TABLE_ITEM_CHARACTER
    }
  })

  const columns = [
    {
      title: getMessage('description'),
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: getMessage('trigger'),
      dataIndex: 'trigger',
      key: 'trigger',
      render: trigger => <label>{TriggerEnum[trigger] || EMPTY_TABLE_ITEM_CHARACTER}</label>
    },
    {
      title: getMessage('onlyNewCustomers'),
      dataIndex: 'onlyNewCustomers',
      key: 'onlyNewCustomers'
    },
    {
      title: getMessage('afterDays'),
      dataIndex: 'afterDays',
      key: 'afterDays'
    },
    {
      title: getMessage('afterMinutes'),
      dataIndex: 'afterMinutes',
      key: 'afterMinutes'
    },
    {
      title: getMessage('specificHour'),
      dataIndex: 'specificHour',
      key: 'specificHour',
      render: date => <label>{formatTemplateMessagesDate(date, EMPTY_TABLE_ITEM_CHARACTER)}</label>
    }
  ]

  useEffect(() => {
    requests()
  }, [])

  useEffect(() => {
    if (values.state) {
      setFieldValue('city', undefined)
    }
  }, [values.state])

  async function requests() {
    try {
      await requestData()

      const formatedCategory = await requestCategory()

      setFilters(f => ({
        ...f,
        ...formatedCategory,
        formatedOnlyNewCustomers,
        formatedTriggers
      }))
    } catch (error) {
      console.tron.log(error)
    }
  }

  function onClickEdit(templateMessage) {
    props.history.push(`/template-messages/${templateMessage.id}`)
  }

  async function onClickDelete(templateMessage) {
    setModalDelete({
      ...modalDelete,
      title: getMessage('info.delete'),
      visible: true,
      onOk: async () => onConfirmDelete(templateMessage)
    })
  }

  async function onConfirmDelete(templateMessage) {
    alert.open(getMessage('successDelete'), 'success')
    await request(api.deleteTemplateMessage(templateMessage.id))
    setModalDelete({
      ...modalDelete,
      visible: false
    })
    await requestData(values)
  }

  function onClickView(templateMessage) {
    props.history.push(`/template-messages/${templateMessage.id}?readOnly=true`)
  }

  function onClickOpenOthertab(templateMessage) {
    const win = window.open(`/template-messages/${templateMessage.id}`)
    win.focus()
  }

  function onClickDuplicate(templateMessage) {
    props.history.push(`/template-messages/create?duplicate=${templateMessage.id}`)
  }

  const requestData = (params = {}) => request(api.listTemplateMessages({ ...params })).then(data => setData(data))

  const requestCategory = () =>
    request(
      api.listCategory({
        ignorePagination: true
      }),
      true
    ).then(({ items }) => {
      return {
        formatedCategory: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name
          }
        })
      }
    })

  const handleFilter = value => {
    const { description, trigger, onlyNewCustomers, categoryId } = values

    const filters = {
      ...(description && { description }),
      ...(trigger && { trigger }),
      ...(onlyNewCustomers && { onlyNewCustomers }),
      ...(categoryId && { categoryId })
    }

    if (value && value.trim() !== '') {
      filters.description = value
    }

    requestData({
      ...filters
    })
  }

  const onSearch = value => handleFilter(value)

  async function requestPage(page) {
    await requestData({ page: page - 1 })
  }

  const onChangeCreateButton = () => {
    props.history.push('/template-messages/create')
  }

  function filtersList() {
    return (
      <Row type="flex" justify="start">
        <CustomInput field="description" rowSpan={12} context={formik} />
        <CustomSelect context={formik} showSearch field="trigger" rowSpan={12} values={filters.formatedTriggers} />
        <CustomSelect context={formik} showSearch field="categoryId" rowSpan={12} values={filters.formatedCategory} />
        <CustomSelect
          context={formik}
          showSearch
          field="onlyNewCustomers"
          rowSpan={12}
          values={filters.formatedOnlyNewCustomers}
        />
      </Row>
    )
  }

  const validFieldsKeys = ['description', 'trigger', 'categoryId', 'onlyNewCustomers']

  return (
    <ListScreen
      context={formik}
      columns={columns}
      data={templateMessages}
      title={getMessage('sidebar.template-messages')}
      titleButtonAdd={getMessage('new')}
      onSearch={onSearch}
      filtersList={filtersList}
      validFieldsKeys={validFieldsKeys}
      dataFilters={filters}
      onChange={requestPage}
      onChangeCreateButton={onChangeCreateButton}
      searchPlaceholder={getMessage('description')}
      pagination={{
        currentPage: page + 1,
        pages: pages,
        total: count
      }}
      onFilter={handleFilter}
      onClickDelete={onClickDelete}
      modalDelete={modalDelete}
      setModalDelete={setModalDelete}
      onClickEdit={onClickEdit}
      onClickView={onClickView}
      onClickDuplicate={onClickDuplicate}
      onClickOpenOthertab={onClickOpenOthertab}
    />
  )
}

export default ListTemplateMessage
