import React, { useState } from 'react'
import { Col, Row, Button, Divider, Table, Modal } from 'antd'
import { useFormik } from 'formik'
import { CustomInput, CustomSelect } from '../../../components'
import { getMessage } from '../../../util/IntlMessages'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'
import * as yup from 'yup'

import './stepContacts.css'

const ditionaryType = {
  PHONE: 'Telefone',
  EMAIL: 'E-mail'
}

const Contacts = props => {
  const { isReadOnly, setContacts, dataToPost, options } = props
  const [btnTitle, setBtnTitle] = useState('add')

  const form = useFormik({
    initialValues: {},
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object().shape({
      type: yup
        .string()
        .typeError()
        .required(getMessage('isRequired', getMessage('type'))),
      value: yup
        .string()
        .typeError()
        .required(getMessage('isRequired', getMessage('value')))
    }),
    onSubmit: values => {
      if (btnTitle === 'add') {
        addEntry(values)
      } else {
        editEntry(values)
      }
    }
  })

  const {
    values: { type },
    submitForm,
    setValues
  } = form
  const data = dataToPost.contacts
  const setData = value =>
    setContacts({
      ...dataToPost,
      contacts: value
    })
  var ID = function() {
    return (
      '_' +
      Math.random()
        .toString(36)
        .substr(2, 9)
    )
  }

  const addEntry = values => {
    const { ...rest } = values

    const dataToAdd = {
      key: ID(),
      ...rest
    }
    setData([...data, dataToAdd])
    setValues({})
  }

  const deleteEntry = index => {
    Modal.confirm({
      title: getMessage('infoDelete'),
      onOk() {
        const contacts = data.filter((c, i) => i !== index)
        setData(contacts)
      }
    })
  }

  const enableEdit = (index, param) => {
    setValues({
      ...param,
      index
    })
    setBtnTitle('edit')
  }

  const editEntry = values => {
    const { index, ...rest } = values

    const data = {
      key: ID(),
      ...rest
    }
    const newData = [...dataToPost.contacts]
    newData[index] = { ...newData[index], ...data }
    setData(newData)

    setValues({})
    setBtnTitle('add')
  }

  function handlerChange(index) {
    return (
      <div className={isReadOnly && 'disabled'}>
        <FontAwesomeIcon
          className="mr5"
          icon={faEdit}
          size="lg"
          onClick={() => enableEdit(index, dataToPost.contacts[index])}
        />
        <FontAwesomeIcon icon={faTrash} size="lg" onClick={() => deleteEntry(index)} />
      </div>
    )
  }

  const columns = [
    {
      title: getMessage('type'),
      dataIndex: 'type',
      align: 'center'
    },
    {
      title: getMessage('value'),
      dataIndex: 'value',
      align: 'center'
    },
    {
      title: getMessage('description'),
      dataIndex: 'description',
      align: 'center'
    },
    {
      title: getMessage('actions'),
      dataIndex: 'actions',
      align: 'center',
      render: function(text, row, index) {
        return handlerChange(index, row)
      }
    }
  ]

  return (
    <Row className="container__contacts" type="flex" justify="start">
      <CustomSelect
        context={form}
        field="type"
        rowSpan={8}
        values={options.contactTypes}
        disabled={isReadOnly}
        required
      />
      <CustomInput
        context={form}
        field="value"
        rowSpan={8}
        disabled={isReadOnly}
        mask={type !== 'EMAIL' ? '(11) 1 1111-1111' : ''}
        required
      />
      <CustomInput context={form} field="description" rowSpan={8} disabled={isReadOnly} />
      <Col className="add__contact" span={24}>
        <Button type="link" onClick={() => setValues({})} disabled={isReadOnly}>
          {getMessage('clean')}
        </Button>
        <Button type="primary" onClick={() => submitForm()} disabled={isReadOnly}>
          {getMessage(btnTitle)}
        </Button>
      </Col>
      <Divider />
      <Table
        className="tb__contacts"
        dataSource={dataToPost.contacts.map(item => ({
          ...item,
          type: ditionaryType[item.type]
        }))}
        locale={{ emptyText: getMessage('noRecords') }}
        rowKey="key"
        columns={columns}
        bordered
        scroll={{ x: 600 }}
        pagination={false}
      />
    </Row>
  )
}

export default Contacts
