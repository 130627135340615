import React, { useState, useEffect } from 'react'
import { Row } from 'antd'
import { useFormik } from 'formik'
import CreateScreen from '../../components/CreateScreen'
import CustomInput from '../../components/CustomInput/customInput'
import queryString from 'query-string'
import { api, request } from '../../util/handlerRequestUtil'
import * as yup from 'yup'

function CreateTypeTicket(props) {
  const { id } = props.match.params
  const { alert, loading, getMessage } = props
  const isDuplicate = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true
  }).duplicate
  const formik = useFormik({
    initialValues: {
      enable: true
    },
    validationSchema: yup.object().shape({
      name: yup
        .string()
        .typeError()
        .required(getMessage('isRequired', getMessage('name')))
    }),
    onSubmit: values => {
      onSubmit(values)
    }
  })
  const { setValues } = formik
  const [filters, setFilters] = useState({})

  useEffect(() => {
    requests()
  }, [])

  async function requests() {
    try {
      loading.open()
      if (id) {
        await retrieve(id)
      }
      if (isDuplicate) {
        await retrieve(isDuplicate, true)
      }
      setFilters({})
    } catch (error) {
      console.tron.log(error)
    } finally {
      loading.close()
    }
  }

  function fields(isReadOnly) {
    return (
      <Row type="flex" justify="start" align="middle">
        <CustomInput disabled={isReadOnly} field="name" rowSpan={24} context={formik} />
      </Row>
    )
  }

  async function onSubmit(values) {
    try {
      if (id) {
        await request(api.updateTypeTicket(id, values))
        alert.open(getMessage('successTypeTicketUpdate'), 'success')
      } else {
        await request(api.storeTypeTicket(values))
        alert.open(getMessage('successTypeTicketStore'), 'success')
      }
      props.history.push(`/type-tickets/list`)
    } catch (error) {}
  }

  async function retrieve(id, isDuplicate) {
    try {
      const response = await request(api.retrieveTypeTicket(id), true)
      if (isDuplicate) {
        delete response.id
      }
      setValues({
        ...response
      })
    } catch (error) {}
  }

  function onClickBack() {
    props.history.push('/type-tickets/list')
  }

  return (
    <CreateScreen
      fields={fields}
      context={formik}
      editTitle={getMessage('updateTypeTicket')}
      editTitleButton={getMessage('edit')}
      newTitle={getMessage('newTypeTicket')}
      newTitleButton={getMessage('register')}
      onClickBack={() => onClickBack()}
      readOnlyTitle={getMessage('viewTypeTicket')}
      readOnlyTitleButton={getMessage('edit')}
      isEdit={id}
    />
  )
}

export default CreateTypeTicket
