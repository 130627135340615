import React, { useState, useEffect } from 'react'
import { Row } from 'antd'
import { useFormik } from 'formik'
import _ from 'lodash'

import ListScreen from '../../components/ListScreen'
import CustomInput from '../../components/CustomInput/customInput'
import { api, request } from '../../util/handlerRequestUtil'
import './listProvider.css'

function ListProvider(props) {
  const { alert, getMessage } = props
  const formik = useFormik({
    initialValues: {}
  })
  const [modalDelete, setModalDelete] = useState({
    description: getMessage('defaultDeleteDescription'),
    visible: false
  })
  const { values } = formik
  const [data, setData] = useState({})
  const [filters, setFilters] = useState({})
  const { page = 1, pageSize = 10, count = 0, pages = 0 } = data
  const provider = _.get(data, 'items', []).map(item => {
    return {
      key: item.id,
      ...item
    }
  })
  const columns = [
    {
      title: getMessage('name'),
      dataIndex: 'name',
      key: 'name'
    }
    // cli-columns
  ]

  useEffect(() => {
    requests()
  }, [])

  //requests
  async function requests() {
    try {
      // cli-request-function
      await requestData()

      setFilters({
        // cli-request-filter
      })
    } catch (error) {
      console.tron.log(error)
    }
  }

  function onClickEdit(provider) {
    props.history.push(`/provider/${provider.key}`)
  }

  async function onClickDelete(provider) {
    setModalDelete({
      ...modalDelete,
      title: getMessage('deleteProviderTitle', provider.name),
      visible: true,
      onOk: async () => onConfirmDelete(provider)
    })
  }

  async function onConfirmDelete(provider) {
    alert.open(getMessage('successProviderDelete'), 'success')
    await request(api.deleteProvider(provider.key))
    setModalDelete({
      ...modalDelete,
      visible: false
    })
    await requestData(values)
  }

  function onClickView(provider) {
    props.history.push(`/provider/${provider.key}?readOnly=true`)
  }

  function onClickDuplicate(provider) {
    props.history.push(`/provider/create?duplicate=${provider.key}`)
  }

  const requestData = (params = {}) => request(api.listProvider({ ...params })).then(data => setData(data))

  // cli-requests

  //functions
  function handleFilter(value) {
    let filters = {}
    const {
      cnpj

      // cli-handler-filters-destruturing
    } = values
    if (cnpj && cnpj.trim() !== '') {
      filters.cnpj = cnpj
    }

    // cli-handle-filters
    if (value && value.trim() !== '') {
      filters.name = value
    }
    requestData({
      ...filters
    })
  }

  const onSearch = value => handleFilter(value)

  async function requestPage(page) {
    await requestData({ page: page - 1 })
  }

  const onChangeCreateButton = () => {
    props.history.push('/provider/create')
  }

  function filtersList() {
    return (
      <Row type="flex" justify="start">
        <CustomInput field="cnpj" rowSpan={12} context={formik} />

        {/* cli-filters */}
      </Row>
    )
  }
  return (
    <ListScreen
      context={formik}
      columns={columns}
      data={provider}
      title={getMessage('provider')}
      titleButtonAdd={getMessage('newProvider')}
      onSearch={onSearch}
      filtersList={filtersList}
      dataFilters={filters}
      onChange={requestPage}
      onChangeCreateButton={onChangeCreateButton}
      pagination={{
        currentPage: page + 1,
        pages: pages,
        total: count,
        pageSize: pageSize
      }}
      onFilter={handleFilter}
      onClickDelete={onClickDelete}
      modalDelete={modalDelete}
      setModalDelete={setModalDelete}
      onClickEdit={onClickEdit}
      onClickView={onClickView}
      onClickDuplicate={onClickDuplicate}
    />
  )
}

export default ListProvider
