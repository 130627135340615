import React, { useState, useEffect } from 'react'
import { Col, Row, Button, Divider, Table, Modal } from 'antd'
import { useFormik } from 'formik'
import { CustomInput, CustomSelect } from '../../../components'
import { getMessage } from '../../../util/IntlMessages'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'
import { api, request } from '../../../util/handlerRequestUtil'
import { get } from 'lodash'
import * as yup from 'yup'

import './stepAddress.css'

const Address = props => {
  const { isReadOnly, setAddresses, dataToPost, id, isDuplicate } = props
  const [data, setData] = useState([])
  const [btnTitle, setBtnTitle] = useState('add')
  const [filters, setFilters] = useState({
    states: [],
    cities: []
  })

  const form = useFormik({
    initialValues: {},
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object().shape({
      zipCode: yup
        .string()
        .typeError()
        .required(getMessage('isRequired', getMessage('zipCode'))),
      street: yup
        .string()
        .typeError()
        .required(getMessage('isRequired', getMessage('street'))),
      number: yup
        .string()
        .typeError()
        .required(getMessage('isRequired', getMessage('number'))),
      neighborhood: yup
        .string()
        .typeError()
        .required(getMessage('isRequired', getMessage('neighborhood'))),
      cityName: yup
        .string()
        .typeError()
        .required(getMessage('isRequired', getMessage('city')))
    }),
    onSubmit: values => {
      if (btnTitle === 'add') {
        addEntry(values)
      } else {
        editEntry(values)
      }
    }
  })

  const { submitForm, setValues, setFieldValue, values } = form

  var ID = function() {
    return (
      '_' +
      Math.random()
        .toString(36)
        .substr(2, 9)
    )
  }

  const addEntry = values => {
    const { cityName, state, ...rest } = values
    const data = {
      key: ID(),
      state: filters.states.find(c => c.value === state).description,
      cityName: filters.cities.find(c => c.value === cityName).description,
      cityId: cityName,
      ...rest
    }
    setData(r => [...r, data])
    setValues({})
  }

  const deleteEntry = index => {
    Modal.confirm({
      title: getMessage('infoDelete'),
      onOk() {
        const contacts = data.filter((c, i) => i !== index)
        setData(contacts)
      }
    })
  }

  const enableEdit = (index, { state, ...rest }) => {
    setValues({
      state: filters.states.find(c => c.description === state).value,
      index,
      ...rest
    })
    setBtnTitle('edit')
  }

  const editEntry = values => {
    const { cityName, state, index, ...rest } = values
    const data = {
      key: ID(),
      state: filters.states.find(c => c.value === state).description,
      cityName: filters.cities.find(c => c.value === cityName).description,
      ...rest
    }
    setData(r => {
      const newData = [...r]
      newData[index] = data
      return newData
    })

    setValues({})
    setBtnTitle('add')
  }

  function handlerChange(index, row) {
    return (
      <div className={isReadOnly && 'disabled'}>
        <FontAwesomeIcon className="mr5" icon={faEdit} size="lg" onClick={() => enableEdit(index, row)} />
        <FontAwesomeIcon icon={faTrash} size="lg" onClick={() => deleteEntry(index)} />
      </div>
    )
  }

  const columns = [
    {
      key: 'description',
      title: getMessage('description'),
      dataIndex: 'description',
      align: 'center'
    },
    {
      key: 'zipCode',
      title: getMessage('zipCode'),
      dataIndex: 'zipCode',
      align: 'center'
    },
    {
      key: 'street',
      title: getMessage('street'),
      dataIndex: 'street',
      align: 'center'
    },
    {
      key: 'number',
      title: getMessage('number'),
      dataIndex: 'number',
      align: 'center'
    },
    {
      key: 'neighborhood',
      title: getMessage('neighborhood'),
      dataIndex: 'neighborhood',
      align: 'center'
    },
    {
      key: 'complement',
      title: getMessage('complement'),
      dataIndex: 'complement',
      align: 'center'
    },
    {
      key: 'state',
      title: getMessage('state'),
      dataIndex: 'state',
      align: 'center'
    },
    {
      key: 'cityName',
      title: getMessage('cityName'),
      dataIndex: 'cityName',
      align: 'center'
    },
    {
      key: 'actions',
      title: getMessage('actions'),
      dataIndex: 'actions',
      align: 'center',
      render: function(text, row, index) {
        return handlerChange(index, row)
      }
    }
  ]

  async function requestState(skip) {
    try {
      const states = await request(api.listState({ ignorePagination: true }), skip)
      const format = get(states, 'items', []).map(s => ({
        value: s.stateAbbreviation,
        description: s.stateName
      }))

      setFilters(f => ({
        ...f,
        ...{ states: format }
      }))
    } catch (error) {
      console.tron.log(error)
    }
  }

  async function requestCity(state, skip) {
    try {
      const cities = await request(api.listCity({ ignorePagination: true, stateAbbreviation: state }), skip)
      const format = get(cities, 'items', []).map(c => ({
        value: c.id,
        description: c.cityName
      }))

      setFilters(f => ({
        ...f,
        ...{ cities: format }
      }))
    } catch (error) {
      console.tron.log(error)
    }
  }

  useEffect(() => {
    if (id || isDuplicate) {
      let addresses = dataToPost.addresses.map(c => ({
        street: c.streetName,
        ...c
      }))
      setData(addresses)
    }
  }, [])

  useEffect(() => {
    //duvida payload ao montar tela
    let addresses = []
    if (data.length > 0) {
      addresses = data.map(c => ({
        ...((id || isDuplicate) && { id: c.id }),
        ...(c.city && { city: c.city }),
        ...(c.description && { description: c.description }),
        ...(c.complement && { complement: c.complement }),
        neighborhood: c.neighborhood,
        cityId: Number(c.cityId),
        zipCode: c.zipCode.replace(/[^0-9]+/g, ''),
        streetName: c.street,
        number: c.number
      }))
    }

    setAddresses({
      ...dataToPost,
      addresses
    })
  }, [data])

  useEffect(() => {
    if (values.state) {
      setFieldValue('cityName', undefined)
      requestCity(values.state)
    }
  }, [values.state])

  useEffect(() => {
    requestState(true)
  }, [])

  return (
    <Row className="container__address" type="flex" justify="start">
      <CustomInput context={form} field="description" rowSpan={6} disabled={isReadOnly} />
      <CustomInput context={form} field="zipCode" rowSpan={6} disabled={isReadOnly} mask="11111-111" required />
      <CustomInput context={form} field="street" rowSpan={12} disabled={isReadOnly} required />
      <CustomInput context={form} field="number" rowSpan={6} disabled={isReadOnly} required />
      <CustomInput context={form} field="neighborhood" rowSpan={6} disabled={isReadOnly} required />
      <CustomInput context={form} field="complement" rowSpan={12} disabled={isReadOnly} />
      <CustomSelect context={form} field="state" rowSpan={6} disabled={isReadOnly} values={filters.states} />
      <CustomSelect
        context={form}
        field="cityName"
        rowSpan={10}
        disabled={isReadOnly || !values.state}
        values={filters.cities}
        required
      />
      <Col className="add__address" xs={24} sm={24} md={8} lg={8} xl={8}>
        <Button type="link" onClick={() => setValues({})} disabled={isReadOnly}>
          {getMessage('clean')}
        </Button>
        <Button type="primary" onClick={() => submitForm()} disabled={isReadOnly}>
          {getMessage(btnTitle)}
        </Button>
      </Col>
      <Divider />
      <Table
        className="tb__address"
        dataSource={data}
        locale={{ emptyText: getMessage('noRecords') }}
        rowKey="key"
        columns={columns}
        bordered
        scroll={{ x: 'calc(1500px + 50%)' }}
        pagination={false}
      />
    </Row>
  )
}

export default Address
