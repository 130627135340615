import React from 'react'
import { Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatPrice } from '../../../../util/format'
import { get } from 'lodash'
import * as icons from '@fortawesome/free-solid-svg-icons'
import notFound from '../../../../assets/images/noImage2.png'
import Image from '../../../../components/Helper/Image'
import './productModalVariations.css'

export function Variations(props) {
  const { variations = [], images = [], amount, handleMinus, handlePlus, manageStock } = props

  const getAmount = i => get(amount, `[${i}]['qtd']`, 0)

  const getImg = id => {
    if (images.length > 0) {
      const imgLocation = images.find(i => i.id === id)

      if (imgLocation) return imgLocation.location
      return notFound
    }

    return notFound
  }

  const getVariationStockAlert = (qtd = 0) => {
    if (qtd === 0) return 'Indisponível'
    const txt = qtd === 1 ? 'disponível' : 'disponíveis'

    return `${qtd} ${txt}`
  }

  return (
    <div className="container-variations">
      {variations.map((v, i) => {
        return (
          <div key={v.id} className="content-variations">
            <div className="top">
              <Image src={getImg(v.imageId)} alt="thumbnail" />
              <div>
                {v.attributes.length > 0 && <span>{v.attributes.map(item => (item.name ? `${item.name} ` : ''))}</span>}
                <span>{formatPrice(amount[i].finalPrice || v.price)}</span>
              </div>
            </div>
            <div className="bottom">
              <div>
                <Button disabled={getAmount(i) === 0} onClick={() => handleMinus(i)}>
                  <FontAwesomeIcon icon={icons.faMinus} />
                </Button>
                <span>{getAmount(i)}</span>
                <Button disabled={manageStock ? getAmount(i) === v.stockQuantity : false} onClick={() => handlePlus(i)}>
                  <FontAwesomeIcon icon={icons.faPlus} />
                </Button>
              </div>
              {manageStock && <span>{getVariationStockAlert(v.stockQuantity)}</span>}
            </div>
          </div>
        )
      })}
    </div>
  )
}
