import React from 'react'
import { Popover } from 'antd'

const CustomPopover = function(props) {
  const { content, children, placement, trigger, title } = props
  return (
    <Popover
      content={content}
      title={title || ''}
      placement={placement || 'left'}
      trigger={trigger || 'click'}
      getPopupContainer={trigger => trigger.parentNode}
      {...props}
    >
      {children}
    </Popover>
  )
}

export default CustomPopover
