import React, { useState, useEffect } from 'react'
import { Modal, Table, Button, Col, Form, Row, Divider } from 'antd'
import { columnsTitle } from './utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCheck } from '@fortawesome/free-solid-svg-icons'
import { getMessage } from '../../util/IntlMessages'
import { useFormik, FormikProvider } from 'formik'
import { api, request } from '../../util/handlerRequestUtil'
import { CustomInput, Pagination } from '../index'
import { onlyNumbers, phoneMask } from '../../util/format'

import './styles.css'

const { NAME, EMAIL, PHONE, ACTIONS } = columnsTitle

function ModalCustomerSelect(props) {
  const { visible, setVisible, setPerson } = props

  const [loading, setLoading] = useState(false)
  const [dataTable, setDataTable] = useState([])
  const [queryDetails, setQueryDetails] = useState({})

  const form = useFormik({
    initialValues: {},
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async values => {
      search(values)
    }
  })

  const { values, submitForm } = form

  const search = async (values, params) => {
    try {
      setLoading(true)
      const { name, phone, email } = values

      const payload = {
        ...(name && { name }),
        ...(phone && { phone: onlyNumbers(phone) }),
        ...(email && { email }),
        ...params
      }

      const { items, page = 0, count = 0, pages = 0 } = await request(api.listPerson(payload), { skipLoading: true })

      const serchFirstPhone = (contacts = []) => {
        const hasPhone = contacts.find(contact => contact.type === 'PHONE')

        return hasPhone ? onlyNumbers(hasPhone.value) : null
      }

      const data = items.map(({ id, name, email, contacts }) => ({
        id,
        name,
        email,
        phone: serchFirstPhone(contacts)
      }))

      setDataTable(data)
      setQueryDetails({ page, count, pages })
    } catch (error) {
      console.tron.log(error)
    } finally {
      setLoading(false)
    }
  }

  const onChangePage = page => search(values, { page: page - 1 })

  const handleCancel = () => setVisible(false)

  const handleClear = () => {
    const newData = dataTable.map(person => {
      return {
        ...person,
        selected: false
      }
    })

    setPerson({})
    setDataTable(newData)
  }

  const setSelectedPerson = ({ id }) => {
    const newData = dataTable.map(person => {
      if (person.id === id) {
        setPerson(person)

        return {
          ...person,
          selected: true
        }
      }

      return {
        ...person,
        selected: false
      }
    })

    setDataTable(newData)
  }

  const formatActions = (value, record) => {
    return record.selected ? (
      <FontAwesomeIcon icon={faCheck} />
    ) : (
      <FontAwesomeIcon
        icon={faPlus}
        onClick={() => {
          setSelectedPerson(record)
          setVisible(false)
        }}
      />
    )
  }

  const handleColumn = value => {
    return phoneMask(value)
  }

  const defaultColumnAtributtes = value => ({
    title: getMessage(value),
    dataIndex: value
  })

  const columns = [
    defaultColumnAtributtes(NAME),
    defaultColumnAtributtes(EMAIL),
    {
      ...defaultColumnAtributtes(PHONE),
      render: value => {
        return handleColumn(value)
      }
    },
    {
      ...defaultColumnAtributtes(ACTIONS),
      align: 'center',
      width: 75,
      fixed: 'right',
      render: (value, record) => {
        return formatActions(value, record)
      }
    }
  ]

  useEffect(() => {
    submitForm()
  }, [])

  return (
    <Modal
      zIndex={3000}
      className="modal__selectPerson"
      visible={visible}
      footer={null}
      onCancel={() => handleCancel()}
    >
      <FormikProvider value={form}>
        <Form layout="vertical">
          <Row justify="start">
            <CustomInput context={form} field="name" rowSpan={12} />
            <CustomInput context={form} field="phone" rowSpan={12} mask="(11) 11111-1111" />
            <CustomInput context={form} field="email" rowSpan={24} />
          </Row>

          <Col span={24} className="btn__search__person">
            <Button type="primary" onClick={() => submitForm()}>
              {getMessage('search')}
            </Button>
          </Col>
        </Form>

        <div className="divider">
          <Divider />
        </div>

        {dataTable.length > 0 && (
          <Col span={24} className="col__pagination">
            <Pagination
              currentPage={queryDetails.page + 1}
              pages={queryDetails.pages}
              total={queryDetails.count}
              onChange={onChangePage}
            />
          </Col>
        )}

        <Table
          rowKey="id"
          className="table"
          locale={{ emptyText: getMessage('noRecords') }}
          columns={columns}
          dataSource={dataTable}
          bordered
          loading={loading}
          scroll={{ x: 800, y: 300 }}
          pagination={false}
        />

        <Row justify="end">
          <Col span={24} className={'mr5 col-btns'}>
            <Button className={'mr5 button-cancel'} type="primary" onClick={() => handleClear()}>
              {getMessage('clear')}
            </Button>
            <Button type="primary" onClick={() => handleCancel()}>
              {getMessage('close')}
            </Button>
          </Col>
        </Row>
      </FormikProvider>
    </Modal>
  )
}

export default ModalCustomerSelect
