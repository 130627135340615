import React, { useState, useEffect } from 'react'
import { Row } from 'antd'
import { useFormik } from 'formik'
import { CustomInput, CustomSelect } from '../../components'
import { getMessage } from '../../util/IntlMessages'
import { api, request } from '../../util/handlerRequestUtil'
import { get } from 'lodash'
import * as yup from 'yup'

import './stepAddress.css'

const Address = props => {
  const { isReadOnly, setAddress, dataToPost, id, isDuplicate, submitting, validating, submit } = props
  const [filters, setFilters] = useState({
    states: [],
    cities: []
  })

  const form = useFormik({
    initialValues: {},
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object().shape({
      zipCode: yup
        .string()
        .typeError()
        .required(getMessage('isRequired', getMessage('zipCode'))),
      street: yup
        .string()
        .typeError()
        .required(getMessage('isRequired', getMessage('street'))),
      number: yup
        .string()
        .typeError()
        .required(getMessage('isRequired', getMessage('number'))),
      neighborhood: yup
        .string()
        .typeError()
        .required(getMessage('isRequired', getMessage('neighborhood'))),
      cityName: yup
        .string()
        .typeError()
        .required(getMessage('isRequired', getMessage('city')))
    }),
    onSubmit: values => {
        onSubmit(values)
    }
  })

  const { submitForm, setFieldValue, setValues, validateForm, values } = form

  const onSubmit = values => {
    const { 
      street,
      complement,
      description,
      cityName,
      neighborhood,
      number, 
      zipCode
    } = values
    setAddress('address',
      { 
        streetName: street,
        cityId: Number(cityName),
        complement,
        description,
        neighborhood,
        number,
        zipCode
      })
    submit()
  }

  async function requestState(skip) {
    try {
      const states = await request(api.listState({ ignorePagination: true }), skip)
      const format = get(states, 'items', []).map(s => ({
        value: s.stateAbbreviation,
        description: s.stateName
      }))

      setFilters(f => ({
        ...f,
        ...{ states: format }
      }))
    } catch (error) {
      console.tron.log(error)
    }
  }

  async function requestCity(state, skip) {
    try {
      const cities = await request(api.listCity({ ignorePagination: true, stateAbbreviation: state }), skip)
      const format = get(cities, 'items', []).map(c => ({
        value: c.id,
        description: c.cityName
      }))

      setFilters(f => ({
        ...f,
        ...{ cities: format }
      }))
    } catch (error) {
      console.tron.log(error)
    }
  }

  useEffect(() => {
    if (submitting) {
      submitForm()
    }
  }, [submitting])

  useEffect(() => {
    if (validating) {
      validateForm()
    }
  }, [validating])

  useEffect(() => {
    if (id || isDuplicate) {
      let address = dataToPost.address

      if (address === undefined) return

      if (address.city) {
        setValues({ ...address, street: address.streetName, state: address.city.stateAbbreviation, cityName: String(address.cityId) })

        return
      }

      setAddress('address', address)
      setValues({ ...address, street: address.streetName, cityName: String(address.cityId) })
    }
  }, dataToPost)

  useEffect(() => {
    if (values.state) {
      if (dataToPost.address) {
        if (dataToPost.address.city.stateAbbreviation !== values.state) {
          setFieldValue('cityName', undefined)
        }
      }

      if (!(id ^ isDuplicate)) {
        setFieldValue('cityName', undefined)
      }

      requestCity(values.state)
    }
  }, [values.state])

  useEffect(() => {
    requestState(true)
  }, [])

  return (
    <Row className="container__address" type="flex" justify="start">
      <CustomInput context={form} field="zipCode" mask="11111111" rowSpan={6} disabled={isReadOnly} required />
      <CustomInput context={form} field="street" rowSpan={12} disabled={isReadOnly} required />
      <CustomInput context={form} field="number" rowSpan={6} disabled={isReadOnly} required />
      <CustomInput context={form} field="neighborhood" rowSpan={12} disabled={isReadOnly} required />
      <CustomInput context={form} field="complement" rowSpan={12} disabled={isReadOnly} />
      <CustomSelect context={form} field="state" rowSpan={6} disabled={isReadOnly} values={filters.states} />
      <CustomSelect
        context={form}
        field="cityName"
        rowSpan={10}
        disabled={isReadOnly || !values.state}
        values={filters.cities}
        required
      />
    </Row>
  )
}

export default Address
