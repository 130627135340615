import React, { useState, useEffect } from 'react'
import { Row } from 'antd'
import { useFormik } from 'formik'
import { api, request } from '../../util/handlerRequestUtil'
import _ from 'lodash'

import ListScreen from '../../components/ListScreen'
import CustomSelect from '../../components/CustomSelect/customSelect'
import './listStoreBranch.css'

function ListStoreBranch(props) {
  const { alert, getMessage } = props
  const formik = useFormik({
    initialValues: {}
  })
  const [modalDelete, setModalDelete] = useState({
    description: getMessage('defaultDeleteDescription'),
    visible: false
  })
  const { values } = formik
  const [data, setData] = useState({})
  const [filters, setFilters] = useState({})
  const { page = 1, pageSize = 10, count = 0, pages = 0 } = data
  const storebranch = _.get(data, 'items', []).map(item => {
    return {
      key: item.id,
      ...item,
      addressId: _.get(item, 'address.street_name'),
      storeId: _.get(item, 'store.name')
      // cli-formatter-table
    }
  })
  const columns = [
    {
      title: getMessage('name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: getMessage('store'),
      dataIndex: 'storeId',
      key: 'storeId'
    }
    // cli-columns
  ]

  useEffect(() => {
    requests()
  }, [])

  //requests
  async function requests() {
    try {
      const formatedStore = await requestStore()

      // cli-request-function
      await requestData()

      setFilters({
        ...formatedStore

        // cli-request-filter
      })
    } catch (error) {
      console.tron.log(error)
    }
  }

  function onClickEdit(storebranch) {
    props.history.push(`/storebranch/${storebranch.key}`)
  }

  async function onClickDelete(storebranch) {
    setModalDelete({
      ...modalDelete,
      title: getMessage('deleteStoreBranchTitle', storebranch.name),
      visible: true,
      onOk: async () => onConfirmDelete(storebranch)
    })
  }

  async function onConfirmDelete(storebranch) {
    alert.open(getMessage('successStoreBranchDelete'), 'success')
    await request(api.deleteStoreBranch(storebranch.key))
    setModalDelete({
      ...modalDelete,
      visible: false
    })
    await requestData(values)
  }

  function onClickView(storebranch) {
    props.history.push(`/storebranch/${storebranch.key}?readOnly=true`)
  }

  function onClickDuplicate(storebranch) {
    props.history.push(`/storebranch/create?duplicate=${storebranch.key}`)
  }

  const requestData = (params = {}) =>
    request(
      api.listStoreBranch({
        ...params,
        include: `
    // cli-include
store,
address,
    `
      })
    ).then(data => setData(data))

  const requestStore = () =>
    request(
      api.listStore({
        ignorePagination: true
      })
    ).then(({ items }) => {
      return {
        formatedStore: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.name
          }
        })
      }
    })

  // cli-requests

  //functions
  function handleFilter(value) {
    let filters = {}
    const {
      store

      // cli-handler-filters-destruturing
    } = values
    if (store) {
      filters.storeId = store
    }

    // cli-handle-filters
    if (value && value.trim() !== '') {
      filters.name = value
    }
    requestData({
      ...filters
    })
  }

  const onSearch = value => handleFilter(value)

  async function requestPage(page) {
    await requestData({ page: page - 1 })
  }

  const onChangeCreateButton = () => {
    props.history.push('/storebranch/create')
  }

  function filtersList() {
    return (
      <Row type="flex" justify="start">
        <CustomSelect context={formik} showSearch field="store" rowSpan={12} values={filters.formatedStore} />

        {/* cli-filters */}
      </Row>
    )
  }
  return (
    <ListScreen
      context={formik}
      columns={columns}
      data={storebranch}
      title={getMessage('storebranch')}
      titleButtonAdd={getMessage('newStoreBranch')}
      onSearch={onSearch}
      filtersList={filtersList}
      dataFilters={filters}
      onChange={requestPage}
      onChangeCreateButton={onChangeCreateButton}
      pagination={{
        currentPage: page + 1,
        pages: pages,
        total: count,
        pageSize: pageSize
      }}
      onFilter={handleFilter}
      onClickDelete={onClickDelete}
      modalDelete={modalDelete}
      setModalDelete={setModalDelete}
      onClickEdit={onClickEdit}
      onClickView={onClickView}
      onClickDuplicate={onClickDuplicate}
    />
  )
}

export default ListStoreBranch
