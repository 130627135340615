import React from 'react'
import styles from './Image.module.css'

const Image = ({ alt, className, ...props }) => {
  const [skeleton, setSkeleton] = React.useState(true)

  function handleLoad({ target }) {
    setSkeleton(false)
    target.style.opacity = 1
  }

  return (
    <div className={styles.wrapper}>
      {skeleton && <div className={styles.skeleton}></div>}
      <img className={`${styles.img} ${className ? className : ''}`} alt={alt} onLoad={handleLoad} {...props} />
    </div>
  )
}

export default Image
