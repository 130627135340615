import React, { useEffect, useState } from 'react'

import '@ant-design/compatible/assets/index.css'
import { Col, Row } from 'antd'
import IconWithTextCard from 'components/Metrics/IconWithTextCard'
import './styles.css'
import { useFormik, FormikProvider } from 'formik'
import CustomSelect from '../../components/CustomSelect/customSelect'
import CustomInput from '../../components/CustomInput/customInput'
import * as yup from 'yup'
import { Card, Form, Button } from 'antd'
import types from './groupDashboard'
import moment from 'moment-timezone'
import { api, request } from '../../util/handlerRequestUtil'
import get from 'lodash.get'
import { If } from 'react-extras'
import 'react-activity/dist/react-activity.css'
import NumberFormat from 'react-number-format'
import { getGroupField, getArrayGroupField } from '../../util/chartsUtil'
import LineChart from '../../components/Charts/Line'
import PieChart from '../../components/Charts/Pie'
import BarChart from '../../components/Charts/Bar'
const DashboardOrders = function (props) {
  const { getMessage, alert } = props
  const [detail, setDetail] = useState({})
  const [detailStoreBranchByDate, setDetailStoreBranchByDate] = useState({
    axxisYBalance: [],
    axisX: [],
    axxisYCount: []
  })
  const [detailBalanceByDate, setDetailBalanceByDate] = useState({
    axxisYBalance: [],
    axisX: [],
    axxisYCount: []
  })
  const [detailPlatformByDate, setDetailPlatformByDate] = useState({
    axxisYBalance: [],
    axisX: [],
    axxisYCount: []
  })
  const [detailOrderEmployeeByDate, setDetailOrderEmployeeByDate] = useState({
    axxisYBalance: [],
    axisX: [],
    axxisYCount: []
  })
  const [detailPaymentByDate, setDetailPaymentByDate] = useState({
    axisY: [],
    axisX: []
  })
  const [detailStatusByDate, setDetailStatusByDate] = useState({
    axisY: [],
    axisX: []
  })
  const [hasSubmit, setHasSubmit] = useState(false)
  const formik = useFormik({
    initialValues: {},
    validationSchema: yup.object().shape({
      initialDate: yup
        .string()
        .typeError()
        .min(0)
        .required(getMessage('isRequired', getMessage('initialDate'))),
      finalDate: yup
        .string()
        .typeError()
        .nullable(false)
        .required(getMessage('isRequired', getMessage('finalDate'))),
      agroupBy: yup
        .string()
        .typeError()
        .required(getMessage('isRequired', getMessage('agroupBy')))
    }),
    onSubmit: async values => {
      const { initialDate, finalDate, agroupBy } = values
      if (!initialDate || !finalDate || !agroupBy) {
        alert.open('Todos os campos precisam ser preenchidos')
        return
      }
      setHasSubmit(true)
      setDetail({
        ...detail,
        isLoading: true
      })
      setDetailStoreBranchByDate({
        ...detailStoreBranchByDate,
        isLoading: true
      })
      setDetailPlatformByDate({
        ...detailPlatformByDate,
        isLoading: true
      })
      setDetailBalanceByDate({
        ...detailBalanceByDate,
        isLoading: true
      })
      setDetailOrderEmployeeByDate({
        ...detailOrderEmployeeByDate,
        isLoading: true
      })
      setDetailPaymentByDate({
        ...detailPaymentByDate,
        isLoading: true
      })
      setDetailStatusByDate({
        ...detailStatusByDate,
        isLoading: true
      })
      await requestDetailStoreBranchByDate()
      await requestDetailPlatformByDate()
      await requestDetailStatusByDate()
      await requestDetailPaymentByDate()
      await requestResume()
      await requestDetailBalanceByDate()
      await requestDetailOrderEmployeeByDate()
    }
  })
  const { values } = formik

  useEffect(() => {
    formik.setValues({
      initialDate: moment(),
      finalDate: moment(),
      agroupBy: types.DAY
    })
  }, [])

  async function requestResume() {
    const detail = await request(
      api.orderDashboardDetailByDate({
        initialDate: values.initialDate.format('YYYY-MM-DD'),
        finalDate: values.finalDate.format('YYYY-MM-DD'),
        status: values.agroupBy
      }),
      {
        skipLoading: true
      }
    )
    setDetail({
      ...detail,
      isLoading: false,
      totaOrder: detail.totaOrder !== null ? detail.totaOrder.replace('.', ',') : 0,
      ticket: detail.ticket !== null ? detail.ticket.replace('.', ',') : 0
    })
  }
  async function requestDetailBalanceByDate() {
    const detail = await request(
      api.orderDashboardDetailBalanceByDate({
        initialDate: values.initialDate.format('YYYY-MM-DD'),
        finalDate: values.finalDate.format('YYYY-MM-DD'),
        status: values.agroupBy
      }),
      {
        skipLoading: true
      }
    )
    const axisX = getGroupField('date', detail)
    const axxisYCount = getArrayGroupField('date', 'countOrders', detail, axisX)
    const axxisYBalance = getArrayGroupField('date', 'balanceOrders', detail, axisX)
    setDetailBalanceByDate({
      ...detailBalanceByDate,
      isLoading: false,
      axisX,
      axxisYCount: [
        {
          name: 'Quantidade pedidos',
          data: axxisYCount
        }
      ],
      axxisYBalance: [
        {
          name: 'Faturamento pedidos',
          data: axxisYBalance
        }
      ]
    })
  }
  async function requestDetailPlatformByDate() {
    const detail = await request(
      api.orderDashboardDetailPlatformByDate({
        initialDate: values.initialDate.format('YYYY-MM-DD'),
        finalDate: values.finalDate.format('YYYY-MM-DD'),
        status: values.agroupBy
      }),
      {
        skipLoading: true
      }
    )
    const axisX = getGroupField('date', detail)
    const groupPlatform = getGroupField('platform', detail)
    const axxisYCount = []
    const axxisYBalance = []
    groupPlatform.forEach(item => {
      const result = getArrayGroupField('date', 'countOrders', detail, axisX, 'platform', item)
      axxisYCount.push({
        name: item,
        data: result
      })
    })
    groupPlatform.forEach(item => {
      const result = getArrayGroupField('date', 'balanceOrders', detail, axisX, 'platform', item)
      axxisYBalance.push({
        name: item,
        data: result
      })
    })
    setDetailPlatformByDate({
      ...detailPlatformByDate,
      isLoading: false,
      axisX,
      axxisYCount: axxisYCount,
      axxisYBalance: axxisYBalance
    })
  }

  async function requestDetailStoreBranchByDate() {
    const detail = await request(
      api.orderDashboardDetailStoreBranchByDate({
        initialDate: values.initialDate.format('YYYY-MM-DD'),
        finalDate: values.finalDate.format('YYYY-MM-DD'),
        status: values.agroupBy
      }),
      {
        skipLoading: true
      }
    )
    const axisX = getGroupField('date', detail)
    const groupStoreBranch = getGroupField('storeBranch', detail)
    const axxisYCount = []
    const axxisYBalance = []
    groupStoreBranch.forEach(item => {
      const result = getArrayGroupField('date', 'countOrders', detail, axisX, 'storeBranch', item)
      axxisYCount.push({
        name: item,
        data: result
      })
    })
    groupStoreBranch.forEach(item => {
      const result = getArrayGroupField('date', 'balanceOrders', detail, axisX, 'storeBranch', item)
      axxisYBalance.push({
        name: item,
        data: result
      })
    })
    setDetailStoreBranchByDate({
      ...detailStoreBranchByDate,
      isLoading: false,
      axisX,
      axxisYCount: axxisYCount,
      axxisYBalance: axxisYBalance
    })
  }

  async function requestDetailOrderEmployeeByDate() {
    const detail = await request(
      api.orderDashboardDetailOrderEmployeeByDate({
        initialDate: values.initialDate.format('YYYY-MM-DD'),
        finalDate: values.finalDate.format('YYYY-MM-DD'),
        status: values.agroupBy
      }),
      {
        skipLoading: true
      }
    )
    const axisX = getGroupField('date', detail)
    const groupEmployee = getGroupField('employee', detail)
    const axxisYCount = []
    const axxisYBalance = []
    groupEmployee.forEach(item => {
      const result = getArrayGroupField('date', 'countOrders', detail, axisX, 'employee', item)
      axxisYCount.push({
        name: item,
        data: result
      })
    })
    groupEmployee.forEach(item => {
      const result = getArrayGroupField('date', 'balanceOrders', detail, axisX, 'employee', item)
      axxisYBalance.push({
        name: item,
        data: result
      })
    })
    setDetailOrderEmployeeByDate({
      ...detailOrderEmployeeByDate,
      isLoading: false,
      axisX,
      axxisYCount: axxisYCount,
      axxisYBalance: axxisYBalance
    })
  }
  async function requestDetailPaymentByDate() {
    const detail = await request(
      api.orderDashboardDetailPaymentByDate({
        initialDate: values.initialDate.format('YYYY-MM-DD'),
        finalDate: values.finalDate.format('YYYY-MM-DD'),
        status: values.agroupBy
      }),
      {
        skipLoading: true
      }
    )
    const axisX = getGroupField('name', detail)
    const result = []
    axisX.forEach(payment => {
      result.push(detail.find(item => item.name === payment).total)
    })
    setDetailPaymentByDate({
      ...detailPaymentByDate,
      isLoading: false,
      axisX,
      axisY: result
    })
    console.tron.log('axisX - pie', axisX)
    console.tron.log('axisY - pie', result)
  }

  async function requestDetailStatusByDate() {
    const detail = await request(
      api.orderDashboardDetailStatusByDate({
        initialDate: values.initialDate.format('YYYY-MM-DD'),
        finalDate: values.finalDate.format('YYYY-MM-DD'),
        status: values.agroupBy
      }),
      {
        skipLoading: true
      }
    )
    const axisX = getGroupField('name', detail)
    const result = []
    axisX.forEach(payment => {
      result.push(detail.find(item => item.name === payment).balanceOrders)
    })
    setDetailStatusByDate({
      isLoading: false,
      axisX,
      axisY: result
    })
  }

  return (
    <>
      <FormikProvider value={formik}>
        <Card>
          <Form layout={'vertical'} initialValues={{ layout: 'vertical' }}>
            <Row type="flex" justify="start">
              <CustomInput field="initialDate" rowSpan={8} type="date" context={formik} />
              <CustomInput field="finalDate" rowSpan={8} type="date" context={formik} />
              <CustomSelect
                context={formik}
                showSearch
                field="agroupBy"
                rowSpan={8}
                values={[
                  {
                    description: 'Dia',
                    value: types.DAY
                  },
                  {
                    description: 'Semana',
                    value: types.WEEK
                  },
                  {
                    description: 'Mês',
                    value: types.MONTH
                  },
                  {
                    description: 'Ano',
                    value: types.YEAR
                  }
                ]}
              />
              <Button type="primary" className="btn_new" onClick={formik.submitForm}>
                <span>{getMessage('filter')}</span>
              </Button>
            </Row>
          </Form>
        </Card>
        <If condition={hasSubmit}>
          <Row>
            <Col className="dashblock">
              <h2>{getMessage('resume')}</h2>
            </Col>
          </Row>
          <Row>
            <Col xl={5} lg={12} md={12} sm={12} xs={24} className="gx-col-full dashblock">
              <IconWithTextCard
                isLoading={detail.isLoading}
                icon="revenue-new"
                color="red"
                title={
                  <NumberFormat
                    prefix={'R$ '}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    decimalScale={2}
                    value={get(detail, 'totaOrder', 0) || 0}
                  />
                }
                subTitle={getMessage('totalBalance')}
              />
            </Col>
            <Col xl={5} lg={12} md={12} sm={12} xs={24} className="gx-col-full dashblock">
              <IconWithTextCard
                isLoading={detail.isLoading}
                icon="orders"
                color="red"
                title={get(detail, 'qtdOrders', 0) || 0}
                subTitle={getMessage('totalOrders')}
              />
            </Col>
            <Col xl={5} lg={12} md={12} sm={12} xs={24} className="gx-col-full dashblock">
              <IconWithTextCard
                isLoading={detail.isLoading}
                icon="growth"
                color="red"
                title={
                  <NumberFormat
                    prefix={'R$ '}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    decimalScale={2}
                    value={get(detail, 'ticket', 0) || 0}
                  />
                }
                subTitle={getMessage('mediumTicket')}
              />
            </Col>
            <Col xl={5} lg={12} md={12} sm={12} xs={24} className="gx-col-full dashblock">
              <IconWithTextCard
                isLoading={detail.isLoading}
                icon="user-o"
                color="red"
                title={get(detail, 'count', 0) || 0}
                subTitle={getMessage('totalCustomers')}
              />
            </Col>
            <Col xl={4} lg={12} md={12} sm={12} xs={24} className="gx-col-full dashblock">
              <IconWithTextCard
                isLoading={detail.isLoading}
                icon="shopping-cart"
                color="red"
                title={get(detail, 'qtdProducts', 0) || 0}
                subTitle={getMessage('totalProducts')}
              />
            </Col>
          </Row>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={24} className="gx-col-full dashblock">
              <Card>
                <BarChart
                  axisX="axisX"
                  axisY="axxisYCount"
                  data={detailBalanceByDate}
                  title={getMessage('totalOrders')}
                ></BarChart>
              </Card>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={24} className="gx-col-full dashblock">
              <Card>
                <BarChart
                  axisX="axisX"
                  axisY="axxisYBalance"
                  data={detailBalanceByDate}
                  title={getMessage('totalOrdersBalance')}
                ></BarChart>
              </Card>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gx-col-full dashblock">
              <Card>
                <LineChart
                  axisX="axisX"
                  axisY="axxisYCount"
                  data={detailOrderEmployeeByDate}
                  title={getMessage('totalOrdersByEmployee')}
                ></LineChart>
              </Card>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={24} className="gx-col-full dashblock">
              <Card>
                <LineChart
                  axisX="axisX"
                  axisY="axxisYBalance"
                  data={detailOrderEmployeeByDate}
                  title={getMessage('totalOrdersCountyBalance')}
                ></LineChart>
              </Card>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={24} className="gx-col-full dashblock">
              <Card>
                <PieChart
                  axisX="axisX"
                  axisY="axisY"
                  data={detailStatusByDate}
                  title={getMessage('totalOrderByStatus')}
                
                ></PieChart>
              </Card>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={24} className="gx-col-full dashblock">
              <Card>
                <PieChart
                  axisX="axisX"
                  axisY="axisY"
                  data={detailPaymentByDate}
                  title={getMessage('totalOrderPaymentMethod')}
                ></PieChart>
              </Card>
            </Col>

            <Col xl={12} lg={12} md={12} sm={12} xs={24} className="gx-col-full dashblock">
              <Card>
                <LineChart
                  axisX="axisX"
                  axisY="axxisYCount"
                  data={detailStoreBranchByDate}
                  title={getMessage('totalOrdersBalanceCounty')}
                ></LineChart>
              </Card>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={24} className="gx-col-full dashblock">
              <Card>
                <LineChart
                  axisX="axisX"
                  axisY="axxisYBalance"
                  data={detailStoreBranchByDate}
                  title={getMessage('totalOrdersCounty')}
                ></LineChart>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="dashblock">
              <h2>{getMessage('resumePerPlatform')}</h2>
            </Col>
          </Row>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={24} className="gx-col-full dashblock">
              <Card>
                <LineChart
                  axisX="axisX"
                  axisY="axxisYCount"
                  data={detailPlatformByDate}
                  title={getMessage('totalOrdersPlatform')}
                ></LineChart>
              </Card>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={24} className="gx-col-full dashblock">
              <Card>
                <LineChart
                  axisX="axisX"
                  axisY="axxisYBalance"
                  data={detailPlatformByDate}
                  title={getMessage('totalOrdersPlatformBalance')}
                ></LineChart>
              </Card>
            </Col>
          </Row>
        </If>
      </FormikProvider>
    </>
  )
}

export default DashboardOrders
