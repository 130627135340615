import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { NAV_STYLE_FIXED } from '../constants/ThemeSetting'
import * as settingsActions from '../appRedux/actions/Setting'
import AdminRoutes from '../containers/App'
import Login from '../containers/Login/login'
import _ from 'lodash'

const Routes = function() {
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth.auth)
  useEffect(() => {
    dispatch(settingsActions.onNavStyleChange(NAV_STYLE_FIXED))
  }, [])
  return (
    <Switch>
      {!_.get(auth, 'user.token') && <Route path="/" component={Login} />}
      {_.get(auth, 'user.token') && <Route path="/" component={AdminRoutes} />}
    </Switch>
  )
}

export default Routes
