import React, { useState, useEffect } from 'react'
import { Row } from 'antd'
import { useFormik } from 'formik'
import { get } from 'lodash'

import ListScreen from '../../components/ListScreen'
import { CustomSelect } from '../../components'
import { api, request } from '../../util/handlerRequestUtil'

function ListMessages(props) {
  const { alert, getMessage } = props
  const formik = useFormik({
    initialValues: {}
  })
  const [modalDelete, setModalDelete] = useState({
    description: getMessage('defaultDeleteDescription'),
    visible: false
  })
  const { values, setFieldValue } = formik
  const [data, setData] = useState({})
  const [filters, setFilters] = useState({})
  const { page = 1, count = 0, pages = 0 } = data
  const messages = get(data, 'items', []).map(message => ({
    ...message,
    templateMessageDescription: message.templateMessage.description
  }))

  const columns = [
    {
      title: getMessage('sidebar.messages'),
      dataIndex: 'message',
      key: 'message'
    },
    {
      title: getMessage('sidebar.template-messages'),
      dataIndex: 'templateMessageDescription',
      key: 'templateMessage'
    }
    // cli-columns
  ]

  useEffect(() => {
    requests()
  }, [])

  useEffect(() => {
    if (values.state) {
      setFieldValue('city', undefined)
    }
  }, [values.state])

  //requests
  async function requests() {
    try {
      // cli-request-function
      await requestData()
      const formatedTemplateMessage = await requestTemplateMessage()

      setFilters(f => ({
        ...f,
        ...formatedTemplateMessage
      }))
    } catch (error) {
      console.tron.log(error)
    }
  }

  function onClickEdit(message) {
    props.history.push(`/messages/${message.id}`)
  }

  async function onClickDelete(message) {
    setModalDelete({
      ...modalDelete,
      title: getMessage('info.delete'),
      visible: true,
      onOk: async () => onConfirmDelete(message)
    })
  }

  async function onConfirmDelete(message) {
    alert.open(getMessage('successDelete'), 'success')
    await request(api.deleteMessage(message.id))
    setModalDelete({
      ...modalDelete,
      visible: false
    })
    await requestData(values)
  }

  function onClickView(message) {
    props.history.push(`/messages/${message.id}?readOnly=true`)
  }

  function onClickDuplicate(message) {
    props.history.push(`/messages/create?duplicate=${message.id}`)
  }

  const requestData = (params = {}) => request(api.listMessages({ ...params })).then(data => setData(data))

  const requestTemplateMessage = () =>
    request(
      api.listTemplateMessages({
        ignorePagination: true
      }),
      true
    ).then(({ items }) => {
      return {
        formatedTemplateMessage: items.map(item => {
          return {
            ...item,
            value: item.id,
            description: item.description
          }
        })
      }
    })

  const handleFilter = query => {
    const { message, templateId } = values

    const filters = {
      ...(message && { message }),
      ...(templateId && { templateId })
    }

    if (query && query.trim() !== '') {
      filters.message = query.trim()
    }

    requestData({
      ...filters
    })
  }

  const onSearch = value => handleFilter(value)

  async function requestPage(page) {
    await requestData({ page: page - 1 })
  }

  const onChangeCreateButton = () => {
    props.history.push('/messages/create')
  }

  function filtersList() {
    return (
      <Row type="flex" justify="start">
        <CustomSelect
          context={formik}
          showSearch
          field="templateId"
          rowSpan={12}
          values={filters.formatedTemplateMessage}
        />
      </Row>
    )
  }

  const validFieldsKeys = []

  return (
    <ListScreen
      context={formik}
      columns={columns}
      data={messages}
      title={getMessage('sidebar.messages')}
      titleButtonAdd={getMessage('new')}
      onSearch={onSearch}
      filtersList={filtersList}
      validFieldsKeys={validFieldsKeys}
      dataFilters={filters}
      onChange={requestPage}
      onChangeCreateButton={onChangeCreateButton}
      searchPlaceholder={getMessage('message')}
      pagination={{
        currentPage: page + 1,
        pages: pages,
        total: count
      }}
      onFilter={handleFilter}
      onClickDelete={onClickDelete}
      modalDelete={modalDelete}
      setModalDelete={setModalDelete}
      onClickEdit={onClickEdit}
      onClickView={onClickView}
      onClickDuplicate={onClickDuplicate}
    />
  )
}

export default ListMessages
