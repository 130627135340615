import React, { useState, useEffect } from 'react'
import { Row } from 'antd'
import { useFormik } from 'formik'
import CreateScreen from '../../components/CreateScreen'
import CustomInput from '../../components/CustomInput/customInput'
import queryString from 'query-string'
import { api, request } from '../../util/handlerRequestUtil'
import './createAttributeItem.css'
import * as yup from 'yup'

function CreateAttributeItem(props) {
  const { id } = props.match.params
  const { alert, loading, getMessage } = props
  const queryParams = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true
  })
  const attributeId = queryParams.attributeId ? Number(queryParams.attributeId) : undefined
  const isDuplicate = queryParams.duplicate
  const formik = useFormik({
    initialValues: {},
    validationSchema: yup.object().shape({
      // cli-yup-declaration
      name: yup
        .string()
        .typeError()
        .required(getMessage('isRequired', getMessage('name')))
    }),
    onSubmit: values => {
      let formatedValues = { ...values }

      onSubmit(formatedValues)
    }
  })
  const { setValues } = formik
  const [setFilters] = useState({})

  useEffect(() => {
    requests()
  }, [])

  //requests
  async function requests() {
    try {
      loading.open()

      // cli-request-function
      if (id) {
        await retrieve(id)
      }
      if (isDuplicate) {
        await retrieve(isDuplicate, true)
      }
      setFilters({
        // cli-request-filter
      })
    } catch (error) {
      console.tron.log(error)
    } finally {
      loading.close()
    }
  }

  // cli-requests

  function fields(isReadOnly) {
    return (
      <Row type="flex" justify="start">
        <CustomInput disabled={isReadOnly} field="name" rowSpan={12} context={formik} />

        {/* cli-fields */}
      </Row>
    )
  }

  async function onSubmit(values) {
    try {
      if (id) {
        await request(api.updateAttributeItem(id, { ...values, attributeId }))
        alert.open(getMessage('successUpdateAttributeItem'), 'success')
      } else {
        await request(api.storeAttributeItem({ ...values, attributeId }))
        alert.open(getMessage('successStoreAttributeItem'), 'success')
      }

      props.history.push(`/AttributeItem/list?attributeId=${attributeId}`)
    } catch (error) {}
  }

  async function retrieve(id, isDuplicate) {
    try {
      const response = await request(api.retrieveAttributeItem(id), true)
      if (isDuplicate) {
        delete response.id
      }
      setValues({
        ...response,
        attribute: response.attributeId
        // cli-retrieve-state
      })
    } catch (error) {}
  }

  function onClickBack() {
    props.history.push(`/AttributeItem/list?attributeId=${attributeId}`)
  }

  return (
    <CreateScreen
      fields={fields}
      context={formik}
      editTitle={getMessage('updateAttributeItem')}
      editTitleButton={getMessage('edit')}
      newTitle={getMessage('newAttributeItem')}
      newTitleButton={getMessage('register')}
      onClickBack={() => onClickBack()}
      readOnlyTitle={getMessage('viewAttributeItem')}
      readOnlyTitleButton={getMessage('edit')}
      isEdit={id}
    />
  )
}

export default CreateAttributeItem
