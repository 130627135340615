import React, { useState, useEffect } from 'react'
import { Row, Radio } from 'antd'
import { useFormik } from 'formik'
import { get } from 'lodash'

import ListScreen from '../../components/ListScreen'
import { CustomInput, CustomSelect, CustomRadioGroup } from '../../components'
import { api, request } from '../../util/handlerRequestUtil'
import { maskCpfCnpj, onlyNumbers } from '../../util/format'

import './listPersons.css'

function ListPersons(props) {
  const { alert, getMessage } = props
  const formik = useFormik({
    initialValues: {}
  })
  const [modalDelete, setModalDelete] = useState({
    description: getMessage('defaultDeleteDescription'),
    visible: false
  })
  const { values, setFieldValue } = formik
  const [data, setData] = useState({})
  const [filters, setFilters] = useState({})
  const { page = 1, count = 0, pages = 0 } = data
  const people = get(data, 'items', []).map(p => {
    return {
      key: p.id,
      name: p.name,
      document: maskCpfCnpj(p.document),
      city: get(p, 'addresses[0].city.cityName', '-'),
      contact: get(p, 'contacts[0].value', '-')
    }
  })
  const columns = [
    {
      title: getMessage('name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: getMessage('document'),
      dataIndex: 'document',
      key: 'document'
    },
    {
      title: getMessage('city'),
      dataIndex: 'city',
      key: 'city'
    },
    {
      title: getMessage('contact'),
      dataIndex: 'contact',
      key: 'contact'
    }
    // cli-columns
  ]

  useEffect(() => {
    requests()
  }, [])

  useEffect(() => {
    if (values.state) {
      setFieldValue('city', undefined)
      requestCity(values.state)
    }
  }, [values.state])

  //requests
  async function requests() {
    try {
      // cli-request-function
      await requestData()
      await requestState(true)
      await requestOrigin(true)

      setFilters(f => ({
        ...f,
        ...{
          formatedType: [
            { value: 'CUSTOMER', description: getMessage('customer') },
            { value: 'PROVIDER', description: getMessage('provider') }
          ]
        }
      }))
    } catch (error) {
      console.tron.log(error)
    }
  }

  async function requestState(skip) {
    try {
      const states = await request(api.listState({ ignorePagination: true }), skip)
      const format = get(states, 'items', []).map(s => ({
        value: s.stateAbbreviation,
        description: s.stateName
      }))

      setFilters(f => ({
        ...f,
        ...{ formatedState: format }
      }))
    } catch (error) {
      console.tron.log(error)
    }
  }

  async function requestCity(state, skip) {
    try {
      const cities = await request(api.listCity({ ignorePagination: true, stateAbbreviation: state }), skip)
      const format = get(cities, 'items', []).map(c => ({
        value: c.id,
        description: c.cityName
      }))

      setFilters(f => ({
        ...f,
        ...{ formatedCity: format }
      }))
    } catch (error) {
      console.tron.log(error)
    }
  }

  async function requestOrigin(skip) {
    try {
      const origin = await request(api.listOrigin({ ignorePagination: true }), skip)
      const format = origin.map(c => ({
        value: c.origin,
        description: c.origin
      }))

      setFilters(f => ({
        ...f,
        ...{ formatedOrigin: format }
      }))
    } catch (error) {
      console.tron.log(error)
    }
  }

  function onClickEdit(person) {
    props.history.push(`/person/${person.key}`)
  }

  async function onClickDelete(person) {
    setModalDelete({
      ...modalDelete,
      title: getMessage('deleteTitle', person.name),
      visible: true,
      onOk: async () => onConfirmDelete(person)
    })
  }

  async function onConfirmDelete(person) {
    alert.open(getMessage('successDelete'), 'success')
    await request(api.deletePerson(person.key))
    setModalDelete({
      ...modalDelete,
      visible: false
    })
    await requestData(values)
  }

  function onClickView(person) {
    props.history.push(`/person/${person.key}?readOnly=true`)
  }

  function onClickDuplicate(person) {
    props.history.push(`/person/create?duplicate=${person.key}`)
  }

  const requestData = (params = {}) => request(api.listPerson({ ...params })).then(data => setData(data))

  const handleFilter = value => {
    const {
      birth,
      type,
      stateRegistration,
      linkedin,
      instagram,
      facebook,
      gender,
      email,
      document,
      city,
      origin,
      state
    } = values

    const filters = {
      ...(birth && { birth: new Date(birth).toISOString() }),
      ...(type && { type }),
      ...(stateRegistration && { stateRegistration }),
      ...(linkedin && { linkedin }),
      ...(instagram && { instagram }),
      ...(facebook && { facebook }),
      ...(gender && { gender }),
      ...(email && { email }),
      ...(document && { document: onlyNumbers(document) }),
      ...(city && { city: Number(city) }),
      ...(origin && { origin }),
      ...(state && { state })
    }

    // cli-handle-filters
    if (value && value.trim() !== '') {
      filters.name = value
    }
    requestData({
      ...filters
    })
  }

  const onSearch = value => handleFilter(value)

  async function requestPage(page) {
    await requestData({ page: page - 1 })
  }

  const onChangeCreateButton = () => {
    props.history.push('/person/create')
  }

  function filtersList() {
    return (
      <Row type="flex" justify="start">
        <CustomSelect context={formik} field="type" rowSpan={12} values={filters.formatedType} />
        <CustomInput context={formik} field="email" rowSpan={12} />
        <CustomInput context={formik} field="birth" rowSpan={12} type="date" />
        <CustomRadioGroup context={formik} field="gender" span={12} className="">
          <Radio value="MALE">{getMessage('male')}</Radio>
          <Radio value="FEMALE">{getMessage('female')}</Radio>
        </CustomRadioGroup>
        <CustomInput context={formik} field="instagram" rowSpan={12} />
        <CustomInput context={formik} field="facebook" rowSpan={12} />
        <CustomInput context={formik} field="linkedin" rowSpan={12} />
        <CustomInput context={formik} field="document" rowSpan={12} />
        <CustomInput context={formik} field="stateRegistration" rowSpan={12} />
        <CustomSelect context={formik} field="origin" rowSpan={12} values={filters.formatedOrigin} />
        <CustomSelect context={formik} field="state" rowSpan={12} values={filters.formatedState} />
        <CustomSelect
          context={formik}
          field="city"
          rowSpan={12}
          values={filters.formatedCity}
          disabled={!values.state}
        />
      </Row>
    )
  }

  const validFieldsKeys = [
    'type',
    'email',
    'birth',
    'gender',
    'instagram',
    'facebook',
    'linkedin',
    'document',
    'stateRegistration',
    'origin',
    'state',
    'city'
  ]

  return (
    <ListScreen
      context={formik}
      columns={columns}
      data={people}
      title={getMessage('persons')}
      titleButtonAdd={getMessage('newPerson')}
      onSearch={onSearch}
      filtersList={filtersList}
      validFieldsKeys={validFieldsKeys}
      dataFilters={filters}
      onChange={requestPage}
      onChangeCreateButton={onChangeCreateButton}
      pagination={{
        currentPage: page + 1,
        pages: pages,
        total: count
      }}
      onFilter={handleFilter}
      onClickDelete={onClickDelete}
      modalDelete={modalDelete}
      setModalDelete={setModalDelete}
      onClickEdit={onClickEdit}
      onClickView={onClickView}
      onClickDuplicate={onClickDuplicate}
    />
  )
}

export default ListPersons
