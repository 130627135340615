export const Types = {
  OPEN_SYNC_DRAWER: 'platform_sync/OPEN_SYNC_DRAWER',
  CLOSE_SYNC_DRAWER: 'platform_sync/CLOSE_SYNC_DRAWER'
}

const initialState = {
  synchDrawerVisible: false
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.OPEN_SYNC_DRAWER:
      return { ...state, synchDrawerVisible: true }
    case Types.CLOSE_SYNC_DRAWER:
      return { ...state, synchDrawerVisible: false }
    default:
      return state
  }
}

export const Creators = {
  openSyncDrawer: () => ({
    type: Types.OPEN_SYNC_DRAWER
  }),
  closeSyncDrawer: () => ({
    type: Types.CLOSE_SYNC_DRAWER
  })
}
